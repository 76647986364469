import styled from 'styled-components';
import { CRGText } from '../CRGText';
import PropTypes from 'prop-types';

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid
    ${(props) =>
      props.hideBorder ? 'transparent' : props.theme.palette.main.lightTissue};
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 3px solid
    ${(props) =>
      props.hideBorder ? 'transparent' : props.theme.palette.main.lightTissue};
`;

const CRGMobileList = ({ data, headers, rowAction, maxColumnSize }) => {
  return (
    <ListContainer>
      {data.map((i, idx) => {
        return (
          <div key={idx}>
            {headers.map((h, hIdx) => {
              return (
                <CardRow key={hIdx}>
                  <CRGText
                    fontSize={12}
                    fontWeight={700}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {h.label}
                  </CRGText>
                  <CRGText
                    fontSize={12}
                    fontWeight={400}
                    colorVariant={'neutralDarkBlack'}
                    ellipsisAfter={maxColumnSize}
                    textAlign={'right'}
                  >
                    {i[h.key]}
                  </CRGText>
                </CardRow>
              );
            })}
            {rowAction ? (
              <CardRow hideBorder>
                <div></div>
                <div>{rowAction(i)}</div>
              </CardRow>
            ) : null}
          </div>
        );
      })}
    </ListContainer>
  );
};

CRGMobileList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowAction: PropTypes.func,
  handleClick: PropTypes.func,
  maxColumnSize: PropTypes.number,
};

export { CRGMobileList };
