import styled from 'styled-components';
const ArrowsStyle = styled.div`
  display: flex;
  gap: 2px;
`;
const CRGOrderByArrow = ({ orderByDirection }) => {
  if (orderByDirection === 'ASC') {
    return (
      <ArrowsStyle>
        <img src={'/images/table/grey_up.svg'} />
        <img src={'/images/table/black_down.svg'} />
      </ArrowsStyle>
    );
  } else if (orderByDirection === 'DESC') {
    return (
      <ArrowsStyle>
        <img src={'/images/table/black_up.svg'} />
        <img src={'/images/table/grey_down.svg'} />
      </ArrowsStyle>
    );
  } else {
    return (
      <ArrowsStyle>
        <img src={'/images/table/grey_up.svg'} />
        <img src={'/images/table/grey_down.svg'} />
      </ArrowsStyle>
    );
  }
};

CRGOrderByArrow.propTypes = {
  orderByDirection: 'ASC' | 'DESC' | null,
};

export { CRGOrderByArrow };
