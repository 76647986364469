import styled from 'styled-components';
import { CRGButton } from './CRGButton';
import PropTypes from 'prop-types';
import { CRGTooltip } from './CRGTooltip';
import { useCustomIntl } from 'hooks';
import translation from 'utils/translation';

const InfoContainer = styled(CRGButton)`
  display: flex;
  width: 46px;
  height: 46px;
  box-shadow: 0px 3px 6px #00000029;
`;

const CRGInfoButton = ({ infoUrl }) => {
  //i18n
  const intl = useCustomIntl();
  const { infoButton } = translation.components(intl).tooltip;
  return (
    <CRGTooltip title={infoButton}>
      <div>
        <InfoContainer
          customHeight={'46px'}
          customWidth={'46px'}
          onClick={() => {
            window.open(infoUrl, '_blank');
          }}
          colorVariant={'lightWhite'}
        >
          <img width={'24px'} height={'24px'} src="/images/info_icon.svg" />
        </InfoContainer>
      </div>
    </CRGTooltip>
  );
};

CRGInfoButton.propTypes = {
  infoUrl: PropTypes.string,
};

export { CRGInfoButton };
