import styled from 'styled-components';
import PropTypes from 'prop-types';

import { CRGText, CRGPagination } from 'components/atoms';
import { CRGDesktopTableHeaderCell } from './CRGDesktopTableHeaderCell';
import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const HeaderRow = styled.tr`
  background: ${(props) => props.theme.palette.main.lightTissue};
  height: 50px;
`;

const TableStyle = styled.table`
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;
`;

const TableContainer = styled.div`
  padding-bottom: 20px;
  width: fit-content;
  min-width: 97%;
  max-width: 97%;
`;

const DataRow = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.palette.main.lightTissue};
  height: 50px;
  width: 100% !important;
`;

const DataCell = styled.td`
  padding-left: 15px;
  padding-right: 15px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  margin-top: 20px;
`;

const HeadInfoContainer = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const WhiteContainer = styled.div`
  background-color: white;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  min-width: 100%;
`;

const EmptyResultContainer = styled.div`
  padding-bottom: 20px;
  width: 88vw;
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 85vw;
  }
`;

const CRGDesktopTable = ({
  headers,
  data,
  title,
  customActions,
  rowAction,
  handleClick,
  pagination,
  maxColumnSize,
  currentOrder,
  handleOrderChange,
}) => {
  // i18n

  const intl = useCustomIntl();
  const i18n = translation.components(intl).desktopTable;

  return (
    <TableContainer>
      <WhiteContainer>
        {title ? (
          <HeadInfoContainer>
            <CRGText
              fontSize={16}
              fontWeight={600}
              colorVariant={'neutralDarkBlack'}
            >
              {title}
            </CRGText>
            {customActions}
          </HeadInfoContainer>
        ) : null}

        {data?.length ? (
          <TableStyle>
            <thead>
              <HeaderRow>
                {headers.map((h) => {
                  return (
                    <CRGDesktopTableHeaderCell
                      key={h?.key}
                      orderKey={h?.key}
                      currentOrder={currentOrder}
                      handleOrderChange={handleOrderChange}
                      hideOrder={h?.hideOrder}
                    >
                      {h.component || (
                        <CRGText
                          fontSize={13}
                          fontWeight={600}
                          colorVariant={'neutralDarkBlack'}
                          ellipsisAfter={h?.forceColumnSize || maxColumnSize}
                        >
                          {h.label}
                        </CRGText>
                      )}
                    </CRGDesktopTableHeaderCell>
                  );
                })}
                {rowAction ? <CRGDesktopTableHeaderCell /> : null}
              </HeaderRow>
            </thead>
            <tbody>
              {data.map((row, rowIdx) => {
                return (
                  <DataRow key={rowIdx}>
                    {headers.map((h, idx) => {
                      return (
                        <DataCell key={idx}>
                          <CRGText
                            fontSize={14}
                            fontWeight={400}
                            colorVariant={'neutralDarkBlack'}
                            ellipsisAfter={h?.forceColumnSize || maxColumnSize}
                            wordBreak={true}
                            onClick={() => handleClick && handleClick(row)}
                          >
                            {handleClick ? (
                              <span style={{ cursor: 'pointer' }}>
                                {row?.[h?.key]}
                              </span>
                            ) : (
                              row?.[h?.key]
                            )}
                          </CRGText>
                        </DataCell>
                      );
                    })}
                    {rowAction ? <DataCell> {rowAction(row)} </DataCell> : null}
                  </DataRow>
                );
              })}
            </tbody>
          </TableStyle>
        ) : (
          <EmptyResultContainer>
            <CRGText
              fontSize={14}
              fontWeight={400}
              colorVariant={'neutralDarkBlack'}
            >
              {i18n.emptyLabel}
            </CRGText>
          </EmptyResultContainer>
        )}
      </WhiteContainer>
      {pagination && data?.length ? (
        <PaginationContainer>
          <CRGPagination {...pagination} />
        </PaginationContainer>
      ) : null}
    </TableContainer>
  );
};

CRGDesktopTable.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  title: PropTypes.string,
  customActions: PropTypes.node,
  rowAction: PropTypes.func,
  handleClick: PropTypes.func,
  pagination: PropTypes.obj,
  maxColumnSize: PropTypes.string,
  currentOrder: PropTypes.obj,
  handleOrderChange: PropTypes.func,
};

export { CRGDesktopTable };
