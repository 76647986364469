import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Popper } from '@mui/material';

const CRGPopperStyled = styled(Popper)`
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 10px !important;
  box-shadow: ${(props) => {
    switch (props.colorVariant) {
      case 'secondaryBordered':
      case 'fullSecondary':
        return '0px 3px 6px #00000029';
      case 'fullWhite':
        return '0px 5px 15px #00000029';

      default:
        return '0px 3px 6px #00000029';
    }
  }};
  border: 2px solid
    ${(props) => {
      switch (props.colorVariant) {
        case 'secondaryBordered':
          return props.theme.palette.main.secondary;
        case 'fullSecondary':
          return props.theme.palette.main.secondary;
        case 'fullWhite':
          return props.theme.palette.common.white;

        default:
          return props.theme.palette.main.secondary;
      }
    }};
  background: ${(props) => {
    switch (props.colorVariant) {
      case 'secondaryBordered':
        return props.theme.palette.neutral.light.white;
      case 'fullSecondary':
        return props.theme.palette.main.secondary;
      case 'fullWhite':
        return props.theme.palette.common.white;

      default:
        return props.theme.palette.neutral.light.white;
    }
  }};
  border-radius: 5px;
`;

/**
 * @param {string} colorVariant           Define the popper color. Value accepted: secondaryBordered, fullSecondary
 */

const CRGPopper = (props) => {
  return <CRGPopperStyled {...props}>{props.children}</CRGPopperStyled>;
};

CRGPopper.propTypes = {
  children: PropTypes.node,
  colorVariant: PropTypes.oneOf(['secondaryBordered', 'fullSecondary']),
};

export { CRGPopper };
