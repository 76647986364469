import { useContext, useState } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import { Menu, NotificationPopper, UserInfo } from 'components/bundle';
import { MenuContext, UserContext } from 'contexts';
import { CRGBadge } from 'components/atoms';

const drawerWidth = 240;

const ContainerStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
`;

const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TopBannerStyle = styled.div`
  height: 85px;
  width: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    height: 70px;
  }
`;

const BurgerMenuIcon = styled.img`
  box-sizing: content-box;
  width: 25px;
  padding: 20px;
  cursor: pointer;
`;

const ChildrenContainerStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  paddingRight: theme.spacing(1),
  backgroundColor: theme.palette.backgrounds.mainLayout,
  height: 'calc(100vh - 85px)',
  width: '100%',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: `-${drawerWidth}`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    margiRight: -240,
  }),
  [theme.breakpoints.down('sm')]: {
    paddingRight: theme.spacing(0),
  },
}));

const MobileMenuButtonBackground = styled.div`
  z-index: 1201;
  background-color: ${(props) => props.theme.palette.backgrounds.burgerMenu};
  height: 70px;
  width: 20vw;
  margin-left: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImageStyle = styled.img`
  height: 50px;
  padding-top: 10px;
`;

const MobileTopBannerLeftContent = styled.div`
  display: flex;
  gap: 10px;
  align-content: center;
  justify-content: center;
`;

const UserActionsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-content: center;
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NotificationIcon = styled.img`
  box-sizing: content-box;
  cursor: pointer;
`;

const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`;

/**
 * @param {string} imageSrc           Source of the image to show
 * @param {node} bottomElement        Node to show at the bottom of image container
 */

const CRGMainLayout = ({ children }) => {
  // Hooks
  const navigate = useNavigate();

  // State
  const { isMenuExpanded, setIsMenuExpanded } = useContext(MenuContext);
  const { badgeCount } = useContext(UserContext);

  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Refs
  const [anchorEl, setAnchorEl] = useState();
  const [arrowRef, setArrowRef] = useState(null);

  const [isNotificationPopperOpen, setIsNotificationPopperOpen] =
    useState(null);

  const handleClick = (event) => {
    if (isMobile) {
      navigate('/notifications');
    } else {
      const isOpen = !isNotificationPopperOpen;
      setAnchorEl(isOpen ? event?.currentTarget : null);
      setIsNotificationPopperOpen(isOpen);
    }
  };

  return (
    <ContainerStyle>
      <Menu
        isMenuExpanded={isMenuExpanded}
        onClose={() => setIsMenuExpanded(false)}
      />
      <Main open={isMenuExpanded}>
        <TopBannerStyle>
          {isMobile ? (
            <MobileTopBannerLeftContent>
              {isMenuExpanded ? (
                <MobileMenuButtonBackground
                  onClick={() => setIsMenuExpanded(false)}
                >
                  <img src={'/images/close_white.svg'} />
                </MobileMenuButtonBackground>
              ) : (
                <MobileMenuButtonBackground
                  onClick={() => {
                    setIsMenuExpanded(!isMenuExpanded);
                  }}
                >
                  <BurgerMenuIcon src={'/images/burger_white.svg'} />
                </MobileMenuButtonBackground>
              )}
              <LogoImageStyle src={'/images/logo.svg'} />
            </MobileTopBannerLeftContent>
          ) : (
            <BurgerMenuIcon
              src={'/images/burger.svg'}
              onClick={() => {
                setIsMenuExpanded(!isMenuExpanded);
              }}
            />
          )}
          <UserActionsContainer>
            <UserInfoContainer>
              {isMobile ? null : <UserInfo />}
            </UserInfoContainer>

            <NotificationContainer onClick={handleClick}>
              <CRGBadge
                badgeContent={badgeCount}
                color="error"
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <NotificationIcon src={'/images/notifications_ring.svg'} />
              </CRGBadge>
            </NotificationContainer>
          </UserActionsContainer>
        </TopBannerStyle>
        <ChildrenContainerStyle isMenuExpanded={isMenuExpanded}>
          {children}
        </ChildrenContainerStyle>
      </Main>
      <NotificationPopper
        isNotificationPopperOpen={isNotificationPopperOpen}
        setIsNotificationPopperOpen={setIsNotificationPopperOpen}
        anchorEl={anchorEl}
        arrowRef={arrowRef}
        setArrowRef={setArrowRef}
      />
    </ContainerStyle>
  );
};

CRGMainLayout.propTypes = {
  children: PropTypes.node,
  imageSrc: PropTypes.string,
  bottomElement: PropTypes.node,
};

export { CRGMainLayout };
