import { useState, useEffect } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CRGButton, CRGPopper, CRGText } from 'components/atoms';

import { safeNum } from 'utils/utils';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

import dayjs from 'dayjs';

const ContainerStyle = styled.div`
  background: ${(props) => props.theme.palette.neutral.light.white};
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 45px;
  padding-right: 45px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    gap: 10px;
    padding: 10px;
    width: 85%;
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

const StockDetailDashboard = ({ data, refWidth }) => {
  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).stockDetailDashboard;

  // State
  const [isEmailPopperOpen, setIsEmailPopperOpen] = useState();
  const [emailPopperAnchorEl, setEmailAnchorEl] = useState();
  const [dashboardWidth, setDashboardWidth] = useState();

  // Functions
  const handleToggleEmailPopper = (event) => {
    setEmailAnchorEl(event.target);
    setIsEmailPopperOpen(!isEmailPopperOpen);
  };

  useEffect(() => {
    if (refWidth) setDashboardWidth(refWidth);
  }, [refWidth]);

  return (
    <ContainerStyle style={{ width: `${dashboardWidth}px` }}>
      <Row>
        <Cell>
          <CRGText
            fontSize={18}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {data?.stockId || '-'}
          </CRGText>
          <CRGText
            fontSize={14}
            fontWeight={400}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.requestIdLabel}
          </CRGText>
        </Cell>

        <Cell>
          <CRGText
            fontSize={18}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {data?.status || '-'}
          </CRGText>
          <CRGText
            fontSize={14}
            fontWeight={400}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.statusLabel}
          </CRGText>
        </Cell>

        <Cell>
          <CRGText
            fontSize={18}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {data?.customerId || '-'}
          </CRGText>
          <CRGText
            fontSize={14}
            fontWeight={400}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.customerIdLabel}
          </CRGText>
        </Cell>

        <Cell>
          <CRGText
            fontSize={18}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {data?.email?.length > 0 ? (
              <CRGButton
                colorVariant={'mainSecondary'}
                customHeight={'25px'}
                customWidth={'40px'}
                customFontSize={'10px'}
                onClick={handleToggleEmailPopper}
              >
                +{safeNum(data?.email?.split(';').length)} Email
              </CRGButton>
            ) : (
              '-'
            )}
          </CRGText>
          <CRGText
            fontSize={14}
            fontWeight={400}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.emailLabel}
          </CRGText>
        </Cell>

        <Cell>
          <CRGText
            fontSize={18}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {data?.agent?.name || '-'}
          </CRGText>
          <CRGText
            fontSize={14}
            fontWeight={400}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.agentLabel}
          </CRGText>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <CRGText
            fontSize={18}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {data?.requestDate
              ? dayjs(data?.requestDate).format('DD/MM/YYYY HH:mm')
              : '-'}
          </CRGText>
          <CRGText
            fontSize={14}
            fontWeight={400}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.dateTimeRequestLabel}
          </CRGText>
        </Cell>

        <Cell>
          <CRGText
            fontSize={18}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
            ellipsisAfter={'150px'}
          >
            {[data?.user?.name, data?.user?.surname].join(' ')}
          </CRGText>
          <CRGText
            fontSize={14}
            fontWeight={400}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.nameSurnameLabel}
          </CRGText>
        </Cell>

        <Cell>
          <CRGText
            fontSize={18}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {data?.user?.company || '-'}
          </CRGText>
          <CRGText
            fontSize={14}
            fontWeight={400}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.companyLabel}
          </CRGText>
        </Cell>

        <Cell>
          <CRGText
            fontSize={18}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {data?.processingDate
              ? dayjs(data?.processingDate).format('DD/MM/YYYY HH:mm')
              : '-'}
          </CRGText>
          <CRGText
            fontSize={14}
            fontWeight={400}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.dateTimeProcessingLabel}
          </CRGText>
        </Cell>

        <Cell>
          <CRGText
            fontSize={18}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {data?.agentId || '-'}
          </CRGText>
          <CRGText
            fontSize={14}
            fontWeight={400}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.agentIdLabel}
          </CRGText>
        </Cell>
      </Row>

      {data?.email?.length ? (
        <CRGPopper
          id={`popper`}
          open={isEmailPopperOpen}
          disablePortal={false}
          anchorEl={emailPopperAnchorEl}
          style={{ zIndex: 10, width: 200, height: 150 }}
          placement="bottom-start"
          colorVariant={'fullSecondary'}
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window',
            },
          }}
        >
          <EmailContainer>
            {data?.email?.split?.(';')?.map((e, idx) => {
              return (
                <CRGText
                  colorVariant={'neutralLigthWhite'}
                  fontSize={12}
                  fontWeight={400}
                  key={idx}
                >
                  {e}
                </CRGText>
              );
            })}
          </EmailContainer>
        </CRGPopper>
      ) : null}
    </ContainerStyle>
  );
};

StockDetailDashboard.propTypes = {
  data: PropTypes.object,
  refWidth: PropTypes.object,
};

export { StockDetailDashboard };
