import { useCustomIntl } from 'hooks/useCustomIntl';
import {
  orderConfirmation,
  orderProgress,
  orderStatus,
  orderType,
} from 'shared';
import translation from 'utils/translation';

const useMapping = () => {
  // Hooks
  const intl = useCustomIntl();
  const i18n = translation.components(intl).useMapping;
  const i18nOrders = translation.global(intl).app.orders;

  // Constants
  const userStatusList = {
    0: i18n.userStatusList.status0,
    1: i18n.userStatusList.status1,
    2: i18n.userStatusList.status2,
    3: i18n.userStatusList.status3,
    4: i18n.userStatusList.status4,
    5: i18n.userStatusList.status5,
  };

  const userCountryList = {
    0: i18n.userCountryList.country0,
    1: i18n.userCountryList.country1,
  };

  const userLanguageList = {
    0: i18n.userLanguageList.language0,
    1: i18n.userLanguageList.language1,
  };

  const userTypeList = {
    0: i18n.userTypeList.type0,
    1: i18n.userTypeList.type1,
    2: i18n.userTypeList.type2,
    3: i18n.userTypeList.type3,
  };

  const stocklistRequests = {
    0: i18n.stocklistRequests.stocklistRequest0,
    1: i18n.stocklistRequests.stocklistRequest1,
  };

  const stockFormat = {
    0: 'PDF',
    1: 'EXCEL',
    2: 'JSON',
    3: 'XML',
    4: 'VIDEO',
  };

  const notificationType = {
    0: i18n.notificationType.notificationType0,
    1: i18n.notificationType.notificationType1,
    2: i18n.notificationType.notificationType2,
  };

  const notificationStatus = {
    0: i18n.notificationsStatus.notificationStatus0,
    1: i18n.notificationsStatus.notificationStatus1,
  };

  const ordersTypeValues = [
    {
      value: null,
      label: i18nOrders.allOptions,
    },
    {
      value: orderType.Yarn,
      label: i18nOrders.filatiLabel,
    },
    {
      value: orderType.Sampling,
      label: i18nOrders.campionarioLabel,
    },
    {
      value: orderType.Labdip,
      label: i18nOrders.labdipLabel,
    },
  ];

  const orderStatusValues = [
    {
      value: null,
      label: i18nOrders.allOptions,
    },
    {
      value: orderStatus.Open,
      label: i18nOrders.statuses.open,
    },
    {
      value: orderStatus.PartiallyShipped,
      label: i18nOrders.statuses.partiallyShipped,
    },
    {
      value: orderStatus.Shipped,
      label: i18nOrders.statuses.shipped,
    },
  ];

  const orderConfirmationValues = [
    {
      value: null,
      label: i18nOrders.allOptions,
    },
    {
      value: orderConfirmation.Confirmed,
      label: i18nOrders.confirmedStatuses.confirmed,
    },
    {
      value: orderConfirmation.ToBeConfirmed,
      label: i18nOrders.confirmedStatuses.toBeConfirmed,
    },
  ];

  const orderProgressValues = [
    {
      value: null,
      label: i18nOrders.allOptions,
    },
    {
      value: orderProgress.UnderProcessing,
      label: i18nOrders.progressStatuses.underProcessing,
    },
    {
      value: orderProgress.WaitingColorApproval,
      label: i18nOrders.progressStatuses.waitingColorApproval,
    },
    {
      value: orderProgress.InProduction,
      label: i18nOrders.progressStatuses.labdipInProduction,
    },
    {
      value: orderProgress.Shipped,
      label: i18nOrders.progressStatuses.labdipShipped,
    },
    {
      value: orderProgress.InternalApproval,
      label: i18nOrders.progressStatuses.labdipApproved,
    },
    {
      value: orderProgress.Closed,
      label: i18nOrders.progressStatuses.closed,
    },
  ];

  const orderDaysToExtractValues = [
    {
      value: 30,
      label: i18nOrders.daysToExtract.last30Days,
    },
    {
      value: 60,
      label: i18nOrders.daysToExtract.last60Days,
    },
    {
      value: 90,
      label: i18nOrders.daysToExtract.last90Days,
    },
    {
      value: 180,
      label: i18nOrders.daysToExtract.last180Days,
    },
    {
      value: 365,
      label: i18nOrders.daysToExtract.last365Days,
    },
  ];

  return {
    userStatusList,
    userCountryList,
    userTypeList,
    userLanguageList,
    stocklistRequests,
    stockFormat,
    notificationStatus,
    notificationType,
    ordersTypeValues,
    orderStatusValues,
    orderConfirmationValues,
    orderProgressValues,
    orderDaysToExtractValues,
  };
};

export { useMapping };
