import { countryIt, countryEn } from '../data/countries';

const useCountries = () => {
  const get = (locale) =>
    new Promise((resolve, reject) => {
      try {
        if (locale === 'it-IT') {
          resolve(countryIt);
        } else if (locale === 'en-GB') {
          resolve(countryEn);
        }
      } catch (e) {
        reject(e);
        console.error(e);
      }
    });

  return {
    get,
  };
};

export { useCountries };
