import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

const CRGTooltip = (props) => {
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={props.title}
      placement={props.placement || 'right'}
      {...props}
    >
      {props.children}
    </Tooltip>
  );
};

CRGTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  placement: PropTypes.oneOf(['right', 'left', 'top', 'bottom']),
};

export { CRGTooltip };
