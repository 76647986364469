import { forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

const CRGTextFieldStyle = styled(TextField)`
  background: ${(props) => props.theme.palette.neutral.light.white};
  box-shadow: 5px 5px 10px #0000000d;
  width: ${(props) => props.customWidth || '100%'};
  @media screen and (max-width: 360px) {
    width: ${(props) =>
      props.customWidth ? props.customWidth : props.isSmall ? '30px' : '300px'};
  }
  .MuiInputBase-root {
    height: ${(props) => props.customHeight || 'auto'};
    background: ${(props) => {
      switch (props.colorVariant) {
        case 'neutralLigthWhite':
          return props.theme.palette.neutral.light.white;

        default:
          return 'none';
      }
    }};
    .MuiInputBase-input {
      font-family: Montserrat;
    }
    fieldset {
      border: 1px solid #dee1e2;
      .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
      }
    }
    :hover {
      fieldset {
        border: 2px solid #d1bfae;
        .MuiOutlinedInput-notchedOutline {
          border-color: transparent;
        }
      }
    }
    :focus-visible {
      border: 2px solid #d1bfae;
      .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
      }
    }
    :focus {
      border: 2px solid #d1bfae;
      .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
      }
    }
  }
  .MuiFormLabel-root {
    color: ${(props) => props.theme.palette.neutral.dark.spanishGray};
    top: ${(props) =>
      props.customLabelTopPosition ? props.customLabelTopPosition : 0};
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
  }
  .MuiInputLabel-shrink {
    top: 0px !important;
  }
`;

const CRGTextField = forwardRef(({ colorVariant, ...props }) => {
  return <CRGTextFieldStyle {...props} colorVariant={colorVariant} />;
});

CRGTextField.propTypes = {
  colorVariant: PropTypes.oneOf(['neutralLigthWhite']),
  customLabelTopPosition: PropTypes.string,
};

CRGTextField.displayName = 'CRGTextField';

export { CRGTextField };
