const translations = {
  global: (intl) => ({
    app: {
      title: intl.formatMessage({
        description: 'global app name',
        defaultMessage: 'Cariaggi web ita',
        id: '2hk6q8',
      }),
      filters: {
        search: intl.formatMessage({
          description: 'global app filters search',
          defaultMessage: 'Cerca',
          id: 'axaSlb',
        }),
        advancedFilters: intl.formatMessage({
          description: 'global app filters advanced filters',
          defaultMessage: 'Filtri Avanzati',
          id: 'GUtJ0I',
        }),
        resetFilters: intl.formatMessage({
          description: 'global app filters reset filters',
          defaultMessage: 'Azzera filtri',
          id: '2QXx+y',
        }),
        cancel: intl.formatMessage({
          description: 'global app filters cancel',
          defaultMessage: 'Annulla',
          id: 'DyMCVJ',
        }),
        filters: intl.formatMessage({
          description: 'global app filters filters',
          defaultMessage: 'Filtri',
          id: 'DjEOPd',
        }),
        advanced: intl.formatMessage({
          description: 'global app filters advanced',
          defaultMessage: 'Avanzati',
          id: '3XQ3H0',
        }),
      },
      orders: {
        allOptions: intl.formatMessage({
          description: 'global app orders all options',
          defaultMessage: 'Tutti',
          id: 'kSGb6Q',
        }),
        filatiLabel: intl.formatMessage({
          description: 'global app orders filati',
          defaultMessage: 'Filati',
          id: 'AVYrSX',
        }),
        labdipLabel: intl.formatMessage({
          description: 'global app orders labdip',
          defaultMessage: 'Labdip',
          id: '30jTbz',
        }),
        campionarioLabel: intl.formatMessage({
          description: 'global app orders campionario',
          defaultMessage: 'Campionario',
          id: 'GoIxME',
        }),
        otherLabel: intl.formatMessage({
          description: 'global app orders other',
          defaultMessage: 'Altro tipo',
          id: 'ghZ3JV',
        }),
        statuses: {
          open: intl.formatMessage({
            description: 'global app orders open status label',
            defaultMessage: 'Aperto',
            id: '/G87EK',
          }),
          partiallyShipped: intl.formatMessage({
            description: 'global app orders partially shipped status label',
            defaultMessage: 'Parzialmento spedito',
            id: 'DCCTzM',
          }),
          shipped: intl.formatMessage({
            description: 'global app orders shipped status label',
            defaultMessage: 'Spedito',
            id: 'jb469W',
          }),
        },
        progressStatuses: {
          underProcessing: intl.formatMessage({
            description: 'global app orders open status label',
            defaultMessage: 'In lavorazione',
            id: '3V2nW5',
          }),
          waitingColorApproval: intl.formatMessage({
            description: 'global app orders partially shipped status',
            defaultMessage: 'In attesa di approvazione colore',
            id: 'JijBJq',
          }),
          labdipInProduction: intl.formatMessage({
            description: 'global app orders labdip in production status label',
            defaultMessage: 'Labdip in produzione',
            id: 'Ts0R48',
          }),
          labdipShipped: intl.formatMessage({
            description: 'global app orders labdip shipped status label',
            defaultMessage: 'Labdip inviato - in attesa di approvazione',
            id: 'lzqg4M',
          }),
          labdipApproved: intl.formatMessage({
            description: 'global app orders labdip approved status label',
            defaultMessage: 'Labdip approvato Cariaggi',
            id: 'm/3njr',
          }),
          closed: intl.formatMessage({
            description: 'global app orders closed status label',
            defaultMessage: 'Chiuso',
            id: '8htVCR',
          }),
        },
        confirmedStatuses: {
          confirmed: intl.formatMessage({
            description: 'global app orders confirmed status label',
            defaultMessage: 'Confermato',
            id: 'q2nTvO',
          }),
          toBeConfirmed: intl.formatMessage({
            description: 'global app orders to be confirmed status label',
            defaultMessage: 'Da confermare',
            id: 'n/tk9p',
          }),
        },
        daysToExtract: {
          last30Days: intl.formatMessage({
            description: 'global app orders last 30 days label',
            defaultMessage: 'Ultimi 30 giorni',
            id: 'SortWc',
          }),
          last60Days: intl.formatMessage({
            description: 'global app orders last 60 days label',
            defaultMessage: 'Ultimi 60 giorni',
            id: 'stqP/0',
          }),
          last90Days: intl.formatMessage({
            description: 'global app orders last 90 days label',
            defaultMessage: 'Ultimi 90 giorni',
            id: 'fMCzt0',
          }),
          last180Days: intl.formatMessage({
            description: 'global app orders last 180 days label',
            defaultMessage: 'Ultimi 180 giorni',
            id: 'x19U5j',
          }),
          last365Days: intl.formatMessage({
            description: 'global app orders last 365 days label',
            defaultMessage: 'Ultimi 365 giorni',
            id: 'oNPTEG',
          }),
        },
      },
    },
    errors: {
      genericRetry: {
        title: intl.formatMessage({
          description: 'global app errors generic error retry title',
          defaultMessage: 'Errore',
          id: '5ORZKi',
        }),
        message: intl.formatMessage({
          description: 'global app errors generic error retry message',
          defaultMessage:
            'Si è verificato un errore, ti invitiamo a riprovare più tardi',
          id: '8YgDgG',
        }),
        closeButton: intl.formatMessage({
          description: 'global app errors generic error retry button label',
          defaultMessage: 'Chiudi',
          id: '6yWbFo',
        }),
      },
    },
  }),
  components: (intl) => ({
    goToRegistration: {
      labelText: intl.formatMessage({
        description: 'go to registration component label text',
        defaultMessage: "Non hai un'account?",
        id: '9ATibl',
      }),
      buttonText: intl.formatMessage({
        description: 'go to registration component button text',
        defaultMessage: 'Registrati',
        id: 'faUD/8',
      }),
    },
    alreadyRegistered: {
      labelText: intl.formatMessage({
        description: 'already registered component label text',
        defaultMessage: "Hai già un'account?",
        id: 'nBNa9R',
      }),
      buttonText: intl.formatMessage({
        description: 'already registered component button text',
        defaultMessage: 'Accedi',
        id: 'REcjNa',
      }),
    },
    loginForm: {
      username: intl.formatMessage({
        description: 'login page login form username field label',
        defaultMessage: 'Username',
        id: 'jyQ1f+',
      }),
      password: intl.formatMessage({
        description: 'login page login form password field label',
        defaultMessage: 'Password',
        id: '4ytxH4',
      }),
      button: intl.formatMessage({
        description: 'login page login form submit button label',
        defaultMessage: 'Accedi',
        id: 'ZHliZp',
      }),
      errors: {
        username: {
          required: intl.formatMessage({
            description: 'login page login form username required label',
            defaultMessage: "L'username è obbligatorio",
            id: 'm5MXcU',
          }),
        },
        password: {
          required: intl.formatMessage({
            description: 'login page login form password required label',
            defaultMessage: 'La password è obbligatorio',
            id: 'mdvXNH',
          }),
        },
      },
    },
    signInForm: {
      name: intl.formatMessage({
        description: 'sign in page sign in form name field label',
        defaultMessage: 'Nome',
        id: 'AHFoqd',
      }),
      surname: intl.formatMessage({
        description: 'sign in page sign in form surname field label',
        defaultMessage: 'Cognome',
        id: 'ukTgB8',
      }),
      type: intl.formatMessage({
        description: 'sign in page sign in form customerType field label',
        defaultMessage: 'Tipo Utente',
        id: 'Vl+Q/L',
      }),
      username: intl.formatMessage({
        description: 'sign in page sign in form username field label',
        defaultMessage: 'Username',
        id: '8p2AEI',
      }),
      company: intl.formatMessage({
        description: 'sign in page sign in form company field label',
        defaultMessage: 'Azienda',
        id: 'TVEVKC',
      }),
      email: intl.formatMessage({
        description: 'sign in page sign in form email field label',
        defaultMessage: 'Email',
        id: 'gDHkUT',
      }),
      confirmEmail: intl.formatMessage({
        description: 'sign in page sign in form confirmEmail field label',
        defaultMessage: 'Conferma Mail',
        id: 'RP5QkU',
      }),
      language: intl.formatMessage({
        description: 'sign in page sign in form language field label',
        defaultMessage: 'Lingua',
        id: 'Ffdoki',
      }),
      country: intl.formatMessage({
        description: 'sign in page sign in form country field label',
        defaultMessage: 'Nazione',
        id: 'qbr73K',
      }),
      note: intl.formatMessage({
        description: 'sign in page sign in form note field label',
        defaultMessage: 'Note',
        id: 'SAh1lM',
      }),
      privacyPolicyPre: intl.formatMessage({
        description: 'sign in page sign in form privacy policy pre field label',
        defaultMessage: 'Ho letto e accetto le condizioni della',
        id: 'tKWR68',
      }),
      privacyPolicy: intl.formatMessage({
        description: 'sign in page sign in form privacy policy field label',
        defaultMessage: 'Privacy Policy',
        id: 'd2bgXc',
      }),
      button: intl.formatMessage({
        description: 'sign in page sign in form submit button label',
        defaultMessage: 'Invia',
        id: 'bt7N/M',
      }),
      errors: {
        name: {
          required: intl.formatMessage({
            description: 'sign in page sign in form name required label',
            defaultMessage: 'Il nome è obbligatorio',
            id: 'EANIFw',
          }),
        },
        surname: {
          required: intl.formatMessage({
            description: 'sign in page sign in form surname required label',
            defaultMessage: 'Il cognome è obbligatorio',
            id: 'vRWbhY',
          }),
        },
        type: {
          required: intl.formatMessage({
            description:
              'sign in page sign in form customerType required label',
            defaultMessage: 'Il tipo cliente è obbligatorio',
            id: 'n+qYvt',
          }),
        },
        username: {
          required: intl.formatMessage({
            description: 'sign in page sign in form username required label',
            defaultMessage: "L'username è obbligatorio",
            id: 'vgnLuM',
          }),
        },
        company: {
          required: intl.formatMessage({
            description: 'sign in page sign in form company required label',
            defaultMessage: "L'azienda è obbligatorio",
            id: 'j+5rqN',
          }),
        },
        email: {
          required: intl.formatMessage({
            description: 'sign in page sign in form email required label',
            defaultMessage: 'La mail è obbligatorio',
            id: 'FPTOHU',
          }),
        },
        mailFormat: {
          required: intl.formatMessage({
            description: 'sign in page sign in form email format label',
            defaultMessage: 'Il formato della mail non è corretto',
            id: 'YQTxaz',
          }),
        },
        confirmEmail: {
          required: intl.formatMessage({
            description:
              'sign in page sign in form confirmEmail required label',
            defaultMessage: 'La mail di conferma è obbligatorio',
            id: 'kuWds1',
          }),
          notMatch: intl.formatMessage({
            description:
              'sign in page sign in form confirmEmail not match label',
            defaultMessage: 'Le mail non coincidono',
            id: 'gPIO/V',
          }),
        },
        language: {
          required: intl.formatMessage({
            description: 'sign in page sign in form language required label',
            defaultMessage: 'La lingua è obbligatorio',
            id: 'mnWVbE',
          }),
        },
        country: {
          required: intl.formatMessage({
            description: 'sign in page sign in form country required label',
            defaultMessage: 'La nazione è obbligatorio',
            id: 'OzX132',
          }),
        },
        privacyPolicy: {
          required: intl.formatMessage({
            description: 'sign in page sign in form privacy policy label',
            defaultMessage: 'Il consenso alla privacy è obbligatorio',
            id: 'ohUHDp',
          }),
        },
      },
    },
    signInComplete: {
      text1: intl.formatMessage({
        description: 'sign in page sign in complete text 1',
        defaultMessage:
          'Grazie per aver inviato la tua richiesta di registrazione.',
        id: 'W4Y/DN',
      }),
      text2: intl.formatMessage({
        description: 'sign in page sign in complete text 2',
        defaultMessage:
          'Ora ti chiediamo di confermarla cliccando sul link nella mail che ti abbiamo inviato.',
        id: 'ZQP5id',
      }),
      text3: intl.formatMessage({
        description: 'sign in page sign in complete text 3',
        defaultMessage:
          'Effettua il click sul link ricevuto per email per completare la richiesta di registrazione.',
        id: 'G3R7qT',
      }),
      buttonLabel: intl.formatMessage({
        description: 'sign in page sign in complete button label',
        defaultMessage: 'Chiudi',
        id: 'eyCVdY',
      }),
    },
    forceUpdatePasswordForm: {
      text1: intl.formatMessage({
        description: 'login page force update password component text 1',
        defaultMessage: 'Reimposta Password',
        id: 'wNOzsr',
      }),
      text2: intl.formatMessage({
        description: 'login page force update password component text 2',
        defaultMessage: 'Imposta Nuova Password',
        id: 'an4gwT',
      }),
      buttonLabel: intl.formatMessage({
        description: 'login page force update password component button',
        defaultMessage: 'Reimposta password',
        id: '2rsVh1',
      }),
      form: {
        username: intl.formatMessage({
          description:
            'login page force update password component form username label',
          defaultMessage: 'Username',
          id: 'NNEKi/',
        }),
        password: intl.formatMessage({
          description:
            'login page force update password component form password label',
          defaultMessage: 'Password',
          id: 'Xx8FLA',
        }),
        confirmPassword: intl.formatMessage({
          description:
            'login page force update password component form confirmPassword label',
          defaultMessage: 'Conferma Password',
          id: 'jpHyLy',
        }),
        errors: {
          password: {
            required: intl.formatMessage({
              description:
                'login page force update password component form password required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'B9zUw5',
            }),
          },
          passwordFormat: {
            required: intl.formatMessage({
              description:
                'login page force update password component form password format error label',
              defaultMessage: 'Il formato della password non è corretto',
              id: 'q7hlbD',
            }),
          },
          confirmPassword: {
            required: intl.formatMessage({
              description:
                'login page force update password component form confirmPassword required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'jqvqPT',
            }),
          },
          passwordNotMatching: intl.formatMessage({
            description:
              'login page force update password component form confirmPassword not match error label',
            defaultMessage: 'Le password non coincidono',
            id: 'OSWrx+',
          }),
        },
      },
    },
    forgotPasswordForm: {
      text1: intl.formatMessage({
        description: 'login page forgot password component text 1',
        defaultMessage: 'Password dimenticata?',
        id: '7A1zSg',
      }),
      text2: intl.formatMessage({
        description: 'login page forgot password component text 2',
        defaultMessage:
          'Inserisci il tuo username, poi segui le istruzioni che riceverai via email per reimpostare la tua password',
        id: 'qoZRpF',
      }),
      buttonLabel: intl.formatMessage({
        description: 'login page forgot password component button',
        defaultMessage: 'Prosegui',
        id: 'dQOqRr',
      }),
      form: {
        username: intl.formatMessage({
          description:
            'login page forgot password component form username label',
          defaultMessage: 'Username',
          id: 'AW7TCZ',
        }),
        errors: {
          username: {
            required: intl.formatMessage({
              description:
                'login page forgot password component form password required error label',
              defaultMessage: 'Campo obbligatorio',
              id: '18sdpm',
            }),
          },
        },
      },
    },
    forgotPasswordSubmitForm: {
      text1: intl.formatMessage({
        description:
          'forgot password page forgot password submit component text 1',
        defaultMessage: 'Reimposta Password',
        id: 'B/2oL9',
      }),
      text2: intl.formatMessage({
        description:
          'forgot password page forgot password submit component text 2',
        defaultMessage: 'Imposta Nuova Password',
        id: 'E7LHeX',
      }),
      buttonLabel: intl.formatMessage({
        description:
          'forgot password page forgot password submit component button',
        defaultMessage: 'Reimposta password',
        id: 'LnR6i3',
      }),
      form: {
        username: intl.formatMessage({
          description:
            'forgot password page forgot password submit component form username label',
          defaultMessage: 'Username',
          id: 'qMD3+j',
        }),
        password: intl.formatMessage({
          description:
            'forgot password page forgot password submit component form password label',
          defaultMessage: 'Password',
          id: 'i/Ufse',
        }),
        code: intl.formatMessage({
          description:
            'forgot password page forgot password submit component form code label',
          defaultMessage: 'Codice',
          id: '5SxqUm',
        }),
        confirmPassword: intl.formatMessage({
          description:
            'forgot password page forgot password submit component form confirmPassword label',
          defaultMessage: 'Conferma Password',
          id: '/nbE85',
        }),
        errors: {
          password: {
            required: intl.formatMessage({
              description:
                'forgot password page forgot password submit component form password required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'HL48E+',
            }),
          },
          passwordFormat: {
            required: intl.formatMessage({
              description:
                'forgot password page forgot password submit component form password format error label',
              defaultMessage: 'Il formato della password non è corretto',
              id: 'YSqdGp',
            }),
          },
          confirmPassword: {
            required: intl.formatMessage({
              description:
                'login page force update password component form confirmPassword required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'jqvqPT',
            }),
          },
          code: {
            required: intl.formatMessage({
              description:
                'login page force update password component form code required error label',
              defaultMessage: 'Campo obbligatorio',
              id: '5bx+l5',
            }),
          },
          passwordNotMatching: intl.formatMessage({
            description:
              'login page force update password component form confirmPassword not match error label',
            defaultMessage: 'Le password non coincidono',
            id: 'OSWrx+',
          }),
        },
      },
    },
    passwordPolicyList: {
      main: intl.formatMessage({
        description: 'sign in page sign in form password policy main label',
        defaultMessage: 'Requisiti password',
        id: '/qIU8Q',
      }),
      policy1: intl.formatMessage({
        description: 'sign in page sign in form password policy policy1 label',
        defaultMessage: 'Contenere almeno 8 caratteri',
        id: 'UP3hjg',
      }),
      policy2: intl.formatMessage({
        description: 'sign in page sign in form password policy policy2 label',
        defaultMessage: 'Contenere caratteri speciali',
        id: 'sCoSFw',
      }),
      policy3: intl.formatMessage({
        description: 'sign in page sign in form password policy policy3 label',
        defaultMessage: 'Contenere numeri',
        id: 'xEc/YY',
      }),
      policy4: intl.formatMessage({
        description: 'sign in page sign in form password policy policy4 label',
        defaultMessage: 'Contenere lettere maiuscole',
        id: 'HQByI1',
      }),
      policy5: intl.formatMessage({
        description: 'sign in page sign in form password policy policy5 label',
        defaultMessage: 'Contenere lettere minuscole',
        id: 'o2+TVe',
      }),
    },
    changeTempPasswordComplete: {
      text1: intl.formatMessage({
        description: 'sign in page temp password changed text 1',
        defaultMessage: 'Registrazione completata con successo',
        id: 'ruk2GV',
      }),
      buttonLabel: intl.formatMessage({
        description: 'sign in page temp password changed button label',
        defaultMessage: 'Accedi',
        id: 'QOwPPh',
      }),
    },
    userListFilters: {
      nameSurnameLabel: intl.formatMessage({
        description: 'user list filters component nameSurname label',
        defaultMessage: 'Nome Cognome',
        id: 'KjrVWY',
      }),
      statusLabel: intl.formatMessage({
        description: 'user list filters component status label',
        defaultMessage: 'Status Utente',
        id: '56qUh9',
      }),
      countryLabel: intl.formatMessage({
        description: 'user list filters component country label',
        defaultMessage: 'Nazione',
        id: 'WwllXj',
      }),
      emailLabel: intl.formatMessage({
        description: 'user list filters component email label',
        defaultMessage: 'Email',
        id: '/1jMBu',
      }),
      companyLabel: intl.formatMessage({
        description: 'user list filters component company label',
        defaultMessage: 'Azienda',
        id: 'yB+Jfr',
      }),
      typeLabel: intl.formatMessage({
        description: 'user list filters component type label',
        defaultMessage: 'Tipologia Utente',
        id: 'C+TfSM',
      }),
      buttonSubmitLabel: intl.formatMessage({
        description: 'user list filters component button submit label',
        defaultMessage: 'Cerca',
        id: 'YEEJbo',
      }),
      buttonClearFilters: intl.formatMessage({
        description: 'user list filters component button clear filters label',
        defaultMessage: 'Azzera Filtri',
        id: 'JJrhaA',
      }),
    },
    stocklistListFilters: {
      requestNameLabel: intl.formatMessage({
        description: 'stocklist list  filters component request name label',
        defaultMessage: 'Nome Richiesta',
        id: '6bC5uB',
      }),
      dataLabel: intl.formatMessage({
        description: 'stocklist list  filters component date label',
        defaultMessage: 'Data-ora(dal)',
        id: 'gjBPkS',
      }),
      statusLabel: intl.formatMessage({
        description: 'stocklist list  filters component status label',
        defaultMessage: 'Status elaborazione',
        id: 'nHs8ys',
      }),
      emailLabel: intl.formatMessage({
        description: 'stocklist list  filters component email label',
        defaultMessage: 'Email',
        id: '6SBOEx',
      }),
      articleLabel: intl.formatMessage({
        description: 'stocklist list  filters component article label',
        defaultMessage: 'Articolo',
        id: 'AA5bT3',
      }),
      typeLabel: intl.formatMessage({
        description: 'stocklist list  filters component type label',
        defaultMessage: 'Formato',
        id: 'PIQ7fQ',
      }),
      customerLabel: intl.formatMessage({
        description: 'stocklist list  filters component customer label',
        defaultMessage: 'Clienti',
        id: 'NXxu0Y',
      }),
      requestsLabel: intl.formatMessage({
        description: 'stocklist list  filters component requests label',
        defaultMessage: 'Richieste',
        id: 'zziGsx',
      }),
      buttonSubmitLabel: intl.formatMessage({
        description: 'stocklist list  filters component button submit label',
        defaultMessage: 'Cerca',
        id: 'YEyKW1',
      }),
      buttonClearFilters: intl.formatMessage({
        description:
          'stocklist list  filters component button clear filters label',
        defaultMessage: 'Azzera Filtri',
        id: '0GE1wM',
      }),
    },
    userMobileFiltersDrawerContent: {
      nameSurnameLabel: intl.formatMessage({
        description:
          'user list mobile drrawer content  filters component nameSurname label',
        defaultMessage: 'Nome Cognome',
        id: 'gR0XWB',
      }),
      statusLabel: intl.formatMessage({
        description:
          'user list mobile drrawer content  filters component status label',
        defaultMessage: 'Status Utente',
        id: 'Z89giI',
      }),
      countryLabel: intl.formatMessage({
        description:
          'user list mobile drrawer content  filters component country label',
        defaultMessage: 'Nazione',
        id: '8wbKw0',
      }),
      emailLabel: intl.formatMessage({
        description:
          'user list mobile drrawer content  filters component email label',
        defaultMessage: 'Email',
        id: 'KeBMgk',
      }),
      companyLabel: intl.formatMessage({
        description:
          'user list mobile drrawer content  filters component company label',
        defaultMessage: 'Azienda',
        id: '3fVoTW',
      }),
      typeLabel: intl.formatMessage({
        description:
          'user list mobile drrawer content  filters component type label',
        defaultMessage: 'Tipologia Utente',
        id: 'vMV/LU',
      }),
      buttonSubmitLabel: intl.formatMessage({
        description:
          'user list mobile drrawer content  filters component button submit label',
        defaultMessage: 'Cerca',
        id: '2LK9XY',
      }),
      clearFiltersButtonLabel: intl.formatMessage({
        description:
          'user list mobile drrawer content  filters component button clear filters label',
        defaultMessage: 'Azzera Filtri',
        id: 'wAkDay',
      }),
      buttonCancelLabel: intl.formatMessage({
        description:
          'user list mobile drrawer content  filters component button cancel label',
        defaultMessage: 'Annulla',
        id: '9JUaoA',
      }),
      title: intl.formatMessage({
        description:
          'user list mobile drrawer content  filters component title label',
        defaultMessage: 'Ricerca Avanzata',
        id: 'BIn5Sm',
      }),
    },
    stocklistMobileFiltersDrawerContent: {
      requestNameLabel: intl.formatMessage({
        description:
          'stocklist list mobile stocklist drawer content filters component request name label',
        defaultMessage: 'Nome Richiesta',
        id: 'oX89PQ',
      }),
      dataLabel: intl.formatMessage({
        description:
          'stocklist list mobile stocklist drawer content filters component date label',
        defaultMessage: 'Data-ora(dal)',
        id: 'YdwFWS',
      }),
      statusLabel: intl.formatMessage({
        description:
          'stocklist list mobile stocklist drawer content filters component status label',
        defaultMessage: 'Status elaborazione',
        id: 'mSqeAW',
      }),
      emailLabel: intl.formatMessage({
        description:
          'stocklist list mobile stocklist drawer content filters component email label',
        defaultMessage: 'Email',
        id: '5UDJVd',
      }),
      articleLabel: intl.formatMessage({
        description:
          'stocklist list mobile stocklist drawer content filters component article label',
        defaultMessage: 'Articolo',
        id: 'LiG0c6',
      }),
      typeLabel: intl.formatMessage({
        description:
          'stocklist list mobile stocklist drawer content filters component type label',
        defaultMessage: 'Formato',
        id: 'wb1xuo',
      }),
      requestsLabel: intl.formatMessage({
        description:
          'stocklist list mobile stocklist drawer content filters component request label',
        defaultMessage: 'Richieste',
        id: '7cwzb6',
      }),
      customerLabel: intl.formatMessage({
        description:
          'stocklist list mobile stocklist drawer content filters component customer label',
        defaultMessage: 'Clienti',
        id: 'XKwF3P',
      }),
      buttonSubmitLabel: intl.formatMessage({
        description:
          'stocklist list mobile stocklist drawer content filters component button submit label',
        defaultMessage: 'Cerca',
        id: 'dpHwJi',
      }),
      clearFiltersButtonLabel: intl.formatMessage({
        description:
          'stocklist list mobile stocklist drawer content filters component button clear filters label',
        defaultMessage: 'Azzera Filtri',
        id: 'uBeE2X',
      }),
      buttonCancelLabel: intl.formatMessage({
        description:
          'stocklist list mobile stocklist drawer content filters component button cancel label',
        defaultMessage: 'Annulla',
        id: 'j+OlIz',
      }),
      title: intl.formatMessage({
        description:
          'stocklist list mobile stocklist drawer content filters component title label',
        defaultMessage: 'Ricerca Avanzata',
        id: 'qenc2c',
      }),
    },
    addOrUpdateUser: {
      title: intl.formatMessage({
        description: 'add or update user component title label',
        defaultMessage: 'Nuovo Utente',
        id: 'HIS5uq',
      }),
      titleUpdate: intl.formatMessage({
        description: 'add or update user component update title label',
        defaultMessage: 'Modifica Utente',
        id: '23dNgO',
      }),
      cancelButtonLabel: intl.formatMessage({
        description: 'add or update user component cancel button label',
        defaultMessage: 'Annulla',
        id: '2qL/XQ',
      }),
      createButtonLabel: intl.formatMessage({
        description: 'add or update user component create button label',
        defaultMessage: 'Crea',
        id: '/M59tq',
      }),
      updateButtonLabel: intl.formatMessage({
        description: 'add or update user component update button label',
        defaultMessage: 'Salva Modifiche',
        id: 'SyMdS0',
      }),
      deleteButtonLabel: intl.formatMessage({
        description: 'add or update user component delete button label',
        defaultMessage: 'Elimina',
        id: 'uu+lCa',
      }),
      sendVerificationMail: intl.formatMessage({
        description:
          'add or update user component resend verification mail label',
        defaultMessage: 'Invia mail di verifica',
        id: 'nExWCw',
      }),
      verifyEmailDialog: {
        title: intl.formatMessage({
          description:
            'add or update user component verify email title dialog label',
          defaultMessage: 'Email inviata',
          id: 'VMXUYn',
        }),
        message: intl.formatMessage({
          description:
            'add or update user component verify email message dialog label',
          defaultMessage:
            "La mail di verifica è stata inviata correttamente all'utente!",
          id: 'z8g3J6',
        }),
        button: intl.formatMessage({
          description:
            'add or update user component verify email button dialog  label',
          defaultMessage: 'Chiudi',
          id: '6+yOBQ',
        }),
      },
      deleteUserDialog: {
        title: intl.formatMessage({
          description:
            'add or update user component delete user title dialog label',
          defaultMessage: 'Cancella utente',
          id: '0SOKAl',
        }),
        message: intl.formatMessage({
          description:
            'add or update user component delete user message dialog label',
          defaultMessage: "Sei sicuro di voler cancellare l'utente?",
          id: 'Cj+Jab',
        }),
        cancelButton: intl.formatMessage({
          description:
            'add or update user component delete user cancel button dialog label',
          defaultMessage: 'Annulla',
          id: '9gIzLj',
        }),
        agreeButton: intl.formatMessage({
          description:
            'add or update user component delete user agree button dialog label',
          defaultMessage: 'Conferma',
          id: 'zNNQXJ',
        }),
      },
      form: {
        business: intl.formatMessage({
          description: 'add or update user component form business label',
          defaultMessage: 'Ditta',
          id: 'xXQwqd',
        }),
        name: intl.formatMessage({
          description: 'add or update user component form name label',
          defaultMessage: 'Nome',
          id: 'jjEE+a',
        }),
        surname: intl.formatMessage({
          description: 'add or update user component form surname label',
          defaultMessage: 'Cognome',
          id: 'huLxDf',
        }),
        username: intl.formatMessage({
          description: 'add or update user component form username label',
          defaultMessage: 'Nome Utente',
          id: 'qFoNZc',
        }),
        company: intl.formatMessage({
          description: 'add or update user component form company label',
          defaultMessage: 'Azienda',
          id: 'hbKdze',
        }),
        email: intl.formatMessage({
          description: 'add or update user component form email label',
          defaultMessage: 'Email',
          id: 'Qj90w6',
        }),
        country: intl.formatMessage({
          description: 'add or update user component form country label',
          defaultMessage: 'Nazione',
          id: 'YbXogQ',
        }),
        type: intl.formatMessage({
          description: 'add or update user component form type label',
          defaultMessage: 'Tipo Utente',
          id: 'yNdwdo',
        }),
        customerCode: intl.formatMessage({
          description: 'add or update user component form customer code label',
          defaultMessage: 'Codice Cliente',
          id: 'NFDCp4',
        }),
        agentCode: intl.formatMessage({
          description: 'add or update user component form agent code label',
          defaultMessage: 'Codice Agente',
          id: 'kQvBbt',
        }),
        language: intl.formatMessage({
          description: 'add or update user component form language code label',
          defaultMessage: 'Lingua',
          id: 'wx6dmQ',
        }),
        note: intl.formatMessage({
          description: 'add or update user component form note code label',
          defaultMessage: 'Note',
          id: 'mk6+/K',
        }),
        status: intl.formatMessage({
          description: 'add or update user component form status code label',
          defaultMessage: 'Stato',
          id: '1m+8pl',
        }),
        errors: {
          name: {
            required: intl.formatMessage({
              description:
                'add or update user component form name required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'BKTsAl',
            }),
          },
          surname: {
            required: intl.formatMessage({
              description:
                'add or update user component form surname required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'eFrm8K',
            }),
          },
          username: {
            required: intl.formatMessage({
              description:
                'add or update user component form username required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'Y9RCVB',
            }),
          },
          comapny: {
            required: intl.formatMessage({
              description:
                'add or update user component form comapny required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'khaLCU',
            }),
          },
          country: {
            required: intl.formatMessage({
              description:
                'add or update user component form comapny country error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'JizheT',
            }),
          },
          email: {
            required: intl.formatMessage({
              description:
                'add or update user component form email required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'uEGlqI',
            }),
          },
          type: {
            required: intl.formatMessage({
              description:
                'add or update user component form type required error label',
              defaultMessage: 'Campo obbligatorio',
              id: '27Odun',
            }),
          },
          customerCode: {
            required: intl.formatMessage({
              description:
                'add or update user component form customer code required error label',
              defaultMessage: 'Campo obbligatorio',
              id: '7QWiPJ',
            }),
          },
          agentCode: {
            required: intl.formatMessage({
              description:
                'add or update user component form agent code required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'M0TRxt',
            }),
          },
          language: {
            required: intl.formatMessage({
              description:
                'add or update user component form language required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'tKF3Bo',
            }),
          },
          status: {
            required: intl.formatMessage({
              description:
                'add or update user component form status required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'rrIPk4',
            }),
          },
        },
      },
    },
    addStocklistRequest: {
      title: intl.formatMessage({
        description: 'add stocklist request component title label',
        defaultMessage: 'Nuova Richiesta Stocklist',
        id: 'ni3Alc',
      }),
      cancelButtonLabel: intl.formatMessage({
        description: 'add stocklist request component cancel button label',
        defaultMessage: 'Annulla',
        id: 'cVx6lu',
      }),
      createButtonLabel: intl.formatMessage({
        description: 'add stocklist request component create button label',
        defaultMessage: 'Invia Richiesta',
        id: 'H+HRKx',
      }),
      fixedRequestName: intl.formatMessage({
        description: 'add stocklist request component fixed request name label',
        defaultMessage: 'Richiesta',
        id: 'ypzTM1',
      }),
      mailBoxTitle: intl.formatMessage({
        description: 'add stocklist request component mail box title label',
        defaultMessage:
          'Il risultato della ricerca verrà estratto in automatico in PDF.',
        id: 'o2soAv',
      }),
      mailBoxSubtitle1: intl.formatMessage({
        description:
          'add stocklist request component mail box subtitle 1 label',
        defaultMessage: 'Se desideri un formato diverso indicalo qui sotto:',
        id: 'FWxZQV',
      }),
      mailBoxSubtitle2: intl.formatMessage({
        description:
          'add stocklist request component mail box subtitle 2 label',
        defaultMessage:
          'Vuoi inviare il risultato della ricerca ad un indirizzo mail diverso da quello segnato nelle impostazioni utente?',
        id: 'V3WLt3',
      }),
      form: {
        requestNameLabel: intl.formatMessage({
          description:
            'add stocklist request component form request name label',
          defaultMessage: 'Nome ricerca',
          id: 'yWfBZY',
        }),
        customerLabel: intl.formatMessage({
          description: 'add stocklist request component form customer label',
          defaultMessage: 'Cliente*',
          id: 'ncVO4Y',
        }),
        articleLabel: intl.formatMessage({
          description: 'add stocklist request component form article label',
          defaultMessage: 'Articolo*',
          id: 'PpqO8i',
        }),
        colorLabel: intl.formatMessage({
          description: 'add stocklist request component form color label',
          defaultMessage: 'Colore',
          id: '+SJg85',
        }),
        formatLabel: intl.formatMessage({
          description: 'add stocklist request component form format label',
          defaultMessage: 'Formato',
          id: 'vz1tXk',
        }),
        checkboxLabel: intl.formatMessage({
          description: 'label for mail checkbox',
          defaultMessage: 'Voglio ricevere via mail il risultato',
          id: 'rck4LJ',
        }),
        colorNotesLabel: intl.formatMessage({
          description: 'color notes label',
          defaultMessage:
            'Non scrivere niente nel campo colore nel caso vuoi ricevere tutta la disponibilità del filato',
          id: 'r7UTqA',
        }),
        colorNotesLabelExample: intl.formatMessage({
          description: 'color notes label example',
          defaultMessage:
            'Se invece si vuole specificare un colore, digitare sempre sei numeri. Esempio: 020000',
          id: 'pvfJbF',
        }),
        errors: {
          requestName: {
            required: intl.formatMessage({
              description:
                'add stocklist request component form request name required error label',
              defaultMessage: 'Campo obbligatorio',
              id: '/NjNb+',
            }),
          },
          customer: {
            required: intl.formatMessage({
              description:
                'add stocklist request component form customer required error label',
              defaultMessage: 'Campo obbligatorio',
              id: '964Rf7',
            }),
          },
          article: {
            required: intl.formatMessage({
              description:
                'add stocklist request component form username article error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'WZEOxh',
            }),
          },
          color: {
            minLength: intl.formatMessage({
              description:
                'add stoklist request component form company color min length error label',
              defaultMessage: 'Il codice colore deve essere di 6 cifre',
              id: 'mf5nRf',
            }),
            required: intl.formatMessage({
              description:
                'add stocklist request component form comapny color error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'RiMA5l',
            }),
          },
          format: {
            required: intl.formatMessage({
              description:
                'add stocklist request component form comapny format error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'FoP5FG',
            }),
          },
        },
      },
    },
    useMapping: {
      userStatusList: {
        status0: intl.formatMessage({
          description: 'use mapping hook user status status 0',
          defaultMessage: 'Non verificato',
          id: 'c6mPxe',
        }),
        status1: intl.formatMessage({
          description: 'use mapping hook user status status 1',
          defaultMessage: 'Non attivato',
          id: 'aXB5k7',
        }),
        status2: intl.formatMessage({
          description: 'use mapping hook user status status 2',
          defaultMessage: 'Attivato',
          id: 'VA9lar',
        }),
        status3: intl.formatMessage({
          description: 'use mapping hook user status status 3',
          defaultMessage: 'Disattivato',
          id: '3Naxkh',
        }),
        status4: intl.formatMessage({
          description: 'use mapping hook user status status 4',
          defaultMessage: 'Eliminato',
          id: 'az3u1Y',
        }),
        status5: intl.formatMessage({
          description: 'use mapping hook user status status 5',
          defaultMessage: 'Rifiutato',
          id: 'QK5olU',
        }),
      },
      userCountryList: {
        country0: intl.formatMessage({
          description: 'use mapping hook user country 0 label',
          defaultMessage: 'Italia',
          id: 'TvnWvG',
        }),
        country1: intl.formatMessage({
          description: 'use mapping hook user country 1 label',
          defaultMessage: 'Estero',
          id: 'AtItH2',
        }),
      },
      userLanguageList: {
        language0: intl.formatMessage({
          description: 'use mapping hook user language 0 label',
          defaultMessage: 'Italiano',
          id: 'D0IM0i',
        }),
        language1: intl.formatMessage({
          description: 'use mapping hook user language 1 label',
          defaultMessage: 'Inglese',
          id: '3nysM0',
        }),
      },
      userTypeList: {
        type0: intl.formatMessage({
          description: 'use mapping hook user type 0 label',
          defaultMessage: 'Interno',
          id: '6lFCZq',
        }),
        type1: intl.formatMessage({
          description: 'use mapping hook user type 1 label',
          defaultMessage: 'Agente',
          id: 'S10rrY',
        }),
        type2: intl.formatMessage({
          description: 'use mapping hook user type 2 label',
          defaultMessage: 'Cliente',
          id: 'kMoq9e',
        }),
        type3: intl.formatMessage({
          description: 'use mapping hook user type 3 label',
          defaultMessage: 'Admin',
          id: 'kyF0ki',
        }),
      },
      stocklistRequests: {
        stocklistRequest0: intl.formatMessage({
          description: 'use mapping hook stocklist requests request 0 label',
          defaultMessage: 'Tutte',
          id: 'ZsICsJ',
        }),
        stocklistRequest1: intl.formatMessage({
          description: 'use mapping hook stocklist requests request 1 label',
          defaultMessage: 'Solo le mie',
          id: 'G8XDKT',
        }),
      },
      notificationsStatus: {
        notificationStatus0: intl.formatMessage({
          description: 'use mapping hook notification status 0 label',
          defaultMessage: 'Non letta',
          id: 'qrmUwM',
        }),
        notificationStatus1: intl.formatMessage({
          description: 'use mapping hook notification status 1 label',
          defaultMessage: 'Letta',
          id: 'Feokr3',
        }),
      },
      notificationType: {
        notificationType0: intl.formatMessage({
          description: 'use mapping hook notification type 0 label',
          defaultMessage: 'Utenti',
          id: 'Mf5gsg',
        }),
        notificationType1: intl.formatMessage({
          description: 'use mapping hook notification type 1 label',
          defaultMessage: 'Stocklist',
          id: 'cUrC+4',
        }),
        notificationType2: intl.formatMessage({
          description: 'use mapping hook notification type 2 label',
          defaultMessage: 'Generica',
          id: '2lXt0K',
        }),
      },
    },
    stockDetailDashboard: {
      requestIdLabel: intl.formatMessage({
        description: 'stock detail dashboard component request id label',
        defaultMessage: 'ID (Richiesta)',
        id: '3P8uza',
      }),
      statusLabel: intl.formatMessage({
        description: 'stock detail dashboard component status label',
        defaultMessage: 'Status',
        id: 'ZzFofN',
      }),
      customerIdLabel: intl.formatMessage({
        description: 'stock detail dashboard component customer id id label',
        defaultMessage: 'ID cliente',
        id: 'CeHD13',
      }),
      emailLabel: intl.formatMessage({
        description: 'stock detail dashboard component email label',
        defaultMessage: 'Email',
        id: '0ClOLM',
      }),
      agentLabel: intl.formatMessage({
        description: 'stock detail dashboard component agent label',
        defaultMessage: 'Agente',
        id: 'PrQkiy',
      }),
      dateTimeRequestLabel: intl.formatMessage({
        description: 'stock detail dashboard component date time request label',
        defaultMessage: 'Data e ora richiesta',
        id: 'yqXYfy',
      }),
      nameSurnameLabel: intl.formatMessage({
        description: 'stock detail dashboard component name surname label',
        defaultMessage: 'Nome Cognome',
        id: '9eTteh',
      }),
      companyLabel: intl.formatMessage({
        description: 'stock detail dashboard component company label',
        defaultMessage: 'Azienda',
        id: 'ibRlgG',
      }),
      dateTimeProcessingLabel: intl.formatMessage({
        description:
          'stock detail dashboard component processing date time label',
        defaultMessage: 'Data e ora ricezione',
        id: 'iV4X2D',
      }),
      agentIdLabel: intl.formatMessage({
        description: 'stock detail dashboard component agent id label',
        defaultMessage: 'ID Agente',
        id: '+HWhpO',
      }),
    },
    updateUserSettingsDrawerContent: {
      title: intl.formatMessage({
        description: 'update user settings component title label',
        defaultMessage: 'Modifica Impostazioni',
        id: 'jRP4fn',
      }),
      personalizePermits: intl.formatMessage({
        description:
          'update user settings personalize permits button title label',
        defaultMessage: 'Personalizza permessi',
        id: 'CDft8j',
      }),
      cancelButtonLabel: intl.formatMessage({
        description: 'update user settings component cancel button label',
        defaultMessage: 'Annulla',
        id: 'WvSKc8',
      }),
      createButtonLabel: intl.formatMessage({
        description: 'update user settings component create button label',
        defaultMessage: 'Modifica',
        id: 'hzksyG',
      }),
      form: {
        nameLabel: intl.formatMessage({
          description: 'update user settings component form request name label',
          defaultMessage: 'Nome',
          id: 'UcC5SR',
        }),
        surnameLabel: intl.formatMessage({
          description: 'update user settings component form surname label',
          defaultMessage: 'Cognome*',
          id: '2iW4M6',
        }),
        emailLabel: intl.formatMessage({
          description: 'update user settings component form email label',
          defaultMessage: 'Email*',
          id: 'x38Qmq',
        }),
        countryLabel: intl.formatMessage({
          description: 'update user settings component form country label',
          defaultMessage: 'Nazione',
          id: '7L801M',
        }),
        languageLabel: intl.formatMessage({
          description: 'update user settings component form language label',
          defaultMessage: 'Lingua',
          id: 'T9JAk1',
        }),
        errors: {
          name: {
            required: intl.formatMessage({
              description:
                'update user settings component form request name required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'TgwSom',
            }),
          },
          surname: {
            required: intl.formatMessage({
              description:
                'update user settings component form surname required error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'JHnCJj',
            }),
          },
          email: {
            required: intl.formatMessage({
              description:
                'update user settings component form username email error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'pmmYcF',
            }),
          },
          country: {
            required: intl.formatMessage({
              description:
                'update user settings component form comapny country error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'irpBTK',
            }),
          },
          language: {
            required: intl.formatMessage({
              description:
                'update user settings component form comapny language error label',
              defaultMessage: 'Campo obbligatorio',
              id: 'piW4J5',
            }),
          },
        },
      },
    },
    menuList: {
      menu: {
        home: intl.formatMessage({
          description: 'menu list component home label',
          defaultMessage: 'Home',
          id: 'NTi6nt',
        }),
        users: intl.formatMessage({
          description: 'menu list component users label',
          defaultMessage: 'Utenti',
          id: '69dF5j',
        }),
        stocklist: intl.formatMessage({
          description: 'menu list component stocklist label',
          defaultMessage: 'Stocklist',
          id: 'bQ6Qho',
        }),
        settings: intl.formatMessage({
          description: 'menu list component settings label',
          defaultMessage: 'Impostazioni',
          id: 'hImV46',
        }),
        notifications: intl.formatMessage({
          description: 'menu list component notifications label',
          defaultMessage: 'Notifiche',
          id: 'azbgjQ',
        }),
        logout: intl.formatMessage({
          description: 'menu list component logout label',
          defaultMessage: 'Logout',
          id: 'n80FA4',
        }),
        orders: intl.formatMessage({
          description: 'menu list component orders label',
          defaultMessage: 'Ordini',
          id: 'OcQmA2',
        }),
        ordersHistory: intl.formatMessage({
          description: 'menu list component orders history label',
          defaultMessage: 'Storico ordini',
          id: 'CR0LXS',
        }),
      },
      logoutInfoDialog: {
        title: intl.formatMessage({
          description: 'menu list component info popup title label',
          defaultMessage: 'Logout',
          id: 'GOeSD6',
        }),
        message: intl.formatMessage({
          description: 'menu list component info popup message label',
          defaultMessage: 'Sei sicuro di effettuare il logout?',
          id: 'QVAFYM',
        }),
        cancelButtonLabel: intl.formatMessage({
          description: 'menu list component info popup cancel button label',
          defaultMessage: 'Annulla',
          id: 'zN3ca8',
        }),
        agreeButtonLabel: intl.formatMessage({
          description:
            'menu list component info popup agree button label label',
          defaultMessage: 'Conferma',
          id: 'WbzqqL',
        }),
      },
    },
    usePermissions: {
      userTypePermission: {
        WIDGET_READ: intl.formatMessage({
          description: 'use permission hook user type permission WIDGET_READ',
          defaultMessage: 'Home',
          id: 'DHg7IT',
        }),
        WIDGET_STOCKLIST: intl.formatMessage({
          description:
            'use permission hook user type permission WIDGET_STOCKLIST',
          defaultMessage: 'Widget Stocklist',
          id: 'QdLTCG',
        }),
        WIDGET_USERS: intl.formatMessage({
          description: 'use permission hook user type permission WIDGET_USERS',
          defaultMessage: 'Widget Utenti',
          id: 'R7L6rG',
        }),
        WIDGET_ORDERS_FILATI: intl.formatMessage({
          description:
            'use permission hook user type permission WIDGET_ORDERS_FILATI',
          defaultMessage: 'Widget Ordini Filati',
          id: '/YP9Kh',
        }),
        WIDGET_ORDERS_CAMPIONARIO: intl.formatMessage({
          description:
            'use permission hook user type permission WIDGET_ORDERS_CAMPIONARIO',
          defaultMessage: 'Widget Ordini Campionario',
          id: 'ZTo0p6',
        }),
        WIDGET_ORDERS_LABDIP: intl.formatMessage({
          description:
            'use permission hook user type permission WIDGET_ORDERS_LABDIP',
          defaultMessage: 'Widget Ordini Labdip',
          id: 'tblh1I',
        }),
        USER_READ: intl.formatMessage({
          description: 'use permission hook user type permission USER_READ',
          defaultMessage: 'Utenti',
          id: '5fUkMi',
        }),
        USER_CREATE: intl.formatMessage({
          description: 'use permission hook user type permission USER_CREATE',
          defaultMessage: 'Crea',
          id: 'IXNr/u',
        }),
        USER_PARTIAL_UPDATE: intl.formatMessage({
          description:
            'use permission hook user type permission USER_PARTIAL_UPDATE',
          defaultMessage: 'Modifica (parziale)',
          id: 'OurOwF',
        }),
        USER_UPDATE: intl.formatMessage({
          description: 'use permission hook user type permission USER_UPDATE',
          defaultMessage: 'Modifica (completa)',
          id: 'vTCCr1',
        }),
        USER_DELETE: intl.formatMessage({
          description: 'use permission hook user type permission USER_DELETE',
          defaultMessage: 'Elimina',
          id: 'pj6kNj',
        }),
        STOCKLIST_READ: intl.formatMessage({
          description:
            'use permission hook user type permission STOCKLIST_READ',
          defaultMessage: 'Richieste Stocklist',
          id: 'b8mVnF',
        }),
        STOCKLIST_CREATE: intl.formatMessage({
          description:
            'use permission hook user type permission STOCKLIST_CREATE',
          defaultMessage: 'Crea',
          id: 'q+0J7L',
        }),
        STOCKLIST_READ_CUSTOMER_GROUP: intl.formatMessage({
          description:
            'use permission hook user type permission STOCKLIST_READ_CUSTOMER_GROUP',
          defaultMessage: 'Visualizza richieste gruppo clienti',
          id: 'BfTGiY',
        }),
        MANUAL_NOTIFICATION: intl.formatMessage({
          description:
            'use permission hook user type permission MANUAL_NOTIFICATION',
          defaultMessage: 'Notifiche Manuali',
          id: 'pxf2T0',
        }),
        SEND_MANUAL_NOTIFICATION: intl.formatMessage({
          description:
            'use permission hook user type permission SEND_MANUAL_NOTIFICATION',
          defaultMessage: 'Invia Notifiche',
          id: 'hp9LNE',
        }),
        INVALIDATE_CACHE: intl.formatMessage({
          description:
            'use permission hook user type permission INVALIDATE_CACHE',
          defaultMessage: 'Invalidazione Cache',
          id: 'xonwog',
        }),
        INVALIDATE_CACHE_2: intl.formatMessage({
          description:
            'use permission hook user type permission INVALIDATE_CACHE_2',
          defaultMessage: 'Invalidazione Cache',
          id: 'DX74lf',
        }),
        ORDERS_READ: intl.formatMessage({
          description: 'use permission hook user type permission ORDERS_READ',
          defaultMessage: 'Ordini',
          id: 'qcOvYl',
        }),
        ORDERS_HISTORY_READ: intl.formatMessage({
          description:
            'use permission hook user type permission ORDERS_HISTORY_READ',
          defaultMessage: 'Storico Ordini',
          id: 'F7cbDN',
        }),
        BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY: intl.formatMessage({
          description:
            'use permission hook user type permission BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY',
          defaultMessage: 'Condizioni commerciali su ordini',
          id: 'k4dTWm',
        }),
        BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2: intl.formatMessage({
          description:
            'use permission hook user type permission BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2',
          defaultMessage: 'Condizioni commerciali su ordini',
          id: 'nNHe4s',
        }),
      },
    },
    permissionSelector: {
      title: intl.formatMessage({
        description: 'permission selection component title label',
        defaultMessage: 'Permessi',
        id: 'Z5c9if',
      }),
      resetButtonlabel: intl.formatMessage({
        description: 'permission selection component reset button label',
        defaultMessage: 'Reset Permessi',
        id: 'Vvd0aW',
      }),
    },
    userInfo: {
      hello: intl.formatMessage({
        description: 'user info component hello label',
        defaultMessage: 'Ciao',
        id: 'aId+ut',
      }),
    },
    mobileTable: {
      orderLabel: intl.formatMessage({
        description: 'mobile table component select order label',
        defaultMessage: 'Ordina Per',
        id: 'm7yfvH',
      }),
      emptyLabel: intl.formatMessage({
        description: 'mobile table component empty label',
        defaultMessage: 'Non ci sono risultati',
        id: 'pDkY3p',
      }),
    },
    userListFiltersMobile: {
      emailLabel: intl.formatMessage({
        description: 'user list filter mobile component email label',
        defaultMessage: 'Email',
        id: 'c6D398',
      }),
    },
    desktopTable: {
      emptyLabel: intl.formatMessage({
        description: 'desktop table component empty label',
        defaultMessage: 'Non ci sono risultati',
        id: 'nS1Dj8',
      }),
    },
    notificationTime: {
      moreDayAgo: intl.formatMessage({
        description: 'notification time more than a day ago',
        defaultMessage: 'Più di un giorno fa',
        id: 'd9G+V/',
      }),
      oneDayAgo: intl.formatMessage({
        description: 'notification time day ago',
        defaultMessage: 'Un giorno fa',
        id: 'BzRwmf',
      }),
      moreHourAgo: intl.formatMessage({
        description: 'notification time more than an hour ago',
        defaultMessage: 'Più di un ora fa',
        id: '3dHOOd',
      }),
      minuteAgo: intl.formatMessage({
        description: 'notification time minute ago',
        defaultMessage: 'minuti fa',
        id: 'gXUe3v',
      }),
    },
    notificationPopper: {
      title: intl.formatMessage({
        description: 'notification popper title',
        defaultMessage: 'Elenco Notifiche',
        id: 'X6+AYE',
      }),
      readAll: intl.formatMessage({
        description: 'notification popper read all',
        defaultMessage: 'Segna come lette',
        id: 'TMNgP5',
      }),
      viewAll: intl.formatMessage({
        description: 'notification popper view all',
        defaultMessage: 'VEDI TUTTE',
        id: 'o3LheY',
      }),
    },
    newManualNotificationDrawerContent: {
      title: intl.formatMessage({
        description: 'new manual notification drawer content title',
        defaultMessage: 'Elenco Notifiche',
        id: 'oRFSQq',
      }),
      bottomContainerTitle: intl.formatMessage({
        description:
          'new manual notification drawer content bottom container title',
        defaultMessage: 'Seleziona destinatari',
        id: 'uXt2CY',
      }),
      bottomContainerSubtitle: intl.formatMessage({
        description:
          'new manual notification drawer content bottom container title',
        defaultMessage: 'Seleziona un gruppo di utenti',
        id: 'PE7qxr',
      }),
      bottomSelectTextType: intl.formatMessage({
        description:
          'new manual notification drawer content bottom container title',
        defaultMessage:
          'Seleziona il gruppo di utenti a cui vuoi inviare la notifica',
        id: 'IbddSD',
      }),
      bottomSelectTextUsers: intl.formatMessage({
        description:
          'new manual notification drawer content bottom container title',
        defaultMessage:
          'Inserisci qui sotto i nomi degli utenti a cui vuoi inviare la notifica',
        id: 'If4Xjo',
      }),
      form: {
        notificationTitleLabel: intl.formatMessage({
          description:
            'new manual notification drawer content notification title',
          defaultMessage: 'Titolo',
          id: 'Gcg6tQ',
        }),
        messageLabel: intl.formatMessage({
          description:
            'new manual notification drawer content notification message',
          defaultMessage: 'Testo del messaggio',
          id: 'ddZTPE',
        }),
        recipientTypeLabel: intl.formatMessage({
          description:
            'new manual notification drawer content recipient type label',
          defaultMessage: 'Invia a',
          id: 'TXBeVN',
        }),
        recipientsLabel: intl.formatMessage({
          description:
            'new manual notification drawer content recipients label',
          defaultMessage: 'Seleziona',
          id: 'QV3QfR',
        }),
        sendButtonLabel: intl.formatMessage({
          description:
            'new manual notification drawer content notification send button label',
          defaultMessage: 'Invia',
          id: '+zFmtx',
        }),
        cancelButtonLabel: intl.formatMessage({
          description:
            'new manual notification drawer content notification cancel button label',
          defaultMessage: 'Annulla',
          id: 'Qmmx5U',
        }),
        errors: {
          title: {
            required: intl.formatMessage({
              description:
                'new manual notification drawer content notification title required label',
              defaultMessage: 'Il titolo è obbligatorio',
              id: '68krmQ',
            }),
          },
          message: {
            required: intl.formatMessage({
              description:
                'new manual notification drawer content notification message required label',
              defaultMessage: 'Il messaggio è obbligatorio',
              id: 'Bo+rYn',
            }),
          },
          recipients: {
            required: intl.formatMessage({
              description:
                'new manual notification drawer content notification recipients required label',
              defaultMessage: 'Il destinatari sono obbligatori',
              id: 'f09AcC',
            }),
          },
        },
      },
    },
    duplicateStock: {
      title: intl.formatMessage({
        description: 'Duplicate stock popup title',
        defaultMessage: 'Reinvia richiesta',
        id: '2UR72W',
      }),
      message: intl.formatMessage({
        description: 'Duplicate stock popup message',
        defaultMessage: 'Vuoi rinviare nuovamente questa richiesta?',
        id: 'iYOXqI',
      }),
      cancelButtonLabel: intl.formatMessage({
        description: 'Duplicate stock popup cancel button',
        defaultMessage: 'Annulla',
        id: 'yp+SzO',
      }),
      agreeButtonLabel: intl.formatMessage({
        description: 'Duplicate stock popup agree button',
        defaultMessage: 'Conferma',
        id: 'IKHvD8',
      }),
    },
    manualNotificationListFilters: {
      titleLabel: intl.formatMessage({
        description: 'manual notification list  filters component title label',
        defaultMessage: 'Titolo o Testo',
        id: '6J+XYY',
      }),
      dateFromLabel: intl.formatMessage({
        description: 'manual notification list  filters component title label',
        defaultMessage: 'Data-Ora Richiesta (Da)',
        id: 'Mp92Zn',
      }),
      dateToLabel: intl.formatMessage({
        description: 'manual notification list  filters component title label',
        defaultMessage: 'Data-Ora Richiesta (A)',
        id: '4xwEX/',
      }),
      buttonSubmitLabel: intl.formatMessage({
        description:
          'manual notification list  filters component button submit label',
        defaultMessage: 'Cerca',
        id: 'wf9llA',
      }),
      buttonClearFilters: intl.formatMessage({
        description:
          'manual notification list  filters component button clear filters label',
        defaultMessage: 'Azzera Filtri',
        id: 'KdmnsP',
      }),
    },
    manualNotificationsUsersFilters: {
      title: intl.formatMessage({
        description:
          'manual notification user list filters component section title label',
        defaultMessage: 'Includi Utenti',
        id: 'h1gnpG',
      }),
      nameSurnameLabel: intl.formatMessage({
        description:
          'manual notification user list filters component nameSurname label',
        defaultMessage: 'Nome Cognome',
        id: 'OVx2hk',
      }),
      countryLabel: intl.formatMessage({
        description:
          'manual notification user list filters component country label',
        defaultMessage: 'Nazione',
        id: 'vD94At',
      }),
      languageLabel: intl.formatMessage({
        description:
          'manual notification user list filters component language label',
        defaultMessage: 'Lingua',
        id: '5vWeF3',
      }),
      companyLabel: intl.formatMessage({
        description:
          'manual notification user list filters component company label',
        defaultMessage: 'Azienda',
        id: 'zjrFpa',
      }),
      typeLabel: intl.formatMessage({
        description:
          'manual notification user list filters component type label',
        defaultMessage: 'Tipologia Utente',
        id: 'oqIX1v',
      }),
      buttonSubmitLabel: intl.formatMessage({
        description:
          'manual notification user list filters component button submit label',
        defaultMessage: 'Aggiungi',
        id: '42uR3B',
      }),
    },
    ordersFilters: {
      customerOrder: intl.formatMessage({
        description: 'orders filters customer order label',
        defaultMessage: 'Ordine cliente',
        id: 'OktlDy',
      }),
      orderDate: intl.formatMessage({
        description: 'orders filters order date label',
        defaultMessage: 'Data ord. cliente (dal-al)',
        id: 'NT8B7r',
      }),
      orderConfirmation: intl.formatMessage({
        description: 'orders filters order confirmation label',
        defaultMessage: 'Conferma ordine',
        id: '2IDERH',
      }),
      orderType: intl.formatMessage({
        description: 'orders filters order type label',
        defaultMessage: 'Tipo',
        id: 'VlurQe',
      }),
      progress: intl.formatMessage({
        description: 'orders filters progress label',
        defaultMessage: 'Avanzamento',
        id: 'FP0wSn',
      }),
      customer: intl.formatMessage({
        description: 'orders filters customer label',
        defaultMessage: 'Cliente',
        id: '59ZQpS',
      }),
      cariaggiColor: intl.formatMessage({
        description: 'orders filters cariaggi color label',
        defaultMessage: 'Articolo - Colore della cariaggi',
        id: 'GcbDS9',
      }),
      customerColor: intl.formatMessage({
        description: 'orders filters customer color label',
        defaultMessage: 'Articolo - Colore del cliente',
        id: 'jEK0hF',
      }),
      shippingDate: intl.formatMessage({
        description: 'orders filters shipping date label',
        defaultMessage: 'Data spedizione (dal-al)',
        id: '5Ft4AR',
      }),
      orderByUndefinedShippingDate: intl.formatMessage({
        description: 'orders filters order by undefined shipping date label',
        defaultMessage:
          'Mostra solo ordini con data spedizione "in definizione"',
        id: 'CepBoN',
      }),
      finalCustomer: intl.formatMessage({
        description: 'orders filters final customer label',
        defaultMessage: 'Cliente finale',
        id: '5Hgpfn',
      }),
      finalDestination: intl.formatMessage({
        description: 'orders filters final destination label',
        defaultMessage: 'Destinazione finale',
        id: 'FG88b2',
      }),
      customerSeason: intl.formatMessage({
        description: 'orders filters customer season label',
        defaultMessage: 'Stagione cliente',
        id: 'ue3P76',
      }),
      customerLine: intl.formatMessage({
        description: 'orders filters customer line label',
        defaultMessage: 'Linea cliente',
        id: '1LEv8W',
      }),
      customerBoard: intl.formatMessage({
        description: 'orders filters customer board label',
        defaultMessage: 'Board cliente',
        id: '0CHwFw',
      }),
      currency: intl.formatMessage({
        description: 'orders filters currency label',
        defaultMessage: 'Moneta',
        id: 'Pld0sb',
      }),
      payment: intl.formatMessage({
        description: 'orders filters payment label',
        defaultMessage: 'Pagamento',
        id: 'HUhtOS',
      }),
      status: intl.formatMessage({
        description: 'orders filters status label',
        defaultMessage: 'Stato',
        id: 'LiJXKO',
      }),
      daysToExtract: intl.formatMessage({
        description: 'orders filters days to extract label',
        defaultMessage: 'N. giorni da estrarre',
        id: 'pYU9mf',
      }),
    },
    tooltip: {
      sectionRefresh: intl.formatMessage({
        description: 'tooltip component section refresh label',
        defaultMessage: 'Aggiorna i dati di questa sezione',
        id: 'PE5Hke',
      }),
      infoButton: intl.formatMessage({
        description: 'tooltip component info button label',
        defaultMessage: 'Accedi alla documentazione di piattaforma',
        id: '8IaiNS',
      }),
      orderDetail: intl.formatMessage({
        description: 'tooltip component order detail label',
        defaultMessage: "Visualizza ulteriori dettagli dell'ordine",
        id: '/30Rp4',
      }),
      menu: {
        home: intl.formatMessage({
          description: 'tooltip component home label',
          defaultMessage: 'Vai alla Home della piattaforma',
          id: 'iZ+6v3',
        }),
        users: intl.formatMessage({
          description: 'tooltip component users label',
          defaultMessage: 'Crea e gestisci gli utenti in piattaforma',
          id: '9r9pjI',
        }),
        stocklist: intl.formatMessage({
          description: 'tooltip component stocklist label',
          defaultMessage: 'Consulta o crea una nuova richiesta stocklist',
          id: 'AKSB/K',
        }),
        orders: intl.formatMessage({
          description: 'tooltip component orders label',
          defaultMessage: 'Consulta gli ordini attivi',
          id: 'uoKx4o',
        }),
        ordersHistory: intl.formatMessage({
          description: 'tooltip component orders history label',
          defaultMessage: 'Consulta gli ordini conclusi',
          id: 'agATEu',
        }),
        notifications: intl.formatMessage({
          description: 'tooltip component notifications label',
          defaultMessage: 'Invia messaggi agli utenti',
          id: 'KyqpWI',
        }),
        settings: intl.formatMessage({
          description: 'tooltip component settings label',
          defaultMessage: 'Gestisci le tue impostazioni di piattaforma',
          id: '/KeQnr',
        }),
        logout: intl.formatMessage({
          description: 'tooltip component logout label',
          defaultMessage: 'Esci dalla piattaforma',
          id: 'FsyJqL',
        }),
      },
    },
  }),
  pages: (intl) => ({
    login: {
      welcome: intl.formatMessage({
        description: 'login page welcome label',
        defaultMessage: 'Benvenuto',
        id: 'HVUyBk',
      }),
      enter: intl.formatMessage({
        description: 'login page enter label',
        defaultMessage: 'Accedi per continuare',
        id: 'e0X1ws',
      }),
      forgotPassword: intl.formatMessage({
        description: 'login page forgot password label',
        defaultMessage: 'Password dimenticata?',
        id: 'og5EmE',
      }),
      incorrectUsernameOrPassword: {
        title: intl.formatMessage({
          description: 'login page incorrect username or password dialog label',
          defaultMessage: 'Errore durante il login',
          id: 'OpfCgh',
        }),
        message: intl.formatMessage({
          description: 'login page incorrect username or password dialog label',
          defaultMessage: 'Il nome utente o la password non sono corretti',
          id: 'VmEmLM',
        }),
        closeButton: intl.formatMessage({
          description: 'login page incorrect username or password dialog label',
          defaultMessage: 'Chiudi',
          id: '+sqgat',
        }),
      },
    },
    signin: {
      title: intl.formatMessage({
        description: 'signin page title label',
        defaultMessage: 'Registrazione',
        id: 'GEUzsq',
      }),
      subtitle: intl.formatMessage({
        description: 'signin page subtitle label',
        defaultMessage: 'Completa la form per fare richiesta di registrazione',
        id: 'R92eGQ',
      }),
      errors: {
        userAlreadyExists: {
          title: intl.formatMessage({
            description: 'signin page user already exists title label',
            defaultMessage: 'Errore durante la registrazione',
            id: '6vAU92',
          }),
          message: intl.formatMessage({
            description: 'signin page user already exists message label',
            defaultMessage: 'Nome utente già esistente nel sistema',
            id: 'TRuakc',
          }),
          button: intl.formatMessage({
            description: 'signin page user already exists button label',
            defaultMessage: 'Chiudi',
            id: 'lkZqTQ',
          }),
        },
      },
    },
    activate: {
      text1: intl.formatMessage({
        description: 'activate page text1 label',
        defaultMessage:
          'Grazie per aver completato la richiesta di registrazione.',
        id: 'trJ1Jw',
      }),
      text2: intl.formatMessage({
        description: 'activate page text2 label',
        defaultMessage:
          'Non appena un nostro operatore validerà i dati inseriti riceverai una mail con le istruzioni per accedere alla piattaforma',
        id: '0nuFle',
      }),
      buttonLabel: intl.formatMessage({
        description: 'activate page button label',
        defaultMessage: 'Chiudi',
        id: 'XSMfKi',
      }),
    },
    users: {
      title: intl.formatMessage({
        description: 'users page title label',
        defaultMessage: 'Utenti',
        id: 'ssXSo4',
      }),
      newUserButton: intl.formatMessage({
        description: 'users page new user button label',
        defaultMessage: 'Nuovo Utente',
        id: 'qBPstE',
      }),
      userListLabel: intl.formatMessage({
        description: 'users page user list label',
        defaultMessage: 'Lista Utenti Attivi',
        id: 'm0E8HD',
      }),
      headers: {
        status: intl.formatMessage({
          description: 'users page header status label',
          defaultMessage: 'Status',
          id: 'T+uqnJ',
        }),
        name: intl.formatMessage({
          description: 'users page header name label',
          defaultMessage: 'Nome',
          id: 'u+Mktw',
        }),
        surname: intl.formatMessage({
          description: 'users page header surname label',
          defaultMessage: 'Cognome',
          id: 'xhMpem',
        }),
        company: intl.formatMessage({
          description: 'users page header company label',
          defaultMessage: 'Azienda',
          id: 'cs+DjZ',
        }),
        email: intl.formatMessage({
          description: 'users page header email label',
          defaultMessage: 'Email',
          id: 'osZ5wK',
        }),
        type: intl.formatMessage({
          description: 'users page header type label',
          defaultMessage: 'Tipo Utente',
          id: 'dX7TXo',
        }),
        customer: intl.formatMessage({
          description: 'users page header customer label',
          defaultMessage: 'Cliente',
          id: 'MWARp/',
        }),
        country: intl.formatMessage({
          description: 'users page header country label',
          defaultMessage: 'Nazione',
          id: 'c3up76',
        }),
        language: intl.formatMessage({
          description: 'users page header language label',
          defaultMessage: 'Lingua',
          id: 'UlQl8C',
        }),
      },
    },
    notifications: {
      readAllButton: intl.formatMessage({
        description: 'notifications page read all button label',
        defaultMessage: 'Segna tutte come lette',
        id: 'vlET1o',
      }),
      notificationListLabel: intl.formatMessage({
        description: 'notifications page user list label',
        defaultMessage: 'Elenco notifiche',
        id: 'qLxW2s',
      }),
      menuReadNotification: intl.formatMessage({
        description: 'notifications page read menu read notification label',
        defaultMessage: 'Segna come letta',
        id: 'NiRsO3',
      }),
      menuToReadNotification: intl.formatMessage({
        description: 'notifications page read menu to read notification label',
        defaultMessage: 'Segna come da leggere',
        id: 'KOSmTK',
      }),
      menuDeleteNotification: intl.formatMessage({
        description: 'notifications page read menu delete notification label',
        defaultMessage: 'Elimina',
        id: 'SuG1+t',
      }),
      deleteDialog: {
        title: intl.formatMessage({
          description: 'notifications page read menu delete dialog title',
          defaultMessage: 'Elimina Notifica',
          id: '+TssRc',
        }),
        message: intl.formatMessage({
          description: 'notifications page read menu delete dialog message',
          defaultMessage: 'Sei sicuro di voler eliminare la notifica?',
          id: 'lbVf1E',
        }),
        confirmButton: intl.formatMessage({
          description:
            'notifications page read menu delete dialog confirm button label',
          defaultMessage: 'Cancella',
          id: 'wB+qBo',
        }),
        cancelButton: intl.formatMessage({
          description:
            'notifications page read menu delete dialog cancel button label',
          defaultMessage: 'Annulla',
          id: 'Wi27ec',
        }),
      },
      headers: {
        status: intl.formatMessage({
          description: 'notifications page header status label',
          defaultMessage: 'Status',
          id: '/RTfJd',
        }),
        message: intl.formatMessage({
          description: 'notifications page header notification message label',
          defaultMessage: 'Nome',
          id: '2KyNdK',
        }),
        text: intl.formatMessage({
          description: 'notifications page header notification message label',
          defaultMessage: 'Testo del Messaggio',
          id: '06Rtkh',
        }),
        time: intl.formatMessage({
          description: 'notifications page header time label',
          defaultMessage: 'Ricevuta',
          id: 'F5mQ6M',
        }),
      },
    },
    manualNotifications: {
      title: intl.formatMessage({
        description: 'manual notifications page title label',
        defaultMessage: 'Gestione notifiche manuali',
        id: 'fDQwWj',
      }),
      newMessage: intl.formatMessage({
        description: 'manual notifications page new message button label',
        defaultMessage: 'Nuovo Messaggio',
        id: '/Vs4v0',
      }),
      notificationListLabel: intl.formatMessage({
        description: 'manual notifications page notification sent list label',
        defaultMessage: 'Elenco notifiche inviate',
        id: 'QQQcX+',
      }),
      viewButtonLabel: intl.formatMessage({
        description: 'manual notifications page notification view button label',
        defaultMessage: 'Vedi',
        id: 'aWhXBJ',
      }),
      headers: {
        title: intl.formatMessage({
          description: 'manual notifications page header title label',
          defaultMessage: 'Titolo',
          id: 'JB0DxB',
        }),
        message: intl.formatMessage({
          description:
            'manual notifications page header notification message label',
          defaultMessage: 'Testo',
          id: 'lTCAtk',
        }),
        time: intl.formatMessage({
          description: 'manual notifications page header time label',
          defaultMessage: 'Data/ora invio',
          id: 'T4+N+E',
        }),
        recipients: intl.formatMessage({
          description: 'manual notifications page header recipients label',
          defaultMessage: 'Destinatari',
          id: 'Pee2zy',
        }),
      },
    },
    newManualNotifications: {
      title: intl.formatMessage({
        description: 'new manual notifications page title label',
        defaultMessage: 'Invia notifiche manuali',
        id: 'rs9j7y',
      }),
      buttonSubmitSend: intl.formatMessage({
        description: 'new manual notifications page title label',
        defaultMessage: 'Invia Notifica',
        id: 'Ojm6UP',
      }),
      removeAllSelectedButton: intl.formatMessage({
        description:
          'new manual notifications page remove all selected button label',
        defaultMessage: 'Rimuovi Tutti',
        id: 'mcjnvp',
      }),
      form: {
        titleLabel: intl.formatMessage({
          description: 'new manual notifications form title label',
          defaultMessage: 'Titolo',
          id: 'jb6RX6',
        }),
        messageLabel: intl.formatMessage({
          description: 'new manual notifications form message label',
          defaultMessage: 'Testo del messaggio',
          id: 'SL3n14',
        }),
        resultMessage: intl.formatMessage({
          description: 'new manual notifications result message label',
          defaultMessage: 'Seleziona destinatari della notifica',
          id: 'gG8U8f',
        }),
        errors: {
          title: {
            required: intl.formatMessage({
              description:
                'new manual notifications form message title required label',
              defaultMessage: 'Il titolo e obbligatorio',
              id: 'r91qyZ',
            }),
          },
          text: {
            required: intl.formatMessage({
              description:
                'new manual notifications form message text required label',
              defaultMessage: 'Il testo e obbligatorio',
              id: 'l8s7V+',
            }),
          },
        },
      },
      headers: {
        nameLabel: intl.formatMessage({
          description: 'new manual notifications page header name label',
          defaultMessage: 'Nome',
          id: 'MJWldJ',
        }),
        surnameLabel: intl.formatMessage({
          description:
            'new manual notifications page header notification surname label',
          defaultMessage: 'Cognome',
          id: 'aaKFHe',
        }),
        userTypeLabel: intl.formatMessage({
          description: 'new manual notifications page header userType label',
          defaultMessage: 'Tipologia Utente',
          id: 'Pu+Mlj',
        }),
        countryLabel: intl.formatMessage({
          description: 'new manual notifications page header country label',
          defaultMessage: 'Nazione',
          id: 'ZSOKlD',
        }),
        languageLabel: intl.formatMessage({
          description: 'new manual notifications page header language label',
          defaultMessage: 'Lingua',
          id: 'J2r3j9',
        }),
        companyLabel: intl.formatMessage({
          description: 'new manual notifications page header company label',
          defaultMessage: 'Azienda',
          id: 'Mbf1N2',
        }),
      },
    },
    manualNotificationDetail: {
      title: intl.formatMessage({
        description: 'manual notifications detail page title label',
        defaultMessage: 'Dettaglio notifica manuale',
        id: 'rEprBy',
      }),
      form: {
        titleLabel: intl.formatMessage({
          description: 'manual notifications detail form title label',
          defaultMessage: 'Titolo',
          id: 'edRxdo',
        }),
        messageLabel: intl.formatMessage({
          description: 'manual notifications detail form message label',
          defaultMessage: 'Testo del messaggio',
          id: 'ETS3vh',
        }),
      },
      listTitle: intl.formatMessage({
        description: 'manual notifications detail page list title',
        defaultMessage: 'Destinatari della notifica',
        id: 'KXkGZ9',
      }),
      headers: {
        name: intl.formatMessage({
          description: 'manual notifications detail page header name label',
          defaultMessage: 'Nome',
          id: '+EmIT7',
        }),
        surname: intl.formatMessage({
          description:
            'manual notifications detail page header notification surname label',
          defaultMessage: 'Cognome',
          id: 'tUg12q',
        }),
        userType: intl.formatMessage({
          description:
            'manual notifications detail page header user Type label',
          defaultMessage: 'Tipologia Utente',
          id: 'dI0fGe',
        }),
        country: intl.formatMessage({
          description: 'manual notifications detail page header country label',
          defaultMessage: 'Nazione',
          id: 'So9JsL',
        }),
        language: intl.formatMessage({
          description: 'manual notifications detail page header language label',
          defaultMessage: 'Lingua',
          id: 'eFFP3Z',
        }),
        company: intl.formatMessage({
          description: 'manual notifications detail page header company label',
          defaultMessage: 'Azienda',
          id: 'e6IH0p',
        }),
      },
    },
    stocklist: {
      title: intl.formatMessage({
        description: 'stocklist page title label',
        defaultMessage: 'Stocklist',
        id: 'mESaxr',
      }),
      newRequestButton: intl.formatMessage({
        description: 'stocklist page new request button label',
        defaultMessage: 'Nuova richiesta',
        id: 'DI5qDS',
      }),
      userListLabel: intl.formatMessage({
        description: 'stocklist page stocklist list label',
        defaultMessage: 'Lista richieste Stocklist',
        id: 'KmHrd0',
      }),
      headers: {
        status: intl.formatMessage({
          description: 'stocklist page header status label',
          defaultMessage: 'Status',
          id: 'gfLbtv',
        }),
        idRequest: intl.formatMessage({
          description: 'stocklist page header id request label',
          defaultMessage: 'ID richiesta',
          id: 'UBpBaF',
        }),
        name: intl.formatMessage({
          description: 'stocklist page header name label',
          defaultMessage: 'Nome Richiesta',
          id: '2EYLPG',
        }),
        user: intl.formatMessage({
          description: 'stocklist page header user label',
          defaultMessage: 'Utente Richiedente',
          id: 'XbUy6p',
        }),
        requestDateTime: intl.formatMessage({
          description: 'stocklist page header request date time label',
          defaultMessage: 'Data e ora richiesta',
          id: 'KMY0HI',
        }),
        processingDateTime: intl.formatMessage({
          description: 'stocklist page header processing date time label',
          defaultMessage: 'Data e ora fine elaborazione',
          id: 'QCiNq2',
        }),
        customer: intl.formatMessage({
          description: 'stocklist page header customer label',
          defaultMessage: 'Cliente',
          id: 'Semy8y',
        }),
        agent: intl.formatMessage({
          description: 'stocklist page header customer label',
          defaultMessage: 'Agente',
          id: 'J1KkrP',
        }),
        article: intl.formatMessage({
          description: 'stocklist page header article label',
          defaultMessage: 'Articolo',
          id: 'QWNd87',
        }),
        color: intl.formatMessage({
          description: 'stocklist page header color label',
          defaultMessage: 'Colore',
          id: 'eIhUD1',
        }),
        email: intl.formatMessage({
          description: 'stocklist page header email label',
          defaultMessage: 'Email',
          id: 'or0c18',
        }),
        type: intl.formatMessage({
          description: 'stocklist page header type label',
          defaultMessage: 'Formato',
          id: '5a42wI',
        }),
      },
    },
    stockDetail: {
      title: intl.formatMessage({
        description: 'stock detail page title label',
        defaultMessage: 'Richiesta Stocklist',
        id: 'O+J2bf',
      }),
      stockDetailTableTitle: intl.formatMessage({
        description: 'stock detail page table title label',
        defaultMessage: 'Risultato della richiesta',
        id: 'LF5Fva',
      }),
      popperNotesLabel: intl.formatMessage({
        description: 'stock detail page popper notes label label',
        defaultMessage: 'Note',
        id: 'ezb7ho',
      }),
      exportButton: intl.formatMessage({
        description: 'stock detail page export button label',
        defaultMessage: 'Esporta PDF',
        id: 'iXZ0pm',
      }),
      emptyStocklistLabel: intl.formatMessage({
        description: 'empty stocklist detail label',
        defaultMessage:
          'Non sono presenti prodotti disponibili per la richiesta effettuata.',
        id: 'STFrSP',
      }),
      searchBarLabel: intl.formatMessage({
        description: 'search bar detail label',
        defaultMessage: 'Cerca',
        id: 'UBRAkX',
      }),
      headers: {
        article: intl.formatMessage({
          description: 'stock detail page table header article label',
          defaultMessage: 'Articolo',
          id: '5g/F2E',
        }),
        color: intl.formatMessage({
          description: 'stock detail page table header color label',
          defaultMessage: 'Colore',
          id: 'vwsexv',
        }),
        batch: intl.formatMessage({
          description: 'stock detail page table header batch label',
          defaultMessage: 'Partita',
          id: 'IKQT7h',
        }),
        bagno: intl.formatMessage({
          description: 'stock detail page table header bagno label',
          defaultMessage: 'Bagno',
          id: '6yOUX0',
        }),
        packaging: intl.formatMessage({
          description: 'stock detail page table header packaging label',
          defaultMessage: 'Confezione',
          id: 'BbU7/g',
        }),
        availible: intl.formatMessage({
          description: 'stock detail page table header availible label',
          defaultMessage: 'Disponibile',
          id: 'ST3pEX',
        }),
        futureAvailable: intl.formatMessage({
          description: 'stock detail page table header future available label',
          defaultMessage: 'Disponibile futuro',
          id: 'DKWsyP',
        }),
        firstQntAvailable: intl.formatMessage({
          description:
            'stock detail page table header first qnt available label',
          defaultMessage: 'Prima Q.tà disponibile',
          id: '8HM3hV',
        }),
        firstDateAvailable: intl.formatMessage({
          description:
            'stock detail page table header first date available label',
          defaultMessage: 'Prima data disponibile',
          id: 'mG6NaR',
        }),
        notes: intl.formatMessage({
          description: 'stock detail page table header first date notes label',
          defaultMessage: 'Note',
          id: 'QhpF/w',
        }),
      },
    },
    orders: {
      title: intl.formatMessage({
        description: 'orders page title label',
        defaultMessage: 'Ordini',
        id: 'oVptf1',
      }),
      refresh: intl.formatMessage({
        description: 'orders page refresh label',
        defaultMessage: 'ricarica dati',
        id: '3Uy4My',
      }),
      exportExcel: intl.formatMessage({
        description: 'orders page export excel label',
        defaultMessage: 'esporta excel',
        id: 'P9Z7i9',
      }),
      headers: {
        customerOrder: intl.formatMessage({
          description: 'orders table header customer order label',
          defaultMessage: 'Ordine cliente',
          id: 'K9+7Wv',
        }),
        customerOrderDate: intl.formatMessage({
          description: 'orders table header customer order date label',
          defaultMessage: 'Data ordine Cliente',
          id: 'rq1K7M',
        }),
        rows: intl.formatMessage({
          description: 'orders table header rows order label',
          defaultMessage: 'Riga',
          id: '5qi+Eq',
        }),
        confirmOrder: intl.formatMessage({
          description: 'orders table header confirm order label',
          defaultMessage: 'Conferma Ordine',
          id: 'f8fW07',
        }),
        type: intl.formatMessage({
          description: 'orders table header type order label',
          defaultMessage: 'Tipo',
          id: 'Ue+Qz8',
        }),
        status: intl.formatMessage({
          description: 'orders table header status order label',
          defaultMessage: 'Stato',
          id: 'Wprg+U',
        }),
        advancement: intl.formatMessage({
          description: 'orders table header advancement order label',
          defaultMessage: 'Avanzamento',
          id: 'rVLCbN',
        }),
        customer: intl.formatMessage({
          description: 'orders table header customer order label',
          defaultMessage: 'Cliente',
          id: 'mC+ST+',
        }),
        products: intl.formatMessage({
          description: 'orders table header products order label',
          defaultMessage: 'Prodotto',
          id: 't/K4Qb',
        }),
        orderedQuantity: intl.formatMessage({
          description: 'orders table header ordered quantity label',
          defaultMessage: 'Quantità ordinata',
          id: 'r1jDRP',
        }),
        orderedShipped: intl.formatMessage({
          description: 'orders table header ordered shipped label',
          defaultMessage: 'Quantità spedita',
          id: 'DMiVYp',
        }),
        shipmentDate: intl.formatMessage({
          description: 'orders table header shipment date label',
          defaultMessage: 'Data spedizione',
          id: 'bttx/O',
        }),
        unitPrice: intl.formatMessage({
          description: 'orders table header unit price label',
          defaultMessage: 'Prezzo unitario',
          id: 'CprsLk',
        }),
        unitIncrease: intl.formatMessage({
          description: 'orders table header unit increase label',
          defaultMessage: 'Maggiorazione unitaria',
          id: 'BIWOD+',
        }),
        increase: intl.formatMessage({
          description: 'orders table header increase order label',
          defaultMessage: 'Maggiorazione',
          id: 'sDBZud',
        }),
        discount: intl.formatMessage({
          description: 'orders table header discount order label',
          defaultMessage: 'Sconto',
          id: 'P+HcDu',
        }),
        detail: intl.formatMessage({
          description: 'orders table header detail order label',
          defaultMessage: 'Dettaglio',
          id: 'FM26bW',
        }),
        address: intl.formatMessage({
          description: 'orders table header address label',
          defaultMessage: 'Indirizzo',
          id: 'pVR0Lg',
        }),
        customerSeason: intl.formatMessage({
          description: 'orders table header customer season label',
          defaultMessage: 'Stagione cliente',
          id: 'znegCA',
        }),
        customerLine: intl.formatMessage({
          description: 'orders table header customer season label',
          defaultMessage: 'Linea cliente',
          id: 'DWA9Kc',
        }),
        goodsDestination: intl.formatMessage({
          description: 'orders table header goods destination label',
          defaultMessage: 'Destinazione merce',
          id: 'TFnNsJ',
        }),
        finalCustomer: intl.formatMessage({
          description: 'orders table header final customer label',
          defaultMessage: 'Cliente finale',
          id: 'HV/PlA',
        }),
        agent: intl.formatMessage({
          description: 'orders table header agent label',
          defaultMessage: 'Agente',
          id: 'Ib5DCD',
        }),
        customerClerk: intl.formatMessage({
          description: 'orders table customer clerk label',
          defaultMessage: 'Addetto cliente',
          id: '4+NRDd',
        }),
        orderDate: intl.formatMessage({
          description: 'orders table order date label',
          defaultMessage: 'Data ordine',
          id: '3RPL1M',
        }),
        payment: intl.formatMessage({
          description: 'orders table payment label',
          defaultMessage: 'Pagamento',
          id: 'jXMNsl',
        }),
        shippingDocRef: intl.formatMessage({
          description: 'order details shipping doc reference label',
          defaultMessage: 'Rif. Doc. Spedizione',
          id: '1xkYNq',
        }),
        company: intl.formatMessage({
          description: 'global app orders company label',
          defaultMessage: 'Ditta',
          id: 'nV9SR+',
        }),
        // eslint-disable-next-line formatjs/enforce-id
        orderReference: intl.formatMessage({
          description: 'global app orders order reference label',
          defaultMessage: 'Conferma Ordine',
          id: 'R+y5BS',
        }),
        // eslint-disable-next-line formatjs/enforce-id
        shipmentNumber: intl.formatMessage({
          description: 'global app orders shipment number label',
          defaultMessage: 'Numero di spedizioni',
          id: 'GCG5vn',
        }),
        firstShipmentDate: intl.formatMessage({
          description: 'global app orders first shipment date label',
          defaultMessage: 'Data prima spedizione',
          id: '0rIV00',
        }),
        lastShipmentDate: intl.formatMessage({
          description: 'global app orders last shipment date label',
          defaultMessage: 'Data ultima spedizione',
          id: 'JD4VVE',
        }),
        // eslint-disable-next-line formatjs/enforce-id
        ddtReference: intl.formatMessage({
          description: 'global app orders ddt reference label',
          defaultMessage: 'Numero DDT/PL',
          id: 'MzdDaa',
        }),
        // eslint-disable-next-line formatjs/enforce-id
        ddtDate: intl.formatMessage({
          description: 'global app orders ddt date label',
          defaultMessage: 'Data DDT/PL',
          id: 'UNTP2H',
        }),
        invoiceNumber: intl.formatMessage({
          description: 'global app orders invoice number label',
          defaultMessage: 'Num. fattura',
          id: 'fzqbo4',
        }),
        invoiceDate: intl.formatMessage({
          description: 'global app orders invoice date label',
          defaultMessage: 'Data fattura',
          id: 'fja79N',
        }),
      },
    },
    orderDetails: {
      title: intl.formatMessage({
        description: 'order details page title label',
        defaultMessage: 'Dettagli aggiuntivi Ordine',
        id: 'muZdQX',
      }),
    },
    forgotPassword: {
      title: intl.formatMessage({
        description: 'forgot password page dialog error message',
        defaultMessage: 'Reset password avvenuto con successo!',
        id: 'YFVhbJ',
      }),
      subtitle: intl.formatMessage({
        description: 'forgot password page dialog error message',
        defaultMessage:
          "Se il tuo account esiste riceverai una mail all'indirizzo email associato al tuo username",
        id: 'NahGYx',
      }),
      button: intl.formatMessage({
        description: 'forgot password page dialog error message',
        defaultMessage: 'Torna indietro',
        id: '8IU01U',
      }),
    },
    forgotPasswordSubmit: {
      dialog: {
        error: {
          title: intl.formatMessage({
            description: 'forgot password submit page dialog error title',
            defaultMessage: 'Errore',
            id: 'PrZ8ka',
          }),
          message: intl.formatMessage({
            description: 'forgot password submit page dialog error message',
            defaultMessage:
              'Si è verificato un errore con i dati inseriti, riprova!',
            id: 'BqY8De',
          }),
          buttonText: intl.formatMessage({
            description: 'forgot password submit page dialog error buttonText',
            defaultMessage: 'Chiudi',
            id: 'AHaliN',
          }),
        },
      },
    },
    settings: {
      title: intl.formatMessage({
        description: 'settings page title label',
        defaultMessage: 'Impostazioni',
        id: 'KbWKmR',
      }),
      info: {
        name: intl.formatMessage({
          description: 'settings page info name label',
          defaultMessage: 'Nome',
          id: 'zJcKNr',
        }),
        surname: intl.formatMessage({
          description: 'settings page info surname label',
          defaultMessage: 'Cognome',
          id: '5raWZe',
        }),
        company: intl.formatMessage({
          description: 'settings page info company label',
          defaultMessage: 'Azienda',
          id: '/lTCHP',
        }),
        email: intl.formatMessage({
          description: 'settings page info email label',
          defaultMessage: 'Email',
          id: 'A9CmaH',
        }),
        country: intl.formatMessage({
          description: 'settings page info country label',
          defaultMessage: 'Nazione',
          id: 'T9Lp9y',
        }),
        language: intl.formatMessage({
          description: 'settings page info language label',
          defaultMessage: 'Lingua',
          id: '/v44ZD',
        }),
        version: intl.formatMessage({
          description: 'settings page info version label',
          defaultMessage: 'Versione Portale',
          id: '8LGkTX',
        }),
        invalidateCache: intl.formatMessage({
          description: 'settings page info invalidate cache label',
          defaultMessage: 'Invalida cache',
          id: 'FgWeOd',
        }),
      },
      deleteInfoPopup: {
        title: intl.formatMessage({
          description: 'settings page info delete popup title label',
          defaultMessage: 'Cancellazione utente',
          id: 'rD2yE6',
        }),
        message: intl.formatMessage({
          description: 'settings page info delete popup message label',
          defaultMessage: "Sei sicuro di voler cancellare l'utente?",
          id: 'cv399v',
        }),
        cancelButtonLabel: intl.formatMessage({
          description: 'settings page info delete popup cancel button label',
          defaultMessage: 'Annulla',
          id: 'psYAab',
        }),
        agreeButtonLabel: intl.formatMessage({
          description: 'settings page info delete popup agree button label',
          defaultMessage: 'Conferma',
          id: 's/f5Dq',
        }),
      },
      deleteButtonLabel: intl.formatMessage({
        description: 'settings page info delete button label',
        defaultMessage: 'Elimina account',
        id: 'TK0z7p',
      }),
      updateButtonLabel: intl.formatMessage({
        description: 'settings page info update button label',
        defaultMessage: 'Modifica',
        id: 'vSZgmi',
      }),
    },
    home: {
      title: intl.formatMessage({
        description: 'home page title label',
        defaultMessage: 'Home',
        id: 'BIjkJW',
      }),
      usersWidget: {
        title: intl.formatMessage({
          description: 'home page user widget title label',
          defaultMessage: 'Gestione Utenti',
          id: 'ZD9UKd',
        }),
        bottomText: intl.formatMessage({
          description: 'home page user widget bottom text label',
          defaultMessage: 'Vedi tutti',
          id: 'gixkuo',
        }),
        cell1Label: intl.formatMessage({
          description: 'home page user widget cell1 label',
          defaultMessage: 'Richieste di iscrizione',
          id: '91DL1N',
        }),
        cell2Label: intl.formatMessage({
          description: 'home page user widget cell2 label',
          defaultMessage: 'Utenti totali',
          id: 'qor8+V',
        }),
        cell3Label: intl.formatMessage({
          description: 'home page user widget cell3 label',
          defaultMessage: 'Utenti bloccati',
          id: 'GUu0t7',
        }),
      },
      stockWidget: {
        title: intl.formatMessage({
          description: 'home page stock widget title label',
          defaultMessage: 'Richieste Stocklist',
          id: 'yOGpak',
        }),
        bottomText: intl.formatMessage({
          description: 'home page stock widget bottom text label',
          defaultMessage: 'Vedi tutti',
          id: '7NyGEa',
        }),
        cell1Label: intl.formatMessage({
          description: 'home page stock widget cell1 label',
          defaultMessage: 'Disponibili',
          id: 'P9ODvZ',
        }),
        cell2Label: intl.formatMessage({
          description: 'home page stock widget cell2 label',
          defaultMessage: 'In elaborazione',
          id: '6BKr7M',
        }),
        cell3Label: intl.formatMessage({
          description: 'home page stock widget cell3 label',
          defaultMessage: 'Inviate',
          id: 'ZgwmBv',
        }),
      },
      ordersWidget: {
        filati: intl.formatMessage({
          description: 'home page orders widget filati label',
          defaultMessage: 'Ordini Filati',
          id: '9VqyoJ',
        }),
        campionario: intl.formatMessage({
          description: 'home page orders widget campionario label',
          defaultMessage: 'Ordini Campionario',
          id: 'G9yTFv',
        }),
        labdip: intl.formatMessage({
          description: 'home page orders widget labdip label',
          defaultMessage: 'Ordini Labdip',
          id: '2A8rz+',
        }),
        bottomText: intl.formatMessage({
          description: 'home page orders widget bottom text label',
          defaultMessage: 'Vedi tutti',
          id: 'OEETQc',
        }),
        cell1Label: intl.formatMessage({
          description: 'home page orders widget cell1 label',
          defaultMessage: 'Aperti',
          id: '6Uua5M',
        }),
        cell2Label: intl.formatMessage({
          description: 'home page orders widget cell2 label',
          defaultMessage: 'Confermati',
          id: 'omszj1',
        }),
        cell3Label: intl.formatMessage({
          description: 'home page orders widget cell3 label',
          defaultMessage: 'Da confermare',
          id: 'ERtg28',
        }),
      },
    },
  }),
};

export default translations;
