import styled from 'styled-components';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';

import { CRGButton } from 'components/atoms/CRGButton';
import { CRGText } from 'components/atoms/CRGText';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const AlreadyRegisteredContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-bottom: 60px;
  }
`;

const AlreadyRegistered = ({ onButtonClick }) => {
  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).alreadyRegistered;

  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <AlreadyRegisteredContainerStyle>
      <CRGText
        textAlign={'center'}
        fontSize={isMobile ? 12 : 15}
        fontWeight={500}
        colorVariant={isMobile ? 'neutralDarkBlack' : 'neutralLigthWhite'}
      >
        {i18n.labelText}
      </CRGText>
      <CRGButton onClick={onButtonClick} colorVariant={'mainPrimary'}>
        {i18n.buttonText}
      </CRGButton>
    </AlreadyRegisteredContainerStyle>
  );
};

AlreadyRegistered.propTypes = {
  onButtonClick: PropTypes.func,
};

export { AlreadyRegistered };
