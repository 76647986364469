import { useContext } from 'react';

import { CRGText } from 'components/atoms';
import styled from 'styled-components';
// import PropTypes from 'prop-types';

import { useMapping } from 'hooks';

import { Avatar, useMediaQuery } from '@mui/material';
import { UserContext } from 'contexts';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const ContainerStyle = styled.div`
  display: flex;
  gap: 10px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

const UserTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${(props) => props.theme.breakpoints.down('sm')} {
    align-items: flex-start;
  }
`;

const UserAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserInfo = () => {
  // Hooks
  const { userTypeList } = useMapping();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).userInfo;

  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Contexts
  const { user } = useContext(UserContext);

  return (
    <ContainerStyle>
      <UserTextContainer>
        <CRGText
          fontSize={14}
          fontWeight={400}
          colorVariant={isMobile ? 'mainPrimary' : 'neutralDarkBlack'}
        >
          {i18n.hello}, {user?.cariaggiUser?.name} {user?.cariaggiUser?.surname}
        </CRGText>
        <CRGText fontSize={14} fontWeight={400} colorVariant={'mainSecondary'}>
          {userTypeList[user?.cariaggiUser?.type]}
        </CRGText>
      </UserTextContainer>
      <UserAvatarContainer>
        <Avatar>
          {user?.cariaggiUser?.name?.substring?.(0, 1)}
          {user?.cariaggiUser?.surname?.substring?.(0, 1)}
        </Avatar>
      </UserAvatarContainer>
    </ContainerStyle>
  );
};

UserInfo.propTypes = {};

export { UserInfo };
