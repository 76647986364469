/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import { Controller } from 'react-hook-form';
import { CRGCheckBox, CRGText } from '.';

const CRGCheckboxControlled = ({
  control,
  name,
  label,
  rules,
  errors,
  customWidth,
}) => {
  // State
  const [currentError, setCurrentError] = useState();

  // Effect
  useEffect(() => {
    if (name && errors) {
      const errorObj = errors;
      const errorKey = Object?.keys?.(errorObj?.message)?.[0];
      const nextCurrentError = errorObj?.message?.[errorKey];
      setCurrentError(nextCurrentError);
    } else {
      setCurrentError(null);
    }
  }, [errors, name]);

  return control ? (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <CRGCheckBox label={label} {...field} customWidth={customWidth} />
        )}
        rules={rules}
      />
      {currentError && (
        <CRGText fontWeight={500} fontSize={14} colorVariant={'mainError'}>
          {currentError}
        </CRGText>
      )}
    </>
  ) : null;
};

export { CRGCheckboxControlled };
