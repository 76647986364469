const AWS_SETUP_DEV = {
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_gco0RiZnl',
    userPoolWebClientId: '1pdm41ga8hu63l13a5av6cqtfl',
  },
};

const AWS_SETUP_TEST = {
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_GipCr2MBu',
    userPoolWebClientId: 'p5qitiro4h18vsf09af3gq7re',
  },
};

const AWS_SETUP_PROD = {
  Auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_Ndu2r114V',
    userPoolWebClientId: '101o5ujem0g1l0n1is76e2up70',
  },
};

export { AWS_SETUP_DEV, AWS_SETUP_TEST, AWS_SETUP_PROD };
