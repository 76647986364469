import { useContext } from 'react';

import { Routes, Route } from 'react-router-dom';

import { UserContext } from 'contexts';
import { permissions } from 'shared';

import {
  Main,
  Login,
  SignIn,
  Home,
  Users,
  Stocklist,
  Settings,
  Activate,
  ForgotPassword,
  StockDetail,
  ForgotPasswordSubmit,
  PageNotFound,
  Notifications,
  ManualNotifications,
  ManualNotificationDetail,
  NewManualNotification,
  Orders,
  OrderDetail,
  OrdersHistory,
} from 'pages';

const Routing = () => {
  // Hooks
  const { user } = useContext(UserContext);

  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signin" element={<SignIn />} />
        <Route exact path="/home" element={<Home />} />
        {user?.cariaggiUser?.permissions
          ?.split('|')
          .includes(permissions.STOCKLIST_READ?.toString?.()) ? (
          <Route exact path="/stock/:id" element={<StockDetail />} />
        ) : (
          <Route exact path="/stock/:id" element={<PageNotFound />} />
        )}
        {user?.cariaggiUser?.permissions
          ?.split('|')
          .includes(permissions.STOCKLIST_READ?.toString?.()) ? (
          <Route exact path="/stocklist" element={<Stocklist />} />
        ) : (
          <Route exact path="/stocklist" element={<PageNotFound />} />
        )}
        {user?.cariaggiUser?.permissions
          ?.split('|')
          .includes(permissions.ORDERS_READ?.toString?.()) ? (
          <Route exact path="/orders" element={<Orders />} />
        ) : (
          <Route exact path="/orders" element={<PageNotFound />} />
        )}
        {user?.cariaggiUser?.permissions
          ?.split('|')
          .includes(permissions.ORDERS_HISTORY_READ?.toString?.()) ? (
          <Route exact path="/ordersHistory" element={<OrdersHistory />} />
        ) : (
          <Route exact path="/ordersHistory" element={<PageNotFound />} />
        )}
        {user?.cariaggiUser?.permissions
          ?.split('|')
          .includes(permissions.ORDERS_READ?.toString?.()) ? (
          <Route exact path="/order/:id" element={<OrderDetail />} />
        ) : (
          <Route exact path="/order/:id" element={<PageNotFound />} />
        )}
        {(user?.cariaggiUser?.type === 3 || user?.cariaggiUser?.type === 0) &&
        user?.cariaggiUser?.permissions
          ?.split('|')
          .includes(permissions.USER_READ?.toString?.()) ? (
          <Route exact path="/users" element={<Users />} />
        ) : (
          <Route exact path="/users" element={<PageNotFound />} />
        )}
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/notifications" element={<Notifications />} />
        {user?.cariaggiUser?.permissions
          ?.split('|')
          .includes(permissions.MANUAL_NOTIFICATION?.toString?.()) ? (
          <Route
            exact
            path="/manual-notifications"
            element={<ManualNotifications />}
          />
        ) : (
          <Route
            exact
            path="/manual-notifications"
            element={<PageNotFound />}
          />
        )}
        {user?.cariaggiUser?.permissions
          ?.split('|')
          .includes(permissions.MANUAL_NOTIFICATION?.toString?.()) ? (
          <Route
            exact
            path="/manual-notifications/detail/:id"
            element={<ManualNotificationDetail />}
          />
        ) : (
          <Route
            exact
            path="/manual-notifications/detail/:id"
            element={<PageNotFound />}
          />
        )}
        {user?.cariaggiUser?.permissions
          ?.split('|')
          .includes(permissions.SEND_MANUAL_NOTIFICATION?.toString?.()) ? (
          <Route
            exact
            path="/manual-notifications/new"
            element={<NewManualNotification />}
          />
        ) : (
          <Route
            exact
            path="/manual-notifications/new"
            element={<PageNotFound />}
          />
        )}
        <Route exact path="/activate" element={<Activate />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route
          exact
          path="/forgot-password-submit"
          element={<ForgotPasswordSubmit />}
        />
      </Routes>
    </div>
  );
};

export { Routing };
