import { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFieldArray, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import { useMediaQuery } from '@mui/material';

import { useArticles, useStocklist } from 'shared';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

import {
  CRGButton,
  CRGText,
  CRGTextFieldControlled,
  CRGAutocompleteControlled,
  CRGSelectControlled,
  CRGTextField,
  CRGChips,
  CRGCheckBox,
} from 'components/atoms';
import { InfoDialogContext, LoadingContext, UserContext } from 'contexts';
import { useMapping } from 'hooks';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 30px;
  min-height: 75vh;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: flex-start;
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
  }
`;

const Content = styled.div``;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FormatEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 8px;
  gap: 10px;
  background: ${(props) => props.theme.palette.main.lightTissue};
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: 20px;
  }
`;

const FormStyle = styled.form`
  width: 100%;
`;

const EmailFieldsContainer = styled.div`
  display: flex;
  gap: 10px;
`;
const TopFormatEmailTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmailChipsContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const LetterPlusImg = styled.img`
  width: 15px;
`;

const AddStocklistRequest = ({ onClose, customers }) => {
  // Hooks
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      description: '',
      articleId: null,
      customerId: null,
      color: '',
      format: 0,
    },
    reValidateMode: 'all',
  });
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'email', // unique name for your Field Array
  });

  const stocklistHelper = useStocklist();
  const articlesHelper = useArticles();
  const { stockFormat } = useMapping();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).addStocklistRequest;
  const i18nGlobal = translation.global(intl);

  // State
  const [articles, setArticles] = useState([]);
  const [emailField, setEmailField] = useState('');
  const [isSelected, setIsSelected] = useState(false);

  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Context
  const { setIsLoading } = useContext(LoadingContext);
  const { setInfoDialog } = useContext(InfoDialogContext);
  const { user } = useContext(UserContext);

  // Effects
  useEffect(() => {
    fetchArticles();
  }, []);

  useEffect(() => {
    if (customers && user?.cariaggiUser?.type === 2) {
      setValue('customerId', customers[0]);
    }
  }, [user, customers]);

  useEffect(() => {
    if (user) {
      append({ emailField: user?.cariaggiUser?.email });
    }
  }, [user, customers]);

  // Functions
  const submitForm = (data) => {
    let formattedData = {
      ...data,
      description: data?.description
        ? data?.description
        : `${i18n.fixedRequestName} - ${dayjs(data?.requestDate).format(
            'DD-MM-YYYY HH:mm'
          )}`,
      articleId: data?.articleId?.id,
      format: isSelected ? stockFormat?.[data?.format] : stockFormat[4],
      customerId: data?.customerId?.id,
    };
    if (isSelected && data?.email?.length > 0)
      formattedData.email = data?.email?.map((e) => e.emailField).join(';');
    else {
      delete formattedData.email;
    }

    setIsLoading(true);
    stocklistHelper
      .create(formattedData)
      .then(() => {
        onClose?.();
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setInfoDialog({
          isOpen: true,
          title: i18nGlobal.errors.genericRetry.title,
          message: i18nGlobal.errors.genericRetry.message,
          customActions: (
            <CRGButton onClick={() => setInfoDialog(null)}>
              {i18nGlobal.errors.genericRetry.closeButton}
            </CRGButton>
          ),
        });
        setIsLoading(false);
      });
  };

  const fetchArticles = async () => {
    articlesHelper
      .list({ status: 0 })
      .then((res) => {
        setArticles(res);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleEmailChange = (e) => {
    setEmailField(e.target.value);
  };

  const onClickAddEmail = () => {
    append({ emailField });
    setEmailField('');
  };

  return (
    <Container>
      <Content>
        <CRGText
          fontSize={23}
          fontWeight={600}
          colorVariant={'neutralDarkBlack'}
        >
          {i18n.title}
        </CRGText>
        <FormStyle onSubmit={handleSubmit(submitForm)}>
          <FormContainer>
            <FieldsContainer>
              {/* Nome Richiesta */}
              <CRGTextFieldControlled
                label={i18n.form.requestNameLabel}
                name="description"
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: i18n.form.errors.requestName,
                  },
                }}
                errors={errors?.['description']}
              />

              {/* Clienti */}
              <CRGAutocompleteControlled
                name="customerId"
                control={control}
                options={customers}
                getOptionLabel={(option) => option.searchField}
                renderInput={(params) => (
                  <CRGTextField
                    {...params}
                    label={i18n.form.customerLabel}
                    customWidth={'100%'}
                    error={errors?.['customerId']}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: i18n.form.errors.customer,
                  },
                }}
                errors={errors?.['customerId']}
                disabled={user?.cariaggiUser?.type === 2}
              />

              {/* Articolo */}
              <CRGAutocompleteControlled
                name="articleId"
                control={control}
                options={articles}
                getOptionLabel={(option) => option.searchField}
                renderInput={(params) => (
                  <CRGTextField
                    {...params}
                    label={i18n.form.articleLabel}
                    customWidth={'100%'}
                    error={errors?.['articleId']}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: i18n.form.errors.article,
                  },
                }}
                errors={errors?.['articleId']}
              />

              {/* Nome Richiesta */}
              <CRGTextFieldControlled
                label={i18n.form.colorLabel}
                name="color"
                control={control}
                rules={{
                  minLength: {
                    value: 6,
                    message: i18n.form.errors.color,
                  },
                  maxLength: {
                    value: 6,
                    message: i18n.form.errors.color,
                  },
                  required: {
                    value: false,
                    message: i18n.form.errors.color,
                  },
                }}
                errors={errors?.['color']}
              />
              <CRGText
                fontSize={12}
                fontWeight={400}
                colorVariant={'neutralDarkBlack'}
              >
                {i18n.form.colorNotesLabel}
              </CRGText>
              <CRGText
                fontSize={12}
                fontWeight={400}
                colorVariant={'neutralDarkBlack'}
                margin={'-10px 0px 0px 0px'}
              >
                {i18n.form.colorNotesLabelExample}
              </CRGText>
              <div>
                <CRGCheckBox
                  checked={isSelected}
                  onChange={() => setIsSelected(!isSelected)}
                />
                <CRGText
                  fontSize={14}
                  fontWeight={400}
                  colorVariant={'neutralDarkBlack'}
                >
                  {i18n.form.checkboxLabel}
                </CRGText>
              </div>
            </FieldsContainer>

            {isSelected && (
              <FormatEmailContainer>
                <TopFormatEmailTextContainer>
                  <CRGText
                    fontSize={13}
                    fontWeight={600}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {i18n.mailBoxTitle}
                  </CRGText>
                  <CRGText
                    fontSize={13}
                    fontWeight={400}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {i18n.mailBoxSubtitle1}
                  </CRGText>
                </TopFormatEmailTextContainer>
                {/* 
                Formato
              */}

                <CRGSelectControlled
                  name="format"
                  label={i18n.form.formatLabel}
                  control={control}
                  colorVariant={'neutralLigthWhite'}
                  rules={{
                    required: {
                      value: true,
                      message: i18n.form.errors.format,
                    },
                  }}
                  errors={errors?.['format']}
                  options={[
                    {
                      value: 0,
                      label: stockFormat[0],
                    },
                    {
                      value: 1,
                      label: stockFormat[1],
                    },
                    {
                      value: 2,
                      label: stockFormat[2],
                    },
                    {
                      value: 3,
                      label: stockFormat[3],
                    },
                  ]}
                />
                <CRGText
                  fontSize={13}
                  fontWeight={400}
                  colorVariant={'neutralDarkBlack'}
                >
                  {i18n.mailBoxSubtitle2}
                </CRGText>

                <EmailFieldsContainer>
                  <CRGTextField
                    onChange={handleEmailChange}
                    colorVariant={'neutralLigthWhite'}
                    customHeight="56px"
                    customWidth={'100%'}
                    value={emailField}
                  />
                  <CRGButton
                    customHeight="56px"
                    customWidth="56px"
                    onClick={onClickAddEmail}
                    disabled={
                      !emailField
                        .toLowerCase()
                        .match(
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        )
                    }
                  >
                    <LetterPlusImg src={'/images/letter_plus.svg'} />
                  </CRGButton>
                </EmailFieldsContainer>

                <EmailChipsContainer>
                  {fields.map((field, index) => (
                    <div key={index}>
                      <CRGChips
                        text={field.emailField}
                        onRemove={() => remove(index)}
                      />
                    </div>
                  ))}
                </EmailChipsContainer>
              </FormatEmailContainer>
            )}
          </FormContainer>
          <ButtonContainer>
            <CRGButton
              colorVariant={'mainSecondary'}
              customWidth={isMobile ? '40vw' : '240px'}
              onClick={onClose}
            >
              {i18n.cancelButtonLabel}
            </CRGButton>

            <CRGButton
              type="submit"
              colorVariant={'mainPrimary'}
              customWidth={isMobile ? '40vw' : '240px'}
            >
              {i18n.createButtonLabel}
            </CRGButton>
          </ButtonContainer>
        </FormStyle>
      </Content>
    </Container>
  );
};

AddStocklistRequest.propTypes = {
  onClose: PropTypes.func,
  customers: PropTypes.array,
};

export { AddStocklistRequest };
