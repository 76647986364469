import React, { createContext, useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

export const MenuContext = createContext();
const MenuContextProvider = ({ children }) => {
  // Hooks
  const location = useLocation();

  // State
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [currentItemSelected, setCurrentItemSelected] = useState();

  useEffect(() => {
    if (currentItemSelected === null || currentItemSelected === undefined) {
      if (location?.pathname.includes('/home')) {
        setCurrentItemSelected(0);
      } else if (location?.pathname.includes('/settings')) {
        setCurrentItemSelected(3);
      } else if (location?.pathname.includes('/manual-notifications')) {
        setCurrentItemSelected(5);
      } else if (location?.pathname.includes('/users')) {
        setCurrentItemSelected(1);
      } else if (
        location?.pathname.includes('/stock/') ||
        location?.pathname.includes('/stocklist')
      ) {
        setCurrentItemSelected(2);
      } else if (location?.pathname.includes('/ordersHistory')) {
        setCurrentItemSelected(7);
      } else if (location?.pathname.includes('/orders')) {
        setCurrentItemSelected(6);
      }
    }
  }, [location]);

  return (
    <MenuContext.Provider
      value={{
        isMenuExpanded,
        setIsMenuExpanded,
        currentItemSelected,
        setCurrentItemSelected,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

MenuContextProvider.propTypes = {
  children: PropTypes.node,
};

export default MenuContextProvider;
