import React, { createContext, useState, useEffect } from 'react';

import { onMessageListener, getCurrentToken } from './../firebaseInit.js';

import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStore } from 'hooks';
import { useNotifications, useToken } from 'shared';
import { PROVIDER_TOKEN } from 'utils/constants';

const nonAuthPaths = [
  '/login',
  '/signin',
  '/activate',
  'forgot-password-submit',
];

export const UserContext = createContext();
const UserContextProvider = ({ children }) => {
  // Hooks
  const navigate = useNavigate();
  let location = useLocation();
  const store = useStore();
  const notificationHelper = useNotifications();
  const tokenHelper = useToken();

  // State
  const [user, setUser] = useState(null);
  const [firstRun, setFirstRun] = useState(true);
  const [, setTokenFound] = useState(false);
  const [token, setToken] = useState(null);
  const [badgeCount, setBadgeCount] = useState();
  // Effect
  useEffect(() => {
    const user = localStorage.getItem('cariaggi-web-user');
    if (user) {
      setUser(JSON.parse(user));
      if (location?.pathname === '/') {
        navigate('/home');
      }
    } else {
      if (!isNonAuthPath()) {
        navigate('/login');
      }
    }
    setFirstRun(true);
  }, []);

  useEffect(() => {
    if (user) {
      getCurrentToken(setTokenFound, user.cariaggiUser.id).then((token) => {
        setToken(token);
        tokenHelper
          .create({ provider: PROVIDER_TOKEN, token })
          .then((data) => console.log('Data correctly sent', data))
          .catch((ex) => console.error('Error while sending token', ex));
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) fetchNotifications();
  }, [user]);

  onMessageListener()
    .then((payload) => {
      console.log('App 👽', payload);
      fetchNotifications();
    })
    .catch((err) => console.log('failed: ', err));

  useEffect(() => {
    if (!user && !isNonAuthPath() && !firstRun) {
      navigate('login');
    }
  }, [location]);

  // Functions
  const isNonAuthPath = () => {
    const privatePath = nonAuthPaths.find(
      (p) => location?.pathname?.indexOf(p) !== -1
    );
    return !!privatePath;
  };

  const fetchNotifications = async () => {
    notificationHelper
      .listUserNotifications()
      .then((res) => {
        setBadgeCount(res.badgeCount);
        store.initNotifications(res);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <UserContext.Provider
      value={{ user, setUser, token, badgeCount, setBadgeCount }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node,
};

export default UserContextProvider;
