/* eslint-disable react/prop-types */
import { Autocomplete } from '@mui/material';

const CRGAutocomplete = (props) => {
  return (
    <Autocomplete
      {...props}
      value={props?.field?.value}
      style={{ width: props.customWidth }}
    />
  );
};

export { CRGAutocomplete };
