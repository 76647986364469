import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ThemeProvider } from '@mui/material';

import { dark, light } from 'theme/index';

export const CustomThemeContext = createContext();
const CustomThemeContextProvider = ({ children }) => {
  const [currentTheme, setTheme] = useState('light');

  useEffect(() => {
    // storing input name
    const nextTheme = localStorage.getItem('cariaggi-web-theme');

    if (!nextTheme || nextTheme === 'undefined') {
      setTheme('light');
    } else {
      setTheme(nextTheme);
    }
  }, []);

  const onChangeTheme = (theme) => {
    localStorage.setItem('cariaggi-web-theme', theme);
    setTheme(theme);
  };

  return (
    <CustomThemeContext.Provider value={{ currentTheme, onChangeTheme }}>
      <ThemeProvider theme={currentTheme === 'light' ? light : dark}>
        {children}
      </ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

CustomThemeContextProvider.propTypes = {
  children: PropTypes.node,
};

export default CustomThemeContextProvider;
