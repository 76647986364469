import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

const CRGButtonStyled = styled(Button)`
  background: ${(props) => {
    switch (props.variant) {
      case 'mainPrimary':
        return props.theme.palette.main.primary;
      case 'mainSecondary':
        return props.theme.palette.main.secondary;
      case 'mainAccentSecondary':
        return props.theme.palette.main.accentSecondary;
      case 'mainAccent':
        return props.theme.palette.main.accent;
      case 'mainLight':
        return props.theme.palette.main.light;
      case 'lightWhite':
        return props.theme.palette.neutral.light.white;

      default:
        return props.theme.palette.main.primary;
    }
  }};
  color: ${(props) => {
    switch (props.variant) {
      case 'mainSecondary':
        return props.theme.palette.main.primary;
      case 'mainPrimary':
      case 'mainAccentSecondary':
      case 'mainAccent':
      case 'mainLight':
      case 'lightWhite':
        return props.theme.palette.neutral.light.white;

      default:
        return props.theme.palette.neutral.light.white;
    }
  }};
  font-family: Montserrat;
  font-size: ${(props) =>
    props.customFontSize ? props.customFontSize : '16px'};
  min-width: ${(props) =>
    props.customWidth ? props.customWidth : props.isSmall ? '30px' : '21.8rem'};
  width: ${(props) =>
    props.isSmall ? '30px' : props.customWidth || '21.8rem'};
  @media screen and (max-width: 360px) {
    min-width: auto;
    width: ${(props) =>
      props.customWidth ? props.customWidth : props.isSmall ? '30px' : '18rem'};
  }
  width: ${(props) => (props.isSmall ? '30px' : 'auto')};
  height: ${(props) =>
    props.isSmall ? '30px' : props.customHeight ? props.customHeight : '50px'};
  border: none;
  border-radius: 5px;
  &:hover {
    opacity: ${(props) => {
      switch (props.variant) {
        case 'mainSecondary':
        case 'mainPrimary':
        case 'mainAccent':
        case 'mainLight':
        case 'lightWhite':
          return 0.85;
        default:
          return 0.9;
      }
    }};
    background: ${(props) => {
      switch (props.variant) {
        case 'mainPrimary':
          return props.theme.palette.main.primary;
        case 'mainSecondary':
          return props.theme.palette.main.secondary;
        case 'mainAccent':
          return props.theme.palette.main.accent;
        case 'mainAccentSecondary':
          return props.theme.palette.main.accentSecondary;
        case 'mainLight':
          return props.theme.palette.main.light;
        case 'lightWhite':
          return props.theme.palette.neutral.light.white;

        default:
          return props.theme.palette.main.primary;
      }
    }};
    border: none;
  }
  &:disabled {
    background: ${(props) => props.theme.palette.neutral.dark.lightGray};
    color: ${(props) => props.theme.palette.neutral.dark.spanishGray};
    border: none;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: ${(props) =>
      props.customFontSize ? props.customFontSize : '14px'};
  }
`;

/**
 * @param {string} colorVariant           Define the button color. Value accepted: mainPrimary, mainSecondary, mainAccent, mainLight, lightWhite
 * @param {int} customWidth               Define the button width. if null set default by styleguide
 * @param {int} customHeight              Define the button height. if null set default by styleguide
 * @param {int} customFontSize            Define the button font size. if null set default by styleguide
 * @param {int} isSmall                   Define if the button is small (icon only)
 */

const CRGButton = ({ colorVariant, customWidth, isSmall, ...rest }) => {
  return (
    <CRGButtonStyled
      {...rest}
      variant={colorVariant}
      customWidth={customWidth}
      isSmall={isSmall}
    />
  );
};

CRGButton.propTypes = {
  colorVariant: PropTypes.oneOf([
    'mainPrimary',
    'mainSecondary',
    'mainAccentSecondary',
    'mainAccent',
    'mainLight',
    'lightWhite',
  ]),
  customWidth: PropTypes.string,
  customHeight: PropTypes.string,
  isSmall: PropTypes.bool,
};

export { CRGButton };
