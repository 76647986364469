import { Grid, useMediaQuery } from '@mui/material';
import { CRGGridCell } from 'components/atoms';
import { useOrdersMapping } from 'hooks';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const DetailContainerStyle = styled.div`
  display: flex;
  padding: 33px 35px 35px 33px;
  background-color: ${(props) => props.colorVariant};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 30px 25px 30px 25px;
    border-radius: 10px;
  }
`;

const TopDetailInfo = ({ order }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { headerDetail } = useOrdersMapping(order);

  return (
    <DetailContainerStyle colorVariant={'#ebe3dc'} border={'top'}>
      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        columns={{ xs: 4, md: 14 }}
      >
        {headerDetail.map((item) => (
          <CRGGridCell
            key={item.header}
            md={item.md}
            header={item.header}
            value={item.value}
            keyFontSize={isMobile ? 14 : undefined}
            keyFontWeight={isMobile ? 400 : undefined}
            valueFontSize={isMobile ? 14 : undefined}
            valueFontWeight={isMobile ? 600 : undefined}
            showDivider={false}
            valuesOnTop={!isMobile}
          >
            {item.children}
          </CRGGridCell>
        ))}
      </Grid>
    </DetailContainerStyle>
  );
};

TopDetailInfo.propTypes = {
  order: PropTypes.object,
};

export { TopDetailInfo };
