import { CRGCheckboxControlled, CRGText } from 'components/atoms';
import PropTypes from 'prop-types';

const PreferenceGroup = ({
  preferenceType,
  title,
  userPermissions,
  control,
}) => {
  const permissionsIds = preferenceType?.map((el) => el.id.toString()); //id del gruppo di permessi

  return (
    permissionsIds.some((el) => userPermissions.includes(el.toString())) && (
      <>
        <CRGText
          fontSize={14}
          fontWeight={600}
          colorVariant={'neutralDarkBlack'}
        >
          {title}
        </CRGText>
        {preferenceType.map((preference) => {
          return userPermissions.includes(preference?.id?.toString()) ? (
            <CRGCheckboxControlled
              key={preference.id}
              control={control}
              name={preference.name}
              label={preference.label}
            />
          ) : null;
        })}
      </>
    )
  );
};

PreferenceGroup.propTypes = {
  preferenceType: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.number).isRequired,
  control: PropTypes.object.isRequired,
};

export { PreferenceGroup };
