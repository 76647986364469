import { useMediaQuery } from '@mui/material';
import { CRGButton } from 'components/atoms';
/* import styled from 'styled-components'; */
import { useCustomIntl } from './useCustomIntl';
import translation from 'utils/translation';
import { useContext } from 'react';
import { DrawerContext, UserContext } from 'contexts';
import { OrderDetail } from 'pages';
import { permissions } from 'shared';

/* const DetailButtonContainer = styled.div`
  align-self: flex-end;
  margin-right: 6px;
`; */

const useOrdersMapping = (order) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).orders.headers;
  const i18nFilters = translation.components(intl).ordersFilters;
  /* const { orderDetail: orderDetailTooltip } =
    translation.components(intl).tooltip; */
  const { setIsDrawerOpen, closeDrawer } = useContext(DrawerContext);

  const { user } = useContext(UserContext);
  const permissionsArray = user?.cariaggiUser?.permissions?.split('|') || [];
  const hasBusinessTermsPermission = permissionsArray.includes(
    permissions.BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2.toString()
  );

  const goToOrderDetail = () => {
    setIsDrawerOpen({
      isOpen: true,
      children: <OrderDetail onClose={closeDrawer} order={order} />,
    });
  };

  const {
    VS_ORD,
    VS_RIGA_ORD,
    DATA_VS_ORD,
    RIF_ORDINE,
    CONFERMA_ORDINE,
    TIPO_ORDINE,
    STATO_ORDINE,
    AVANZAMENTO_ORDINE,
    RAG_SOC_E_INDIRIZZO_CLIENTE,
    COD_CLIENTE,
    RAGIONE_SOCIALE_CLIENTE,
    DESCR_RISERVA,
  } = order || {};

  const header = [
    {
      md: 1.5,
      header: i18n.customerOrder,
      value: VS_ORD,
      valueFontWeight: 700,
    },
    {
      md: 2,
      header: i18n.customerOrderDate,
      value: DATA_VS_ORD,
    },
    {
      md: 1,
      header: i18n.rows,
      value: VS_RIGA_ORD,
    },
    {
      md: 2,
      header: i18n.confirmOrder,
      value: `${RIF_ORDINE} ${CONFERMA_ORDINE}`,
    },
    {
      md: 1,
      header: i18n.type,
      value: TIPO_ORDINE,
    },
    {
      md: 1.5,
      header: i18n.status,
      value: STATO_ORDINE,
    },
    {
      md: 3,
      header: i18n.advancement,
      value:
        DESCR_RISERVA?.trim() ?? '' !== ''
          ? `${AVANZAMENTO_ORDINE} - ${DESCR_RISERVA}`
          : AVANZAMENTO_ORDINE,
    },
    {
      md: 2,
      header: i18n.customer,
      value: `${COD_CLIENTE} - ${RAGIONE_SOCIALE_CLIENTE}`,
      showDivider: false,
    },
  ];

  const headerDetail = [
    {
      md: 1,
      header: i18n.customerOrder,
      value: VS_ORD ?? '-',
    },
    {
      md: 1,
      header: i18n.rows,
      value: VS_RIGA_ORD ?? '-',
    },
    {
      md: 2,
      header: i18n.customerOrderDate,
      value: DATA_VS_ORD,
    },
    {
      md: 2,
      header: i18n.confirmOrder,
      value: `${RIF_ORDINE} ${CONFERMA_ORDINE}`,
    },
    {
      md: 1,
      header: i18n.type,
      value: TIPO_ORDINE ?? '-',
    },
    {
      md: 2,
      header: i18n.status,
      value: STATO_ORDINE ?? '-',
    },
    {
      md: 2,
      header: i18n.advancement,
      value: AVANZAMENTO_ORDINE ?? '-',
    },
    {
      md: 3,
      header: i18n.customer,
      value: `${COD_CLIENTE} - ${RAGIONE_SOCIALE_CLIENTE}`,
    },
  ];

  const {
    CODICE_MATERIALE_CARIAGGI,
    QTA_ORD,
    QTA_SPED,
    DATA_CONSEGNA,
    PREZZO_UNITARIO,
    MAGGIORAZIONE_UNITARIO,
    MAGGIORAZIONE,
    SCONTO1,
    SCONTO2,
    SCONTO3,
    UM,
    MONETA,
    CODICE_MATERIALE_CLIENTE,
  } = order || {};

  const discount = () => {
    let discounts = [];
    if (SCONTO1) {
      discounts.push(`${SCONTO1}%`);
    }
    if (SCONTO2) {
      discounts.push(`${SCONTO2}%`);
    }
    if (SCONTO3) {
      discounts.push(`${SCONTO3}%`);
    }
    return discounts.join(' + ') || '-';
  };

  const content = [
    {
      md: hasBusinessTermsPermission ? 4.5 : 4,
      header: i18n.products,
      value: (
        <>
          <b>•</b> {CODICE_MATERIALE_CARIAGGI ?? '-'}
          <br />
          <b>•</b> {CODICE_MATERIALE_CLIENTE ?? '-'}
        </>
      ),
      visibility: true,
    },
    {
      md: hasBusinessTermsPermission ? 1 : 2.5,
      header: i18n.orderedQuantity,
      value: `${QTA_ORD ?? '-'} ${UM}`,
      visibility: true,
    },
    {
      md: hasBusinessTermsPermission ? 1 : 2.5,
      header: i18n.orderedShipped,
      value:
        QTA_SPED != null && QTA_SPED > 0 ? `${QTA_SPED ?? '-'} ${UM}` : '-',
      visibility: true,
    },
    {
      md: hasBusinessTermsPermission ? 1.2 : 2.5,
      header: i18n.shipmentDate,
      value: DATA_CONSEGNA ?? '-',
      valueFontWeight: 700,
      visibility: true,
    },
    {
      md: 1,
      header: i18n.unitPrice,
      value: `${PREZZO_UNITARIO ?? '-'} ${MONETA}`,
      visibility: hasBusinessTermsPermission,
    },
    {
      md: 1.5,
      header: i18n.unitIncrease,
      value: `${MAGGIORAZIONE_UNITARIO ?? '-'} ${MONETA}`,
      visibility: hasBusinessTermsPermission,
    },
    {
      md: 1.5,
      header: i18n.increase,
      value: `${MAGGIORAZIONE ?? '-'} ${MONETA}`,
      visibility: hasBusinessTermsPermission,
    },
    {
      md: 1,
      header: hasBusinessTermsPermission ? i18n.discount : '',
      value: hasBusinessTermsPermission ? discount() : '',
      visibility: hasBusinessTermsPermission,
    },
    {
      md: 1,
      header: isMobile ? i18n.detail : '',
      showDivider: false,
      visibility: true,
      children: (
        <CRGButton
          customHeight={'30px'}
          customWidth={'30px'}
          onClick={() => goToOrderDetail()}
        >
          <img src={'images/go-to-detail.svg'} />
        </CRGButton>
      ),
    },
  ];

  const contentDetail = [
    {
      md: 3,
      header: i18n.products,
      value: (
        <>
          <b>•</b> {CODICE_MATERIALE_CARIAGGI ?? '-'}
          <br />
          <b>•</b> {CODICE_MATERIALE_CLIENTE ?? '-'}
        </>
      ),
      visibility: true,
    },
    {
      md: 1,
      header: i18n.orderedQuantity,
      value: `${QTA_ORD ?? '-'} ${UM}`,
      visibility: true,
    },
    {
      md: 1,
      header: i18n.orderedShipped,
      value:
        QTA_SPED != null && QTA_SPED > 0 ? `${QTA_SPED ?? '-'} ${UM}` : '-',
      visibility: true,
    },
    {
      md: 1,
      header: i18n.shipmentDate,
      value: DATA_CONSEGNA ?? '-',
      valueFontWeight: 700,
      visibility: true,
    },
    {
      md: 1,
      header: i18n.unitPrice,
      value: `${PREZZO_UNITARIO ?? '-'} ${MONETA}`,
      visibility: hasBusinessTermsPermission,
    },
    {
      md: 1.5,
      header: i18n.unitIncrease,
      value: `${MAGGIORAZIONE_UNITARIO ?? '-'} ${MONETA}`,
      visibility: hasBusinessTermsPermission,
    },
    {
      md: 1.3,
      header: i18n.increase,
      value: `${MAGGIORAZIONE ?? '-'} ${MONETA}`,
      visibility: hasBusinessTermsPermission,
    },
    {
      md: 3.2,
      header: hasBusinessTermsPermission ? i18n.discount : '',
      value: discount(),
      visibility: hasBusinessTermsPermission,
    },
  ];

  const {
    INDIRIZZO_DESTINAZIONE,
    RAGIONE_SOCIALE_CLIENTE_FINALE,
    STAGIONE_CLIENTE,
    AGENTE,
    DESCR_VS_LINEA,
    DESCR_VS_BOARD,
    DATA_ORDINE,
    PAGAMENTO,
    REFERENTE_CARIAGGI,
  } = order || {};

  const detailMapping = [
    {
      md: 4,
      header: i18n.address,
      value: RAG_SOC_E_INDIRIZZO_CLIENTE ?? '-',
      visibility: true,
    },
    {
      md: 8,
      header: i18n.finalCustomer,
      value: RAGIONE_SOCIALE_CLIENTE_FINALE ?? '-',
      visibility: true,
    },
    {
      md: 4,
      header: i18n.customerSeason,
      value: STAGIONE_CLIENTE ?? '-',
      visibility: true,
    },
    {
      md: 8,
      header: i18n.agent,
      value: AGENTE ?? '-',
      visibility: true,
    },
    {
      md: 4,
      header: i18n.customerLine,
      value: DESCR_VS_LINEA ?? '-',
      visibility: true,
    },
    {
      md: 8,
      header: i18n.customerClerk,
      value: REFERENTE_CARIAGGI ?? '-',
      visibility: true,
    },
    {
      md: 4,
      header: i18nFilters.customerBoard,
      value: DESCR_VS_BOARD ?? '-',
      visibility: true,
    },
    {
      md: 8,
      header: i18n.orderDate,
      value: DATA_ORDINE ?? '-',
      visibility: true,
    },
    {
      md: 4,
      header: i18n.goodsDestination,
      value: INDIRIZZO_DESTINAZIONE ?? '-',
      visibility: true,
    },
    {
      md: 8,
      header: i18n.payment,
      value: PAGAMENTO ?? '-',
      visibility: hasBusinessTermsPermission,
    },
  ];

  const ordersValuesForOrderBy = [
    { key: 'VS_ORD', label: i18nFilters.customerOrder },
    { key: 'DATA_VS_ORD', label: i18nFilters.orderDate },
    { key: 'COD_CONFERMA_ORDINE', label: i18nFilters.orderConfirmation },
    { key: 'COD_AVANZAMENTO_ORDINE', label: i18nFilters.progress },
    { key: 'COD_STATO_ORDINE', label: i18nFilters.status },
    { key: 'COD_CLIENTE', label: i18nFilters.customer },
    { key: 'CODICE_MATERIALE_CARIAGGI', label: i18nFilters.cariaggiColor },
    { key: 'CODICE_MATERIALE_CLIENTE', label: i18nFilters.customerColor },
    { key: 'DATA_CONSEGNA', label: i18nFilters.shippingDate },
  ];

  return {
    header,
    content,
    ordersValuesForOrderBy,
    headerDetail,
    contentDetail,
    detailMapping,
  };
};

export { useOrdersMapping };
