import { useContext } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import {
  CRGButton,
  CRGTextFieldControlled,
  CRGSelectControlled,
  CRGDateTimePickerControlled,
  CRGAutocompleteControlled,
  CRGTextField,
  CRGMultiselectControlled,
} from 'components/atoms';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { useMapping } from 'hooks';
import { UserContext } from 'contexts';

const FilterContainer = styled.div``;

const FormStyle = styled.form`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 1600px) {
    flex-wrap: wrap;
  }
`;

const defaultValues = {
  description: '',
  requestDate: null,
  status: '',
  email: '',
  article: '',
  format: '',
  customerId: null,
  articleId: null,
};

const StocklistListFilters = ({
  handleFilterChange,
  articles,
  status,
  customers,
}) => {
  // Hooks
  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: defaultValues,
  });
  const { stockFormat } = useMapping();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).stocklistListFilters;

  // Context
  const { user } = useContext(UserContext);

  // Functions
  const clearFilters = () => {
    reset(defaultValues);
    handleFilterChange(defaultValues);
  };

  return (
    <FilterContainer>
      <FormStyle onSubmit={handleSubmit(handleFilterChange)}>
        {/* Nome Richiesta */}
        <CRGTextFieldControlled
          label={i18n.requestNameLabel}
          name="description"
          control={control}
          customWidth={'11vw'}
        />

        {/* Data Ora */}
        <CRGDateTimePickerControlled
          label={i18n.dataLabel}
          name="requestDate"
          control={control}
          customWidth={'11vw'}
        />

        {/* Status */}
        <CRGMultiselectControlled
          name="status"
          label={i18n.statusLabel}
          control={control}
          customWidth={'10vw'}
          setValue={setValue}
          options={status}
        />

        {/* Email */}
        <CRGTextFieldControlled
          label={i18n.emailLabel}
          name="email"
          control={control}
          customWidth={'10vw'}
        />

        {/* Articolo */}
        <CRGAutocompleteControlled
          name="articleId"
          control={control}
          options={articles}
          getOptionLabel={(option) => option.searchField}
          renderInput={(params) => (
            <CRGTextField
              {...params}
              label={i18n.articleLabel}
              customWidth={'10vw'}
            />
          )}
        />

        {/* Formato */}
        <CRGSelectControlled
          name="format"
          label={i18n.typeLabel}
          control={control}
          customWidth={'10vw'}
          options={[
            {
              value: 0,
              label: stockFormat[0],
            },
            {
              value: 1,
              label: stockFormat[1],
            },
            {
              value: 2,
              label: stockFormat[2],
            },
            {
              value: 3,
              label: stockFormat[3],
            },
          ]}
        />

        {/* Clienti */}
        {user?.cariaggiUser?.type === 2 ? null : (
          <CRGAutocompleteControlled
            name="customerId"
            control={control}
            options={customers}
            getOptionLabel={(option) => option.searchField}
            renderInput={(params) => (
              <CRGTextField
                {...params}
                label={i18n.customerLabel}
                customWidth={'10vw'}
              />
            )}
          />
        )}

        <CRGButton
          type="submit"
          colorVariant={'mainPrimary'}
          customWidth={'150px'}
        >
          {i18n.buttonSubmitLabel}
        </CRGButton>
        <CRGButton
          colorVariant={'mainSecondary'}
          customWidth={'170px'}
          onClick={clearFilters}
        >
          {i18n.buttonClearFilters}
        </CRGButton>
      </FormStyle>
    </FilterContainer>
  );
};

StocklistListFilters.propTypes = {
  handleFilterChange: PropTypes.func,
  articles: PropTypes.array,
  status: PropTypes.array,
  customers: PropTypes.array,
};

export { StocklistListFilters };
