import styled from 'styled-components';
import PropTypes from 'prop-types';

const HeaderCell = styled.th`
  padding-left: 15px;
  padding-right: 15px;
`;

const CellContent = styled.div`
  display: flex;
`;

const ArrowsContainer = styled.div`
  cursor: pointer;
  align-self: center;
`;

const ArrowsStyle = styled.div`
  display: flex;
  gap: 2px;
`;

const CRGDesktopTableHeaderCell = ({
  children,
  orderKey,
  currentOrder,
  handleOrderChange,
  hideOrder,
}) => {
  // Functions
  const onChangeOrder = () => {
    handleOrderChange?.(orderKey);
  };

  // Components
  const Arrow = () => {
    if (currentOrder?.orderBy !== orderKey) {
      return (
        <ArrowsStyle>
          <img src={'/images/table/grey_up.svg'} />
          <img src={'/images/table/grey_down.svg'} />
        </ArrowsStyle>
      );
    } else if (currentOrder?.order === 'ASC') {
      return (
        <ArrowsStyle>
          <img src={'/images/table/grey_up.svg'} />
          <img src={'/images/table/black_down.svg'} />
        </ArrowsStyle>
      );
    } else if (currentOrder?.order === 'DESC') {
      return (
        <ArrowsStyle>
          <img src={'/images/table/black_up.svg'} />
          <img src={'/images/table/grey_down.svg'} />
        </ArrowsStyle>
      );
    } else if (orderKey === undefined) {
      return null;
    }
  };

  return (
    <HeaderCell>
      <CellContent>
        {children}
        {hideOrder ? null : (
          <ArrowsContainer onClick={onChangeOrder}>
            {currentOrder ? <Arrow /> : null}
          </ArrowsContainer>
        )}
      </CellContent>
    </HeaderCell>
  );
};

CRGDesktopTableHeaderCell.propTypes = {
  children: PropTypes.node,
  orderKey: PropTypes.string,
  currentOrder: PropTypes.object,
  handleOrderChange: PropTypes.func,
  hideOrder: PropTypes.bool,
};

export { CRGDesktopTableHeaderCell };
