import useFetch from 'use-http';
import { Auth } from 'aws-amplify';

import { BACKEND_URL } from '../utils/constants';

const authUrls = ['/notification', '/usernotification'];

const useNotifications = () => {
  const backendHelper = useFetch(BACKEND_URL, {
    onError: ({ error }) => {
      throw error;
    },
    interceptors: {
      request: async ({ options, route }) => {
        const authenticatedUrl = authUrls.find((p) => route?.indexOf(p) !== -1);

        if (authenticatedUrl) {
          try {
            const data = await Auth.currentSession();
            const jwt = data?.getAccessToken().getJwtToken();
            options.headers.Authorization = `Bearer ${jwt}`;
          } catch (error) {
            console.log(
              '🚀 ~ file: useArticles.js:23 ~ request: ~ error:',
              error
            );
          }
        }
        return options;
      },
      response: ({ response }) => {
        if (!response.ok) throw new Error(JSON.stringify(response.data));
        return response;
      },
    },
  });

  const list = (params) =>
    new Promise((resolve, reject) => {
      const normalizedParams = {
        ...params,
        page: params.page || 0,
        pageSize: params.pageSize || 10,
      };

      const multipleFields = {};

      Object.keys(normalizedParams).forEach((key) => {
        if (
          normalizedParams[key] === null ||
          normalizedParams[key] === '' ||
          normalizedParams[key] === undefined
        ) {
          delete normalizedParams[key];
        } else if (Array.isArray(normalizedParams[key])) {
          multipleFields[key] = normalizedParams[key];
          delete normalizedParams[key];
        }
      });

      const queryParams = new URLSearchParams(normalizedParams);

      Object.keys(multipleFields).forEach((key) => {
        const valueArray = multipleFields[key];
        valueArray.forEach((v) => {
          queryParams.append(`${key}[]`, v);
        });
      });

      backendHelper
        .get(`/notification?${queryParams.toString()}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error get user', e);
          reject(e);
        });
    });

  const listSent = (params) =>
    new Promise((resolve, reject) => {
      /* const normalizedParams = {
        ...params,
        page: params.page || 0,
        pageSize: params.pageSize || 10,
      };

      const multipleFields = {};

      Object.keys(normalizedParams).forEach((key) => {
        if (
          normalizedParams[key] === null ||
          normalizedParams[key] === '' ||
          normalizedParams[key] === undefined
        ) {
          delete normalizedParams[key];
        } else if (Array.isArray(normalizedParams[key])) {
          multipleFields[key] = normalizedParams[key];
          delete normalizedParams[key];
        }
      });

      const queryParams = new URLSearchParams(normalizedParams);

      Object.keys(multipleFields).forEach((key) => {
        const valueArray = multipleFields[key];
        valueArray.forEach((v) => {
          queryParams.append(`${key}[]`, v);
        });
      });

      backendHelper
        .get(`/users?${queryParams.toString()}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error get user', e);
          reject(e);
        }); */
      resolve({
        total: 7,
        notifications: [
          {
            title: 'test send',
            message:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            sentDate:
              'Wed Apr 1 2022 10:20:13 GMT+0200 (Ora legale dell’Europa centrale)',
            recipients: [1, 3],
          },
          {
            title: 'test send',
            message:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            sentDate:
              'Wed Apr 1 2022 10:20:13 GMT+0200 (Ora legale dell’Europa centrale)',
            recipients: [1, 2],
          },
          {
            title: 'test send',
            message:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            sentDate:
              'Wed Apr 1 2022 10:20:13 GMT+0200 (Ora legale dell’Europa centrale)',
            recipients: [2, 4],
          },
        ],
      });
    });

  const send = (params) =>
    new Promise((resolve, reject) => {
      /* const normalizedParams = {
        ...params,
        page: params.page || 0,
        pageSize: params.pageSize || 10,
      };

      const multipleFields = {};

      Object.keys(normalizedParams).forEach((key) => {
        if (
          normalizedParams[key] === null ||
          normalizedParams[key] === '' ||
          normalizedParams[key] === undefined
        ) {
          delete normalizedParams[key];
        } else if (Array.isArray(normalizedParams[key])) {
          multipleFields[key] = normalizedParams[key];
          delete normalizedParams[key];
        }
      });

      const queryParams = new URLSearchParams(normalizedParams);

      Object.keys(multipleFields).forEach((key) => {
        const valueArray = multipleFields[key];
        valueArray.forEach((v) => {
          queryParams.append(`${key}[]`, v);
        });
      });

      backendHelper
        .get(`/users?${queryParams.toString()}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error get user', e);
          reject(e);
        }); */
      resolve(true);
    });

  // User Notification
  const listUserNotifications = (params) =>
    new Promise((resolve, reject) => {
      const normalizedParams = {
        ...params,
        page: params?.page || 0,
        pageSize: params?.pageSize || 10,
      };

      const multipleFields = {};
      Object.keys(normalizedParams).forEach((key) => {
        if (
          normalizedParams[key] === null ||
          normalizedParams[key] === '' ||
          normalizedParams[key] === undefined
        ) {
          delete normalizedParams[key];
        } else if (Array.isArray(normalizedParams[key])) {
          multipleFields[key] = normalizedParams[key];
          delete normalizedParams[key];
        }
      });

      const queryParams = new URLSearchParams(normalizedParams);

      Object.keys(multipleFields).forEach((key) => {
        const valueArray = multipleFields[key];
        valueArray.forEach((v) => {
          queryParams.append(`${key}[]`, v);
        });
      });

      backendHelper
        .get(`/usernotification?${queryParams.toString()}`)
        .then((res) => {
          backendHelper.cache.clear();
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error list user notifications', e);
          reject(e);
        });
    });

  const readAllNotifications = (params) =>
    new Promise((resolve, reject) => {
      backendHelper
        .put(`/usernotification`, params)
        .then((res) => {
          backendHelper.cache.clear();
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error read all notifications', e);
          reject(e);
        });
    });

  const toggleNotificationReadStatus = (id, params) =>
    new Promise((resolve, reject) => {
      backendHelper
        .put(`/usernotification/${id}`, params)
        .then((res) => {
          backendHelper.cache.clear();
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error read single notifications', e);
          reject(e);
        });
    });

  const autoToggleNotificationReadStatus = (
    id,
    notificationId
    // ,params
  ) =>
    new Promise((resolve, reject) => {
      backendHelper
        .put(`/notification/${notificationId}`)
        .then((res) => {
          backendHelper.cache.clear();
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error auto put single notification', e);
          reject(e);
        });
    });

  const deleteNotification = (id, params) =>
    new Promise((resolve, reject) => {
      backendHelper
        .delete(`/usernotification/${id}`, params)
        .then((res) => {
          backendHelper.cache.clear();
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error relete notifications', e);
          reject(e);
        });
    });

  // Manual Notification
  const listManualNotifications = (params) =>
    new Promise((resolve, reject) => {
      console.log('👽 AKLJALKJSKLAJDLJS');

      const normalizedParams = {
        ...params,
        page: params?.page || 0,
        pageSize: params?.pageSize || 10,
      };

      const queryParams = new URLSearchParams(normalizedParams);

      backendHelper
        .get(`/notification?${queryParams.toString()}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error list manual notifications', e);
          reject(e);
        });
    });

  const getManualNotifications = (params) =>
    new Promise((resolve, reject) => {
      const normalizedParams = {
        page: params?.page || 0,
        pageSize: params?.pageSize || 10,
      };

      const queryParams = new URLSearchParams(normalizedParams);

      backendHelper
        .get(`/notification/${params.id}?${queryParams}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error get manual notifications', e);
          reject(e);
        });
    });

  const createManualNotification = (params) =>
    new Promise((resolve, reject) => {
      backendHelper
        .post(`/notification`, params)
        .then((res) => {
          backendHelper.cache.clear();
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error create manual notifications', e);
          reject(e);
        });
    });

  return {
    list,
    listSent,
    send,
    listManualNotifications,
    getManualNotifications,
    listUserNotifications,
    createManualNotification,
    readAllNotifications,
    toggleNotificationReadStatus,
    deleteNotification,
    autoToggleNotificationReadStatus,
  };
};

export { useNotifications };
