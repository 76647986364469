import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import PropTypes from 'prop-types';
import { CRGText } from './CRGText';
import styled from 'styled-components';

const ExpandStyle = styled.img`
  width: 14px;
  height: 9px;
`;

const AccordionSummaryStyle = styled(AccordionSummary)`
  padding-left: 24px;
  padding-right: 24px;
  margin: 0;
  &.Mui-expanded span {
    color: ${(props) => {
      return props.theme.palette.main.accentSecondary;
    }};
  }
`;

const AccordionDetailsStyle = styled(AccordionDetails)`
  padding-left: 24px;
  padding-right: 24px;
  margin: 0;
  &.Mui-expanded {
  }
`;

const CRGAccordion = ({ title, children }) => {
  return (
    <Accordion disableGutters>
      <AccordionSummaryStyle
        id={title}
        aria-controls={`${title}-content`}
        expandIcon={<ExpandStyle src={'images/arrow-down.svg'} />}
      >
        <CRGText
          fontSize={18}
          fontWeight={600}
          colorVariant={'neutralDarkBlack'}
        >
          {title}
        </CRGText>
      </AccordionSummaryStyle>
      <AccordionDetailsStyle>{children}</AccordionDetailsStyle>
    </Accordion>
  );
};

CRGAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export { CRGAccordion };
