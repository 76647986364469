import React, { createContext, useState, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import it from 'compiled-locales/it.json';
import en from 'compiled-locales/en.json';

const Langs = { it, en };

export const LangContext = createContext();
const LangContextProvider = ({ children }) => {
  // hooks
  const [searchParams] = useSearchParams();

  // state
  const [lang, setLang] = useState(null);
  const [queryParamLang] = useState(searchParams.get('locale'));

  const messages = useMemo(() => {
    switch (lang) {
      case 'it-IT':
        return Langs.it;
      case 'en-GB':
        return Langs.en;
      default:
        return Langs.it;
    }
  }, [lang]);

  useEffect(() => {
    let queryLocale = null;
    switch (queryParamLang) {
      case '0':
        queryLocale = 'it-IT';
        break;
      case '1':
        queryLocale = 'en-GB';
        break;

      default:
        queryLocale = null;
        break;
    }

    // storing input name
    const nextLang = queryLocale || localStorage.getItem('cariaggi-web-lang');

    if (nextLang === 'undefined' || nextLang === null) {
      setLang('it-IT');
    } else {
      setLang(nextLang);
    }
  }, [queryParamLang]);

  const onChangeLang = (lang) => {
    localStorage.setItem('cariaggi-web-lang', lang);
    setLang(lang);
  };

  return (
    <LangContext.Provider value={{ lang: lang, onChangeLang, messages }}>
      <IntlProvider locale={lang} messages={messages}>
        {children}
      </IntlProvider>
    </LangContext.Provider>
  );
};

LangContextProvider.propTypes = {
  children: PropTypes.node,
};

export default LangContextProvider;
