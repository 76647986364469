import React, { createContext, useState } from 'react';

import { Drawer } from '@mui/material';

import styled from 'styled-components';
import PropTypes from 'prop-types';

const DrawerStyle = styled(Drawer)`
  z-index: 1202;
  .MuiPaper-root {
    background-color: ${(props) => props.theme.palette.neutral.light.cultured};
  }
`;

const MenuContentStyle = styled.div`
  height: 100%;
  width: ${(props) => (props.customWidth ? props.customWidth : 'fit-content')};
  padding: 30px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100vw;
  }
`;

export const DrawerContext = createContext();
const DrawerContextProvider = ({ children }) => {
  // State
  const [drawer, setIsDrawerOpen] = useState(false);

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <DrawerContext.Provider
      value={{
        drawer,
        setIsDrawerOpen,
        closeDrawer,
      }}
    >
      <DrawerStyle
        anchor={'right'}
        open={drawer?.isOpen}
        onClose={() => {
          drawer?.onClose?.();
          closeDrawer();
        }}
      >
        <MenuContentStyle customWidth={drawer?.customWidth}>
          {drawer?.children}
        </MenuContentStyle>
      </DrawerStyle>
      {children}
    </DrawerContext.Provider>
  );
};

DrawerContextProvider.propTypes = {
  children: PropTypes.node,
};

export default DrawerContextProvider;
