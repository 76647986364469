import create from 'zustand';

const useStore = create((set) => ({
  notifications: [],
  initNotifications: (notifications) =>
    set((state) => ({
      ...state,
      total: notifications.total,
      notifications: [...notifications.userNotifications],
    })),
  addNotification: (notification) =>
    set((state) => ({
      ...state,
      total: state.total + 1,
      notifications: [...state.notifications, notification],
    })),
  readAll: () =>
    set((state) => {
      const nextStatus = {
        ...state,
        notifications: [
          ...state.notifications.map((n) => {
            return { ...n, status: 1 };
          }),
        ],
      };
      console.log(
        '🚀 ~ file: useStore.js ~ line 18 ~ set ~ nextStatus',
        nextStatus
      );
      return { ...nextStatus };
    }),
  toggleNotification: (notification) =>
    set((state) => {
      const nextNotifications = [...state.notifications];

      const targetNotificationStatus = nextNotifications.find(
        (n) => n.id === notification.id
      ).status;

      nextNotifications.find((n) => n.id === notification.id).status = Math.abs(
        targetNotificationStatus - 1
      );

      return {
        ...state,
        notifications: nextNotifications,
      };
    }),
  deleteNotification: (notification) =>
    set((state) => {
      const nextNotifications = [...state.notifications].filter(
        (n) => n.id !== notification.id
      );
      return {
        ...state,
        notifications: nextNotifications,
        total: state.total - 1,
      };
    }),
}));

export { useStore };
