import { CRGInfoButton, CRGText } from 'components/atoms';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import translation from 'utils/translation';
import { BottomDetailInfo, TopDetailInfo } from 'components/bundle';
import { useInfoDocument } from 'hooks';
import { PropTypes } from 'prop-types';

const ContainerStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  margin-left: 5px;
  margin-bottom: 37px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  /* width: 90vw; */
  @media screen and (max-width: 1600px) {
    width: 85vm;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-left: 0px;
  }
`;

const DetailStyle = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  /* width: 90vw; */
  ${(props) => props.theme.breakpoints.down('sm')} {
    gap: 13px;
  }
  @media screen and (max-width: 1600px) {
    width: 85vm;
  }
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 30px;
  width: fit-content;
`;

const OrderDetail = ({ order, onClose }) => {
  const intl = useIntl();
  const { ORDERS_PDF_URL } = useInfoDocument();

  const i18n = translation.pages(intl).orderDetails;

  return (
    <ContainerStyle>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CloseContainer onClick={onClose}>
          <img
            width={'20px'}
            height={'20px'}
            src={'/images/black_close_icon.svg'}
          />
        </CloseContainer>
      </div>
      <TitleContainer isMenuExpanded={false}>
        <CRGText colorVariant="neutralDarkBlack" fontSize={23} fontWeight={600}>
          {i18n.title}
        </CRGText>
        <CRGInfoButton infoUrl={ORDERS_PDF_URL} />
      </TitleContainer>
      <DetailStyle isMenuExpanded={false}>
        <TopDetailInfo order={order} />
        <BottomDetailInfo order={order} />
      </DetailStyle>
    </ContainerStyle>
  );
};

OrderDetail.propTypes = {
  order: PropTypes.object,
  onClose: PropTypes.func,
};

export { OrderDetail };
