import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Select, FormControl, InputLabel } from '@mui/material';

const CRGSelectStyle = styled(Select)`
  background: ${(props) => props.theme.palette.neutral.light.white};
  box-shadow: 5px 5px 10px #0000000d;
  height: ${(props) => (props.customHeight ? props.customHeight : 'auto')};
  font-size: ${(props) =>
    props.customFontSize ? props.customFontSize : 'auto'};
  @media screen and (max-width: 360px) {
    width: ${(props) => (props.customWidth ? props.customWidth : '300px')};
  }
  fieldset {
    border: 1px solid #d1bfae;
  }

  .MuiInputBase-input {
    font-family: Montserrat;
    width: ${(props) => (props.customWidth ? props.customWidth : '100%')};
    background: ${(props) => {
      switch (props.colorVariant) {
        case 'neutralLigthWhite':
          return props.theme.palette.neutral.light.white;

        default:
          return 'none';
      }
    }};
  }
  :hover {
    .MuiOutlinedInput-notchedOutline {
      border: ${(props) =>
        props.error ? 'auto' : `2px solid #d1bfae !important`};
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: ${(props) =>
      props.error ? 'auto' : `1px solid #dee1e2 !important`};
  }
`;

const CRGInputLabelStyle = styled(InputLabel)`
  color: ${(props) => props.theme.palette.neutral.dark.spanishGray};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
`;

const FormControlStyle = styled(FormControl)`
  width: ${(props) => (props.customWidth ? props.customWidth : '100%')};
`;

const CRGSelect = ({
  customWidth,
  customHeight,
  customFontSize,
  error,
  ...props
}) => {
  return (
    <FormControlStyle customWidth={customWidth} customHeight={customHeight}>
      <CRGInputLabelStyle id={props.label}>{props.label}</CRGInputLabelStyle>
      <CRGSelectStyle
        customHeight={customHeight}
        customFontSize={customFontSize}
        error={error}
        {...props}
        labelId="demo-customized-select-label"
      />
    </FormControlStyle>
  );
};

CRGSelect.propTypes = {
  colorVariant: PropTypes.oneOf(['neutralLigthWhite']),
  customWidth: PropTypes.string,
  customHeight: PropTypes.string,
  customFontSize: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.boolean,
};

export { CRGSelect };
