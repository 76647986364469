import { useEffect } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';

import { useForm } from 'react-hook-form';
import {
  CRGButton,
  CRGTextFieldControlled,
  CRGMultiselectControlled,
  CRGText,
  CRGAutocompleteControlled,
  CRGTextField,
} from 'components/atoms';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { useMapping } from 'hooks';

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(90vh -50px);
  padding-top: 50px;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  gap: 10px;
  width: 85%;
`;

const TitleContainer = styled.div`
  width: 70vw;
  margin-bottom: 10px;
`;

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  height: 100%;
`;

const defaultValus = {
  nameSurname: null,
  status: null,
  country: null,
  email: null,
  company: null,
  type: null,
};

const UserMobileFiltersDrawerContent = ({
  onClose,
  onSearch,
  setLastFullSearch,
  lastFullSearch,
  countries,
}) => {
  // Hooks
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: defaultValus,
  });
  const { userStatusList, userTypeList } = useMapping();

  // Media Query
  const isSmallDevice = useMediaQuery('(max-width:360px)');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).userMobileFiltersDrawerContent;

  const handleFilterChange = (data) => {
    setLastFullSearch(data);
    onSearch?.(data);
    onClose?.();
  };

  // Effect
  useEffect(() => {
    if (lastFullSearch) reset(lastFullSearch);
  }, [lastFullSearch]);

  // Functions
  const clearFilters = () => {
    reset(defaultValus);
    handleFilterChange(defaultValus);
  };

  return (
    <ContainerStyle>
      <FormStyle onSubmit={handleSubmit(handleFilterChange)}>
        <TopContainer>
          <TitleContainer>
            <CRGText
              colorVariant={'neutralDarkBlack'}
              fontSize={20}
              fontWeight={600}
            >
              {i18n.title}
            </CRGText>
          </TitleContainer>
          {/* Nome e Cognome */}
          <CRGTextFieldControlled
            label={i18n.nameSurnameLabel}
            name="nameSurname"
            control={control}
            customWidth={'100%'}
          />

          {/* Nazione */}
          <CRGMultiselectControlled
            name="status"
            label={i18n.statusLabel}
            control={control}
            setValue={setValue}
            options={[
              {
                value: 0,
                label: userStatusList[0],
              },
              {
                value: 1,
                label: userStatusList[1],
              },
              {
                value: 2,
                label: userStatusList[2],
              },
              {
                value: 3,
                label: userStatusList[3],
              },
              {
                value: 5,
                label: userStatusList[5],
              },
            ]}
          />

          {/* Nazione */}
          {/* <CRGSelectControlled
            name="country"
            label={i18n.countryLabel}
            control={control}
            options={countries}
          /> */}
          <CRGAutocompleteControlled
            name="country"
            control={control}
            options={countries}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <CRGTextField
                {...params}
                label={i18n.countryLabel}
                customWidth={'100%'}
              />
            )}
          />

          {/* Email */}
          <CRGTextFieldControlled
            label={i18n.emailLabel}
            name="email"
            control={control}
            customWidth={'100%'}
          />

          {/* Azienda */}
          <CRGTextFieldControlled
            label={i18n.companyLabel}
            name="company"
            control={control}
            customWidth={'100%'}
          />

          {/* Nazione */}
          <CRGMultiselectControlled
            name="type"
            label={i18n.typeLabel}
            control={control}
            setValue={setValue}
            options={[
              {
                value: 0,
                label: userTypeList[0],
              },
              {
                value: 1,
                label: userTypeList[1],
              },
              {
                value: 2,
                label: userTypeList[2],
              },
              {
                value: 3,
                label: userTypeList[3],
              },
            ]}
          />

          <CRGButton
            type="submit"
            customWidth={isSmallDevice ? '100%' : '170px'}
            colorVariant={'mainSecondary'}
            onClick={clearFilters}
          >
            {i18n.clearFiltersButtonLabel}
          </CRGButton>
        </TopContainer>
        <ButtonContainer>
          <CRGButton
            customWidth={isMobile ? '130px' : '170px'}
            onClick={onClose}
          >
            {i18n.buttonCancelLabel}
          </CRGButton>
          <CRGButton
            type="submit"
            customWidth={isMobile ? '130px' : '170px'}
            colorVariant={'mainSecondary'}
          >
            {i18n.buttonSubmitLabel}
          </CRGButton>
        </ButtonContainer>
      </FormStyle>
    </ContainerStyle>
  );
};

UserMobileFiltersDrawerContent.propTypes = {
  onSearch: PropTypes.func,
  onClose: PropTypes.func,
  setLastFullSearch: PropTypes.func,
  lastFullSearch: PropTypes.object,
  countries: PropTypes.array,
};

export { UserMobileFiltersDrawerContent };
