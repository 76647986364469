import { useState, useContext, useEffect } from 'react';

import { useMediaQuery } from '@mui/material';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useForm, useWatch } from 'react-hook-form';

import { permissions, useUsers } from 'shared';
import { usePermissions } from 'hooks';

import {
  CRGButton,
  CRGText,
  CRGTextFieldControlled,
  CRGSelectControlled,
  CRGTextField,
  CRGAutocompleteControlled,
} from 'components/atoms';
import { CRGPermissionSelector } from 'components/bundle';
import { InfoDialogContext, LoadingContext, UserContext } from 'contexts';
import { useMapping } from 'hooks';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 30px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 15px;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 85%;
  }
`;

const RigthColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 85%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-right: 5%;
  width: 45vw;
  @media screen and (max-width: 1600px) {
    padding-bottom: 20px;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: flex-start;
    margin-bottom: 20px;
    flex-wrap: wrap;
    width: 85%;
    justify-content: space-between;
  }
`;

const Content = styled.div``;

const FormStyle = styled.form`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100vw;
  }
`;

const DeleteDialogButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const AddOrUpdateUser = ({ selectedUser, onClose, countries = [] }) => {
  // Hooks
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      business: '',
      name: '',
      username: '',
      company: '',
      email: '',
      country: null,
      type: '',
      customerCode: '',
      agentCode: '',
      languages: '',
      note: '',
      status: '',
    },
    reValidateMode: 'all',
  });
  const userHelper = useUsers();
  const { userStatusList, userTypeList, userLanguageList } = useMapping();
  const valueWatch = useWatch({
    control,
    name: 'type',
  });
  const currentUserTypeSelected = useWatch({
    control,
    name: 'type',
  });
  const currentStatus = useWatch({
    control,
    name: 'status',
  });
  const permissionHelper = usePermissions();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).addOrUpdateUser;

  // State
  const [userPermission, setUserPermission] = useState({});
  const [permittedStatus, setPermittedStatus] = useState([]);

  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Context
  const { setIsLoading } = useContext(LoadingContext);
  const { user } = useContext(UserContext);
  const { setInfoDialog } = useContext(InfoDialogContext);

  // Effects
  useEffect(() => {
    onResetPermission();
  }, [valueWatch]);

  useEffect(() => {
    if (selectedUser) {
      getUser(selectedUser);
    }
  }, [selectedUser]);

  useEffect(() => {
    let nextPermittedStatus = [];

    switch (selectedUser?.status) {
      case 0: // Caso utente non verificato
        nextPermittedStatus = [
          {
            value: 0,
            label: userStatusList[0],
          },
        ];
        break;
      case 1: // Caso utente verificato da approvare
        nextPermittedStatus = [
          {
            value: 1,
            label: userStatusList[1],
          },
          {
            value: 2,
            label: userStatusList[2],
          },
          {
            value: 3,
            label: userStatusList[3],
          },
          {
            value: 5,
            label: userStatusList[5],
          },
        ];
        break;
      case 2: // Caso utente approvato
      case 3: // Caso utente disabilitato
        nextPermittedStatus = [
          {
            value: 2,
            label: userStatusList[2],
          },
          {
            value: 3,
            label: userStatusList[3],
          },
        ];
        break;
      case 5: // Caso utente rifiutato
        nextPermittedStatus = [
          {
            value: 2,
            label: userStatusList[2],
          },
          {
            value: 5,
            label: userStatusList[5],
          },
        ];
        break;

      default:
        // Caso nuovo utente
        nextPermittedStatus = [
          {
            value: 0,
            label: userStatusList[0],
          },
        ];
        break;
    }

    setPermittedStatus(nextPermittedStatus);
  }, [selectedUser]);

  // Functions
  const submitForm = (data) => {
    const permissionsToSet =
      permissionHelper.getStringFromPermissionObj(userPermission);

    const nextData = {
      ...data,
      permissions: permissionsToSet,
      country: data?.country?.value,
      fromBackoffice: true,
    };
    setIsLoading(true);
    if (selectedUser) {
      userHelper
        .update(nextData)
        .then(() => {
          setIsLoading(false);
          onClose?.();
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    } else {
      userHelper
        .create(nextData)
        .then(() => {
          setIsLoading(false);
          onClose?.();
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    }
  };

  const onDelete = () => {
    setIsLoading(true);
    userHelper
      .remove(selectedUser)
      .then(() => {
        onClose?.();
        setInfoDialog(null);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setInfoDialog(null);
        setIsLoading(false);
      });
  };

  const onConfirmDelete = () => {
    setInfoDialog({
      isOpen: true,
      title: i18n.deleteUserDialog.title,
      message: i18n.deleteUserDialog.message,
      customActions: (
        <DeleteDialogButtonContainer>
          <CRGButton
            onClick={() => setInfoDialog(null)}
            colorVariant={'mainSecondary'}
            customWidth={'150px'}
          >
            {i18n.deleteUserDialog.cancelButton}
          </CRGButton>
          <CRGButton onClick={onDelete} customWidth={'150px'}>
            {i18n.deleteUserDialog.agreeButton}
          </CRGButton>
        </DeleteDialogButtonContainer>
      ),
    });
  };

  const getUser = async (userData) => {
    setIsLoading(true);
    userHelper
      .get(userData)
      .then(({ permissions, ...res }) => {
        reset({
          ...res,
          country: countries.find((c) => c.value === res?.country),
        });
        const nextPermissions =
          permissionHelper.getPermissionFromString(permissions);

        const userTypePermission = permissionHelper.getPermissionByUserType(
          res?.type
        );

        Object.keys(userTypePermission).forEach((parentKey) => {
          const isParentDisabled = userTypePermission[parentKey].disabled;
          if (isParentDisabled) {
            nextPermissions[parentKey].disabled = true;
          }
          const child = userTypePermission[parentKey].acl;
          Object.keys(child).forEach((childKey) => {
            const isChildrenDisabled =
              userTypePermission[parentKey].acl[childKey].disabled;
            if (isChildrenDisabled) {
              nextPermissions[parentKey].acl[childKey].disabled = true;
            }
          });
        });
        setUserPermission(nextPermissions);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const onResetPermission = () => {
    const nextPermissions = permissionHelper.getPermissionByUserType(
      valueWatch || 0
    );
    setUserPermission(nextPermissions);
  };

  const reSendVerificationMail = async () => {
    if (selectedUser) {
      try {
        await userHelper.verify(selectedUser);
        setInfoDialog({
          isOpen: true,
          title: i18n.verifyEmailDialog.title,
          message: i18n.verifyEmailDialog.message,
          customActions: (
            <CRGButton
              onClick={() => setInfoDialog(null)}
              colorVariant={'mainSecondary'}
              customWidth={'150px'}
            >
              {i18n.verifyEmailDialog.button}
            </CRGButton>
          ),
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <Container>
      <Content>
        <CRGText
          fontSize={30}
          fontWeight={600}
          colorVariant={'neutralDarkBlack'}
        >
          {selectedUser ? i18n.titleUpdate : i18n.title}
        </CRGText>
        <FormStyle onSubmit={handleSubmit(submitForm)}>
          <FormContainer>
            <LeftColumn>
              {/* Ditta */}
              <CRGTextFieldControlled
                name="business"
                label={i18n.form.business}
                control={control}
              />
              {/* Nome */}
              <CRGTextFieldControlled
                name="name"
                label={i18n.form.name}
                control={control}
                rules={{
                  required: { value: true, message: i18n.form.errors.name },
                }}
                errors={errors?.['name']}
              />
              {/* 
               Cognome
            */}
              <CRGTextFieldControlled
                name="surname"
                label={i18n.form.surname}
                control={control}
                rules={{
                  required: { value: true, message: i18n.form.errors.surname },
                }}
                errors={errors?.['surname']}
              />
              {/* 
               Username
            */}
              <CRGTextFieldControlled
                name="username"
                label={i18n.form.username}
                control={control}
                rules={{
                  required: { value: true, message: i18n.form.errors.username },
                }}
                errors={errors?.['username']}
              />
              {/* 
               Azienda
            */}
              <CRGTextFieldControlled
                name="company"
                label={i18n.form.company}
                control={control}
                rules={{
                  required: {
                    value: getValues('type').toString() !== '0',
                    message: i18n.form.errors.comapny,
                  },
                }}
                errors={errors?.['company']}
              />
              {/* 
               Email
            */}
              <CRGTextFieldControlled
                name="email"
                label={i18n.form.email}
                control={control}
                rules={{
                  required: { value: true, message: i18n.form.errors.email },
                }}
                errors={errors?.['email']}
              />
              {/* 
                Nazione
              */}
              {/* <CRGSelectControlled
                name="country"
                label={i18n.form.country}
                control={control}
                rules={{
                  required: { value: true, message: i18n.form.errors.country },
                }}
                errors={errors?.['country']}
                options={countries}
              /> */}
              <CRGAutocompleteControlled
                name="country"
                control={control}
                options={countries}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <CRGTextField
                    {...params}
                    label={i18n.form.country}
                    customWidth={'100%'}
                    error={errors?.['country']}
                  />
                )}
                rules={{
                  required: { value: true, message: i18n.form.errors.country },
                }}
                errors={errors?.['country']}
              />
              {/* Tipo Utente */}
              <CRGSelectControlled
                name="type"
                label={i18n.form.type}
                disabled={
                  user?.cariaggiUser?.type === 0 && selectedUser?.type === 3
                }
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: i18n.form.errors.type,
                  },
                }}
                errors={errors?.['type']}
                options={[
                  ...(user?.cariaggiUser?.type === 3
                    ? [
                        {
                          value: 0,
                          label: userTypeList[0],
                        },
                      ]
                    : []),
                  {
                    value: 1,
                    label: userTypeList[1],
                  },
                  {
                    value: 2,
                    label: userTypeList[2],
                  },
                  ...(user?.cariaggiUser?.type === 3 ||
                  (user?.cariaggiUser?.type === 0 && selectedUser?.type === 3)
                    ? [
                        {
                          value: 3,
                          label: userTypeList[3],
                        },
                      ]
                    : []),
                ]}
              />
              {/* 
               Codice Cliente
            */}
              <CRGTextFieldControlled
                name="customerCode"
                label={i18n.form.customerCode}
                control={control}
                rules={{
                  required: {
                    value: currentUserTypeSelected !== 3 && currentStatus !== 5,
                    message: i18n.form.errors.customerCode,
                  },
                }}
                errors={errors?.['customerCode']}
              />
              {/* 
               Codice Agent
            */}
              {currentUserTypeSelected === 1 ? (
                <CRGTextFieldControlled
                  name="agentCode"
                  label={i18n.form.agentCode}
                  control={control}
                  rules={{
                    required: {
                      value:
                        currentUserTypeSelected === 1 && currentStatus !== 5,
                      message: i18n.form.errors.agentCode,
                    },
                  }}
                  errors={errors?.['agentCode']}
                />
              ) : null}
              {/* Lingua */}
              <CRGSelectControlled
                name="language"
                label={i18n.form.language}
                control={control}
                rules={{
                  required: { value: true, message: i18n.form.errors.language },
                }}
                errors={errors?.['language']}
                options={[
                  {
                    value: 0,
                    label: userLanguageList[0],
                  },
                  {
                    value: 1,
                    label: userLanguageList[1],
                  },
                ]}
              />
              {/* Note */}
              <CRGTextFieldControlled
                name="note"
                label={i18n.form.note}
                control={control}
                rules={{ required: { value: false } }}
                errors={errors?.['note']}
                rows={4}
                multiline
              />
            </LeftColumn>
            <RigthColumn>
              {/* 
               StatStato
            */}
              <CRGSelectControlled
                name="status"
                label={i18n.form.status}
                control={control}
                disabled={
                  !user?.cariaggiUser?.permissions
                    ?.split('|')
                    .includes(permissions.USER_UPDATE?.toString())
                }
                rules={{
                  required: { value: true, message: i18n.form.errors.status },
                }}
                errors={errors?.['status']}
                options={permittedStatus}
              />
              {selectedUser?.status === 0 ? (
                <CRGButton
                  onClick={reSendVerificationMail}
                  customWidth={100}
                  customHeight={30}
                  customFontSize={18}
                >
                  {i18n.sendVerificationMail}
                </CRGButton>
              ) : null}
              <CRGPermissionSelector
                permission={userPermission}
                setPermission={setUserPermission}
                onReset={onResetPermission}
                customFontSize={12}
                isDisabled={
                  user?.cariaggiUser?.type === 0 && selectedUser?.type === 3
                }
              />
            </RigthColumn>
          </FormContainer>
          <ButtonContainer>
            <CRGButton
              className="cancel"
              customWidth={isMobile ? '40vw' : '30%'}
              onClick={onClose}
              colorVariant={'mainSecondary'}
            >
              {i18n.cancelButtonLabel}
            </CRGButton>
            {selectedUser &&
            user?.cariaggiUser?.permissions
              ?.split('|')
              .includes(permissions.USER_DELETE?.toString?.()) ? (
              <CRGButton
                className="delete"
                customWidth={isMobile ? '40vw' : '30%'}
                onClick={onConfirmDelete}
              >
                {i18n.deleteButtonLabel}
              </CRGButton>
            ) : null}
            <CRGButton
              type="submit"
              colorVariant={'mainPrimary'}
              customWidth={
                isMobile
                  ? selectedUser &&
                    user?.cariaggiUser?.permissions
                      ?.split('|')
                      .includes(permissions.USER_DELETE?.toString?.())
                    ? '100%'
                    : '40vw'
                  : '30%'
              }
            >
              {selectedUser ? i18n.updateButtonLabel : i18n.createButtonLabel}
            </CRGButton>
          </ButtonContainer>
        </FormStyle>
      </Content>
    </Container>
  );
};

AddOrUpdateUser.propTypes = {
  selectedUser: PropTypes.obj,
  onClose: PropTypes.func,
  countries: PropTypes.array,
};

export { AddOrUpdateUser };
