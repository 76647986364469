import useFetch from 'use-http';
import { Auth } from 'aws-amplify';

import { BACKEND_URL } from '../utils/constants';

const authUrls = ['/users'];

const useUsers = (isMobile) => {
  const backendHelper = useFetch(BACKEND_URL, {
    onError: ({ error }) => {
      throw error;
    },
    persist: !isMobile,
    cachePolicy: isMobile ? 'no-cache' : 'cache-first',
    cacheLife: 30000,
    interceptors: {
      request: async ({ options, route }) => {
        const authenticatedUrl = authUrls.find((p) => route?.indexOf(p) !== -1);

        if (authenticatedUrl) {
          try {
            const data = await Auth.currentSession();
            const jwt = data?.getAccessToken().getJwtToken();
            options.headers.Authorization = `Bearer ${jwt}`;
          } catch (error) {
            console.log(
              '🚀 ~ file: useArticles.js:23 ~ request: ~ error:',
              error
            );
          }
        }
        return options;
      },
      response: ({ response }) => {
        if (!response.ok) throw new Error(JSON.stringify(response.data));
        return response;
      },
    },
  });

  const list = (params) =>
    new Promise((resolve, reject) => {
      const normalizedParams = {
        ...params,
        page: params.page || 0,
        pageSize: params.pageSize || 10,
      };

      const multipleFields = {};

      Object.keys(normalizedParams).forEach((key) => {
        if (
          normalizedParams[key] === null ||
          normalizedParams[key] === '' ||
          normalizedParams[key] === undefined
        ) {
          delete normalizedParams[key];
        } else if (Array.isArray(normalizedParams[key])) {
          multipleFields[key] = normalizedParams[key];
          delete normalizedParams[key];
        }
      });

      const queryParams = new URLSearchParams(normalizedParams);

      Object.keys(multipleFields).forEach((key) => {
        const valueArray = multipleFields[key];
        valueArray.forEach((v) => {
          queryParams.append(`${key}[]`, v);
        });
      });

      backendHelper
        .get(`/users?${queryParams.toString()}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error get user', e);
          reject(e);
        });
    });

  const get = (params) =>
    new Promise((resolve, reject) => {
      backendHelper
        .get(`/users/${params?.id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error create user', e);
          reject(e);
        });
    });

  const create = (params) =>
    new Promise((resolve, reject) => {
      backendHelper
        .post(`/users`, params)
        .then((res) => {
          backendHelper.cache.clear();
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error create user', e);
          reject(e);
        });
    });

  const update = (params) =>
    new Promise((resolve, reject) => {
      backendHelper
        .put(`/users`, params)
        .then((res) => {
          backendHelper.cache.clear();
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error update user', e);
          reject(e);
        });
    });

  const remove = (params) =>
    new Promise((resolve, reject) => {
      backendHelper
        .delete(`/users/${params.id}`)
        .then((res) => {
          backendHelper.cache.clear();
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error delete user', e);
          reject(e);
        });
    });

  const verify = (params) =>
    new Promise((resolve, reject) => {
      backendHelper
        .put(`/users/verify/${params.id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error verify user', e);
          reject(e);
        });
    });

  const doWhile = async (params, pages) => {
    let i = 1;
    const usersDB = [];
    const actions = [];
    do {
      actions.push(list({ ...params, page: i }));
      i++;
    } while (i < pages);

    const data = await Promise.all(actions);
    data.map((d) => usersDB.push(...d.users));
    return usersDB;
  };

  const listAll = async (params) => {
    return new Promise((resolve, reject) => {
      let pages = 0;
      const result = list(params);
      result
        .then((response) => {
          const { users, total } = response;
          pages = Math.ceil(total / params.pageSize);
          doWhile(params, pages).then((data) =>
            resolve({ users: [...data, ...users], total })
          );
        })
        .catch((ex) => reject(ex));
    });
  };

  return {
    list,
    create,
    update,
    remove,
    get,
    verify,
    listAll,
  };
};

export { useUsers };
