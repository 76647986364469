const permissions = {
  WIDGET_READ: 1,
  WIDGET_STOCKLIST: 2,
  WIDGET_USERS: 3,
  USER_READ: 4,
  USER_CREATE: 5,
  USER_PARTIAL_UPDATE: 6,
  USER_UPDATE: 7,
  USER_DELETE: 8,
  STOCKLIST_READ: 9,
  STOCKLIST_CREATE: 10,
  STOCKLIST_READ_CUSTOMER_GROUP: 11,
  MANUAL_NOTIFICATION: 12,
  SEND_MANUAL_NOTIFICATION: 13,
  INVALIDATE_CACHE: 14,
  INVALIDATE_CACHE_2: 15,
  ORDERS_READ: 16,
  ORDERS_FILATI: 17,
  ORDERS_LABDIP: 18,
  ORDERS_CAMPIONARIO: 19,
  ORDERS_HISTORY_READ: 20,
  ORDERS_HISTORY_FILATI: 21,
  ORDERS_HISTORY_LABDIP: 22,
  ORDERS_HISTORY_CAMPIONARIO: 23,
  WIDGET_ORDERS_FILATI: 24,
  WIDGET_ORDERS_LABDIP: 25,
  WIDGET_ORDERS_CAMPIONARIO: 26,
  BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY: 27,
  BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2: 28,
};

export { permissions };
