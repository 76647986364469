import { Box, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import {
  CRGButton,
  CRGInfoButton,
  CRGText,
  CRGTooltip,
} from 'components/atoms';
import {
  OrderContainer,
  OrdersFilter,
  OrdersFilterMobile,
} from 'components/bundle';
import { CRGMainLayout } from 'components/layouts';
import { MenuContext, UserContext } from 'contexts';
import { useCustomIntl, useInfoDocument, useOrdersFilter } from 'hooks';
import { Fragment, useContext, useEffect, useState } from 'react';
import { permissions, useCustomers } from 'shared';
import translation from 'utils/translation';
import styled from 'styled-components';
import { useOrdersHistoryFiltersStore } from '../stores/ordersHistoryFilters';
import shallow from 'zustand/shallow';
import PropTypes from 'prop-types';

const ContainerStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 30px;
  padding-left: 50px;
  position: relative;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  margin-left: 5px;
  margin-bottom: 10px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.isMenuExpanded ? '79vw' : '90vw')};
  @media screen and (max-width: 1600px) {
    width: ${(props) => (props.isMenuExpanded ? '80vw' : '85vw')};
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-left: 0px;
    width: 100%;
  }
`;

const TitleSyncStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FiltersContainer = styled.div`
  margin-left: 5px;
  margin-bottom: 36px;
  width: ${(props) => (props.isMenuExpanded ? '79vw' : '90vw')};
  @media screen and (max-width: 1600px) {
    width: ${(props) => (props.isMenuExpanded ? '80vw' : '85vw')};
  }
`;

const AccordionContainer = styled.div`
  flex-direction: column;
  display: flex;
  gap: 5px;
  width: ${(props) => (props.isMenuExpanded ? '79vw' : '90vw')};
  @media screen and (max-width: 1600px) {
    width: ${(props) => (props.isMenuExpanded ? '80vw' : '90vw')};
  }
  margin-bottom: 40px;
`;

const ExportStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const OrdersHistory = () => {
  const customersHelper = useCustomers();
  const { ORDERS_HISTORY_PDF_URL } = useInfoDocument();

  // Object pick, re-renders the component when either state.nuts or state.honey change
  const { filters, setFilters, orderByObj, setOrderByObj } =
    useOrdersHistoryFiltersStore(
      (state) => ({
        filters: state.filters,
        setFilters: state.setFilters,
        orderByObj: state.orderByObj,
        setOrderByObj: state.setOrderByObj,
      }),
      shallow
    );

  //i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).orders;
  const i18nGlobal = translation.global(intl).app.orders;
  const { ordersHistory: ordersHistoryTitle } =
    translation.components(intl).menuList.menu;
  const { sectionRefresh: sectionRefreshTooltip } =
    translation.components(intl).tooltip;

  // Context
  const { isMenuExpanded } = useContext(MenuContext);

  const { user } = useContext(UserContext);
  const permissionsPrefs =
    user?.cariaggiUser?.permissionsPrefs?.split('|')?.map((p) => Number(p)) ||
    [];

  const daysToExtractDefault = (() => {
    if (user?.cariaggiUser?.type === 2) return 90;
    if (user?.cariaggiUser?.type === 1) return 30;
  })();

  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  //State
  const [customers, setCustomers] = useState([]);
  const [value, setValue] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [currentFilters, setCurrentFilters] = useState({
    VS_ORD: filters?.customerOrder,
    DATA_VS_ORD: filters?.orderDate,
    COD_CONFERMA_ORDINE: filters?.orderConfirmation,
    COD_TIPO_ORDINE: filters?.orderType,
    COD_AVANZAMENTO_ORDINE: filters?.progress,
    COD_STATO_ORDINE: filters?.orderStatus,
    COD_CLIENTE: filters?.customer ? filters?.customer?.id : null,
    CODICE_MATERIALE_CARIAGGI: filters?.cariaggiColor,
    CODICE_MATERIALE_CLIENTE: filters?.customerColor,
    DATA_CONSEGNA:
      filters?.orderByUndefinedShippingDate === true
        ? '0'
        : filters?.shippingDate,
    RAGIONE_SOCIALE_CLIENTE_FINALE: filters?.finalCustomer,
    INDIRIZZO_DESTINAZIONE: filters?.finalDestination,
    STAGIONE_CLIENTE: filters?.customerSeason,
    DESCR_VS_LINEA: filters?.customerLine,
    DESCR_VS_BOARD: filters?.customerBoard,
    MONETA: filters?.currency,
    PAGAMENTO: filters?.payment,
    GIORNI: daysToExtractDefault,
  });

  const { orders, getOrdersData, getExcelData } = useOrdersFilter({
    filters: currentFilters,
    orderByObj: orderByObj,
    isFromHistory: true,
  });

  const { campionarioOrders, filatiOrders, labdipOrders } = orders;

  const onFilterChange = (data) => {
    // Change filters on zustand store
    setFilters(data);

    const DATA_CONSEGNA =
      data?.orderByUndefinedShippingDate === true ? '0' : data?.shippingDate;

    const filters = {
      VS_ORD: data?.customerOrder,
      DATA_VS_ORD: data?.orderDate,
      COD_CONFERMA_ORDINE: data?.orderConfirmation,
      COD_TIPO_ORDINE: data?.orderType,
      COD_AVANZAMENTO_ORDINE: data?.progress,
      COD_STATO_ORDINE: data?.orderStatus,
      COD_CLIENTE: data?.customer ? data?.customer?.id : null,
      CODICE_MATERIALE_CARIAGGI: data?.cariaggiColor,
      CODICE_MATERIALE_CLIENTE: data?.customerColor,
      DATA_CONSEGNA,
      RAGIONE_SOCIALE_CLIENTE_FINALE: data?.finalCustomer,
      INDIRIZZO_DESTINAZIONE: data?.finalDestination,
      STAGIONE_CLIENTE: data?.customerSeason,
      DESCR_VS_LINEA: data?.customerLine,
      DESCR_VS_BOARD: data?.customerBoard,
      MONETA: data?.currency,
      PAGAMENTO: data?.payment,
      GIORNI: data?.daysToExtract,
      fetchData: data?.daysToExtract !== currentFilters?.GIORNI,
    };
    setCurrentFilters(filters);
  };

  const fetchCustomers = async () => {
    customersHelper
      .get()
      .then((res) => {
        setCustomers(res);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    getOrdersData();
  }, []);

  useEffect(() => {
    if (user != null && user?.cariaggiUser?.type !== 2) {
      fetchCustomers();
    }
  }, [user]);

  useEffect(() => {
    const hasFilati = permissionsPrefs?.includes(
      permissions.ORDERS_HISTORY_FILATI
    );
    const hasCampionario = permissionsPrefs?.includes(
      permissions.ORDERS_HISTORY_CAMPIONARIO
    );
    const hasLabdip = permissionsPrefs?.includes(
      permissions.ORDERS_HISTORY_LABDIP
    );
    const tabs = [];
    if (hasFilati)
      tabs.push({
        label: `${i18nGlobal.filatiLabel} (${filatiOrders?.length})`,
        data: filatiOrders,
      });
    if (hasCampionario)
      tabs.push({
        label: `${i18nGlobal.campionarioLabel} (${campionarioOrders?.length})`,
        data: campionarioOrders,
      });
    if (hasLabdip)
      tabs.push({
        label: `${i18nGlobal.labdipLabel} (${labdipOrders?.length})`,
        data: labdipOrders,
      });
    setTabs(tabs);
  }, [orders]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <CRGMainLayout>
      <ContainerStyle>
        <TitleContainer isMenuExpanded={isMenuExpanded}>
          <TitleSyncStyle>
            <CRGText
              fontSize={23}
              fontWeight={600}
              colorVariant={'neutralDarkBlack'}
            >
              {ordersHistoryTitle}
            </CRGText>
            {!isMobile && (
              <CRGTooltip title={sectionRefreshTooltip}>
                <div>
                  <CRGButton
                    customHeight={'45px'}
                    customWidth={'45px'}
                    onClick={getOrdersData}
                    colorVariant={'mainSecondary'}
                  >
                    <img
                      width={'24px'}
                      height={'24px'}
                      src="/images/refresh.svg"
                    />
                  </CRGButton>
                </div>
              </CRGTooltip>
            )}
          </TitleSyncStyle>
          <CRGInfoButton infoUrl={ORDERS_HISTORY_PDF_URL} />
        </TitleContainer>
        {isMobile ? (
          <OrdersFilterMobile
            onSearch={onFilterChange}
            customers={customers}
            currentFilters={filters}
            orderByObj={orderByObj}
            setOrderByObj={setOrderByObj}
            isFromHistory
          />
        ) : (
          <FiltersContainer isMenuExpanded={isMenuExpanded}>
            <OrdersFilter
              handleFilterChange={onFilterChange}
              customers={customers}
              orderByObj={orderByObj}
              setOrderByObj={setOrderByObj}
              isFromHistory
              currentFilters={filters}
            />
          </FiltersContainer>
        )}
        <AccordionContainer isMenuExpanded={isMenuExpanded}>
          {!isMobile && (
            <ExportStyle>
              <CRGButton
                customHeight={'45px'}
                colorVariant={'mainSecondary'}
                onClick={getExcelData}
              >
                {i18n.exportExcel}
              </CRGButton>
            </ExportStyle>
          )}
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} {...a11yProps(index)} />
            ))}
          </Tabs>
          {tabs.map((tab, index) => (
            <Fragment key={index}>
              <TabPanel
                value={value}
                index={index}
                style={{
                  backgroundColor:
                    tab?.data?.length > 0 ? 'white' : 'transparent',
                }}
              >
                <OrderContainer orders={tab?.data} />
              </TabPanel>
            </Fragment>
          ))}
        </AccordionContainer>
      </ContainerStyle>
    </CRGMainLayout>
  );
};

export { OrdersHistory };
