import useFetch from 'use-http';

import { Auth } from 'aws-amplify';
import { BACKEND_URL } from '../utils/constants';

const authUrls = ['/order'];

const useOrders = (isMobile) => {
  const backendHelper = useFetch(BACKEND_URL, {
    onError: ({ error }) => {
      throw error;
    },
    persist: false,
    cachePolicy: isMobile ? 'no-cache' : 'cache-first',
    cacheLife: 120000,
    interceptors: {
      request: async ({ options, route }) => {
        const authenticatedUrl = authUrls.find((p) => route?.indexOf(p) !== -1);
        if (authenticatedUrl) {
          try {
            const data = await Auth.currentSession();
            const jwt = data?.getAccessToken().getJwtToken();
            options.headers.Authorization = `Bearer ${jwt}`;
          } catch (error) {
            console.log(
              '🚀 ~ file: useArticles.js:23 ~ request: ~ error:',
              error
            );
          }
        }
        return options;
      },
      response: ({ response }) => {
        if (!response.ok) throw new Error(JSON.stringify(response.data));
        return response;
      },
    },
  });

  const get = (isFromHistory = false, daysToExtract) => {
    let params = `history=${isFromHistory}`;
    if (daysToExtract) params += `&GIORNI=${daysToExtract}`;
    return new Promise((resolve, reject) => {
      backendHelper
        .get(`/order?${params}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error get orders', e);
          reject(e);
        });
    });
  };

  const exportData = (history = undefined, params) =>
    new Promise((resolve, reject) => {
      const normalizedParams = {
        ...params,
        history,
      };
      const multipleFields = {};
      Object.keys(params).forEach((key) => {
        if (
          normalizedParams[key] === null ||
          normalizedParams[key] === '' ||
          normalizedParams[key] === undefined
        ) {
          delete normalizedParams[key];
        } else if (Array.isArray(normalizedParams[key])) {
          multipleFields[key] = normalizedParams[key];
          delete normalizedParams[key];
        }
      });
      const queryParams = new URLSearchParams(normalizedParams);
      Object.keys(multipleFields).forEach((key) => {
        const valueArray = multipleFields[key];
        valueArray.forEach((v) => {
          queryParams.append(`${key}[]`, v);
        });
      });

      backendHelper
        .get(`/order/excel?${queryParams.toString()}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error get orders', e);
          reject(e);
        });
    });

  const getShipping = ({ rifOrdine }) =>
    new Promise((resolve, reject) => {
      backendHelper
        .get(`/order/shipping/${rifOrdine}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error get orders', e);
          reject(e);
        });
    });

  return {
    get,
    exportData,
    getShipping,
  };
};

export { useOrders };
