import { useContext } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { MenuItem } from 'components/bundle';
import { InfoDialogContext, MenuContext, UserContext } from 'contexts';
import { CRGButton } from 'components/atoms';
import { permissions, useAuthentication, useToken } from 'shared';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { PROVIDER_TOKEN } from 'utils/constants';
import { useOrdersFiltersStore } from 'stores/ordersFilters';
import { useOrdersHistoryFiltersStore } from 'stores/ordersHistoryFilters';

const MenuListContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoutCustomButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  @media screen and (max-width: 360px) {
    flex-direction: column;
  }
`;

const MenuList = ({ isOpen, onChange }) => {
  // Hooks
  const { resetFilters: resetOrderFilters } = useOrdersFiltersStore(
    (state) => ({
      resetFilters: state.resetFilters,
    })
  );
  const { resetFilters: resetOrdHistoryFilters } = useOrdersHistoryFiltersStore(
    (state) => ({
      resetFilters: state.resetFilters,
    })
  );

  const navigate = useNavigate();

  const hubListenerCallback = async (event) => {
    switch (event) {
      case 'tokenRefresh_failure':
        handleLogout();
        break;
      default:
        break;
    }
  };

  const authHelper = useAuthentication(hubListenerCallback);
  const tokenHelper = useToken();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).menuList;
  const i18nTooltip = translation.components(intl).tooltip;

  // Contexts
  const { setInfoDialog } = useContext(InfoDialogContext);
  const { user, setUser, token } = useContext(UserContext);

  // State
  const { currentItemSelected, setCurrentItemSelected } =
    useContext(MenuContext);

  // Const
  const menuItems = [
    {
      id: 0,
      label: i18n.menu.home,
      imgSrc: '/images/menu/home.svg',
      action: () => navigate('/home'),
      tooltip: i18nTooltip.menu.home,
    },
    ...((user?.cariaggiUser?.type === 3 || user?.cariaggiUser?.type === 0) &&
    user?.cariaggiUser?.permissions
      ?.split('|')
      ?.includes(permissions?.USER_READ?.toString())
      ? [
          {
            id: 1,
            label: i18n.menu.users,
            imgSrc: '/images/menu/users.svg',
            action: () => navigate('/users'),
            tooltip: i18nTooltip.menu.users,
          },
        ]
      : []),
    ...(user?.cariaggiUser?.permissions
      ?.split('|')
      ?.includes(permissions?.STOCKLIST_READ?.toString())
      ? [
          {
            id: 2,
            label: i18n.menu.stocklist,
            imgSrc: '/images/menu/stocklist.svg',
            action: () => navigate('/stocklist'),
            tooltip: i18nTooltip.menu.stocklist,
          },
        ]
      : []),
    ...(user?.cariaggiUser?.permissions
      ?.split('|')
      ?.includes(permissions?.ORDERS_READ?.toString())
      ? [
          {
            id: 6,
            label: i18n.menu.orders,
            imgSrc: '/images/menu/orderlist.svg',
            action: () => navigate('/orders'),
            tooltip: i18nTooltip.menu.orders,
          },
        ]
      : []),
    ...(user?.cariaggiUser?.permissions
      ?.split('|')
      ?.includes(permissions?.ORDERS_HISTORY_READ?.toString())
      ? [
          {
            id: 7,
            label: i18n.menu.ordersHistory,
            imgSrc: '/images/menu/ordersHistory.svg',
            action: () => navigate('/ordersHistory'),
            tooltip: i18nTooltip.menu.ordersHistory,
          },
        ]
      : []),
    ...(user?.cariaggiUser?.permissions
      ?.split('|')
      ?.includes(permissions?.MANUAL_NOTIFICATION?.toString())
      ? [
          {
            id: 5,
            label: i18n.menu.notifications,
            imgSrc: '/images/menu/notifications.svg',
            action: () => navigate('/manual-notifications'),
            tooltip: i18nTooltip.menu.notifications,
          },
        ]
      : []),
    {
      id: 3,
      label: i18n.menu.settings,
      imgSrc: '/images/menu/settings.svg',
      action: () => navigate('/settings'),
      tooltip: i18nTooltip.menu.settings,
    },
    {
      id: 4,
      label: i18n.menu.logout,
      imgSrc: '/images/menu/logout.svg',
      action: () => {
        setInfoDialog({
          isOpen: true,
          title: i18n.logoutInfoDialog.title,
          message: i18n.logoutInfoDialog.message,
          customActions: (
            <LogoutCustomButtonContainer>
              <CRGButton
                colorVariant={'mainSecondary'}
                onClick={() => {
                  const lastItemSelected = currentItemSelected;
                  setCurrentItemSelected(lastItemSelected);
                  setInfoDialog(null);
                }}
                customWidth={'150px'}
              >
                {i18n.logoutInfoDialog.cancelButtonLabel}
              </CRGButton>
              <CRGButton onClick={handleLogout} customWidth={'150px'}>
                {i18n.logoutInfoDialog.agreeButtonLabel}
              </CRGButton>
            </LogoutCustomButtonContainer>
          ),
        });
      },
      tooltip: i18nTooltip.menu.logout,
    },
  ];

  // Functions
  const handleLogout = () => {
    const execLogout = () => {
      authHelper
        .logout()
        .then(() => {
          localStorage.removeItem('cariaggi-web-user');
          navigate('/login');
          setInfoDialog(null);
          setUser(null);
          setCurrentItemSelected(null);
          resetOrderFilters();
          resetOrdHistoryFilters();
        })
        .catch((err) => {
          console.error(err);
        });
    };
    if (token) {
      tokenHelper
        .remove({ provider: PROVIDER_TOKEN, token: token })
        .then(() => {
          execLogout();
        })
        .catch((ex) => console.error('Error while sending token', ex));
    } else {
      execLogout();
    }
  };

  return (
    <MenuListContainerStyle>
      {menuItems.map((i) => {
        return (
          <MenuItem
            key={i.id}
            label={i.label}
            action={() => {
              setCurrentItemSelected(i.id);
              i.action?.();
              onChange?.();
            }}
            imgSrc={i.imgSrc}
            isSmall={!isOpen}
            isSelected={currentItemSelected === i.id}
            tooltip={i.tooltip}
          />
        );
      })}
    </MenuListContainerStyle>
  );
};

MenuList.propTypes = {
  isOpen: PropTypes.bool,
  onChange: PropTypes.func,
};

export { MenuList };
