import {
  CRGButton,
  CRGOrderByArrow,
  CRGSelect,
  CRGSelectControlled,
} from 'components/atoms';
import { DrawerContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import { OrdersMobileFiltersDrawerContent } from './OrdersMobileFiltersDrawerContent';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  useCustomIntl,
  useMapping,
  useOrdersFilter,
  useOrdersMapping,
} from 'hooks';
import translation from 'utils/translation';
import { MenuItem } from '@mui/material';

const ContainerStyle = styled.form`
  display: flex;
  gap: 15px;
  margin-top: 10px;
  margin-bottom: 25px;
  flex-direction: column;
`;

const RowStyle = styled.div`
  height: 100%;
  display: flex;
  gap: 10px;
  height: 45px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OrdersFilterMobile = ({
  onSearch,
  customers,
  currentFilters,
  orderByObj,
  setOrderByObj,
  isFromHistory,
}) => {
  const { orderStatusValues, orderProgressValues } = useMapping();
  const [lastFullSearch, setLastFullSearch] = useState();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: currentFilters,
  });

  // Context
  const { setIsDrawerOpen, closeDrawer } = useContext(DrawerContext);

  const intl = useCustomIntl();
  const i18n = translation.components(intl).ordersFilters;
  const { exportExcel, refresh } = translation.pages(intl).orders;
  const { orderLabel } = translation.components(intl).mobileTable;

  const { getOrdersData, getExcelData } = useOrdersFilter({
    filters: currentFilters,
    isFromHistory,
    orderByObj: orderByObj,
  });

  const { ordersValuesForOrderBy } = useOrdersMapping();

  //Effect
  useEffect(() => {
    if (lastFullSearch)
      reset({
        orderStatus: lastFullSearch?.orderStatus,
        progress: lastFullSearch?.progress,
      });
  }, [lastFullSearch]);

  const onFilterClick = () => {
    setIsDrawerOpen({
      isOpen: true,
      children: (
        <OrdersMobileFiltersDrawerContent
          onClose={closeDrawer}
          lastFullSearch={lastFullSearch}
          setLastFullSearch={setLastFullSearch}
          onSearch={(data) => {
            onSearch(data);
          }}
          customers={customers}
          isFromHistory={isFromHistory}
        />
      ),
    });
  };

  const handleFilterChange = (data) => {
    const { GIORNI } = currentFilters;
    setLastFullSearch({ ...data, daysToExtract: GIORNI });
    onSearch?.({ ...data, daysToExtract: GIORNI });
  };

  const handleOrderByDirection = () => {
    const newDirection = orderByObj.direction === 'ASC' ? 'DESC' : 'ASC';
    setOrderByObj({ ...orderByObj, direction: newDirection });
  };

  return (
    <ContainerStyle onSubmit={handleSubmit(handleFilterChange)}>
      <RowStyle>
        <CRGSelectControlled
          control={control}
          name="orderStatus"
          label={i18n.status}
          options={orderStatusValues}
          customHeight={'45px'}
        />
        <CRGButton
          customHeight={'45px'}
          customWidth={'45px'}
          onClick={onFilterClick}
        >
          <img
            width={'10px'}
            height={'10px'}
            src={'images/filter_burger.svg'}
          />
        </CRGButton>
      </RowStyle>
      <RowStyle>
        <CRGSelectControlled
          control={control}
          name="progress"
          label={i18n.progress}
          options={orderProgressValues}
          customHeight={'45px'}
        />
        <CRGButton type="submit" customHeight={'45px'} customWidth={'45px'}>
          <img width={'10px'} height={'10px'} src={'images/search_lens.svg'} />
        </CRGButton>
      </RowStyle>
      <RowStyle>
        <CRGSelect
          label={orderLabel}
          customHeight={'45px'}
          defaultValue={orderByObj.field}
          onChange={(e) => {
            const field = e.target.value;
            setOrderByObj({
              ...orderByObj,
              field,
            });
          }}
        >
          {ordersValuesForOrderBy.map((o) => (
            <MenuItem key={o.key} value={o.key}>
              {o.label}
            </MenuItem>
          ))}
        </CRGSelect>
        <CRGButton
          customHeight={'45px'}
          customWidth={'45px'}
          onClick={handleOrderByDirection}
          colorVariant={'lightWhite'}
        >
          <CRGOrderByArrow orderByDirection={orderByObj.direction} />
        </CRGButton>
      </RowStyle>
      <ButtonsContainer>
        <CRGButton
          customHeight={'35px'}
          customWidth={'auto'}
          onClick={getOrdersData}
        >
          {refresh}
        </CRGButton>
        <CRGButton
          customHeight={'35px'}
          customWidth={'auto'}
          colorVariant={'mainSecondary'}
          onClick={getExcelData}
        >
          {exportExcel}
        </CRGButton>
      </ButtonsContainer>
    </ContainerStyle>
  );
};

OrdersFilterMobile.propTypes = {
  onSearch: PropTypes.func.isRequired,
  customers: PropTypes.array,
  currentFilters: PropTypes.object,
  orderByObj: PropTypes.object,
  setOrderByObj: PropTypes.func,
  isFromHistory: PropTypes.bool,
};

export { OrdersFilterMobile };
