import { useState, useEffect, useContext } from 'react';

import { useMediaQuery } from '@mui/material';

import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CRGSplitLayout } from 'components/layouts';

import { useAuthentication } from 'shared';

import { useCustomIntl } from 'hooks';
import translation from 'utils/translation';
import { LoadingContext } from 'contexts/LoadingContext';
import { CRGButton, CRGText } from 'components/atoms';
import { LangContext } from 'contexts';

const LoginContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    margin-top: 20px;
  }
`;

const TextContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 90%;
  }
`;

const LogoImgStyle = styled.img`
  filter: invert(1);
  width: 210px;
  height: 150px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 127px;
    height: 90px;
  }
`;

const RedBulletStyle = styled.img`
  width: 90px;
  height: 90px;
  margin-top: 50px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 60px;
    height: 60px;
  }
`;

const Activate = () => {
  // Hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [id] = useState(searchParams.get('id'));

  const authHelper = useAuthentication();

  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Context
  const { setIsLoading } = useContext(LoadingContext);
  const { lang } = useContext(LangContext);

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).activate;

  // Effects
  useEffect(() => {
    if (id && id !== 'undefined') {
      activateUser();
    }
  }, [id]);

  // Functions
  const activateUser = async () => {
    setIsLoading(true);
    authHelper
      .activate({ id })
      .then(() => {
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const onClose = () => {
    navigate('/login');
  };

  return (
    <CRGSplitLayout
      imageSrc={`images/activate_wallpaper_step_3${isMobile ? '_mobile' : ''}${
        lang === 'it-IT' ? '' : '-en'
      }.jpg`}
    >
      <LoginContainerStyle>
        <LogoImgStyle src={'images/logo.svg'} />
        <RedBulletStyle src={'images/red_bullet.svg'} />
        <TextContainer>
          <CRGText
            fontSize={isMobile ? 22 : 30}
            fontWeight={700}
            colorVariant={'neutralDarkBlack'}
            textAlign={'center'}
          >
            {i18n.text1}
          </CRGText>
          <CRGText
            fontSize={14}
            fontWeight={400}
            colorVariant={'neutralDarkBlack'}
            textAlign={'center'}
          >
            {i18n.text2}
          </CRGText>
          <CRGButton colorVariant="mainSecondary" onClick={onClose}>
            {i18n.buttonLabel}
          </CRGButton>
        </TextContainer>
      </LoginContainerStyle>
    </CRGSplitLayout>
  );
};

export { Activate };
