import React, { useState, useEffect } from 'react';

import { useIntl } from 'react-intl';

export const useCustomIntl = () => {
  const intl = useIntl();
  const [isTranslation, setIsTranslation] = useState();

  const listener = function (event) {
    if (
      process.env.REACT_APP_ENVIRONMENT !== 'prod' &&
      event.ctrlKey &&
      event.altKey &&
      event.key === '1'
    ) {
      setIsTranslation(!isTranslation);
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', listener);

    return () => document.removeEventListener('keyup', listener);
  });

  const wIntl = (data) => {
    const output = intl.formatMessage(data);
    if (!isTranslation) return output;

    return (
      <span data-i18n={data.id} style={{ border: '2px solid red' }}>
        {output + ' <'}
        <span style={{ color: 'red' }}>{data.id}</span>
        {'>'}
      </span>
    );
  };

  return {
    ...intl,
    formatMessage: wIntl,
  };
};
