import { useMediaQuery } from '@mui/material';
import {
  CRGAutocompleteControlled,
  CRGButton,
  CRGCheckboxControlled,
  CRGDateRangePickerControlled,
  CRGSelectControlled,
  CRGText,
  CRGTextField,
  CRGTextFieldControlled,
} from 'components/atoms';
import { UserContext } from 'contexts';
import { useCustomIntl, useMapping } from 'hooks';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import translation from 'utils/translation';
import styled from 'styled-components';
import { orderType, permissions } from 'shared';

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  gap: 10px;
  width: 85%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: space-between;
  }
`;

const TopElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 10px;
`;
const TitleContainer = styled.div`
  width: 70vw;
  margin-bottom: 10px;
`;

const defaultValues = {
  daysToExtract: '',
  customerOrder: '',
  orderDate: [null, null],
  orderConfirmation: '',
  orderType: '',
  progress: '',
  customer: null,
  cariaggiColor: '',
  customerColor: '',
  shippingDate: [null, null],
  finalCustomer: '',
  finalDestination: '',
  customerSeason: '',
  customerLine: '',
  customerBoard: '',
  currency: '',
  payment: '',
  orderByUndefinedShippingDate: false,
};

const OrdersMobileFiltersDrawerContent = ({
  onClose,
  onSearch,
  setLastFullSearch,
  lastFullSearch,
  customers,
  isFromHistory,
}) => {
  // Context
  const { user } = useContext(UserContext);

  // Hooks
  const {
    ordersTypeValues,
    orderStatusValues,
    orderConfirmationValues,
    orderProgressValues,
    orderDaysToExtractValues,
  } = useMapping();

  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues,
  });

  const isSmallDevice = useMediaQuery('(max-width:360px)');

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const intl = useCustomIntl();
  const i18nGlobal = translation.global(intl).app.filters;
  const i18n = translation.components(intl).ordersFilters;

  // Effect
  useEffect(() => {
    if (lastFullSearch) reset(lastFullSearch);
  }, [lastFullSearch]);

  const clearFilters = () => {
    if (!isFromHistory) {
      reset(defaultValues);
      handleFilterChange(defaultValues);
    } else {
      const daysToExtract = (() => {
        if (user?.cariaggiUser?.type === 2) return 90;
        if (user?.cariaggiUser?.type === 1) return 30;
      })();
      reset({ ...defaultValues, daysToExtract });
      handleFilterChange({ ...defaultValues, daysToExtract });
    }
  };

  const handleFilterChange = (data) => {
    setLastFullSearch(data);
    onSearch?.(data);
    onClose?.();
  };

  useEffect(() => {
    if (isFromHistory && lastFullSearch?.daysToExtract == null) {
      const userType = user?.cariaggiUser?.type;

      if (userType === 2) {
        setValue('daysToExtract', 90);
      }
      if (userType === 1) {
        setValue('daysToExtract', 30);
      }
    }
  }, [user]);

  const orderTypesArray = (() => {
    const preferencesArray =
      user?.cariaggiUser?.permissionsPrefs?.split('|') || [];
    let orderTypesArray = ordersTypeValues;
    const ORDERS_FILATI_VAL = isFromHistory
      ? permissions.ORDERS_HISTORY_FILATI.toString()
      : permissions.ORDERS_FILATI.toString();

    const ORDERS_CAMPIONARIO_VAL = isFromHistory
      ? permissions.ORDERS_HISTORY_CAMPIONARIO.toString()
      : permissions.ORDERS_CAMPIONARIO.toString();

    const ORDERS_LABDIP_VAL = isFromHistory
      ? permissions.ORDERS_HISTORY_LABDIP.toString()
      : permissions.ORDERS_LABDIP.toString();

    if (!preferencesArray.includes(ORDERS_FILATI_VAL)) {
      orderTypesArray = orderTypesArray.filter(
        (item) => item.value !== orderType.Yarn
      );
    }
    if (!preferencesArray.includes(ORDERS_CAMPIONARIO_VAL)) {
      orderTypesArray = orderTypesArray.filter(
        (item) => item.value !== orderType.Sampling
      );
    }
    if (!preferencesArray.includes(ORDERS_LABDIP_VAL)) {
      orderTypesArray = orderTypesArray.filter(
        (item) => item.value !== orderType.Labdip
      );
    }
    return orderTypesArray;
  })();
  return (
    <ContainerStyle>
      <FormStyle onSubmit={handleSubmit(handleFilterChange)}>
        <TopContainer>
          <TopElementContainer>
            <TitleContainer>
              <CRGText
                colorVariant={'neutralDarkBlack'}
                fontSize={20}
                fontWeight={600}
              >
                {i18nGlobal.filters}
              </CRGText>
            </TitleContainer>
            <CRGTextFieldControlled
              control={control}
              name="customerOrder"
              label={i18n.customerOrder}
            />
            <CRGDateRangePickerControlled
              name="orderDate"
              label={i18n.orderDate}
              control={control}
            />
            <CRGSelectControlled
              control={control}
              name="orderConfirmation"
              label={i18n.orderConfirmation}
              options={orderConfirmationValues}
            />
            <CRGSelectControlled
              control={control}
              name="orderType"
              label={i18n.orderType}
              options={orderTypesArray}
            />
            <CRGSelectControlled
              control={control}
              name="orderStatus"
              label={i18n.status}
              options={orderStatusValues}
            />
            <CRGSelectControlled
              control={control}
              name="progress"
              label={i18n.progress}
              options={orderProgressValues}
            />
            {user?.cariaggiUser?.type === 2 ? null : (
              <CRGAutocompleteControlled
                name="customer"
                control={control}
                options={customers}
                getOptionLabel={(option) => option.searchField}
                renderInput={(params) => (
                  <CRGTextField {...params} label={i18n.customer} />
                )}
              />
            )}
            <CRGTextFieldControlled
              control={control}
              name="cariaggiColor"
              label={i18n.cariaggiColor}
            />
            <CRGTextFieldControlled
              control={control}
              name="customerColor"
              label={i18n.customerColor}
            />
            <CRGDateRangePickerControlled
              name="shippingDate"
              label={i18n.shippingDate}
              control={control}
            />
            {!isFromHistory && (
              <CRGCheckboxControlled
                control={control}
                name="orderByUndefinedShippingDate"
                label={i18n.orderByUndefinedShippingDate}
              />
            )}
            {/*FILTRI AVANZATI */}
            <TitleContainer>
              <CRGText
                colorVariant={'neutralDarkBlack'}
                fontSize={20}
                fontWeight={600}
              >
                {i18nGlobal.advanced}
              </CRGText>
            </TitleContainer>
            <CRGTextFieldControlled
              control={control}
              name="finalCustomer"
              label={i18n.finalCustomer}
            />
            <CRGTextFieldControlled
              control={control}
              name="finalDestination"
              label={i18n.finalDestination}
            />
            <CRGTextFieldControlled
              control={control}
              name="customerSeason"
              label={i18n.customerSeason}
            />
            <CRGTextFieldControlled
              control={control}
              name="customerLine"
              label={i18n.customerLine}
            />
            <CRGTextFieldControlled
              control={control}
              name="customerBoard"
              label={i18n.customerBoard}
            />
            <CRGTextFieldControlled
              control={control}
              name="currency"
              label={i18n.currency}
            />
            <CRGTextFieldControlled
              control={control}
              name="payment"
              label={i18n.payment}
            />
            {isFromHistory && (
              <CRGSelectControlled
                control={control}
                name="daysToExtract"
                label={i18n.daysToExtract}
                options={orderDaysToExtractValues}
              />
            )}
            <CRGButton
              customWidth={isSmallDevice ? '100%' : '170px'}
              colorVariant={'mainSecondary'}
              onClick={clearFilters}
            >
              {i18nGlobal.resetFilters}
            </CRGButton>
          </TopElementContainer>
          <ButtonContainer>
            <CRGButton
              customWidth={isMobile ? '45%' : '170px'}
              onClick={onClose}
            >
              {i18nGlobal.cancel}
            </CRGButton>
            <CRGButton
              type="submit"
              colorVariant="mainSecondary"
              customWidth={isMobile ? '45%' : '170px'}
            >
              {i18nGlobal.search}
            </CRGButton>
          </ButtonContainer>
        </TopContainer>
      </FormStyle>
    </ContainerStyle>
  );
};

OrdersMobileFiltersDrawerContent.propTypes = {
  onSearch: PropTypes.func,
  onClose: PropTypes.func,
  setLastFullSearch: PropTypes.func,
  lastFullSearch: PropTypes.object,
  customers: PropTypes.array,
  isFromHistory: PropTypes.bool,
};

export { OrdersMobileFiltersDrawerContent };
