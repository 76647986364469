/** NEUTRAL */
export const BLACK = '#000000';
export const SPANISH_GRAY = '#959B9D';
export const LIGHT_GRAY = '#CBCECF';
export const CULTURED = '#F6F5F4';
export const WHITE = '#FFFFFF';
export const RED = '#FF0000';

export const RICK_BLACK = '#00171F';
export const PALE_SILVER = '#D1BFAE';
export const ORANGE = '#C03A1F';
export const ISABELLINE = '#EFEAE6';
