import useFetch from 'use-http';
import { Auth } from 'aws-amplify';

import { BACKEND_URL } from '../utils/constants';

const useToken = (isMobile) => {
  const backendHelper = useFetch(BACKEND_URL, {
    onError: ({ error }) => {
      throw error;
    },
    persist: !isMobile,
    cachePolicy: isMobile ? 'no-cache' : 'cache-first',
    cacheLife: 30000,
    interceptors: {
      request: async ({ options, route }) => {
        try {
          const data = await Auth.currentSession();
          const jwt = data?.getAccessToken().getJwtToken();
          options.headers.Authorization = `Bearer ${jwt}`;
        } catch (error) {
          console.log(
            '🚀 ~ file: useArticles.js:23 ~ request: ~ error:',
            error
          );
        }

        if (!options.headers['Content-Type'])
          options.headers['Content-Type'] = 'application/json';

        return options;
      },
      response: ({ response }) => {
        if (!response.ok) throw new Error(JSON.stringify(response.data));
        return response;
      },
    },
  });

  const create = (params) =>
    new Promise((resolve, reject) => {
      backendHelper
        .post(`/token`, params)
        .then((res) => {
          backendHelper.cache.clear();
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error create token', e);
          reject(e);
        });
    });

  const remove = (params) =>
    new Promise((resolve, reject) => {
      backendHelper
        .delete(`/token`, params)
        .then((res) => {
          backendHelper.cache.clear();
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error delete token', e);
          reject(e);
        });
    });

  return {
    create,
    remove,
  };
};

export { useToken };
