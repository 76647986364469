import { useEffect, useState, useContext } from 'react';

import styled from 'styled-components';

import { Menu, MenuItem, useMediaQuery } from '@mui/material';

import {
  InfoDialogContext,
  LoadingContext,
  MenuContext,
  UserContext,
} from 'contexts';
import { permissions, useNotifications } from 'shared';
import { useMapping, useStore } from 'hooks';
import { CRGMainLayout } from 'components/layouts';
import { CRGTable, CRGButton, CRGText } from 'components/atoms';
import { NotificationListFilters, NotificationTime } from 'components/bundle';
import { useNavigate } from 'react-router-dom';
import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const ConatinerStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-top: 30px;
  padding-left: 50px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 20px;
    padding-left: 20px;
    position: relative;
  }
`;

const MobileCustomActionContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.palette.main.lightTissue};
  border-radius: 5px;
`;

const RedDot = styled.span`
  height: 0.5rem;
  width: 0.5rem;
  background-color: ${(props) => props.theme.palette.main.secondary};
  border-radius: 50%;
  display: inline-block;
`;

const StatusCellContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: flex-end;
  }
`;

const NewButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const DeleteDialogButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const MessageContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Notifications = () => {
  // Hooks
  const notificationsHelper = useNotifications();
  const { notificationStatus } = useMapping();
  const store = useStore();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).notifications;

  // Constants
  const USER_LIST_TABLE_HEADER = [
    {
      label: i18n.headers.status,
      key: 'status',
      forceColumnSize: '10vw',
      hideOrder: false,
    },
    {
      label: i18n.headers.message,
      key: 'message',
      forceColumnSize: '23vw',
      hideOrder: true,
    },
    {
      label: i18n.headers.text,
      key: 'text',
      forceColumnSize: '23vw',
      hideOrder: true,
    },
    {
      label: i18n.headers.time,
      key: 'receivedDate',
      forceColumnSize: '15vw',
      hideOrder: true,
    },
  ];

  // Media Query
  const is1600down = useMediaQuery('(max-width:1600px)');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Contexts
  const { setIsLoading } = useContext(LoadingContext);
  const { isMenuExpanded } = useContext(MenuContext);
  const { user, setBadgeCount } = useContext(UserContext);
  const { setInfoDialog } = useContext(InfoDialogContext);

  // State
  const [notifications, setNotifications] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalNotifications, setTotalNotification] = useState();
  const [pageSize] = useState(10);
  const [currentFilters, setCurrentFilters] = useState({});
  const [currentOrder, setCurrentOrder] = useState({
    order: 'ASC',
    orderBy: 'status',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [menu, setMenu] = useState({ isOpen: false, item: null });

  //Effects
  useEffect(() => {
    fetchNotifications();
  }, [currentPage, currentFilters, currentOrder]);

  // Functions
  const fetchNotifications = async () => {
    setIsLoading(true);

    notificationsHelper
      .listUserNotifications({
        page: currentPage,
        ...currentFilters,
        order: currentOrder.order,
        orderBy: currentOrder.orderBy,
      })
      .then((res) => {
        setBadgeCount(res.badgeCount);
        const nextNotifications = res.userNotifications.map((n) => {
          return {
            ...n,
            statusCode: n.status,
            status: (
              <StatusCellContainer>
                {n.status === 0 ? <RedDot /> : null}{' '}
                {notificationStatus[n.status]}
              </StatusCellContainer>
            ),
            message: (
              <MessageContainer>
                {' '}
                <img
                  src={`images/notifications/${
                    n?.notification?.type === 0
                      ? 'user'
                      : n?.notification?.type === 1
                      ? 'stock'
                      : 'general'
                  }_type_icon.svg`}
                />
                {n?.notification?.title}
              </MessageContainer>
            ),
            text: <MessageContainer>{n?.notification?.text}</MessageContainer>,
            receivedDate: (
              <NotificationTime
                date={n?.notification?.createdAt}
                textStyle={{
                  fontSize: 14,
                  fontWeight: 400,
                  colorVariant: 'neutralDarkBlack',
                }}
              />
            ),
          };
        });

        setTotalNotification(res.total);
        setNotifications(nextNotifications);
      })
      .catch((e) => {
        console.error(e);
      });

    setIsLoading(false);
  };

  const handleClose = () => {
    setMenu({ isOpen: false, item: null });
    setAnchorEl(null);
  };

  const onReadAllNotificationClick = async () => {
    notificationsHelper
      .readAllNotifications()
      .then(() => {
        fetchNotifications();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onNotificationActionClick = (event, item) => {
    setMenu({ isOpen: true, item: item });
    setAnchorEl(event.currentTarget);
  };

  const toggleNotificationStatus = (currentStatus) => {
    if (menu?.item?.id && currentStatus !== menu?.item?.statusCode) {
      notificationsHelper
        .toggleNotificationReadStatus(menu?.item?.id)
        .then(() => {
          fetchNotifications();
        })
        .catch((e) => {
          console.error(e);
        });
    }
    handleClose();
  };

  const handleNotificationClick = async (data) => {
    if (data.statusCode === 0) {
      await notificationsHelper
        .toggleNotificationReadStatus(data.id)
        .then(() => {
          fetchNotifications();
        })
        .catch((e) => {
          console.error(e);
        });
    }
    if (data.notification.type === 1) {
      navigate(`/stock/${data.notification.referId}`);
      setCurrentItemSelected(2);
    }
    if (data.notification.type === 0) {
      await navigate(`/users?id=${data.notification.referId}`);
      setCurrentItemSelected(1);
    }
  };

  const setNotificationToDelete = () => {
    setInfoDialog({
      isOpen: true,
      title: i18n.deleteDialog.title,
      message: i18n.deleteDialog.message,
      customActions: (
        <DeleteDialogButtonContainer>
          <CRGButton
            onClick={() => setInfoDialog(null)}
            colorVariant={'mainSecondary'}
            customWidth={'150px'}
          >
            {i18n.deleteDialog.cancelButton}
          </CRGButton>
          <CRGButton onClick={() => deleteNotification()} customWidth={'150px'}>
            {i18n.deleteDialog.confirmButton}
          </CRGButton>
        </DeleteDialogButtonContainer>
      ),
    });
    handleClose();
  };

  const deleteNotification = () => {
    if (menu?.item?.id) {
      notificationsHelper
        .deleteNotification(menu?.item?.id)
        .then(() => {
          store.deleteNotification(menu.item);
          fetchNotifications();
        })
        .catch((e) => {
          console.error(e);
        });
      setInfoDialog(null);
    }
  };

  // eslint-disable-next-line react/prop-types
  const rowActionComponent = (data) => {
    return (
      <>
        <CRGButton
          isSmall
          onClick={(event) => onNotificationActionClick(event, data)}
        >
          <img
            width={'12px'}
            height={'12px'}
            src={'images/horizontal_dots.svg'}
          />
        </CRGButton>
        <Menu
          id={`basic-menu${data.id}`}
          anchorEl={anchorEl}
          open={menu?.item?.id === data.id}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': `basic-button${data.id}`,
          }}
        >
          <MenuItem
            onClick={() => {
              data.statusCode
                ? toggleNotificationStatus(0)
                : toggleNotificationStatus(1);
            }}
          >
            {data.statusCode
              ? i18n.menuToReadNotification
              : i18n.menuReadNotification}
          </MenuItem>
          {/* <MenuItem onClick={() => toggleNotificationStatus(1)}>
            {i18n.menuToReadNotification}
          </MenuItem> */}
          <MenuItem onClick={() => setNotificationToDelete()}>
            {i18n.menuDeleteNotification}
          </MenuItem>
        </Menu>
      </>
    );
  };

  const onPaginationPageChange = (e, page) => {
    setCurrentPage(page - 1);
  };

  const handleOrderChange = (key) => {
    const isNewOrder = currentOrder.orderBy !== key;
    const nextCurrentOrder = {
      order: isNewOrder
        ? 'DESC'
        : currentOrder.order === 'DESC'
        ? 'ASC'
        : 'DESC',
      orderBy: key,
    };
    setCurrentOrder(nextCurrentOrder);
  };

  const onFilterChange = (data) => {
    const nextData = {
      ...data,
    };

    const nextCurrentFilters = {
      ...currentFilters,
      ...nextData,
    };
    console.log(
      '🚀 ~ file: Notifications.js ~ line 323 ~ onFilterChange ~ nextCurrentFilters',
      nextCurrentFilters
    );
    setCurrentFilters(nextCurrentFilters);
  };

  const navigate = useNavigate();
  const { setCurrentItemSelected } = useContext(MenuContext);

  return (
    <CRGMainLayout>
      <ConatinerStyle>
        <NotificationListFilters handleFilterChange={onFilterChange} />
        <CRGTable
          data={notifications}
          headers={USER_LIST_TABLE_HEADER}
          title={i18n.notificationListLabel}
          handleClick={handleNotificationClick}
          maxColumnSize={
            isMobile
              ? '200px'
              : is1600down
              ? isMenuExpanded
                ? '21vw'
                : '22vw'
              : isMenuExpanded
              ? '22vw'
              : '23vw'
          }
          currentOrder={currentOrder}
          handleOrderChange={handleOrderChange}
          customActions={
            user?.cariaggiUser?.permissions
              ?.split('|')
              ?.includes(permissions?.USER_CREATE?.toString()) ? (
              isMobile ? (
                <MobileCustomActionContainer>
                  <CRGText
                    fontSize={14}
                    fontWeight={700}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {i18n.readAllButton}
                  </CRGText>
                  <CRGButton
                    isSmall
                    onClick={onReadAllNotificationClick}
                    colorVariant={'mainSecondary'}
                  >
                    <img
                      width={'12px'}
                      height={'12px'}
                      src={'images/white_plus.svg'}
                    ></img>
                  </CRGButton>
                </MobileCustomActionContainer>
              ) : (
                <CRGButton
                  onClick={onReadAllNotificationClick}
                  colorVariant={'mainSecondary'}
                  customWidth={'200px'}
                >
                  <NewButtonContainer>
                    <img src={'/images/plus-black.svg'} />
                    {i18n.readAllButton}
                  </NewButtonContainer>
                </CRGButton>
              )
            ) : null
          }
          rowAction={rowActionComponent}
          pagination={{
            count: Math.ceil(totalNotifications / pageSize),
            onChange: onPaginationPageChange,
          }}
        />
      </ConatinerStyle>
    </CRGMainLayout>
  );
};

export { Notifications };
