import { Divider, Grid, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { CRGText } from '../CRGText';
import PropTypes from 'prop-types';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  width: 100%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const CRGGridCell = ({
  header,
  value,
  size,
  md,
  keyFontSize = 13,
  keyFontWeight = 600,
  valueFontSize = 14,
  valueFontWeight = 400,
  children,
  showDivider = true,
  valuesOnTop,
  ...props
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Grid item md={md} lg={size} {...props}>
        <Item>
          {!valuesOnTop ? (
            <>
              <CRGText
                fontSize={keyFontSize}
                fontWeight={keyFontWeight}
                colorVariant={'neutralDarkBlack'}
              >
                {header}
              </CRGText>
              <CRGText
                fontSize={valueFontSize}
                fontWeight={valueFontWeight}
                colorVariant={'neutralDarkBlack'}
                textAlign={isMobile ? 'right' : 'left'}
              >
                {value}
              </CRGText>
              {children}
            </>
          ) : (
            <>
              <CRGText
                fontSize={18}
                fontWeight={700}
                colorVariant={'neutralDarkBlack'}
              >
                {value}
              </CRGText>
              <CRGText
                fontSize={14}
                fontWeight={400}
                colorVariant={'neutralDarkBlack'}
              >
                {header}
              </CRGText>
              {children}
            </>
          )}
        </Item>
      </Grid>
      {isMobile && showDivider && <Divider orientation="horizontal" flexItem />}
    </>
  );
};

CRGGridCell.propTypes = {
  header: PropTypes.string.isRequired,
  value: PropTypes.string,
  size: PropTypes.number,
  md: PropTypes.number,
  keyFontSize: PropTypes.number,
  keyFontWeight: PropTypes.number,
  valueFontSize: PropTypes.number,
  valueFontWeight: PropTypes.number,
  children: PropTypes.node,
  showDivider: PropTypes.bool,
  valuesOnTop: PropTypes.bool,
};

export { CRGGridCell };
