import { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

import {
  CRGButton,
  CRGText,
  CRGTextFieldControlled,
  CRGSelectControlled,
  CRGAutocompleteControlled,
  CRGTextField,
} from 'components/atoms';
import { LangContext, UserContext } from 'contexts';
import { useMapping, usePreferences } from 'hooks';
import { PreferenceGroup } from './PreferenceGroup';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 30px;
  min-height: 75vh;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 5%;
  width: 25vw;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-bottom: 20px;
    width: 100%;
    margin-left: 0px;
  }
`;

const Content = styled.div``;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FormStyle = styled.form`
  width: 100%;
`;

const FieldsStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
  gap: 15px;
`;

const PreferencesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 10px;
`;

const UpdateUserSettingsDrawerContent = ({ onClose, onEdit, countries }) => {
  // Hooks
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      country: null,
      language: '',
    },
    reValidateMode: 'all',
  });
  const { userLanguageList } = useMapping();
  const { PREFERENCES } = usePreferences();
  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).updateUserSettingsDrawerContent;
  const i18nMenu = translation.components(intl).menuList.menu;
  const { title: permissionTitle, resetButtonlabel } =
    translation.components(intl).permissionSelector;

  // Context
  const { user } = useContext(UserContext);
  const { onChangeLang } = useContext(LangContext);

  const userPermissionsArray =
    user?.cariaggiUser?.permissions?.split('|') || [];
  const preferencesArray =
    user?.cariaggiUser?.permissionsPrefs?.split('|') || [];
  // Effects
  useEffect(() => {
    if (user && countries) {
      const nextUser = {
        ...user.cariaggiUser,
        ...setPreferences(),
        country: countries.find((c) => c.value === user?.cariaggiUser?.country),
      };
      reset(nextUser);
    }
  }, [user, countries]);

  // Functions
  const submitForm = (data) => {
    const { name, surname, email, language, ...restOfData } = data;
    let newPreferences = [];

    const keys = Object.keys(restOfData);
    for (const key of keys) {
      //per ogni preferenza settata
      for (const [, value] of Object.entries(PREFERENCES)) {
        const preferenceId = value.find((el) => el.name === key)?.id; //trovo l'id della preferenza dalla constante
        if (preferenceId && data[key] === true)
          //se l'id esiste e la preferenza è settata a true
          newPreferences.push(preferenceId);
      }
    }
    const permissionsPrefs = newPreferences.join('|');
    const nextData = {
      ...user?.cariaggiUser,
      name,
      surname,
      email,
      language,
      country: data?.country?.value,
      permissionsPrefs,
    };
    console.log(
      '🚀 ~ file: UpdateUserSettingsDrawerContent.js ~ line 114 ~ submitForm ~ nextData',
      nextData
    );
    if (Number(data?.language) === 0) {
      onChangeLang('it-IT');
    } else {
      onChangeLang('en-GB');
    }

    onEdit?.(nextData);
  };

  const setPreferences = () => {
    let newPreferences = {};
    if (user?.cariaggiUser) {
      for (const [, value] of Object.entries(PREFERENCES)) {
        value.forEach((preference) => {
          if (userPermissionsArray.includes(preference?.id?.toString())) {
            newPreferences[preference.name] = preferencesArray?.includes(
              preference?.id?.toString()
            );
          }
        });
      }
    }
    return newPreferences;
  };

  const handleResetPreferences = () => {
    let newPreferences = setPreferences();

    for (const [key, value] of Object.entries(newPreferences)) {
      setValue(key, value);
    }
  };

  return (
    <Container>
      <Content>
        <CRGText
          fontSize={23}
          fontWeight={600}
          colorVariant={'neutralDarkBlack'}
        >
          {i18n.title}
        </CRGText>
        <FormStyle onSubmit={handleSubmit(submitForm)}>
          <FormContainer>
            <FieldsContainer>
              <FieldsStyle>
                {/* Nome */}
                <CRGTextFieldControlled
                  label={i18n.form.nameLabel}
                  name="name"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: i18n.form.errors.name,
                    },
                  }}
                  errors={errors?.['name']}
                />
                {/* Cognome */}
                <CRGTextFieldControlled
                  label={i18n.form.surnameLabel}
                  name="surname"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: i18n.form.errors.surname,
                    },
                  }}
                  errors={errors?.['surname']}
                />
                {/* Email */}
                <CRGTextFieldControlled
                  label={i18n.form.emailLabel}
                  name="email"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: i18n.form.errors.email,
                    },
                  }}
                  errors={errors?.['email']}
                />
                {/* Nazione */}
                {/* <CRGSelectControlled
                name="country"
                label={i18n.form.countryLabel}
                control={control}
                rules={{
                  required: { value: true, message: i18n.form.errors.country },
                }}
                errors={errors?.['country']}
                options={countries}
              /> */}
                <CRGAutocompleteControlled
                  name="country"
                  control={control}
                  options={countries}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <CRGTextField
                      {...params}
                      label={i18n.form.countryLabel}
                      customWidth={'100%'}
                      error={errors?.['country']}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: i18n.form.errors.country,
                    },
                  }}
                  errors={errors?.['country']}
                />
                {/* Lingua */}
                <CRGSelectControlled
                  name="language"
                  label={i18n.form.languageLabel}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: i18n.form.errors.language,
                    },
                  }}
                  errors={errors?.['language']}
                  options={[
                    {
                      value: 0,
                      label: userLanguageList[0],
                    },
                    {
                      value: 1,
                      label: userLanguageList[1],
                    },
                  ]}
                />
              </FieldsStyle>
              <CRGText
                fontSize={18}
                fontWeight={600}
                colorVariant={'neutralDarkBlack'}
              >
                {permissionTitle}
              </CRGText>
              <PreferencesContainer>
                <PreferenceGroup
                  control={control}
                  userPermissions={userPermissionsArray}
                  preferences={preferencesArray}
                  preferenceType={PREFERENCES.widget}
                  title={i18nMenu.home}
                />
                <PreferenceGroup
                  control={control}
                  userPermissions={userPermissionsArray}
                  preferences={preferencesArray}
                  preferenceType={PREFERENCES.orders}
                  title={i18nMenu.orders}
                />
                <PreferenceGroup
                  control={control}
                  userPermissions={userPermissionsArray}
                  preferences={preferencesArray}
                  preferenceType={PREFERENCES.ordersHistory}
                  title={i18nMenu.ordersHistory}
                />
              </PreferencesContainer>
            </FieldsContainer>
          </FormContainer>
          <ButtonContainer>
            <CRGButton
              colorVariant={'mainAccentSecondary'}
              customWidth={'100%'}
              onClick={handleResetPreferences}
            >
              {resetButtonlabel}
            </CRGButton>
            <CRGButton
              colorVariant={'mainSecondary'}
              customWidth={'calc(50% - 5px)'}
              onClick={onClose}
            >
              {i18n.cancelButtonLabel}
            </CRGButton>
            <CRGButton
              type="submit"
              colorVariant={'mainPrimary'}
              customWidth={'calc(50% - 5px)'}
            >
              {i18n.createButtonLabel}
            </CRGButton>
          </ButtonContainer>
        </FormStyle>
      </Content>
    </Container>
  );
};

UpdateUserSettingsDrawerContent.propTypes = {
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  countries: PropTypes.array,
};

export { UpdateUserSettingsDrawerContent };
