import * as colors from './colors';

const shared = {
  components: {
    MuiCollapse: {
      // menu laterale
      styleOverrides: {
        '&.menu': {
          backgroundColor: colors.WHITE,
          color: colors.RICK_BLACK,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          //default style button
          // color: colors.WHITE,
          // '& span': {
          //   color: colors.WHITE,
          //   '& .Mui-selected': {
          //     color: colors.WHITE,
          //   },
          // },
          '&:hover': {
            backgroundColor: colors.RICK_BLACK,
            opacity: 0.9,
          },
          backgroundColor: colors.RICK_BLACK,

          '&.delete': {
            '&:hover': {
              backgroundColor: colors.ORANGE,
              opacity: 0.9,
            },
            backgroundColor: colors.ORANGE,
          },

          '&.cancel': {
            '&:hover': {
              backgroundColor: colors.PALE_SILVER,
              opacity: 0.9,
            },
            backgroundColor: colors.PALE_SILVER,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: '0px 3px 6px #0000000a',
          '&:before': {
            display: 'none',
          },
        },
        rounded: {
          borderRadius: '10px',
          '&:first-of-type': {
            borderRadius: '10px',
          },
          '&:last-of-type': {
            borderRadius: '10px',
          },
        },
      },
    },
    MuiDateRangePicker: {
      defaultProps: {
        className: 'custom-range-picker',
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '.custom-range-picker': {
            '& .MuiButton-root': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& .MuiButton-root': {
            color: colors.WHITE,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.WHITE,
          border: '1px solid #B08359',
          borderRadius: '8px',
          color: colors.RICK_BLACK,
          fontSize: '12px',
          padding: '10px 20px',
          boxShadow: '0px 3px 6px #00000029',
        },
      },
    },
  },
};

export { shared };
