const BACKEND_URL =
  process.env.REACT_APP_ENVIRONMENT === 'develop'
    ? 'https://o1erhn1kf6.execute-api.us-east-1.amazonaws.com/dev'
    : process.env.REACT_APP_ENVIRONMENT === 'test'
    ? 'https://js0n3bwgi4.execute-api.us-east-1.amazonaws.com/test'
    : 'https://migshtatse.execute-api.eu-west-1.amazonaws.com/prod';

const RECAPTCHA_KEY =
  process.env.REACT_APP_ENVIRONMENT === 'develop'
    ? '6LdYK8weAAAAAFqaEQq7atX8FKhDnfQ1p59eHg2U'
    : process.env.REACT_APP_ENVIRONMENT === 'test'
    ? '6LdYK8weAAAAAFqaEQq7atX8FKhDnfQ1p59eHg2U'
    : '6LeTFQofAAAAAOLOrdosMtJecOTugGI7itPPMHLX';

export { BACKEND_URL, RECAPTCHA_KEY };
