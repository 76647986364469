import { FormControlLabel, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { CRGText } from '.';

const CheckBoxStyle = styled(Checkbox)`
  &.Mui-checked {
    color: ${(props) => props.theme.palette.main.primary};
  }
`;

const FormControlLabelStyle = styled(FormControlLabel)`
  width: ${(props) => props.customWidth || '100%'};
  margin-right: 0px;
`;

const CRGCheckBox = (props) => {
  return (
    <FormControlLabelStyle
      label={
        <CRGText
          fontWeight={500}
          fontSize={14}
          colorVariant={'neutralDarkBlack'}
        >
          {props.label}
        </CRGText>
      }
      // eslint-disable-next-line react/prop-types
      control={<CheckBoxStyle checked={props.value} {...props} />}
      {...props}
    />
  );
};

CRGCheckBox.propTypes = {
  label: PropTypes.string,
  customWidth: PropTypes.string,
};

export { CRGCheckBox };
