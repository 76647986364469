import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

const { REACT_APP_VAPID_KEY, REACT_APP_ENVIRONMENT } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

const FBC_SERVER_KEY =
  REACT_APP_ENVIRONMENT === 'main'
    ? 'AAAAD4sT14Y:APA91bE0v8sueSVEIjMrRZBcwgugoT-h0MaaW37AB-S_CQaBe9YWW2p4IMHqVz_AHkinUmFpzdCfwsK1ZasaUGUSp77V3PHaDKk018GHzDgBz-V1XNeXLTRXDUlV4jZfnCwP0sYiNkIa'
    : REACT_APP_ENVIRONMENT === 'test'
    ? 'AAAAbw4r1jo:APA91bF5gX-cS1upg6vvi8wy0y8h53xZE4XBpySTy0uwk-nYcXM6SdV6B3F8Xu4NmM6kabr1S4Evz3ItYC50VArcKxRMcxooIP2UFF3b22BqRiZ50t1HF83kbJJ4LYYysUg8oh8sZuKP'
    : 'AAAA7ovZKnU:APA91bHHy3sHinjqLazvRkfG-0bqyVWpy8L8yuEcxnN4O1Z1_5WicaSmYYL9PH_3RFupY9P3QAwZdCXfsEk5jVE5WD97uOk2YlhQstCT1_WsKLLbAM6TU9E1RMx83ZQ2pA-tL56ZlnvX';

const firebaseConfig =
  REACT_APP_ENVIRONMENT === 'main'
    ? {
        apiKey: 'AIzaSyCNwV0Nr1GDkle54oROZEgGhzliD9J_qws',
        authDomain: 'cariaggi-app.firebaseapp.com',
        projectId: 'cariaggi-app',
        storageBucket: 'cariaggi-app.appspot.com',
        messagingSenderId: '66757842822',
        appId: '1:66757842822:web:75101c3516dc59af7b7e07',
      }
    : REACT_APP_ENVIRONMENT === 'test'
    ? {
        apiKey: 'AIzaSyCkUCnMhPO8B4-NTRquKHI2btZAcPY27xc',
        authDomain: 'cariaggi-test.firebaseapp.com',
        projectId: 'cariaggi-test',
        storageBucket: 'cariaggi-test.appspot.com',
        messagingSenderId: '476979123770',
        appId: '1:476979123770:web:7995da29321c10aadac703',
      }
    : {
        apiKey: 'AIzaSyAcESbQF8Bqckb2eYaDeSwBeGze-cFVawg',
        authDomain: 'cariaggi-5fe80.firebaseapp.com',
        projectId: 'cariaggi-5fe80',
        storageBucket: 'cariaggi-5fe80.appspot.com',
        messagingSenderId: '1024548481653',
        appId: '1:1024548481653:web:90a64da999f8ce2ac9263c',
      };

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const getCurrentToken = (setTokenFound, topic) => {
  return getToken(messaging, { vapidKey: publicKey })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        console.log('topic', topic);
        if (topic) subscribeTokenToTopic(currentToken, topic);
        setTokenFound(true);
        return currentToken;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

function subscribeTokenToTopic(token, topic) {
  fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'key=' + FBC_SERVER_KEY,
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        throw (
          'Error subscribing to topic: ' +
          response.status +
          ' - ' +
          response.text()
        );
      }
      console.log('Subscribed to "' + topic + '"');
    })
    .catch((error) => {
      console.error(error);
    });
}

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('firebaseInit', payload);
      resolve(payload);
    });
  });

export { onMessageListener, getCurrentToken };
