import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const safeNum = (value) => (isNaN(Number(value)) ? 0 : Number(value));

const timeFromDateToNow = (date) => {
  const date1 = dayjs(date);
  const date2 = dayjs(new Date());
  return {
    minute: date2.diff(date1, 'minute'),
    hour: date2.diff(date1, 'hour'),
    day: date2.diff(date1, 'day'),
  };
};

const checkIfIncludes = (data, value) => {
  if (data) {
    return data.toLowerCase().includes(value.toLowerCase());
  }
  return false;
};

const checkIfIdEquals = (data, value) => {
  if (data) {
    return Number(data) === Number(value);
  }
  return false;
};

const checkIfDateIsBetween = (data, value) => {
  if (data != null && data !== '0') {
    const date = dayjs(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const [start, end] = value;
    const startDate = dayjs(start).format('YYYY-MM-DD');
    const endDate = dayjs(end).format('YYYY-MM-DD');
    const res = dayjs(date).isBetween(startDate, endDate, null, '[]');
    return res;
  }
  return false;
};

const checkIfIsAfter = (from, to) => {
  const date1 = dayjs(from, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const date2 = dayjs(to, 'DD/MM/YYYY').format('YYYY-MM-DD');
  return dayjs(date1).isAfter(date2);
};

const checkIfIsBefore = (from, to) => {
  const date1 = dayjs(from, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const date2 = dayjs(to, 'DD/MM/YYYY').format('YYYY-MM-DD');
  return dayjs(date1).isBefore(date2);
};

const rangeDateToJulFormat = (rangeDate) => {
  if (!rangeDate) return null;
  const [start, end] = rangeDate;
  if (start == null || end == null) return null;

  const startDate = dayjs(start).format('YYYYMMDD');
  const endDate = dayjs(end).format('YYYYMMDD');
  return `${startDate}:${endDate}`;
};

export {
  safeNum,
  timeFromDateToNow,
  checkIfIncludes,
  checkIfIdEquals,
  checkIfDateIsBetween,
  rangeDateToJulFormat,
  checkIfIsAfter,
  checkIfIsBefore,
};
