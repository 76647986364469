import { LangContext } from 'contexts';
import { useContext } from 'react';

const useInfoDocument = () => {
  const { lang } = useContext(LangContext);

  const prefix = lang === 'it-IT' ? 'it' : 'en';

  const HOME_PDF_URL = `/documents/info/${prefix}/home.pdf`;
  const STOCKLIST_PDF_URL = `/documents/info/${prefix}/stocklist.pdf`;
  const ORDERS_PDF_URL = `/documents/info/${prefix}/orders.pdf`;
  const ORDERS_HISTORY_PDF_URL = `/documents/info/${prefix}/orders-history.pdf`;
  const SETTINGS_PDF_URL = `/documents/info/${prefix}/settings.pdf`;
  const LOGIN_PDF_URL = `/documents/info/${prefix}/login.pdf`;
  const SIGNUP_PDF_URL = `/documents/info/${prefix}/signup.pdf`;

  return {
    HOME_PDF_URL,
    ORDERS_PDF_URL,
    ORDERS_HISTORY_PDF_URL,
    STOCKLIST_PDF_URL,
    SETTINGS_PDF_URL,
    LOGIN_PDF_URL,
    SIGNUP_PDF_URL,
  };
};

export { useInfoDocument };
