import PropTypes from 'prop-types';
import { OrderItem } from './OrderItem';

const OrderContainer = ({ orders }) => {
  if (!orders || orders?.length === 0) return null;

  return orders.map((order) => (
    <OrderItem key={order.RIF_ORDINE} order={order} />
  ));
};

OrderContainer.propTypes = {
  orders: PropTypes.array,
  estimatedItemSize: PropTypes.number,
  itemSize: PropTypes.number,
};

export { OrderContainer };
