import PropTypes from 'prop-types';

import { useMediaQuery } from '@mui/material';

import { CRGDesktopTable, CRGMobileTable } from 'components/atoms';

const CRGTable = ({
  headers,
  data,
  title,
  customActions,
  rowAction,
  pagination,
  maxColumnSize,
  handleClick,
  mobileFilters,
  currentOrder,
  handleOrderChange,
}) => {
  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return data ? (
    isMobile ? (
      <CRGMobileTable
        headers={headers}
        data={data}
        title={title}
        customActions={customActions}
        handleClick={handleClick}
        rowAction={rowAction}
        pagination={pagination}
        maxColumnSize={maxColumnSize}
        filtersComponent={mobileFilters}
        currentOrder={currentOrder}
        handleOrderChange={handleOrderChange}
      />
    ) : (
      <CRGDesktopTable
        headers={headers}
        data={data}
        handleClick={handleClick}
        title={title}
        customActions={customActions}
        rowAction={rowAction}
        pagination={pagination}
        maxColumnSize={maxColumnSize}
        currentOrder={currentOrder}
        handleOrderChange={handleOrderChange}
      />
    )
  ) : (
    <></>
  );
};

CRGTable.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  title: PropTypes.string,
  customActions: PropTypes.node,
  rowAction: PropTypes.func,
  handleClick: PropTypes.func,
  pagination: PropTypes.obj,
  maxColumnSize: PropTypes.string,
  mobileFilters: PropTypes.node,
  currentOrder: PropTypes.obj,
  handleOrderChange: PropTypes.func,
};

export { CRGTable };
