import useFetch from 'use-http';
import { Auth } from 'aws-amplify';

import { BACKEND_URL } from '../utils/constants';

const authUrls = ['/status'];

const useStatus = () => {
  const backendHelper = useFetch(BACKEND_URL, {
    onError: ({ error }) => {
      throw error;
    },
    interceptors: {
      request: async ({ options, route }) => {
        const authenticatedUrl = authUrls.find((p) => route?.indexOf(p) !== -1);

        if (authenticatedUrl) {
          try {
            const data = await Auth.currentSession();
            const jwt = data?.getAccessToken().getJwtToken();
            options.headers.Authorization = `Bearer ${jwt}`;
          } catch (error) {
            console.log(
              '🚀 ~ file: useArticles.js:23 ~ request: ~ error:',
              error
            );
          }
        }
        return options;
      },
      response: ({ response }) => {
        if (!response.ok) throw new Error(JSON.stringify(response.data));
        return response;
      },
    },
  });

  const get = (locale) =>
    new Promise((resolve, reject) => {
      const serverLang = locale === 'it-IT' ? 'IT' : 'IN';
      backendHelper
        .get(`/status?language=${serverLang}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error get status', e);
          reject(e);
        });
    });

  return {
    get,
  };
};

export { useStatus };
