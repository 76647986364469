import { BrowserRouter } from 'react-router-dom';
import { Routing } from './Routing';

import { useAuthentication } from 'shared';

import LangContextProvider from 'contexts/LangContext';
import CustomThemeContext from 'contexts/CustomThemeContext';
import UserContextProvider from 'contexts/UserContext';
import LoadingContextProvider from 'contexts/LoadingContext';
import MenuContextProvider from 'contexts/MenuContext';
import InfoDialogContextProvider from 'contexts/InfoDialogContext';
import DrawerContextProvider from 'contexts/DrawerContext';

const App = () => {
  //Hooks
  const authHelper = useAuthentication();

  authHelper.configureAws();
  return (
    <CustomThemeContext>
      <BrowserRouter>
        <LangContextProvider>
          <LoadingContextProvider>
            <InfoDialogContextProvider>
              <UserContextProvider>
                <DrawerContextProvider>
                  <MenuContextProvider>
                    <Routing />
                  </MenuContextProvider>
                </DrawerContextProvider>
              </UserContextProvider>
            </InfoDialogContextProvider>
          </LoadingContextProvider>
        </LangContextProvider>
      </BrowserRouter>
    </CustomThemeContext>
  );
};

export default App;
