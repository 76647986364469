import useFetch from 'use-http';

import { Amplify, Auth, Hub } from 'aws-amplify';
import {
  AWS_SETUP_DEV,
  AWS_SETUP_TEST,
  AWS_SETUP_PROD,
} from './../utils/aws-exports';

import { BACKEND_URL } from './../utils/constants';
import { useEffect } from 'react';

const authUrls = ['/users/me'];

const useAuthentication = (hubListenerCallback) => {
  useEffect(() => {
    const unsubscribe = Hub.listen(
      'auth',
      async ({ payload: { event, data } }) => {
        await hubListenerCallback?.(event);
      }
    );

    return unsubscribe;
  }, [hubListenerCallback]);

  const backendHelper = useFetch(BACKEND_URL, {
    cachePolicy: 'no-cache',
    onError: ({ error }) => {
      throw error;
    },
    interceptors: {
      request: async ({ options, route }) => {
        if (authUrls.includes(route)) {
          try {
            const data = await Auth.currentSession();
            const jwt = data?.getAccessToken().getJwtToken();
            options.headers.Authorization = `Bearer ${jwt}`;
          } catch (error) {
            console.log(
              '🚀 ~ file: useArticles.js:23 ~ request: ~ error:',
              error
            );
          }
        }
        return options;
      },
      response: ({ response }) => {
        if (!response.ok) throw new Error(JSON.stringify(response.data));
        return response;
      },
    },
  });

  const configureAws = () => {
    Amplify.configure(
      process.env.REACT_APP_ENVIRONMENT === 'develop'
        ? AWS_SETUP_DEV
        : process.env.REACT_APP_ENVIRONMENT === 'test'
        ? AWS_SETUP_TEST
        : AWS_SETUP_PROD
    );
  };

  const login = ({ username, password }) =>
    new Promise((resolve, reject) => {
      Auth.signIn(username, password.trim())
        .then((data) => {
          const { storage, pool, ...resOfData } = data;
          if (data?.challengeName === 'NEW_PASSWORD_REQUIRED') {
            resolve({
              cognitoUser: data,
              cariaggiUser: null,
            });
          } else {
            me()
              .then((meRes) => {
                resolve({
                  cognitoUser: resOfData,
                  cariaggiUser: meRes,
                });
              })
              .catch((meErr) => {
                console.error('🔴', 'login (me) error');
                reject(meErr);
              });
          }
        })
        .catch((error) => {
          console.error('🔴', 'login error');
          reject(error);
        });
    });

  const logout = () =>
    new Promise((resolve, reject) => {
      Auth.signOut()
        .then((data) => {
          backendHelper.cache.clear();
          resolve(data);
        })
        .catch((error) => {
          console.error('🔴', 'logout error');
          reject(error);
        });
    });

  const signin = (params) => backendHelper.post('/users', params);

  const me = () =>
    new Promise((resolve, reject) => {
      backendHelper
        .get('/users/me')
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });

  const forceUpdatePassword = ({ user, password }) =>
    new Promise((resolve, reject) => {
      Auth.completeNewPassword(
        user, // the Cognito User Object
        password, // the new password
        {}
      )
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });

  const activate = ({ id }) => backendHelper.put(`/users/active/${id}`);

  const forgotPassword = ({ username }) =>
    new Promise((resolve, reject) => {
      Auth.forgotPassword(username)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.error('🔴', 'forgot password error' + e);
          reject(e);
        });
    });

  const submitForgotPassword = ({ username, code, password }) =>
    new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(username, code, password)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.error('🔴', 'forgot password error' + e);
          reject(e);
        });
    });

  const edit = (params) =>
    new Promise((resolve, reject) => {
      backendHelper
        .put(`/users/me`, params)
        .then((res) => {
          backendHelper.cache.clear();
          me()
            .then((meRes) => {
              resolve(meRes);
            })
            .catch((meErr) => {
              console.error('🔴', 'edit (me) error');
              reject(meErr);
            });
        })
        .catch((e) => {
          console.log('🔴 error edit user', e);
          reject(e);
        });
    });

  const deleteMe = () =>
    new Promise((resolve, reject) => {
      backendHelper
        .delete('/users/me')
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });

  const getCurrentAuth = () =>
    new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });

  return {
    configureAws,
    login,
    signin,
    forceUpdatePassword,
    activate,
    forgotPassword,
    submitForgotPassword,
    logout,
    edit,
    me,
    deleteMe,
    getCurrentAuth,
  };
};

export { useAuthentication };
