import { useContext } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

const ContainerStyle = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

import { CRGButton, CRGTextField } from 'components/atoms';
import { StocklistMobileFiltersDrawerContent } from 'components/bundle';
import { useState } from 'react';
import { DrawerContext } from 'contexts';

const StocklistListFiltersMobile = ({
  onSearch,
  articles,
  status,
  customers,
}) => {
  // State
  const [name, setName] = useState();
  const [lastFullSearch, setLastFullSearch] = useState();

  // Context
  const { setIsDrawerOpen, closeDrawer } = useContext(DrawerContext);

  // Functions
  const handleNameChange = (e) => {
    setName(e?.target?.value);
  };

  const onFilterClick = () => {
    setIsDrawerOpen({
      isOpen: true,
      children: (
        <StocklistMobileFiltersDrawerContent
          onClose={closeDrawer}
          lastFullSearch={lastFullSearch}
          setLastFullSearch={setLastFullSearch}
          onSearch={(data) => {
            setName('');
            onSearch(data);
          }}
          articles={articles}
          status={status}
          customers={customers}
        />
      ),
    });
  };

  return (
    <ContainerStyle>
      <CRGTextField
        onChange={handleNameChange}
        value={name}
        customWidth={'calc(100% - 70px)'}
        customHeight={'30px'}
      />
      <CRGButton
        colorVariant={'mainSecondary'}
        isSmall
        onClick={() => {
          setLastFullSearch(null);
          onSearch({ description: name });
        }}
      >
        <img width={'12px'} height={'12px'} src={'images/search_lens.svg'} />
      </CRGButton>
      <CRGButton isSmall onClick={onFilterClick}>
        <img width={'12px'} height={'12px'} src={'images/filter_burger.svg'} />
      </CRGButton>
    </ContainerStyle>
  );
};

StocklistListFiltersMobile.propTypes = {
  onSearch: PropTypes.func,
  articles: PropTypes.array,
  status: PropTypes.array,
  customers: PropTypes.array,
};

export { StocklistListFiltersMobile };
