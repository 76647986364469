import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import {
  CRGButton,
  CRGTextFieldControlled,
  CRGMultiselectControlled,
  CRGAutocompleteControlled,
  CRGTextField,
  CRGSelectControlled,
  CRGText,
} from 'components/atoms';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { useMapping } from 'hooks';

const FilterContainer = styled.div``;

const FormStyle = styled.form`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 1600px) {
    flex-wrap: wrap;
  }
`;

const defaultValues = {
  nameSurname: '',
  country: null,
  language: '',
  company: '',
  type: '',
};

const ManualNotificationsUsersFilters = ({ handleFilterChange, countries }) => {
  // Hooks
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: defaultValues,
  });
  const { userLanguageList, userTypeList } = useMapping();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).manualNotificationsUsersFilters;

  // Functions
  const clearFilters = () => {
    reset(defaultValues);
  };

  return (
    <FilterContainer>
      <CRGText fontSize={14} fontWeight={600} colorVariant={'neutralDarkBlack'}>
        {i18n.title}
      </CRGText>
      <FormStyle
        onSubmit={handleSubmit((data) => {
          handleFilterChange(data);
          clearFilters();
        })}
      >
        {/* Nome e Cognome */}
        <CRGTextFieldControlled
          label={i18n.nameSurnameLabel}
          name="nameSurname"
          control={control}
          customWidth={'25vw'}
        />

        {/* Tipo Utente */}
        <CRGMultiselectControlled
          name="type"
          label={i18n.typeLabel}
          control={control}
          customWidth={'12vw'}
          setValue={setValue}
          options={[
            {
              value: 0,
              label: userTypeList[0],
            },
            {
              value: 1,
              label: userTypeList[1],
            },
            {
              value: 2,
              label: userTypeList[2],
            },
            {
              value: 3,
              label: userTypeList[3],
            },
          ]}
        />

        {/* Nazione */}
        <CRGAutocompleteControlled
          name="country"
          control={control}
          options={countries}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <CRGTextField
              {...params}
              label={i18n.countryLabel}
              customWidth={'12vw'}
            />
          )}
        />

        {/* Lingua */}
        <CRGSelectControlled
          name="language"
          label={i18n.languageLabel}
          control={control}
          customWidth={'12vw'}
          options={[
            {
              value: 0,
              label: userLanguageList[0],
            },
            {
              value: 1,
              label: userLanguageList[1],
            },
          ]}
        />

        {/* Azienda */}
        <CRGTextFieldControlled
          label={i18n.companyLabel}
          name="company"
          control={control}
          customWidth={'12vw'}
        />

        <CRGButton
          type="submit"
          colorVariant={'mainSecondary'}
          customWidth={'140px'}
        >
          {i18n.buttonSubmitLabel}
        </CRGButton>
      </FormStyle>
    </FilterContainer>
  );
};

ManualNotificationsUsersFilters.propTypes = {
  handleFilterChange: PropTypes.func,
  countries: PropTypes.array,
};

export { ManualNotificationsUsersFilters };
