import React, { useEffect, useState, useContext } from 'react';

import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

import { CRGMainLayout } from 'components/layouts';

import { permissions, useCountries, useUsers } from 'shared';
import { CRGTable, CRGButton, CRGText } from 'components/atoms';
import {
  DrawerContext,
  LangContext,
  LoadingContext,
  MenuContext,
  UserContext,
} from 'contexts';
import {
  AddOrUpdateUser,
  UserListFilters,
  UserListFiltersMobile,
} from 'components/bundle';
import { useMapping } from 'hooks';

import { safeNum } from 'utils/utils';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const ConatinerStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 30px;
  padding-left: 50px;
  position: relative;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 20px;
    padding-left: 20px;
    position: relative;
  }
`;

const MobileCustomActionContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.palette.main.lightTissue};
  border-radius: 5px;
`;

const TitleContainer = styled.div`
  margin-left: 5px;
  width: 90vw;
  margin-bottom: 10px;
`;

const FiltersContainer = styled.div`
  margin-left: 5px;
  width: ${(props) => (props.isMenuExpanded ? '79vw' : '90vw')};
  margin-bottom: 10px;
  @media screen and (max-width: 1600px) {
    width: ${(props) => (props.isMenuExpanded ? '80vw' : '85vw')};
  }
`;

const RedDot = styled.span`
  height: 0.5rem;
  width: 0.5rem;
  background-color: ${(props) => props.theme.palette.main.secondary};
  border-radius: 50%;
  display: inline-block;
`;
const StatusCellContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: flex-end;
  }
`;

const NewButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Users = () => {
  // Hooks
  const userHelper = useUsers();
  const { userStatusList, userTypeList, userLanguageList } = useMapping();
  const countriesHelper = useCountries();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).users;

  // Constants
  const USER_LIST_TABLE_HEADER = [
    { label: i18n.headers.status, key: 'status' },
    { label: i18n.headers.name, key: 'name' },
    { label: i18n.headers.surname, key: 'surname' },
    { label: i18n.headers.company, key: 'company' },
    { label: i18n.headers.email, key: 'email' },
    { label: i18n.headers.type, key: 'type' },
    { label: i18n.headers.customer, key: 'customerCode' },
    { label: i18n.headers.country, key: 'country' },
    { label: i18n.headers.language, key: 'language' },
  ];

  // Media Query
  const is1600down = useMediaQuery('(max-width:1600px)');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Contexts
  const { setIsLoading } = useContext(LoadingContext);
  const { setIsDrawerOpen, closeDrawer } = useContext(DrawerContext);
  const { lang } = useContext(LangContext);
  const { isMenuExpanded } = useContext(MenuContext);
  const { user } = useContext(UserContext);

  // State
  const [users, setUsers] = useState();

  const [currentPage, setCurrentPage] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [pageSize] = useState(10);
  const [currentFilters, setCurrentFilters] = useState({});
  const [currentOrder, setCurrentOrder] = useState({
    order: 'ASC',
    orderBy: 'status',
  });
  const [countries, setCountries] = useState([]);

  //Effects
  useEffect(() => {
    if (countries?.length) fetchUsers();
  }, [currentPage, currentFilters, currentOrder, countries]);

  useEffect(() => {
    if (lang) fetchCountries();
  }, [lang]);

  // Functions
  const fetchUsers = async () => {
    setIsLoading(true);
    userHelper
      .list({
        page: currentPage,
        pageSize: pageSize,
        ...currentFilters,
        ...currentOrder,
      })
      .then((res) => {
        const nextTotalUsers = res.total;
        setTotalUsers(nextTotalUsers);
        const nextUsers = res.users.map((user) => {
          return {
            ...user,
            status: (
              <StatusCellContainer>
                {user.status < 2 ? <RedDot></RedDot> : null}
                {userStatusList[user.status]}
              </StatusCellContainer>
            ),
            statusId: user.status,
            country: countries.find((c) => c.value === user.country)?.label,
            countryId: user.country,
            language: userLanguageList[user.language],
            languageId: user.language,
            type: userTypeList[user.type],
            typeId: user.type,
          };
        });
        setUsers(nextUsers);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const onNewUserClick = () => {
    setIsDrawerOpen({
      isOpen: true,
      children: (
        <AddOrUpdateUser
          onClose={() => {
            closeDrawer();
            fetchUsers();
          }}
          countries={countries}
        />
      ),
    });
  };

  const location = useLocation();
  const navigate = useNavigate();
  let queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (queryParams.get('id') !== null) {
      userHelper
        .get({
          id: queryParams.get('id'),
        })
        .then((res) => {
          if (res.name) {
            onUpdateUserClick({
              ...res,
              statusId: res.status,
              countryId: res.country,
            });
          }
        });
    }
  }, [queryParams.get('id'), countries]);

  const handleCloseDrawer = () => {
    closeDrawer();
    fetchUsers();
    navigate('/users');
  };

  const onUpdateUserClick = (user) => {
    const formattedUser = {
      ...user,
      country: safeNum(user.countryId),
      language: safeNum(user.languageId),
      type: safeNum(user.typeId),
      status: safeNum(user.statusId),
    };
    setIsDrawerOpen({
      onClose: () => {
        handleCloseDrawer();
      },
      isOpen: true,
      children: (
        <AddOrUpdateUser
          selectedUser={formattedUser}
          onClose={() => {
            handleCloseDrawer();
          }}
          countries={countries}
        />
      ),
    });
  };

  const rowActionComponent = (item) => {
    return user?.cariaggiUser?.permissions
      ?.split('|')
      .includes(
        permissions.USER_PARTIAL_UPDATE?.toString?.() ||
          permissions.USER_UPDATE?.toString?.()
      ) ? (
      <CRGButton isSmall onClick={() => onUpdateUserClick(item)}>
        <img width={'12px'} height={'12px'} src={'images/pencil.svg'}></img>
      </CRGButton>
    ) : null;
  };

  const onPaginationPageChange = (e, page) => {
    setCurrentPage(page - 1);
  };

  const onFilterChange = (data) => {
    const nextCurrentFilters = {
      ...currentFilters,
      ...data,
      country: data?.country?.value || '',
    };
    setCurrentFilters(nextCurrentFilters);
  };

  const handleOrderChange = (key) => {
    const isNewOrder = currentOrder.orderBy !== key;
    const nextCurrentOrder = {
      order: isNewOrder
        ? 'DESC'
        : currentOrder.order === 'DESC'
        ? 'ASC'
        : 'DESC',
      orderBy: key,
    };
    setCurrentOrder(nextCurrentOrder);
  };

  const fetchCountries = () => {
    countriesHelper
      .get(lang)
      .then((res) => {
        const nextCountries = Object.entries(res).map(([key, value]) => {
          return {
            value: key,
            label: value,
          };
        });
        setCountries(nextCountries);
      })
      .catch((e) => console.error(e));
  };

  return (
    <CRGMainLayout>
      <ConatinerStyle>
        <TitleContainer>
          <CRGText
            fontSize={23}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.title}
          </CRGText>
        </TitleContainer>
        {isMobile ? null : (
          <FiltersContainer isMenuExpanded={isMenuExpanded}>
            <UserListFilters
              handleFilterChange={onFilterChange}
              countries={countries}
            />
          </FiltersContainer>
        )}
        <CRGTable
          data={users}
          headers={USER_LIST_TABLE_HEADER}
          title={i18n.userListLabel}
          maxColumnSize={
            isMobile
              ? '200px'
              : is1600down
              ? isMenuExpanded
                ? '5vw'
                : '6vw'
              : isMenuExpanded
              ? '6vw'
              : '7vw'
          }
          currentOrder={currentOrder}
          handleOrderChange={handleOrderChange}
          customActions={
            user?.cariaggiUser?.permissions
              ?.split('|')
              ?.includes(permissions?.USER_CREATE?.toString()) ? (
              isMobile ? (
                <MobileCustomActionContainer>
                  <CRGText
                    fontSize={14}
                    fontWeight={700}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {i18n.newUserButton}
                  </CRGText>
                  <CRGButton
                    isSmall
                    onClick={onNewUserClick}
                    colorVariant={'mainSecondary'}
                  >
                    <img
                      width={'12px'}
                      height={'12px'}
                      src={'images/white_plus.svg'}
                    ></img>
                  </CRGButton>
                </MobileCustomActionContainer>
              ) : (
                <CRGButton
                  onClick={onNewUserClick}
                  colorVariant={'mainSecondary'}
                  customWidth={'200px'}
                >
                  <NewButtonContainer>
                    <img src={'/images/plus-black.svg'} />
                    {i18n.newUserButton}
                  </NewButtonContainer>
                </CRGButton>
              )
            ) : null
          }
          rowAction={rowActionComponent}
          pagination={{
            count: Math.ceil(totalUsers / pageSize),
            onChange: onPaginationPageChange,
          }}
          mobileFilters={
            <UserListFiltersMobile
              onSearch={(data) => onFilterChange(data)}
              countries={countries}
            />
          }
        />
      </ConatinerStyle>
    </CRGMainLayout>
  );
};

export { Users };

Users.propTypes = {
  userId: PropTypes.string,
};
