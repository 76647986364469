import create from 'zustand';

const defaultValues = {
  customerOrder: '',
  orderDate: [null, null],
  orderConfirmation: '',
  orderType: '',
  progress: '',
  customer: null,
  orderStatus: null,
  cariaggiColor: '',
  customerColor: '',
  shippingDate: [null, null],
  finalCustomer: '',
  finalDestination: '',
  customerSeason: '',
  customerLine: '',
  customerBoard: '',
  currency: '',
  payment: '',
  orderByUndefinedShippingDate: false,
  orderByField: null,
  orderByDirection: 'ASC',
  daysToExtract: null,
};

export const useOrdersHistoryFiltersStore = create((set) => ({
  filters: defaultValues,
  setFilters: (newFilters) => {
    set({ filters: newFilters });
  },
  orderByObj: { field: undefined, direction: 'ASC' },
  setOrderByObj: (newOrderObj) => set({ orderByObj: newOrderObj }),
  resetFilters: () => set({ filters: defaultValues }),
}));
