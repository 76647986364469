const orderType = {
  Yarn: '1',
  Sampling: '2',
  Labdip: '3',
  Other: '4',
};

const orderStatus = {
  Open: '1',
  PartiallyShipped: '2',
  Shipped: '3',
};

const orderConfirmation = {
  Confirmed: '1',
  ToBeConfirmed: '2',
};

const orderProgress = {
  UnderProcessing: '1',
  WaitingColorApproval: '2',
  InProduction: '3',
  Shipped: '4',
  InternalApproval: '5',
  Closed: '6',
};

const daysToExtractEnum = {
  Last30Days: '30',
  Last60Days: '60',
  Last90Days: '90',
  Last180Days: '180',
  LastYear: '365',
};

export {
  daysToExtractEnum,
  orderType,
  orderStatus,
  orderConfirmation,
  orderProgress,
};
