import { createTheme } from '@mui/material';
import { shared } from './shared';
import * as colors from './colors';

// Create a theme instance.
const light = createTheme({
  palette: {
    backgrounds: {
      main: colors.WHITE,
      mainLayout: colors.CULTURED,
      table: colors.WHITE,
      widgetStocklist: colors.PALE_SILVER,
      widgetUser: colors.ORANGE,
      burgerMenu: colors.RICK_BLACK,
    },
    main: {
      primary: colors.RICK_BLACK,
      secondary: colors.PALE_SILVER,
      accent: colors.ORANGE,
      accentSecondary: colors.ORANGE,
      light: colors.ISABELLINE,
      lightTissue: colors.ISABELLINE,
      error: colors.RED,
    },
    neutral: {
      dark: {
        black: colors.BLACK,
        spanishGray: colors.SPANISH_GRAY,
        lightGray: colors.LIGHT_GRAY,
      },
      light: {
        white: colors.WHITE,
        cultured: colors.CULTURED,
      },
    },
    primary: {
      main: colors.RICK_BLACK,
    },
    secondary: {
      main: colors.WHITE,
    },
  },
  ...shared,
});

light.components.MuiButton.styleOverrides.root['&.cancel'] = {
  ...light.components.MuiButton.styleOverrides.root['&.cancel'],
  [light.breakpoints.down('md')]: {
    order: 3,
  },
};

light.components.MuiButton.styleOverrides.root['&.delete'] = {
  ...light.components.MuiButton.styleOverrides.root['&.delete'],
  [light.breakpoints.down('md')]: {
    order: 2,
  },
};

export { light };
