import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import {
  CRGButton,
  CRGTextFieldControlled,
  CRGMultiselectControlled,
  CRGAutocompleteControlled,
  CRGTextField,
} from 'components/atoms';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { useMapping } from 'hooks';

const FilterContainer = styled.div``;

const FormStyle = styled.form`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 1600px) {
    flex-wrap: wrap;
  }
`;

const defaultValues = {
  nameSurname: '',
  status: '',
  country: null,
  email: '',
  company: '',
  type: '',
};

const UserListFilters = ({ handleFilterChange, countries }) => {
  // Hooks
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: defaultValues,
  });
  const { userStatusList, userTypeList } = useMapping();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).userListFilters;

  // Functions
  const clearFilters = () => {
    reset(defaultValues);
    handleFilterChange(defaultValues);
  };

  return (
    <FilterContainer>
      <FormStyle onSubmit={handleSubmit(handleFilterChange)}>
        {/* Nome e Cognome */}
        <CRGTextFieldControlled
          label={i18n.nameSurnameLabel}
          name="nameSurname"
          control={control}
          customWidth={'12vw'}
        />

        {/* Nazione */}
        <CRGMultiselectControlled
          name="status"
          label={i18n.statusLabel}
          control={control}
          customWidth={'10vw'}
          setValue={setValue}
          options={[
            {
              value: 0,
              label: userStatusList[0],
            },
            {
              value: 1,
              label: userStatusList[1],
            },
            {
              value: 2,
              label: userStatusList[2],
            },
            {
              value: 3,
              label: userStatusList[3],
            },
            {
              value: 5,
              label: userStatusList[5],
            },
          ]}
        />

        {/* Nazione */}
        {/* <CRGSelectControlled
          name="country"
          label={i18n.countryLabel}
          control={control}
          customWidth={'10vw'}
          options={countries}
        /> */}
        <CRGAutocompleteControlled
          name="country"
          control={control}
          options={countries}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <CRGTextField
              {...params}
              label={i18n.countryLabel}
              customWidth={'10vw'}
            />
          )}
        />

        {/* Email */}
        <CRGTextFieldControlled
          label={i18n.emailLabel}
          name="email"
          control={control}
          customWidth={'12vw'}
        />

        {/* Azienda */}
        <CRGTextFieldControlled
          label={i18n.companyLabel}
          name="company"
          control={control}
          customWidth={'12vw'}
        />

        {/* Nazione */}
        <CRGMultiselectControlled
          name="type"
          label={i18n.typeLabel}
          control={control}
          customWidth={'10vw'}
          setValue={setValue}
          options={[
            {
              value: 0,
              label: userTypeList[0],
            },
            {
              value: 1,
              label: userTypeList[1],
            },
            {
              value: 2,
              label: userTypeList[2],
            },
            {
              value: 3,
              label: userTypeList[3],
            },
          ]}
        />

        <CRGButton
          type="submit"
          colorVariant={'mainPrimary'}
          customWidth={'140px'}
        >
          {i18n.buttonSubmitLabel}
        </CRGButton>
        <CRGButton
          colorVariant={'mainSecondary'}
          customWidth={'180px'}
          onClick={clearFilters}
        >
          {i18n.buttonClearFilters}
        </CRGButton>
      </FormStyle>
    </FilterContainer>
  );
};

UserListFilters.propTypes = {
  handleFilterChange: PropTypes.func,
  countries: PropTypes.array,
};

export { UserListFilters };
