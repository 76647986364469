/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import { Controller } from 'react-hook-form';
import { CRGTextField, CRGText } from '.';

const CRGTextFieldControlled = ({
  control,
  name,
  label,
  rules,
  fieldType = 'text',
  multiline = false,
  rows = 2,
  errors,
  disabled,
  customWidth,
  defaultValue = '',
}) => {
  // State
  const [currentError, setCurrentError] = useState();

  // Effect
  useEffect(() => {
    if (name && errors) {
      const errorObj = errors;
      const errorKey = Object?.keys?.(errorObj?.message)?.[0];
      const nextCurrentError = errorObj?.message?.[errorKey];
      setCurrentError(nextCurrentError);
    } else {
      setCurrentError(null);
    }
  }, [errors, name]);

  return control ? (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <CRGTextField
            label={label}
            multiline={multiline}
            rows={multiline ? rows : null}
            type={fieldType}
            disabled={disabled}
            customWidth={customWidth}
            error={currentError}
            {...field}
          />
        )}
        rules={rules}
      />
      {currentError && (
        <CRGText fontWeight={500} fontSize={14} colorVariant={'mainError'}>
          {currentError}
        </CRGText>
      )}
    </>
  ) : null;
};

export { CRGTextFieldControlled };
