import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import {
  CRGButton,
  CRGTextFieldControlled,
  CRGDateTimePickerControlled,
} from 'components/atoms';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const FilterContainer = styled.div``;

const FormStyle = styled.form`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 1600px) {
    flex-wrap: wrap;
  }
`;

const defaultValues = {
  text: '',
  fromDate: null,
  toDate: null,
};

const ManualNotificationListFilters = ({ handleFilterChange }) => {
  // Hooks
  const { handleSubmit, control, reset } = useForm({
    defaultValues: defaultValues,
  });

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).manualNotificationListFilters;

  // Functions
  const clearFilters = () => {
    reset(defaultValues);
    handleFilterChange(defaultValues);
  };

  return (
    <FilterContainer>
      <FormStyle onSubmit={handleSubmit(handleFilterChange)}>
        {/* Nome Richiesta */}
        <CRGTextFieldControlled
          label={i18n.titleLabel}
          name="text"
          control={control}
          customWidth={'40vw'}
        />

        {/* Data Ora */}
        <CRGDateTimePickerControlled
          label={i18n.dateFromLabel}
          name="fromDate"
          control={control}
          customWidth={'15vw'}
        />

        {/* Data Ora */}
        <CRGDateTimePickerControlled
          label={i18n.dateToLabel}
          name="toDate"
          control={control}
          customWidth={'15vw'}
        />

        <CRGButton
          type="submit"
          colorVariant={'mainPrimary'}
          customWidth={'150px'}
        >
          {i18n.buttonSubmitLabel}
        </CRGButton>
        <CRGButton
          colorVariant={'mainSecondary'}
          customWidth={'170px'}
          onClick={clearFilters}
        >
          {i18n.buttonClearFilters}
        </CRGButton>
      </FormStyle>
    </FilterContainer>
  );
};

ManualNotificationListFilters.propTypes = {
  handleFilterChange: PropTypes.func,
};

export { ManualNotificationListFilters };
