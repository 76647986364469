import { useEffect, useState, useContext } from 'react';

import styled from 'styled-components';

import { useMediaQuery } from '@mui/material';

import { LangContext, LoadingContext, MenuContext } from 'contexts';
import { useCountries, useNotifications } from 'shared';
import { CRGMainLayout } from 'components/layouts';
import { CRGTable, CRGText, CRGTextFieldControlled } from 'components/atoms';
import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useMapping } from 'hooks';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  gap: 15px;
  ${(props) => props.theme.breakpoints.down('sm')} {
  }
`;
const FormStyle = styled.form`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100vw;
  }
`;

const ContainerStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-top: 30px;
  padding-left: 50px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 20px;
    padding-left: 20px;
    position: relative;
  }
`;

const TitleContainer = styled.div`
  margin-left: 5px;
  width: 90vw;
  margin-bottom: 10px;
`;

const ManualNotificationDetail = () => {
  // Hooks
  const { id } = useParams();
  const notificationHelper = useNotifications();
  const countriesHelper = useCountries();
  const { userTypeList, userLanguageList } = useMapping();
  const { control, reset } = useForm({
    defaultValues: {
      title: '',
      text: '',
    },
    reValidateMode: 'all',
  });

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).manualNotificationDetail;

  // Constants
  const USER_LIST_TABLE_HEADER = [
    { label: i18n.headers.name, key: 'name', forceColumnSize: '7vw' },
    { label: i18n.headers.surname, key: 'surname', forceColumnSize: '7vw' },
    { label: i18n.headers.userType, key: 'userType', forceColumnSize: '7vw' },
    { label: i18n.headers.country, key: 'country', forceColumnSize: '30vw' },
    { label: i18n.headers.language, key: 'language', forceColumnSize: '10vw' },
    {
      label: i18n.headers.company,
      key: 'company',
      forceColumnSize: '7vw',
    },
  ];

  // Media Query
  const is1600down = useMediaQuery('(max-width:1600px)');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Contexts
  const { setIsLoading } = useContext(LoadingContext);
  const { isMenuExpanded } = useContext(MenuContext);
  const { lang } = useContext(LangContext);
  // State
  const [displayUsers, setDisplayUsers] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [pageSize] = useState(10);
  const [countries, setCountries] = useState([]);

  //Effects
  useEffect(() => {
    if (id) fetchNotification();
  }, [id, currentPage, countries]);

  useEffect(() => {
    fetchCountries();
  }, [lang, countries]);

  // Functions
  const fetchNotification = async () => {
    setIsLoading(true);

    notificationHelper
      .getManualNotifications({
        page: currentPage,
        pageSize,
        id,
      })
      .then((res) => {
        reset(res);
        const nextDisplayUsers = res?.users?.map((u) => {
          return {
            ...u,
            userType: <>{userTypeList[u?.type]}</>,
            country: <>{countries[u?.country]}</>,
            language: <>{userLanguageList[u?.language]}</>,
          };
        });
        setTotalUsers(res?.usersCount);
        setDisplayUsers(nextDisplayUsers);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const fetchCountries = async () => {
    countriesHelper
      .get(lang)
      .then((res) => {
        setCountries(res);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onPaginationPageChange = (e, page) => {
    setCurrentPage(page - 1);
  };

  return (
    <CRGMainLayout>
      <ContainerStyle>
        <TitleContainer>
          <CRGText
            fontSize={23}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.title}
          </CRGText>
        </TitleContainer>
        <FormStyle>
          <FormContainer>
            {/* Title */}
            <CRGTextFieldControlled
              name="title"
              label={i18n.form.titleLabel}
              control={control}
              customWidth={'80vw'}
              disabled
            />
            {/* Message */}
            <CRGTextFieldControlled
              name="text"
              label={i18n.form.messageLabel}
              control={control}
              customWidth={'80vw'}
              disabled
            />
          </FormContainer>
        </FormStyle>
        <CRGTable
          data={displayUsers}
          headers={USER_LIST_TABLE_HEADER}
          title={i18n.form.titleLabel}
          maxColumnSize={
            isMobile
              ? '200px'
              : is1600down
              ? isMenuExpanded
                ? '21vw'
                : '22vw'
              : isMenuExpanded
              ? '22vw'
              : '23vw'
          }
          pagination={{
            count: Math.ceil(totalUsers / pageSize),
            onChange: onPaginationPageChange,
          }}
        />
      </ContainerStyle>
    </CRGMainLayout>
  );
};

export { ManualNotificationDetail };
