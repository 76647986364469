import { useEffect, useState, useContext } from 'react';

import styled from 'styled-components';
import dayjs from 'dayjs';

import { useMediaQuery } from '@mui/material';

import { CRGMainLayout } from 'components/layouts';
import {
  permissions,
  useArticles,
  useStatus,
  useStocklist,
  useCustomers,
} from 'shared';
import {
  DrawerContext,
  LangContext,
  LoadingContext,
  MenuContext,
  UserContext,
  InfoDialogContext,
} from 'contexts';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { CRGButton, CRGInfoButton, CRGTable, CRGText } from 'components/atoms';
import {
  AddStocklistRequest,
  StocklistListFilters,
  StocklistListFiltersMobile,
} from 'components/bundle';
import { useNavigate } from 'react-router-dom';
import { useInfoDocument, useMapping } from 'hooks';

const ConatinerStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 30px;
  padding-left: 50px;
  position: relative;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 20px;
    padding-left: 20px;
    position: relative;
  }
`;

const MobileCustomActionContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.palette.main.lightTissue};
  border-radius: 5px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: 5px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  margin-left: 5px;
  width: 90vw;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.isMenuExpanded ? '79vw' : '90vw')};
  @media screen and (max-width: 1600px) {
    width: ${(props) => (props.isMenuExpanded ? '80vw' : '85vw')};
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-left: 0px;
    margin-right: 50px;
  }
`;

const FiltersContainer = styled.div`
  margin-left: 5px;
  width: ${(props) => (props.isMenuExpanded ? '79vw' : '90vw')};
  margin-bottom: 10px;
  @media screen and (max-width: 1600px) {
    width: ${(props) => (props.isMenuExpanded ? '80vw' : '85vw')};
  }
`;

const NewButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ColumnCellContent = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.down('sm')} {
    align-items: flex-end;
  }
`;

const DuplicateButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  @media screen and (max-width: 360px) {
    flex-direction: column;
  }
`;

// Constants
const STOCKLIST_LIST_TABLE_HEADER = (
  i18n,
  is1600down,
  isMenuExpanded,
  customerType
) => {
  let headers = [
    {
      label: i18n.headers.status,
      key: 'status',
      forceColumnSize: is1600down ? (isMenuExpanded ? '2vw' : '3vw') : null,
    },
    {
      label: i18n.headers.idRequest,
      key: 'stockId',
      forceColumnSize: is1600down ? (isMenuExpanded ? '2vw' : '3vw') : null,
    },
    { label: i18n.headers.name, key: 'description' },
    { label: i18n.headers.user, key: 'user', hideOrder: true },
    { label: i18n.headers.requestDateTime, key: 'requestDate' },
    {
      label: i18n.headers.processingDateTime,
      key: 'processingDate',
    },
    { label: i18n.headers.customer, key: 'customerId' },
    {
      label: i18n.headers.agent,
      key: 'agentId',
      forceColumnSize: is1600down ? '6vw' : null,
    },
    {
      label: i18n.headers.article,
      key: 'articleId',
      forceColumnSize: is1600down ? '6vw' : null,
    },
    { label: i18n.headers.color, key: 'color' },
    { label: i18n.headers.email, key: 'email' },
    {
      label: i18n.headers.type,
      key: 'format',
      forceColumnSize: is1600down ? (isMenuExpanded ? '2vw' : '3vw') : null,
    },
  ];

  if (customerType === 2)
    headers = headers.filter((h) => h.key !== 'customerId');

  if (customerType === 1 || customerType === 2)
    headers = headers.filter((h) => h.key !== 'agentId');

  return headers;
};

const Stocklist = () => {
  // Hooks
  const stockHelper = useStocklist();
  const articlesHelper = useArticles();
  const customersHelper = useCustomers();
  const statusHelper = useStatus();
  const navigate = useNavigate();
  const { stockFormat } = useMapping();
  const { STOCKLIST_PDF_URL } = useInfoDocument();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).stocklist;
  const i18nComponents = translation.components(intl);
  const i18nGlobal = translation.global(intl);

  // Media Query
  const is1600down = useMediaQuery('(max-width:1600px)');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Contexts
  const { setIsLoading } = useContext(LoadingContext);
  const { setIsDrawerOpen, closeDrawer } = useContext(DrawerContext);
  const { isMenuExpanded } = useContext(MenuContext);
  const { lang } = useContext(LangContext);
  const { user } = useContext(UserContext);

  // State
  const [stocklist, setStocklist] = useState();
  const [articles, setArticles] = useState([]);
  const [stockStatusList, setStockStatusList] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [totalStocklist, setTotalStocklist] = useState();
  const [pageSize] = useState(10);
  const [currentFilters, setCurrentFilters] = useState({});
  const [customers, setCustomers] = useState([]);
  const [currentOrder, setCurrentOrder] = useState({
    order: 'DESC',
    orderBy: 'stockId',
  });
  const [tableHeader] = useState(
    STOCKLIST_LIST_TABLE_HEADER(
      i18n,
      is1600down,
      isMenuExpanded,
      user?.cariaggiUser?.type
    )
  );

  const { setInfoDialog } = useContext(InfoDialogContext);

  //Effects
  useEffect(() => {
    if (stockStatusList?.length) fetchStocklist();
  }, [currentPage, currentFilters, currentOrder, articles, stockStatusList]);

  useEffect(() => {
    fetchArticles();
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (lang) fetchStatus();
  }, [lang]);

  // Functions
  const fetchStocklist = async () => {
    setIsLoading(true);
    stockHelper
      .list({
        page: currentPage,
        pageSize: pageSize,
        ...currentFilters,
        ...currentOrder,
        ...(user?.cariaggiUser?.type === 2 && {
          customerId: [`${user?.cariaggiUser?.customerCode}`],
        }),
        ...(user?.cariaggiUser?.type === 1 && {
          agentId: [`${user?.cariaggiUser?.agentCode}`],
        }),
      })
      .then((res) => {
        const nextTotalStocklist = res.total;
        setTotalStocklist(nextTotalStocklist);
        const nextStocklist = res.stocks.map((stocklist) => {
          return {
            ...stocklist,
            stockId: stocklist?.stockId,
            user: `${stocklist?.user?.name} ${stocklist?.user?.surname}`,
            status:
              stockStatusList.find((s) => s.value === stocklist?.status)
                ?.label || '-',
            requestDate: stocklist?.requestDate
              ? dayjs(stocklist?.requestDate).format('DD/MM/YYYY HH:mm')
              : '-',
            processingDate: stocklist?.processingDate
              ? dayjs(stocklist?.processingDate).format('DD/MM/YYYY HH:mm')
              : '-',
            customerId: (
              <ColumnCellContent>
                <CRGText
                  colorVariant="neutralDarkBlack"
                  fontSize={14}
                  fontWeight={400}
                >
                  {stocklist?.customerData?.companyName}
                </CRGText>
                <CRGText
                  colorVariant="neutralDarkBlack"
                  fontSize={10}
                  fontWeight={400}
                >
                  {stocklist?.customerData?.id}
                </CRGText>
              </ColumnCellContent>
            ),
            agentId: (
              <ColumnCellContent>
                <CRGText
                  colorVariant="neutralDarkBlack"
                  fontSize={14}
                  fontWeight={400}
                >
                  {stocklist?.customerData?.agentName}
                </CRGText>
              </ColumnCellContent>
            ),
            articleId: (
              <ColumnCellContent>
                <CRGText
                  colorVariant="neutralDarkBlack"
                  fontSize={14}
                  fontWeight={400}
                >
                  {stocklist?.article?.description}
                </CRGText>
                <CRGText
                  colorVariant="neutralDarkBlack"
                  fontSize={10}
                  fontWeight={400}
                >
                  {stocklist?.article?.id}
                </CRGText>
              </ColumnCellContent>
            ),
          };
        });
        setStocklist(nextStocklist);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const fetchStatus = async () => {
    statusHelper
      .get(lang)
      .then((res) => {
        const nextStatus = res.map((s) => {
          return {
            value: s.status,
            label: s.description,
          };
        });
        setStockStatusList(nextStatus);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const fetchCustomers = async () => {
    customersHelper
      .get()
      .then((res) => {
        setCustomers(res);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onNewStocklistRequest = () => {
    setIsDrawerOpen({
      isOpen: true,
      customWidth: '30vw',
      children: (
        <AddStocklistRequest
          onClose={() => {
            fetchStocklist();
            closeDrawer();
          }}
          customers={customers}
        />
      ),
    });
  };

  const goToStockDetail = (stock) => {
    navigate(`/stock/${stock.id}`);
  };

  const duplicateRequest = (stock) => {
    setInfoDialog(null);
    setIsLoading(true);

    stockHelper
      .create({
        articleId: stock.article.id,
        customerId: stock.customerData.id,
        agent: stock.customerData.agentId,
        format: stock.format,
        description: `${
          i18nComponents.addStocklistRequest.fixedRequestName
        } - ${dayjs(new Date()).format('DD-MM-YYYY HH:mm')}`,
        color: stock.color,
        email: stock.email,
      })
      .then(() => {
        setIsLoading(false);
        fetchStocklist();
      })
      .catch((e) => {
        console.error(e);
        setInfoDialog({
          isOpen: true,
          title: i18nGlobal.errors.genericRetry.title,
          message: i18nGlobal.errors.genericRetry.message,
          customActions: (
            <CRGButton onClick={() => setInfoDialog(null)}>
              {i18nGlobal.errors.genericRetry.closeButton}
            </CRGButton>
          ),
        });
        setIsLoading(false);
      });
  };

  const showDuplicatePopup = (stock) => {
    setInfoDialog({
      isOpen: true,
      title: i18nComponents.duplicateStock.title,
      message: i18nComponents.duplicateStock.message,
      customActions: (
        <DuplicateButtonContainer>
          <CRGButton
            colorVariant={'mainSecondary'}
            onClick={() => setInfoDialog(null)}
            customWidth={'150px'}
          >
            {i18nComponents.duplicateStock.cancelButtonLabel}
          </CRGButton>
          <CRGButton
            onClick={() => duplicateRequest(stock)}
            customWidth={'150px'}
          >
            {i18nComponents.duplicateStock.agreeButtonLabel}
          </CRGButton>
        </DuplicateButtonContainer>
      ),
    });
  };

  const rowActionComponent = (item) => {
    if (item.format !== 'VIDEO' && item.format !== 'JSON')
      return (
        <CRGButton isSmall onClick={() => showDuplicatePopup(item)}>
          <img width={'12px'} height={'12px'} src={'images/email.svg'}></img>
        </CRGButton>
      );
    return (
      <CRGButton isSmall onClick={() => goToStockDetail(item)}>
        <img
          width={'12px'}
          height={'12px'}
          src={'images/go-to-detail.svg'}
        ></img>
      </CRGButton>
    );
  };

  const onPaginationPageChange = (e, page) => {
    setCurrentPage(page - 1);
  };

  const onFilterChange = (data) => {
    const nextData = {
      ...data,
      requestDate: data?.requestDate
        ? new Date(data.requestDate).getTime()
        : null,
      article: data?.articleId?.id ? [data?.articleId?.id] : null,
      format: stockFormat?.[data?.format]
        ? [stockFormat?.[data?.format]]
        : null,
      articleId: null,
      customerId: data?.customerId ? [data?.customerId?.id] : null,
    };

    const nextCurrentFilters = {
      ...currentFilters,
      ...nextData,
    };
    setCurrentFilters(nextCurrentFilters);
  };

  const handleOrderChange = (key) => {
    const isNewOrder = currentOrder.orderBy !== key;
    const nextCurrentOrder = {
      order: isNewOrder
        ? 'DESC'
        : currentOrder.order === 'DESC'
        ? 'ASC'
        : 'DESC',
      orderBy: key,
    };
    setCurrentOrder(nextCurrentOrder);
  };

  const fetchArticles = async () => {
    articlesHelper
      .list()
      .then((res) => {
        setArticles(res);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  return (
    <CRGMainLayout>
      <ConatinerStyle>
        <TitleContainer isMenuExpanded={isMenuExpanded}>
          <CRGText
            fontSize={23}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.title}
          </CRGText>
          <CRGInfoButton infoUrl={STOCKLIST_PDF_URL} />
        </TitleContainer>
        {isMobile ? null : (
          <FiltersContainer isMenuExpanded={isMenuExpanded}>
            <StocklistListFilters
              handleFilterChange={onFilterChange}
              articles={articles}
              status={stockStatusList}
              customers={customers}
            />
          </FiltersContainer>
        )}
        <CRGTable
          data={stocklist}
          headers={tableHeader}
          title={i18n.userListLabel}
          maxColumnSize={
            isMobile
              ? '200px'
              : is1600down
              ? isMenuExpanded
                ? '3vw'
                : '4vw'
              : isMenuExpanded
              ? '4vw'
              : '5vw'
          }
          currentOrder={currentOrder}
          handleOrderChange={handleOrderChange}
          customActions={
            user?.cariaggiUser?.permissions
              ?.split('|')
              ?.includes(permissions?.STOCKLIST_CREATE?.toString()) ? (
              isMobile ? (
                <MobileCustomActionContainer>
                  <CRGText
                    fontSize={14}
                    fontWeight={700}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {i18n.newRequestButton}
                  </CRGText>
                  <CRGButton
                    isSmall
                    onClick={onNewStocklistRequest}
                    colorVariant={'mainSecondary'}
                  >
                    <img
                      width={'12px'}
                      height={'12px'}
                      src={'images/white_plus.svg'}
                    ></img>
                  </CRGButton>
                </MobileCustomActionContainer>
              ) : (
                <CRGButton
                  onClick={onNewStocklistRequest}
                  colorVariant={'mainSecondary'}
                  customWidth={'200px'}
                >
                  <NewButtonContainer>
                    <img src={'/images/plus-black.svg'} />
                    {i18n.newRequestButton}
                  </NewButtonContainer>
                </CRGButton>
              )
            ) : null
          }
          rowAction={rowActionComponent}
          pagination={{
            count: Math.ceil(totalStocklist / pageSize),
            onChange: onPaginationPageChange,
          }}
          mobileFilters={
            <StocklistListFiltersMobile
              onSearch={(data) => onFilterChange(data)}
              articles={articles}
              status={stockStatusList}
              customers={customers}
            />
          }
        />
      </ConatinerStyle>
    </CRGMainLayout>
  );
};

export { Stocklist };
