import styled from 'styled-components';

import { Pagination } from '@mui/material';

const StyledPagination = styled(Pagination)`
  ul {
    li {
      button {
        font-family: Montserrat;
        font-weight: 400;
      }
      button.MuiPaginationItem-page.Mui-selected {
        background-color: ${(props) => props.theme.palette.main.secondary};
      }
    }
  }
`;

const CRGPagination = (props) => {
  return <StyledPagination {...props} />;
};

export { CRGPagination };
