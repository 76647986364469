import { CRGText, CRGTooltip } from 'components/atoms';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MenuItemContainer = styled.div`
  background-color: ${(props) =>
    props.isSelected ? props.theme.palette.main.primary : 'transparent'};
  color: ${(props) =>
    !props.isSelected
      ? props.theme.palette.neutral.dark.black
      : props.theme.palette.neutral.light.white};
  display: flex;
  height: 50px;
  padding-left: ${(props) => (props.isSmall ? 'unset' : '40px')};
  gap: ${(props) => (props.isSmall ? 'unset' : '20px')};
  justify-content: ${(props) => (props.isSmall ? 'center' : 'flex-start')};
  align-items: center;
  cursor: pointer;
`;

const ImgMenu = styled.img`
  filter: ${(props) => !props.isSelected && !props.isSmall && 'invert(1)'};
  opacity: 1;
`;

const MenuItem = ({ label, action, imgSrc, isSmall, isSelected, tooltip }) => {
  return tooltip ? (
    <CRGTooltip title={tooltip}>
      <MenuItemContainer
        onClick={action}
        isSelected={isSelected}
        isSmall={isSmall}
      >
        <ImgMenu isSelected={isSelected} src={imgSrc} />
        <CRGText
          fontSize={14}
          fontWeight={500}
          cursor={'pointer'}
          colorVariant={isSelected ? 'neutralLigthWhite' : 'neutralDarkBlack'}
        >
          {isSmall ? null : label}
        </CRGText>
      </MenuItemContainer>
    </CRGTooltip>
  ) : (
    <MenuItemContainer
      onClick={action}
      isSelected={isSelected}
      isSmall={isSmall}
    >
      <ImgMenu isSelected={isSelected} src={imgSrc} />
      <CRGText
        fontSize={14}
        fontWeight={500}
        cursor={'pointer'}
        colorVariant={isSelected ? 'neutralLigthWhite' : 'neutralDarkBlack'}
      >
        {isSmall ? null : label}
      </CRGText>
    </MenuItemContainer>
  );
};

MenuItem.propTypes = {
  label: PropTypes.string,
  action: PropTypes.func,
  imgSrc: PropTypes.string,
  isSmall: PropTypes.bool,
  isSelected: PropTypes.bool,
  theme: PropTypes.object,
  tooltip: PropTypes.string,
};

export { MenuItem };
