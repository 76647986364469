import { useState, useContext } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import { CRGSplitLayout } from 'components/layouts';
import { CRGButton, CRGInfoButton, CRGText } from 'components/atoms';
import {
  ChangeTempPasswordComplete,
  ForceUpdatePasswordForm,
  GoToRegistration,
  LoginForm,
} from 'components/bundle';

import { useAuthentication } from 'shared';

import { useCustomIntl, useInfoDocument } from 'hooks';
import translation from 'utils/translation';
import { LoadingContext } from 'contexts/LoadingContext';
import { InfoDialogContext, LangContext, UserContext } from 'contexts';

const LoginContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const FormContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: 50%;
  margin-top: 60px;
  margin-top: 60px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: 30px;
  }
`;

const LogoImgStyle = styled.img`
  width: 210px;
  height: 150px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: 20px;
    width: 127px;
    height: 90px;
  }
`;

const RegistrationContainer = styled.div`
  margin-top: 30px;
  @media screen and (max-width: 360px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TopTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 360px) {
    margin-left: 30px;
    width: fit-content;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Login = () => {
  // Hooks
  const navigate = useNavigate();
  const authHelper = useAuthentication();
  const { LOGIN_PDF_URL } = useInfoDocument();

  // State
  const [userToForceUpdatePassword, setUserToForceUpdatePassword] = useState();
  const [passwordUpdated, setPasswordUpdated] = useState();

  // Context
  const { setIsLoading } = useContext(LoadingContext);
  const { setInfoDialog } = useContext(InfoDialogContext);
  const { setUser } = useContext(UserContext);
  const { lang, onChangeLang } = useContext(LangContext);

  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isSmallDevice = useMediaQuery('(max-width:360px)');

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).login;

  // Functions
  const onSubmit = (data) => {
    setIsLoading(true);
    authHelper
      .login(data)
      .then((res) => {
        if (res?.cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setUserToForceUpdatePassword(res?.cognitoUser);
          setPasswordUpdated(false);
        } else {
          localStorage.setItem('cariaggi-web-user', JSON.stringify(res));
          if (res?.cariaggiUser?.language === '0') {
            onChangeLang('it-IT');
          } else {
            onChangeLang('en-GB');
          }
          navigate('/home');
          setUser(res);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        if (e.toString().indexOf('Incorrect username or password')) {
          setInfoDialog({
            isOpen: true,
            title: i18n.incorrectUsernameOrPassword.title,
            message: i18n.incorrectUsernameOrPassword.message,
            customActions: (
              <div>
                <CRGButton
                  colorVariant={'mainSecondary'}
                  onClick={() => setInfoDialog(null)}
                  customWidth={isSmallDevice || isMobile ? '150px' : null}
                >
                  {i18n.incorrectUsernameOrPassword.closeButton}
                </CRGButton>
              </div>
            ),
          });
        }
        setIsLoading(false);
      });
  };

  const handleForceUpdatePassword = (data) => {
    setIsLoading(true);
    authHelper
      .forceUpdatePassword({
        user: userToForceUpdatePassword,
        password: data?.password,
      })
      .then((res) => {
        localStorage.setItem('cariaggi-web-user', JSON.stringify(res));
        setPasswordUpdated(true);
        setUserToForceUpdatePassword(false);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const handleForgotPassword = () => navigate('/forgot-password');

  const handleLoginRedirect = () => {
    setPasswordUpdated(false);
    setUserToForceUpdatePassword(false);
  };

  const handleGoToRegistrationClick = () => {
    navigate('/signin');
  };

  return (
    <CRGSplitLayout
      imageSrc={`images/login_wallpaper${
        userToForceUpdatePassword ? '_step_4' : passwordUpdated ? '_step_5' : ''
      }${isMobile ? '_mobile' : ''}${
        userToForceUpdatePassword && lang === 'en-GB' ? '-en' : ''
      }${passwordUpdated && lang === 'en-GB' ? '-en' : ''}.jpg`}
      bottomElement={
        isMobile ? null : (
          <GoToRegistration onButtonClick={handleGoToRegistrationClick} />
        )
      }
    >
      <LoginContainerStyle>
        <LogoImgStyle src={'images/logo.svg'} />
        <FormContainerStyle>
          {/* UTENTE AUTENTICATO CON PASSWORD TEMPORANEA */}
          {userToForceUpdatePassword && !passwordUpdated ? (
            <>
              <ForceUpdatePasswordForm
                cognitoUser={userToForceUpdatePassword}
                onSubmit={handleForceUpdatePassword}
              />
            </>
          ) : null}

          {/* PASSWORD TEMPORANEA CAMBIATA CON SUCCESSO */}
          {!userToForceUpdatePassword && passwordUpdated ? (
            <>
              <ChangeTempPasswordComplete onClose={handleLoginRedirect} />
            </>
          ) : null}

          {/* FLUSSO STANDARD */}
          {!userToForceUpdatePassword && !passwordUpdated ? (
            <>
              <>
                <InfoContainer>
                  <TopTextContainer>
                    <CRGText fontWeight={800} fontSize={30}>
                      {i18n.welcome}
                    </CRGText>
                    <CRGText fontWeight={300} fontSize={13}>
                      {i18n.enter}
                    </CRGText>
                  </TopTextContainer>
                  <CRGInfoButton infoUrl={LOGIN_PDF_URL} />
                </InfoContainer>
                <LoginForm onSubmit={onSubmit} />
                <CRGText
                  fontSize={14}
                  fontWeight={600}
                  colorVariant={'mainPrimary'}
                  textAlign={'center'}
                  textDecoration={'underline'}
                  cursor={'pointer'}
                  onClick={handleForgotPassword}
                >
                  {i18n.forgotPassword}
                </CRGText>
              </>

              {isMobile ? (
                <RegistrationContainer>
                  <GoToRegistration
                    onButtonClick={handleGoToRegistrationClick}
                  />
                </RegistrationContainer>
              ) : null}
            </>
          ) : null}
        </FormContainerStyle>
      </LoginContainerStyle>
    </CRGSplitLayout>
  );
};

export { Login };
