import styled from 'styled-components';
import PropTypes from 'prop-types';

import { CRGText, CRGPagination } from 'components/atoms';
import { CRGSelect } from '../CRGSelect';
import { MenuItem } from '@mui/material';
import { CRGButton } from '../CRGButton';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { CRGMobileList } from './CRGMobileList';

const PageContainer = styled.div`
  padding-bottom: 20px;
  width: 90%;
  overflow: scroll;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  margin-top: 20px;
`;

const OrderContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
`;

const HeadInfoContainer = styled.div`
  padding-bottom: 5px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
`;
const ArrowsStyle = styled.div`
  display: flex;
  gap: 2px;
`;

const CRGMobileTable = ({
  headers,
  data,
  title,
  customActions,
  handleClick,
  rowAction,
  pagination,
  maxColumnSize,
  filtersComponent,
  currentOrder,
  handleOrderChange,
}) => {
  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).mobileTable;

  // Functions
  const onOrderSelectChange = (orderBy) => {
    handleOrderChange(orderBy);
  };

  // Components
  const Arrow = () => {
    if (currentOrder?.order === 'ASC') {
      return (
        <ArrowsStyle>
          <img src={'/images/table/grey_up.svg'} />
          <img src={'/images/table/black_down.svg'} />
        </ArrowsStyle>
      );
    } else if (currentOrder?.order === 'DESC') {
      return (
        <ArrowsStyle>
          <img src={'/images/table/black_up.svg'} />
          <img src={'/images/table/grey_down.svg'} />
        </ArrowsStyle>
      );
    } else {
      return (
        <ArrowsStyle>
          <img src={'/images/table/grey_up.svg'} />
          <img src={'/images/table/grey_down.svg'} />
        </ArrowsStyle>
      );
    }
  };

  return (
    <PageContainer>
      {title ? (
        <HeadInfoContainer>
          <CRGText
            fontSize={16}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {title}
          </CRGText>
          {customActions}
          {filtersComponent}
        </HeadInfoContainer>
      ) : null}

      <OrderContainer>
        <CRGSelect
          label={i18n.orderLabel}
          onChange={(e) => onOrderSelectChange(e.target.value)}
          defaultValue={currentOrder?.orderBy}
          customHeight={'50px'}
          customFontSize={'12px'}
        >
          {headers
            .filter((h) => !h.hideOrder)
            .map((o) => (
              <MenuItem key={o.key} value={o.key}>
                {o.label}
              </MenuItem>
            ))}
        </CRGSelect>
        <CRGButton
          colorVariant={'lightWhite'}
          customHeight={'50px'}
          customWidth={'50px'}
          onClick={() => onOrderSelectChange(currentOrder?.orderBy)}
        >
          <Arrow />
        </CRGButton>
      </OrderContainer>

      <ListContainer>
        {data?.length ? (
          <>
            <CRGMobileList
              data={data}
              headers={headers}
              rowAction={rowAction}
              handleClick={handleClick}
              maxColumnSize={maxColumnSize}
            />
            <PaginationContainer>
              <CRGPagination {...pagination} />
            </PaginationContainer>
          </>
        ) : (
          <CRGText
            fontSize={18}
            fontWeight={400}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.emptyLabel}
          </CRGText>
        )}
      </ListContainer>
    </PageContainer>
  );
};

CRGMobileTable.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  title: PropTypes.string,
  customActions: PropTypes.node,
  rowAction: PropTypes.func,
  handleClick: PropTypes.func,
  pagination: PropTypes.obj,
  maxColumnSize: PropTypes.string,
  filtersComponent: PropTypes.node,
  currentOrder: PropTypes.obj,
  handleOrderChange: PropTypes.func,
};

export { CRGMobileTable };
