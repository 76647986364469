/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import { Controller } from 'react-hook-form';

import { CRGText } from 'components/atoms';
import { CRGAutocomplete } from './CRGAutocomplete';

const CRGAutocompleteControlled = ({
  control,
  name,
  rules,
  errors,
  disabled,
  defaultValue = '',
  options,
  getOptionLabel,
  renderOption,
  renderInput,
}) => {
  // State
  const [currentError, setCurrentError] = useState();

  // Effect
  useEffect(() => {
    if (name && errors) {
      const errorObj = errors;
      const errorKey = Object?.keys?.(errorObj?.message)?.[0];
      const nextCurrentError = errorObj?.message?.[errorKey];
      setCurrentError(nextCurrentError);
    } else {
      setCurrentError(null);
    }
  }, [errors, name]);

  return control ? (
    <>
      <Controller
        render={({ ...props }) => {
          return (
            <CRGAutocomplete
              disabled={disabled}
              options={options}
              getOptionLabel={getOptionLabel}
              renderOption={renderOption}
              renderInput={renderInput}
              onChange={(e, data) => props.field.onChange(data)}
              {...props}
            />
          );
        }}
        defaultValue={defaultValue}
        name={name}
        control={control}
        rules={rules}
      />
      {currentError && (
        <CRGText fontWeight={500} fontSize={14} colorVariant={'mainError'}>
          {currentError}
        </CRGText>
      )}
    </>
  ) : null;
};

{
  /* <CRGAutocomplete
              disabled={disabled}
              options={countries}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <li {...props}>{option.label}</li>
              )}
              renderInput={(params) => (
                <CRGTextField
                  label="Choose a country"
                  
                  {...params}
                />
              )}
              {...field}
            /> */
}

export { CRGAutocompleteControlled };
