import { MenuItem } from '@mui/material';
import {
  CRGAutocompleteControlled,
  CRGButton,
  CRGCheckboxControlled,
  CRGDateRangePickerControlled,
  CRGSelect,
  CRGSelectControlled,
  CRGText,
  CRGTextField,
  CRGTextFieldControlled,
} from 'components/atoms';
import { CRGOrderByArrow } from 'components/atoms/CRGOrderByArrow';
import { UserContext } from 'contexts';
import { useCustomIntl, useMapping, useOrdersMapping } from 'hooks';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { orderType, permissions } from 'shared';
import styled from 'styled-components';
import translation from 'utils/translation';

const FilterContainer = styled.div``;

const FormStyle = styled.form``;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

const RowStyled = styled.div`
  display: flex;
  gap: 20px;
  @media screen and (max-width: 1600px) {
    flex-wrap: wrap;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 3px;
  gap: 10px;
  margin-left: 10px;
  float: ${(props) => props.right && 'right'};
  @media screen and (max-width: 1600px) {
    flex-wrap: wrap;
  }
`;
// const OrderItemsStyle = styled.div`
//   display: flex;
//   gap: 12px;
//   align-items: center;
// `;

const defaultValues = {
  customerOrder: '',
  orderDate: [null, null],
  orderConfirmation: '',
  orderType: '',
  progress: '',
  customer: null,
  orderStatus: null,
  cariaggiColor: '',
  customerColor: '',
  shippingDate: [null, null],
  finalCustomer: '',
  finalDestination: '',
  customerSeason: '',
  customerLine: '',
  customerBoard: '',
  currency: '',
  payment: '',
  orderByUndefinedShippingDate: false,
  orderByField: null,
  orderByDirection: 'ASC',
  daysToExtract: null,
};

const OrdersFilter = ({
  handleFilterChange,
  customers,
  orderByObj,
  setOrderByObj,
  isFromHistory,
  currentFilters,
}) => {
  const {
    ordersTypeValues,
    orderStatusValues,
    orderConfirmationValues,
    orderProgressValues,
    orderDaysToExtractValues,
  } = useMapping();

  const { ordersValuesForOrderBy } = useOrdersMapping();

  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: currentFilters,
  });

  const intl = useCustomIntl();
  const i18nGlobal = translation.global(intl).app.filters;
  const i18n = translation.components(intl).ordersFilters;
  const { orderLabel } = translation.components(intl).mobileTable;

  const [advancedFilters, setAdvancedFilters] = useState(false);

  // Context
  const { user } = useContext(UserContext);

  const clearFilters = () => {
    if (!isFromHistory) {
      reset(defaultValues);
      handleFilterChange(defaultValues);
    } else {
      const daysToExtract = (() => {
        if (user?.cariaggiUser?.type === 2) return 90;
        if (user?.cariaggiUser?.type === 1) return 30;
      })();
      reset({ ...defaultValues, daysToExtract });
      handleFilterChange({ ...defaultValues, daysToExtract });
    }
  };

  const handleAdvancedFilters = () => {
    setAdvancedFilters((prevState) => !prevState);
  };

  const handleOrderByDirection = () => {
    const newDirection = orderByObj.direction === 'ASC' ? 'DESC' : 'ASC';
    setOrderByObj({ ...orderByObj, direction: newDirection });
  };

  const orderTypesArray = (() => {
    const preferencesArray =
      user?.cariaggiUser?.permissionsPrefs?.split('|') || [];
    let orderTypesArray = ordersTypeValues;
    const ORDERS_FILATI_VAL = isFromHistory
      ? permissions.ORDERS_HISTORY_FILATI.toString()
      : permissions.ORDERS_FILATI.toString();

    const ORDERS_CAMPIONARIO_VAL = isFromHistory
      ? permissions.ORDERS_HISTORY_CAMPIONARIO.toString()
      : permissions.ORDERS_CAMPIONARIO.toString();

    const ORDERS_LABDIP_VAL = isFromHistory
      ? permissions.ORDERS_HISTORY_LABDIP.toString()
      : permissions.ORDERS_LABDIP.toString();

    if (!preferencesArray.includes(ORDERS_FILATI_VAL)) {
      orderTypesArray = orderTypesArray.filter(
        (item) => item.value !== orderType.Yarn
      );
    }
    if (!preferencesArray.includes(ORDERS_CAMPIONARIO_VAL)) {
      orderTypesArray = orderTypesArray.filter(
        (item) => item.value !== orderType.Sampling
      );
    }
    if (!preferencesArray.includes(ORDERS_LABDIP_VAL)) {
      orderTypesArray = orderTypesArray.filter(
        (item) => item.value !== orderType.Labdip
      );
    }
    return orderTypesArray;
  })();

  useEffect(() => {
    if (isFromHistory) {
      const userType = user?.cariaggiUser?.type;

      if (userType === 2) {
        setValue('daysToExtract', 90);
      }
      if (userType === 1) {
        setValue('daysToExtract', 30);
      }
    }
  }, [user]);

  return (
    <FilterContainer>
      <FormStyle onSubmit={handleSubmit(handleFilterChange)}>
        <RowContainer>
          <RowStyled style={{ width: '100%' }}>
            <CRGTextFieldControlled
              control={control}
              name="customerOrder"
              label={i18n.customerOrder}
              customWidth={'20%'}
            />
            <CRGDateRangePickerControlled
              value={[]}
              name="orderDate"
              label={i18n.orderDate}
              control={control}
              customWidth={'10%'}
            />
            <CRGSelectControlled
              control={control}
              name="orderConfirmation"
              label={i18n.orderConfirmation}
              customWidth={'20%'}
              options={orderConfirmationValues}
            />
            <CRGSelectControlled
              control={control}
              name="orderType"
              label={i18n.orderType}
              customWidth={'10%'}
              options={orderTypesArray}
            />
            <CRGSelectControlled
              control={control}
              name="progress"
              label={i18n.progress}
              customWidth={'20%'}
              options={orderProgressValues}
            />
            <CRGSelectControlled
              control={control}
              name="orderStatus"
              label={i18n.status}
              customWidth={'10%'}
              options={orderStatusValues}
            />
          </RowStyled>
          <ButtonsContainer style={{ marginLeft: 30 }}>
            {/* {!advancedFilters && (
              <CRGButton
                type="submit"
                colorVariant={'mainPrimary'}
                customWidth={'180px'}
              >
                {i18nGlobal.search}
              </CRGButton>
            )} */}
            <CRGButton
              colorVariant={'mainAccentSecondary'}
              customWidth={advancedFilters ? '350px' : '180px'}
              onClick={clearFilters}
            >
              {i18nGlobal.resetFilters}
            </CRGButton>
          </ButtonsContainer>
        </RowContainer>
        <RowContainer>
          <RowStyled style={{ width: '100%' }}>
            {user?.cariaggiUser?.type === 2 ? null : (
              <div style={{ width: '19%' }}>
                <CRGAutocompleteControlled
                  name="customer"
                  control={control}
                  options={customers}
                  getOptionLabel={(option) => option.searchField}
                  renderInput={(params) => (
                    <CRGTextField
                      {...params}
                      label={i18n.customer}
                      customWidth={'100%'}
                    />
                  )}
                />
              </div>
            )}
            <CRGTextFieldControlled
              control={control}
              name="cariaggiColor"
              label={i18n.cariaggiColor}
              customWidth={'20%'}
            />
            <CRGTextFieldControlled
              control={control}
              name="customerColor"
              label={i18n.customerColor}
              customWidth={'20%'}
            />
            <CRGDateRangePickerControlled
              value={[]}
              name="shippingDate"
              label={i18n.shippingDate}
              control={control}
              customWidth={'15%'}
            />
            {!isFromHistory && (
              <CRGCheckboxControlled
                control={control}
                name="orderByUndefinedShippingDate"
                label={i18n.orderByUndefinedShippingDate}
                customWidth={'16%'}
              />
            )}
          </RowStyled>

          {!advancedFilters ? (
            <ButtonsContainer>
              <CRGButton
                colorVariant={'mainSecondary'}
                customWidth={'180px'}
                onClick={handleAdvancedFilters}
              >
                {i18nGlobal.advancedFilters}
              </CRGButton>
            </ButtonsContainer>
          ) : (
            <div style={{ width: '200px' }}></div>
          )}
        </RowContainer>
        {advancedFilters && (
          <div>
            <RowContainer>
              <CRGText
                fontSize={23}
                fontWeight={600}
                colorVariant={'neutralDarkBlack'}
              >
                {i18nGlobal.advancedFilters}
              </CRGText>
            </RowContainer>
            <RowContainer>
              <RowStyled style={{ gap: 10, width: '100%' }}>
                <CRGTextFieldControlled
                  control={control}
                  name="finalCustomer"
                  label={i18n.finalCustomer}
                  customWidth={'18%'}
                />
                <CRGTextFieldControlled
                  control={control}
                  name="finalDestination"
                  label={i18n.finalDestination}
                  customWidth={'15%'}
                />
                <CRGTextFieldControlled
                  control={control}
                  name="customerSeason"
                  label={i18n.customerSeason}
                  customWidth={'15%'}
                />
                <CRGTextFieldControlled
                  control={control}
                  name="customerLine"
                  label={i18n.customerLine}
                  customWidth={'15%'}
                />
                <CRGTextFieldControlled
                  control={control}
                  name="customerBoard"
                  label={i18n.customerBoard}
                  customWidth={'15%'}
                />
                <CRGTextFieldControlled
                  control={control}
                  name="currency"
                  label={i18n.currency}
                  customWidth={'15%'}
                />
                <CRGTextFieldControlled
                  control={control}
                  name="payment"
                  label={i18n.payment}
                  customWidth={'15%'}
                />
                {isFromHistory && (
                  <CRGSelectControlled
                    control={control}
                    name="daysToExtract"
                    label={i18n.daysToExtract}
                    customWidth={'10%'}
                    options={orderDaysToExtractValues}
                  />
                )}
              </RowStyled>
            </RowContainer>
            <ButtonsContainer right>
              <CRGButton
                onClick={handleAdvancedFilters}
                colorVariant={'mainPrimary'}
                customWidth={'190px'}
              >
                {i18nGlobal.cancel}
              </CRGButton>
            </ButtonsContainer>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <CRGSelect
              label={orderLabel}
              customWidth={'20%'}
              defaultValue={orderByObj.field}
              onChange={(e) => {
                const field = e.target.value;
                setOrderByObj({
                  ...orderByObj,
                  field,
                });
              }}
            >
              {ordersValuesForOrderBy.map((o) => (
                <MenuItem key={o.key} value={o.key}>
                  {o.label}
                </MenuItem>
              ))}
            </CRGSelect>
            <CRGButton
              customHeight={'50px'}
              customWidth={'50px'}
              onClick={handleOrderByDirection}
              colorVariant={'lightWhite'}
            >
              <CRGOrderByArrow orderByDirection={orderByObj.direction} />
            </CRGButton>
          </div>
          <CRGButton
            type="submit"
            colorVariant={'mainPrimary'}
            customWidth={'180px'}
          >
            {i18nGlobal.search}
          </CRGButton>
        </div>
      </FormStyle>
    </FilterContainer>
  );
};

OrdersFilter.propTypes = {
  handleFilterChange: PropTypes.func,
  customers: PropTypes.array,
  orderByObj: PropTypes.object,
  setOrderByObj: PropTypes.func,
  isFromHistory: PropTypes.bool,
  currentFilters: PropTypes.object,
};

export { OrdersFilter };
