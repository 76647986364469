import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

import { permissions } from 'shared';

const usePermissions = () => {
  // Hooks
  const intl = useCustomIntl();
  const i18nGlobal = translation.global(intl).app.orders;
  const i18n = translation.components(intl).usePermissions;

  // Constants
  const userTypePermission = {
    0: {
      WIDGET_READ: {
        label: i18n.userTypePermission.WIDGET_READ,
        value: true,
        acl: {
          WIDGET_STOCKLIST: {
            label: i18n.userTypePermission.WIDGET_STOCKLIST,
            value: true,
          },
          WIDGET_USERS: {
            label: i18n.userTypePermission.WIDGET_USERS,
            value: true,
          },
          WIDGET_ORDERS_FILATI: {
            label: i18n.userTypePermission.WIDGET_ORDERS_FILATI,
            value: false,
          },
          WIDGET_ORDERS_LABDIP: {
            label: i18n.userTypePermission.WIDGET_ORDERS_LABDIP,
            value: false,
          },
          WIDGET_ORDERS_CAMPIONARIO: {
            label: i18n.userTypePermission.WIDGET_ORDERS_CAMPIONARIO,
            value: false,
          },
        },
      },
      USER_READ: {
        label: i18n.userTypePermission.USER_READ,
        value: true,
        acl: {
          USER_CREATE: {
            label: i18n.userTypePermission.USER_CREATE,
            value: true,
          },
          USER_PARTIAL_UPDATE: {
            label: i18n.userTypePermission.USER_PARTIAL_UPDATE,
            value: true,
          },
          USER_UPDATE: {
            label: i18n.userTypePermission.USER_UPDATE,
            value: true,
          },
          USER_DELETE: {
            label: i18n.userTypePermission.USER_DELETE,
            value: false,
          },
        },
      },
      STOCKLIST_READ: {
        label: i18n.userTypePermission.STOCKLIST_READ,
        value: true,
        acl: {
          STOCKLIST_CREATE: {
            label: i18n.userTypePermission.STOCKLIST_CREATE,
            value: true,
          },
        },
      },
      ORDERS_READ: {
        label: i18n.userTypePermission.ORDERS_READ,
        value: false,
        acl: {
          ORDERS_FILATI: {
            label: i18nGlobal.filatiLabel,
            value: false,
          },
          ORDERS_LABDIP: {
            label: i18nGlobal.labdipLabel,
            value: false,
          },
          ORDERS_CAMPIONARIO: {
            label: i18nGlobal.campionarioLabel,
            value: false,
          },
        },
      },
      ORDERS_HISTORY_READ: {
        label: i18n.userTypePermission.ORDERS_HISTORY_READ,
        value: false,
        acl: {
          ORDERS_HISTORY_FILATI: {
            label: i18nGlobal.filatiLabel,
            value: false,
          },
          ORDERS_HISTORY_LABDIP: {
            label: i18nGlobal.labdipLabel,
            value: false,
          },
          ORDERS_HISTORY_CAMPIONARIO: {
            label: i18nGlobal.campionarioLabel,
            value: false,
          },
        },
      },
      MANUAL_NOTIFICATION: {
        label: i18n.userTypePermission.MANUAL_NOTIFICATION,
        value: false,
        disabled: true,
        acl: {
          SEND_MANUAL_NOTIFICATION: {
            label: i18n.userTypePermission.SEND_MANUAL_NOTIFICATION,
            value: false,
            disabled: true,
          },
        },
      },
      INVALIDATE_CACHE: {
        label: i18n.userTypePermission.INVALIDATE_CACHE,
        value: false,
        disabled: false,
        acl: {
          INVALIDATE_CACHE_2: {
            label: i18n.userTypePermission.INVALIDATE_CACHE_2,
            value: false,
            disabled: false,
          },
        },
      },
      BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY: {
        label: i18n.userTypePermission.BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY,
        value: false,
        acl: {
          BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2: {
            label:
              i18n.userTypePermission
                .BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2,
            value: false,
          },
        },
      },
    }, // Interno
    1: {
      WIDGET_READ: {
        label: i18n.userTypePermission.WIDGET_READ,
        value: true,
        acl: {
          WIDGET_STOCKLIST: {
            label: i18n.userTypePermission.WIDGET_STOCKLIST,
            value: true,
          },
          WIDGET_USERS: {
            label: i18n.userTypePermission.WIDGET_USERS,
            value: false,
          },
          WIDGET_ORDERS_FILATI: {
            label: i18n.userTypePermission.WIDGET_ORDERS_FILATI,
            value: true,
          },
          WIDGET_ORDERS_LABDIP: {
            label: i18n.userTypePermission.WIDGET_ORDERS_LABDIP,
            value: true,
          },
          WIDGET_ORDERS_CAMPIONARIO: {
            label: i18n.userTypePermission.WIDGET_ORDERS_CAMPIONARIO,
            value: true,
          },
        },
      },
      USER_READ: {
        label: i18n.userTypePermission.USER_READ,
        value: false,
        disabled: true,
        acl: {
          USER_CREATE: {
            label: i18n.userTypePermission.USER_CREATE,
            value: false,
            disabled: true,
          },
          USER_PARTIAL_UPDATE: {
            label: i18n.userTypePermission.USER_PARTIAL_UPDATE,
            value: false,
            disabled: true,
          },
          USER_UPDATE: {
            label: i18n.userTypePermission.USER_UPDATE,
            value: false,
            disabled: true,
          },
          USER_DELETE: {
            label: i18n.userTypePermission.USER_DELETE,
            value: false,
            disabled: true,
          },
        },
      },
      STOCKLIST_READ: {
        label: i18n.userTypePermission.STOCKLIST_READ,
        value: true,
        acl: {
          STOCKLIST_CREATE: {
            label: i18n.userTypePermission.STOCKLIST_CREATE,
            value: true,
          },
        },
      },
      ORDERS_READ: {
        label: i18n.userTypePermission.ORDERS_READ,
        value: true,
        acl: {
          ORDERS_FILATI: {
            label: i18nGlobal.filatiLabel,
            value: true,
          },
          ORDERS_LABDIP: {
            label: i18nGlobal.labdipLabel,
            value: true,
          },
          ORDERS_CAMPIONARIO: {
            label: i18nGlobal.campionarioLabel,
            value: true,
          },
        },
      },
      ORDERS_HISTORY_READ: {
        label: i18n.userTypePermission.ORDERS_HISTORY_READ,
        value: true,
        acl: {
          ORDERS_HISTORY_FILATI: {
            label: i18nGlobal.filatiLabel,
            value: true,
          },
          ORDERS_HISTORY_LABDIP: {
            label: i18nGlobal.labdipLabel,
            value: true,
          },
          ORDERS_HISTORY_CAMPIONARIO: {
            label: i18nGlobal.campionarioLabel,
            value: true,
          },
        },
      },
      MANUAL_NOTIFICATION: {
        label: i18n.userTypePermission.MANUAL_NOTIFICATION,
        value: false,
        disabled: true,
        acl: {
          SEND_MANUAL_NOTIFICATION: {
            label: i18n.userTypePermission.SEND_MANUAL_NOTIFICATION,
            value: false,
            disabled: true,
          },
        },
      },
      INVALIDATE_CACHE: {
        label: i18n.userTypePermission.INVALIDATE_CACHE,
        value: false,
        disabled: true,
        acl: {
          INVALIDATE_CACHE_2: {
            label: i18n.userTypePermission.INVALIDATE_CACHE_2,
            value: false,
            disabled: true,
          },
        },
      },
      BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY: {
        label: i18n.userTypePermission.BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY,
        value: true,
        acl: {
          BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2: {
            label:
              i18n.userTypePermission
                .BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2,
            value: true,
          },
        },
      },
    }, // Agente
    2: {
      WIDGET_READ: {
        label: i18n.userTypePermission.WIDGET_READ,
        value: true,
        acl: {
          WIDGET_STOCKLIST: {
            label: i18n.userTypePermission.WIDGET_STOCKLIST,
            value: true,
          },
          WIDGET_USERS: {
            label: i18n.userTypePermission.WIDGET_USERS,
            value: false,
          },
          WIDGET_ORDERS_FILATI: {
            label: i18n.userTypePermission.WIDGET_ORDERS_FILATI,
            value: false,
          },
          WIDGET_ORDERS_LABDIP: {
            label: i18n.userTypePermission.WIDGET_ORDERS_LABDIP,
            value: false,
          },
          WIDGET_ORDERS_CAMPIONARIO: {
            label: i18n.userTypePermission.WIDGET_ORDERS_CAMPIONARIO,
            value: false,
          },
        },
      },
      USER_READ: {
        label: i18n.userTypePermission.USER_READ,
        value: false,
        disabled: true,
        acl: {
          USER_CREATE: {
            label: i18n.userTypePermission.USER_CREATE,
            value: false,
            disabled: true,
          },
          USER_PARTIAL_UPDATE: {
            label: i18n.userTypePermission.USER_PARTIAL_UPDATE,
            value: false,
            disabled: true,
          },
          USER_UPDATE: {
            label: i18n.userTypePermission.USER_UPDATE,
            value: false,
            disabled: true,
          },
          USER_DELETE: {
            label: i18n.userTypePermission.USER_DELETE,
            value: false,
            disabled: true,
          },
        },
      },
      STOCKLIST_READ: {
        label: i18n.userTypePermission.STOCKLIST_READ,
        value: true,
        acl: {
          STOCKLIST_CREATE: {
            label: i18n.userTypePermission.STOCKLIST_CREATE,
            value: true,
          },
        },
      },
      ORDERS_READ: {
        label: i18n.userTypePermission.ORDERS_READ,
        value: true,
        acl: {
          ORDERS_FILATI: {
            label: i18nGlobal.filatiLabel,
            value: false,
          },
          ORDERS_LABDIP: {
            label: i18nGlobal.labdipLabel,
            value: false,
          },
          ORDERS_CAMPIONARIO: {
            label: i18nGlobal.campionarioLabel,
            value: false,
          },
        },
      },
      ORDERS_HISTORY_READ: {
        label: i18n.userTypePermission.ORDERS_HISTORY_READ,
        value: true,
        acl: {
          ORDERS_HISTORY_FILATI: {
            label: i18nGlobal.filatiLabel,
            value: false,
          },
          ORDERS_HISTORY_LABDIP: {
            label: i18nGlobal.labdipLabel,
            value: false,
          },
          ORDERS_HISTORY_CAMPIONARIO: {
            label: i18nGlobal.campionarioLabel,
            value: false,
          },
        },
      },
      MANUAL_NOTIFICATION: {
        label: i18n.userTypePermission.MANUAL_NOTIFICATION,
        value: false,
        disabled: true,
        acl: {
          SEND_MANUAL_NOTIFICATION: {
            label: i18n.userTypePermission.SEND_MANUAL_NOTIFICATION,
            value: false,
            disabled: true,
          },
        },
      },
      INVALIDATE_CACHE: {
        label: i18n.userTypePermission.INVALIDATE_CACHE,
        value: false,
        disabled: true,
        acl: {
          INVALIDATE_CACHE_2: {
            label: i18n.userTypePermission.INVALIDATE_CACHE_2,
            value: false,
            disabled: true,
          },
        },
      },
      BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY: {
        label: i18n.userTypePermission.BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY,
        value: false,
        acl: {
          BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2: {
            label:
              i18n.userTypePermission
                .BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2,
            value: false,
          },
        },
      },
    }, // Cliente
    3: {
      WIDGET_READ: {
        label: i18n.userTypePermission.WIDGET_READ,
        value: true,
        acl: {
          WIDGET_STOCKLIST: {
            label: i18n.userTypePermission.WIDGET_STOCKLIST,
            value: true,
          },
          WIDGET_USERS: {
            label: i18n.userTypePermission.WIDGET_USERS,
            value: true,
          },
          WIDGET_ORDERS_FILATI: {
            label: i18n.userTypePermission.WIDGET_ORDERS_FILATI,
            value: false,
          },
          WIDGET_ORDERS_LABDIP: {
            label: i18n.userTypePermission.WIDGET_ORDERS_LABDIP,
            value: false,
          },
          WIDGET_ORDERS_CAMPIONARIO: {
            label: i18n.userTypePermission.WIDGET_ORDERS_CAMPIONARIO,
            value: false,
          },
        },
      },
      USER_READ: {
        label: i18n.userTypePermission.USER_READ,
        value: true,
        acl: {
          USER_CREATE: {
            label: i18n.userTypePermission.USER_CREATE,
            value: true,
          },
          USER_PARTIAL_UPDATE: {
            label: i18n.userTypePermission.USER_PARTIAL_UPDATE,
            value: true,
          },
          USER_UPDATE: {
            label: i18n.userTypePermission.USER_UPDATE,
            value: true,
          },
          USER_DELETE: {
            label: i18n.userTypePermission.USER_DELETE,
            value: true,
          },
        },
      },
      STOCKLIST_READ: {
        label: i18n.userTypePermission.STOCKLIST_READ,
        value: true,
        acl: {
          STOCKLIST_CREATE: {
            label: i18n.userTypePermission.STOCKLIST_CREATE,
            value: true,
          },
        },
      },
      ORDERS_READ: {
        label: i18n.userTypePermission.ORDERS_READ,
        value: false,
        acl: {
          ORDERS_FILATI: {
            label: i18nGlobal.filatiLabel,
            value: false,
          },
          ORDERS_LABDIP: {
            label: i18nGlobal.labdipLabel,
            value: false,
          },
          ORDERS_CAMPIONARIO: {
            label: i18nGlobal.campionarioLabel,
            value: false,
          },
        },
      },
      ORDERS_HISTORY_READ: {
        label: i18n.userTypePermission.ORDERS_HISTORY_READ,
        value: false,
        acl: {
          ORDERS_HISTORY_FILATI: {
            label: i18nGlobal.filatiLabel,
            value: false,
          },
          ORDERS_HISTORY_LABDIP: {
            label: i18nGlobal.labdipLabel,
            value: false,
          },
          ORDERS_HISTORY_CAMPIONARIO: {
            label: i18nGlobal.campionarioLabel,
            value: false,
          },
        },
      },
      MANUAL_NOTIFICATION: {
        label: i18n.userTypePermission.MANUAL_NOTIFICATION,
        value: true,
        acl: {
          SEND_MANUAL_NOTIFICATION: {
            label: i18n.userTypePermission.SEND_MANUAL_NOTIFICATION,
            value: true,
          },
        },
      },
      INVALIDATE_CACHE: {
        label: i18n.userTypePermission.INVALIDATE_CACHE,
        value: true,
        acl: {
          INVALIDATE_CACHE_2: {
            label: i18n.userTypePermission.INVALIDATE_CACHE_2,
            value: true,
          },
        },
      },
      BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY: {
        label: i18n.userTypePermission.BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY,
        value: false,
        acl: {
          BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2: {
            label:
              i18n.userTypePermission
                .BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2,
            value: false,
          },
        },
      },
    }, // Admin
  };

  // Functions
  const getPermissionByUserType = (userType) => userTypePermission[userType];

  const getPermissionFromString = (permissionString) => {
    const noPermissionObj = {
      WIDGET_READ: {
        label: i18n.userTypePermission.WIDGET_READ,
        value: true,
        acl: {
          WIDGET_STOCKLIST: {
            label: i18n.userTypePermission.WIDGET_STOCKLIST,
            value: false,
          },
          WIDGET_USERS: {
            label: i18n.userTypePermission.WIDGET_USERS,
            value: false,
          },
          WIDGET_ORDERS_FILATI: {
            label: i18n.userTypePermission.WIDGET_ORDERS_FILATI,
            value: false,
          },
          WIDGET_ORDERS_LABDIP: {
            label: i18n.userTypePermission.WIDGET_ORDERS_LABDIP,
            value: false,
          },
          WIDGET_ORDERS_CAMPIONARIO: {
            label: i18n.userTypePermission.WIDGET_ORDERS_CAMPIONARIO,
            value: false,
          },
        },
      },
      USER_READ: {
        label: i18n.userTypePermission.USER_READ,
        value: false,
        acl: {
          USER_CREATE: {
            label: i18n.userTypePermission.USER_CREATE,
            value: false,
          },
          USER_PARTIAL_UPDATE: {
            label: i18n.userTypePermission.USER_PARTIAL_UPDATE,
            value: false,
          },
          USER_UPDATE: {
            label: i18n.userTypePermission.USER_UPDATE,
            value: false,
          },
          USER_DELETE: {
            label: i18n.userTypePermission.USER_DELETE,
            value: false,
          },
        },
      },
      STOCKLIST_READ: {
        label: i18n.userTypePermission.STOCKLIST_READ,
        value: false,
        acl: {
          STOCKLIST_CREATE: {
            label: i18n.userTypePermission.STOCKLIST_CREATE,
            value: false,
          },
        },
      },
      ORDERS_READ: {
        label: i18n.userTypePermission.ORDERS_READ,
        value: false,
        acl: {
          ORDERS_FILATI: {
            label: i18nGlobal.filatiLabel,
            value: false,
          },
          ORDERS_LABDIP: {
            label: i18nGlobal.labdipLabel,
            value: false,
          },
          ORDERS_CAMPIONARIO: {
            label: i18nGlobal.campionarioLabel,
            value: false,
          },
        },
      },
      ORDERS_HISTORY_READ: {
        label: i18n.userTypePermission.ORDERS_HISTORY_READ,
        value: false,
        acl: {
          ORDERS_HISTORY_FILATI: {
            label: i18nGlobal.filatiLabel,
            value: false,
          },
          ORDERS_HISTORY_LABDIP: {
            label: i18nGlobal.labdipLabel,
            value: false,
          },
          ORDERS_HISTORY_CAMPIONARIO: {
            label: i18nGlobal.campionarioLabel,
            value: false,
          },
        },
      },
      MANUAL_NOTIFICATION: {
        label: i18n.userTypePermission.MANUAL_NOTIFICATION,
        value: false,
        acl: {
          SEND_MANUAL_NOTIFICATION: {
            label: i18n.userTypePermission.SEND_MANUAL_NOTIFICATION,
            value: false,
          },
        },
      },
      INVALIDATE_CACHE: {
        label: i18n.userTypePermission.INVALIDATE_CACHE,
        value: false,
        acl: {
          INVALIDATE_CACHE_2: {
            label: i18n.userTypePermission.INVALIDATE_CACHE_2,
            value: false,
          },
        },
      },
      BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY: {
        label: i18n.userTypePermission.BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY,
        value: false,
        acl: {
          BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2: {
            label:
              i18n.userTypePermission
                .BUSINESS_TERMS_ORDERS_AND_ORDERS_HISTORY_2,
            value: false,
          },
        },
      },
    };

    const permissionArray = permissionString.split('|');

    const permissionKeyArray = [];

    Object.entries(permissions).map(([pKey, pValue]) => {
      if (permissionArray.includes(pValue.toString())) {
        permissionKeyArray.push(pKey);
      }
    });

    const nextPermissionObj = { ...noPermissionObj };

    Object.entries(noPermissionObj).map(([parentKey, parentValue]) => {
      const childAcl = parentValue.acl;
      if (permissionKeyArray.includes(parentKey)) {
        nextPermissionObj[parentKey].value = true;
      }
      Object.entries(childAcl).map(([childKey]) => {
        if (permissionKeyArray.includes(childKey)) {
          nextPermissionObj[parentKey].acl[childKey].value = true;
        }
      });
    });
    return nextPermissionObj;
  };

  const getStringFromPermissionObj = (permissionObj) => {
    const permissionArray = [];
    Object.entries(permissionObj).map(([parentKey, parentValue]) => {
      const childAcl = parentValue.acl;
      if (parentValue.value) {
        permissionArray.push(permissions[parentKey]);
      }

      Object.entries(childAcl).map(([childKey, childValue]) => {
        if (childValue.value) {
          permissionArray.push(permissions[childKey]);
        }
      });
    });
    return permissionArray.join('|');
  };

  return {
    getPermissionByUserType,
    getPermissionFromString,
    getStringFromPermissionObj,
  };
};

export { usePermissions };
