import translation from 'utils/translation';
import { useCustomIntl } from './useCustomIntl';
import { permissions } from 'shared';

const usePreferences = () => {
  // Hooks
  const intl = useCustomIntl();
  const i18n = translation.components(intl).usePermissions;
  const i18nOrders = translation.global(intl).app.orders;

  const PREFERENCES = {
    widget: [
      {
        id: permissions.WIDGET_STOCKLIST,
        name: 'widgetStocklist',
        label: i18n.userTypePermission.WIDGET_STOCKLIST,
      },
      {
        id: permissions.WIDGET_USERS,
        name: 'widgetUsers',
        label: i18n.userTypePermission.WIDGET_USERS,
      },
      {
        id: permissions.WIDGET_ORDERS_FILATI,
        name: 'widgetOrdersFilati',
        label: i18n.userTypePermission.WIDGET_ORDERS_FILATI,
      },
      {
        id: permissions.WIDGET_ORDERS_LABDIP,
        name: 'widgetOrdersLabdip',
        label: i18n.userTypePermission.WIDGET_ORDERS_LABDIP,
      },
      {
        id: permissions.WIDGET_ORDERS_CAMPIONARIO,
        name: 'widgetOrdersCampionario',
        label: i18n.userTypePermission.WIDGET_ORDERS_CAMPIONARIO,
      },
    ],
    orders: [
      {
        id: permissions.ORDERS_FILATI,
        name: 'ordersYarn',
        label: i18nOrders.filatiLabel,
      },
      {
        id: permissions.ORDERS_LABDIP,
        name: 'ordersLabdip',
        label: i18nOrders.labdipLabel,
      },
      {
        id: permissions.ORDERS_CAMPIONARIO,
        name: 'ordersSample',
        label: i18nOrders.campionarioLabel,
      },
    ],
    ordersHistory: [
      {
        id: permissions.ORDERS_HISTORY_FILATI,
        name: 'ordersHistoryYarn',
        label: i18nOrders.filatiLabel,
      },
      {
        id: permissions.ORDERS_HISTORY_LABDIP,
        name: 'ordersHistoryLabdip',
        label: i18nOrders.labdipLabel,
      },
      {
        id: permissions.ORDERS_HISTORY_CAMPIONARIO,
        name: 'ordersHistorySample',
        label: i18nOrders.campionarioLabel,
      },
    ],
  };

  return { PREFERENCES };
};

export { usePreferences };
