import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useMediaQuery } from '@mui/material';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { CRGButton, CRGText } from 'components/atoms';

const ChangeTempPasswordCompleteContaienrStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 360px) {
    margin-bottom: 20px;
  }
`;

const TextContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 120px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-bottom: 20px;
  }
`;

const RedBulletStyle = styled.img`
  width: 90px;
  height: 90px;
  margin-top: 50px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 60px;
    height: 60px;
    margin-top: 0px;
  }
`;

const ChangeTempPasswordComplete = ({ onClose }) => {
  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).changeTempPasswordComplete;

  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <ChangeTempPasswordCompleteContaienrStyle>
      <RedBulletStyle src={'images/red_bullet.svg'} />
      <TextContainerStyle>
        <CRGText
          fontSize={isMobile ? 20 : 30}
          fontWeight={700}
          textAlign={'center'}
        >
          {i18n.text1}
        </CRGText>
      </TextContainerStyle>

      <CRGButton onClick={onClose}>{i18n.buttonLabel}</CRGButton>
    </ChangeTempPasswordCompleteContaienrStyle>
  );
};

ChangeTempPasswordComplete.propTypes = {
  onClose: PropTypes.func,
};

export { ChangeTempPasswordComplete };
