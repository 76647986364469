import styled from 'styled-components';

const ContainerStyle = styled.div`
  background-color: ${(props) => props.theme.palette.neutral.light.red};
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: invert(1);
`;

const Main = () => {
  return (
    <ContainerStyle>
      <img src={'images/logo.svg'} />
    </ContainerStyle>
  );
};

export { Main };
