import { useContext, useEffect } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';

import { useForm } from 'react-hook-form';
import {
  CRGButton,
  CRGTextFieldControlled,
  CRGSelectControlled,
  CRGText,
  CRGAutocompleteControlled,
  CRGTextField,
  CRGDateTimePickerControlled,
  CRGMultiselectControlled,
} from 'components/atoms';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { UserContext } from 'contexts';

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: space-between;
  }
`;

const TopElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  gap: 10px;
  width: 85%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }
`;

const TitleContainer = styled.div`
  width: 70vw;
  margin-bottom: 10px;
`;

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  height: 100%;
`;

const defaultValus = {
  description: '',
  requestDate: null,
  status: '',
  email: '',
  article: '',
  format: '',
  customerId: null,
  articleId: null,
};

const StocklistMobileFiltersDrawerContent = ({
  onClose,
  onSearch,
  setLastFullSearch,
  lastFullSearch,
  articles,
  status,
  customers,
}) => {
  // Hooks
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: defaultValus,
  });

  // Media Query
  const isSmallDevice = useMediaQuery('(max-width:360px)');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).stocklistMobileFiltersDrawerContent;

  // Context
  const { user } = useContext(UserContext);

  // Effect
  useEffect(() => {
    if (lastFullSearch) reset(lastFullSearch);
  }, [lastFullSearch]);

  // Functions
  const clearFilters = () => {
    reset(defaultValus);
    handleFilterChange(defaultValus);
  };

  const handleFilterChange = (data) => {
    setLastFullSearch(data);
    onSearch?.(data);
    onClose?.();
  };

  return (
    <ContainerStyle>
      <FormStyle onSubmit={handleSubmit(handleFilterChange)}>
        <TopContainer>
          <TopElementContainer>
            <TitleContainer>
              <CRGText
                colorVariant={'neutralDarkBlack'}
                fontSize={20}
                fontWeight={600}
              >
                {i18n.title}
              </CRGText>
            </TitleContainer>

            {/* Nome Richiesta */}
            <CRGTextFieldControlled
              label={i18n.requestNameLabel}
              name="description"
              control={control}
            />

            {/* Data Ora */}
            <CRGDateTimePickerControlled
              label={i18n.dataLabel}
              name="requestDate"
              control={control}
            />

            {/* Status */}
            <CRGMultiselectControlled
              name="status"
              label={i18n.statusLabel}
              control={control}
              setValue={setValue}
              options={status}
              customWidth={'80vw'}
            />

            {/* Email */}
            <CRGTextFieldControlled
              label={i18n.emailLabel}
              name="email"
              control={control}
            />

            {/* Articolo */}
            <CRGAutocompleteControlled
              name="articleId"
              control={control}
              options={articles}
              getOptionLabel={(option) => option.searchField}
              renderInput={(params) => (
                <CRGTextField
                  {...params}
                  label={i18n.articleLabel}
                  customWidth={'100%'}
                />
              )}
            />

            {/* Formato */}
            <CRGSelectControlled
              name="format"
              label={i18n.typeLabel}
              control={control}
              options={[
                {
                  value: 0,
                  label: 'Formato 0',
                },
                {
                  value: 1,
                  label: 'Formato 1',
                },
                {
                  value: 2,
                  label: 'Formato 2',
                },
                {
                  value: 3,
                  label: 'Formato 3',
                },
              ]}
            />

            {/* Clienti */}
            {user?.cariaggiUser?.type === 2 ? null : (
              <CRGAutocompleteControlled
                name="customerId"
                control={control}
                options={customers}
                getOptionLabel={(option) => option.searchField}
                renderInput={(params) => (
                  <CRGTextField
                    {...params}
                    label={i18n.customerLabel}
                    customWidth={'100%'}
                  />
                )}
              />
            )}

            <CRGButton
              type="submit"
              customWidth={isSmallDevice ? '100%' : '170px'}
              colorVariant={'mainSecondary'}
              onClick={clearFilters}
            >
              {i18n.clearFiltersButtonLabel}
            </CRGButton>
          </TopElementContainer>
          <ButtonContainer>
            <CRGButton
              customWidth={isMobile ? '45%' : '170px'}
              onClick={onClose}
            >
              {i18n.buttonCancelLabel}
            </CRGButton>
            <CRGButton
              type="submit"
              customWidth={isMobile ? '45%' : '170px'}
              colorVariant={'mainSecondary'}
            >
              {i18n.buttonSubmitLabel}
            </CRGButton>
          </ButtonContainer>
        </TopContainer>
      </FormStyle>
    </ContainerStyle>
  );
};

StocklistMobileFiltersDrawerContent.propTypes = {
  onSearch: PropTypes.func,
  onClose: PropTypes.func,
  setLastFullSearch: PropTypes.func,
  lastFullSearch: PropTypes.object,
  articles: PropTypes.array,
  status: PropTypes.array,
  customers: PropTypes.array,
};

export { StocklistMobileFiltersDrawerContent };
