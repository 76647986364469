import { useState, useEffect, useContext, useRef } from 'react';

import { useMediaQuery } from '@mui/material';

import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { CRGMainLayout } from 'components/layouts';
import { useStocklist, useStatus } from 'shared';
import { StockDetailDashboard } from 'components/bundle';
import { LangContext } from 'contexts';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import {
  CRGButton,
  CRGInfoButton,
  CRGPopper,
  CRGTable,
  CRGText,
  CRGTextField,
} from 'components/atoms';
import { useInfoDocument } from 'hooks';

const ConatinerStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-top: 30px;
  padding-left: 50px;
  gap: 10px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 20px;
    padding-left: 20px;
    position: relative;
  }
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  width: fit-content;
`;

const ArrowStyle = styled.span`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid ${(props) => props.theme.palette.main.secondary};
  position: absolute;
  bottom: -10px !important;
  right: 0%;
`;

const PopperContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PopperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 30px;
  padding-top: 10px;
`;

const CloseContainer = styled.div`
  cursor: pointer;
`;

const PopperTextContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  height: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ArticleNode = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.down('sm')} {
    align-items: flex-end;
  }
`;

const ColorNode = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.down('sm')} {
    align-items: flex-end;
  }
`;

const NoteNode = styled.div`
  display: flex;
  justify-content: center;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: flex-end;
  }
`;

const PackagingNode = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: flex-end;
  }
`;
const CustomActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    gap: 10px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const defaultFloat = parseFloat(0).toFixed(2);

const STOCKLIST_DETAIL_TABLE_HEADER = (i18n) => [
  { label: i18n.headers.article, key: 'articleNode' },
  { label: i18n.headers.color, key: 'colorNode' },
  { label: i18n.headers.batch, key: 'Partita' },
  { label: i18n.headers.bagno, key: 'Bagno' },
  { label: i18n.headers.packaging, key: 'packagingNode' },
  { label: i18n.headers.availible, key: 'Disponibile' },
  { label: i18n.headers.futureAvailable, key: 'DisponibileFuturo' },
  { label: i18n.headers.firstQntAvailable, key: 'PrimaQtaDisponibile' },
  { label: i18n.headers.firstDateAvailable, key: 'firstDateAvailable' },
  { label: i18n.headers.notes, key: 'noteNode' },
];

const StockDetail = () => {
  // Hooks
  const { id } = useParams();
  const stockHelper = useStocklist();
  const statusHelper = useStatus();
  const { STOCKLIST_PDF_URL } = useInfoDocument();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).stockDetail;

  // Context
  const { lang } = useContext(LangContext);

  // Media Query
  const is1600down = useMediaQuery('(max-width:1600px)');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // State
  const [stock, setStock] = useState();
  const [openPopperId, setOpenPopperId] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [arrowRef, setArrowRef] = useState(null);
  const [currentNotes, setCurrentPopperNotes] = useState();
  const [stockStatusList, setStockStatusList] = useState();
  const [tableHeader, setTableHeader] = useState(
    STOCKLIST_DETAIL_TABLE_HEADER(i18n)
  );
  const [searchText, setSearchtext] = useState();
  const [displayItems, setDisplayItems] = useState([]);

  // Effect
  useEffect(() => {
    if (id) {
      fetchStock();
    }
  }, [id]);

  useEffect(() => {
    if (stock && lang && !stockStatusList) fetchStatus();
  }, [stock, stockStatusList, lang]);

  // Functions
  const fetchStock = async () => {
    stockHelper
      .get({ id })
      .then((res) => {
        // serve per nascondere le colonne dalla tabella
        const keyToShow = [
          {
            key: 'articleNode',
            toShow: !!res.items.find((i) => i?.DesArticolo?.length),
          },
          {
            key: 'noteNode',
            toShow: !!res.items.find((i) => i?.Note?.length),
          },
          {
            key: 'colorNode',
            toShow: !!res.items.find((i) => i?.Colore?.length),
          },
          {
            key: 'Partita',
            toShow: !!res.items.find((i) => i?.Partita?.length),
          },
          {
            key: 'Bagno',
            toShow: !!res.items.find((i) => i?.Bagno?.length),
          },
          {
            key: 'packagingNode',
            toShow: !!res.items.find(
              (i) => i?.Confezione?.length || i?.DesConfezione?.length
            ),
          },
          {
            key: 'Disponibile',
            toShow: !!res.items.find((i) => i?.Disponibile),
          },
          {
            key: 'DisponibileFuturo',
            toShow: !!res.items.find((i) => i?.DisponibileFuturo),
          },
          {
            key: 'PrimaQtaDisponibile',
            toShow: !!res.items.find((i) => i?.PrimaQtaDisponibile),
          },
          {
            key: 'firstDateAvailable',
            toShow: !!res.items.find((i) => i?.PrimaDataDisponibile),
          },
        ].filter((i) => !i.toShow);

        // tolgo le colonne che non hanno valori associati
        const nextTableHeader = tableHeader.filter(
          (i) => !keyToShow.map((elem) => elem.key).includes(i.key)
        );

        setTableHeader(nextTableHeader);

        const formattedStockDetail = {
          ...res,
          items: res.items.map((i, idx) => {
            const formattedItem = {
              ...i,
              noteNode: (
                <NoteNode>
                  <CRGButton
                    isSmall
                    colorVariant="mainSecondary"
                    onClick={(event) => handleClick(event, idx, i.Note)}
                    disabled={i.Note?.length < 1}
                  >
                    <img
                      width={'12px'}
                      height={'12px'}
                      src={'/images/note.svg'}
                    />
                  </CRGButton>
                </NoteNode>
              ),
              articleNode: (
                <ArticleNode>
                  <CRGText
                    fontSize={14}
                    fontWeight={400}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {i.DesArticolo}
                  </CRGText>
                  <CRGText
                    fontSize={10}
                    fontWeight={400}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {i.Articolo}
                  </CRGText>
                </ArticleNode>
              ),
              colorNode: (
                <ColorNode>
                  <CRGText
                    fontSize={14}
                    fontWeight={600}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {i.Colore}
                  </CRGText>
                </ColorNode>
              ),
              packagingNode: (
                <PackagingNode>
                  <CRGText
                    fontSize={14}
                    fontWeight={400}
                    colorVariant={'neutralDarkBlack'}
                    textAlign="center"
                  >
                    {`${i.Confezione} ${i.DesConfezione}`}
                  </CRGText>
                </PackagingNode>
              ),
              Disponibile: (
                <div style={{ textAlign: 'right' }}>
                  <CRGText
                    fontSize={14}
                    fontWeight={i?.Disponibile ? 600 : 400}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {i?.Disponibile
                      ? parseFloat(i?.Disponibile).toFixed(2)
                      : defaultFloat}
                  </CRGText>
                </div>
              ),
              firstDateAvailable: (
                <CRGText
                  fontSize={14}
                  fontWeight={i?.PrimaDataDisponibile?.Value ? 600 : 400}
                  colorVariant={'neutralDarkBlack'}
                >
                  {i?.PrimaDataDisponibile?.Value || '-'}
                </CRGText>
              ),
              DisponibileFuturo: (
                <div style={{ textAlign: 'right' }}>
                  <CRGText
                    fontSize={14}
                    fontWeight={i?.DisponibileFuturo ? 600 : 400}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {i?.DisponibileFuturo
                      ? parseFloat(i?.DisponibileFuturo).toFixed(2)
                      : defaultFloat}
                  </CRGText>
                </div>
              ),
              PrimaQtaDisponibile: (
                <div style={{ textAlign: 'right' }}>
                  <CRGText
                    fontSize={14}
                    fontWeight={i?.PrimaQtaDisponibile ? 600 : 400}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {i?.PrimaQtaDisponibile
                      ? parseFloat(i?.PrimaQtaDisponibile).toFixed(2)
                      : defaultFloat}
                  </CRGText>
                </div>
              ),
            };
            return formattedItem;
          }),
        };
        setStock(formattedStockDetail);
        setDisplayItems(formattedStockDetail.items);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchStatus = async () => {
    statusHelper
      .get(lang)
      .then((res) => {
        const nextStatus = res?.find(
          (ss) => ss.status === stock?.status
        )?.description;

        setStockStatusList(res);
        const nextStock = {
          ...stock,
          status: nextStatus,
        };
        setStock(nextStock);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleClick = (event, idx, notes) => {
    const isOpen = openPopperId !== idx;
    setAnchorEl(isOpen ? event?.currentTarget : null);
    setOpenPopperId(isOpen);
    setCurrentPopperNotes(notes);
  };

  const handleExport = async () => {
    window.open(stock?.pdfUrl, '_blank');
  };

  const handleSearchText = (e) => {
    setSearchtext(e.target.value);
  };

  const handleSearch = () => {
    let nextDisplayItems = [];
    if (searchText?.length) {
      stock?.items?.forEach((i) => {
        const stringItem = JSON.stringify(i);
        if (
          stringItem
            .toLocaleLowerCase()
            .indexOf(searchText.toLocaleLowerCase()) !== -1
        ) {
          nextDisplayItems.push(i);
        }
      });
    } else {
      nextDisplayItems = stock?.items || [];
    }
    setDisplayItems(nextDisplayItems);
  };

  const tableRef = useRef();

  return (
    <CRGMainLayout>
      <ConatinerStyle>
        <InfoContainer style={{ width: tableRef?.current?.clientWidth }}>
          <CRGText
            fontSize={23}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.title}
          </CRGText>
          <CRGInfoButton infoUrl={STOCKLIST_PDF_URL} />
        </InfoContainer>
        <StockDetailDashboard
          data={stock}
          refWidth={tableRef?.current?.clientWidth}
        />
        <TableContainer ref={tableRef}>
          <CRGTable
            data={displayItems}
            headers={tableHeader}
            title={i18n.stockDetailTableTitle}
            maxColumnSize={isMobile ? '200px' : is1600down ? '5.8vw' : '6.8vw'}
            customActions={
              <CustomActionContainer>
                <CRGButton
                  colorVariant={'mainSecondary'}
                  onClick={handleExport}
                  disabled={!stock?.pdfUrl}
                  customWidth={120}
                >
                  {i18n.exportButton}
                </CRGButton>
                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                  <CRGTextField
                    label={`${i18n.searchBarLabel}...`}
                    onChange={handleSearchText}
                  />
                  <CRGButton customWidth="100px" onClick={handleSearch}>
                    {i18n.searchBarLabel}
                  </CRGButton>
                </div>
              </CustomActionContainer>
            }
          />
        </TableContainer>
      </ConatinerStyle>
      <CRGPopper
        id={`popper`}
        open={openPopperId}
        disablePortal={false}
        anchorEl={anchorEl}
        style={{ zIndex: 10, width: 200, height: 150 }}
        placement="top-end"
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window',
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <PopperContainer>
          <PopperHeader>
            <CRGText
              fontSize={12}
              fontWeight={600}
              colorVariant={'neutralDarkBlack'}
            >
              {i18n.popperNotesLabel}
            </CRGText>
            <CloseContainer onClick={() => setOpenPopperId(null)}>
              <img src={'/images/black_close_icon.svg'} />
            </CloseContainer>
          </PopperHeader>
          <PopperTextContainer>
            {currentNotes?.map((n, idx) => (
              <CRGText
                fontSize={12}
                fontWeight={400}
                colorVariant={'neutralDarkBlack'}
                key={idx}
              >
                {n.nota}
              </CRGText>
            ))}
          </PopperTextContainer>
          <ArrowStyle ref={setArrowRef} />
        </PopperContainer>
      </CRGPopper>
    </CRGMainLayout>
  );
};

export { StockDetail };
