import { useState, useRef, useContext, useEffect } from 'react';

import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import { POLICY_BASE_URL } from 'utils/constants';

import { CRGButton } from 'components/atoms/CRGButton';

import {
  CRGTextFieldControlled,
  CRGSelectControlled,
  CRGCheckboxControlled,
  CRGText,
  CRGTextField,
  CRGAutocompleteControlled,
} from 'components/atoms';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { useMapping } from 'hooks';
import { LangContext } from 'contexts';
import { useCountries, RECAPTCHA_KEY } from 'shared';

const FormRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormRowSplit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${(props) => props.theme.breakpoints.up('sm')} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const PrivacyPolicyText = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const SignInForm = ({ onSubmit }) => {
  // Hooks
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      name: '',
      surname: '',
      type: '',
      username: '',
      companyName: '',
      email: '',
      confirmEmail: '',
      language: '',
      country: null,
      notes: '',
      privacyPolicy: '',
    },
    reValidateMode: 'all',
  });
  const { userTypeList, userLanguageList } = useMapping();
  const countriesHelper = useCountries();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).signInForm;

  // State
  const [reCaptchaToken, setReCaptchaToken] = useState();
  const [countries, setCountries] = useState([]);

  // Contexts
  const { lang } = useContext(LangContext);

  // Refs
  const _reCaptchaRef = useRef();

  // Effects
  useEffect(() => {
    if (lang) fetchCountries();
    console.log('lang', lang);
  }, [lang]);

  // Functions
  const submitForm = async (data) => {
    const { email, confirmEmail } = data;
    if (email === confirmEmail) {
      const nextData = {
        ...data,
        country: data?.country?.value,
      };
      onSubmit?.(nextData);
    } else {
      setError('confirmEmail', {
        type: 'manual',
        message: { mailNotMatch: i18n.errors.confirmEmail.notMatch },
      });
    }
  };

  const asyncScriptOnLoad = () => {
    _reCaptchaRef.current.reset();
  };

  const onCaptchaChange = (e) => {
    setReCaptchaToken(e);
  };

  const fetchCountries = () => {
    countriesHelper
      .get(lang)
      .then((res) => {
        const nextCountries = Object.entries(res).map(([key, value]) => {
          return {
            value: key,
            label: value,
          };
        });
        setCountries(nextCountries);
      })
      .catch((e) => console.error(e));
  };

  const openPrivacyPolicy = () => {
    let uri = '';
    switch (lang) {
      case 'it-IT':
        uri = `${POLICY_BASE_URL}-it`;
        break;
      default:
        uri = `${POLICY_BASE_URL}-en`;
    }
    window.open(uri, '_blank');
  };

  return (
    <FormStyle onSubmit={handleSubmit(submitForm)}>
      {/* 
          Name Text Field
        */}
      <FormRowContainer>
        <CRGTextFieldControlled
          name="name"
          label={i18n.name}
          control={control}
          rules={{ required: { value: true, message: i18n.errors.name } }}
          errors={errors?.['name']}
        />
      </FormRowContainer>
      {/* 
          Surname Text Field
        */}
      <FormRowContainer>
        <CRGTextFieldControlled
          name="surname"
          label={i18n.surname}
          control={control}
          rules={{ required: { value: true, message: i18n.errors.surname } }}
          errors={errors?.['surname']}
        />
      </FormRowContainer>
      {/* 
          Customer Type Text Field
        */}
      <FormRowContainer>
        <CRGSelectControlled
          name="type"
          label={i18n.type}
          control={control}
          rules={{
            required: { value: true, message: i18n.errors.type },
          }}
          errors={errors?.['type']}
          options={[
            /* {
              value: 0,
              label: userTypeList[0],
            }, */
            {
              value: 1,
              label: userTypeList[1],
            },
            {
              value: 2,
              label: userTypeList[2],
            },
            /*{
              value: 3,
              label: userTypeList[3],
            }, */
          ]}
        />
      </FormRowContainer>

      <FormRowSplit>
        {/* 
          Username Text Field
        */}
        <FormRowContainer>
          <CRGTextFieldControlled
            name="username"
            label={i18n.username}
            control={control}
            rules={{ required: { value: true, message: i18n.errors.username } }}
            errors={errors?.['username']}
          />
        </FormRowContainer>

        {/* 
          Company Text Field
        */}
        <FormRowContainer>
          <CRGTextFieldControlled
            name="company"
            label={i18n.company}
            control={control}
            rules={{
              required: {
                value: getValues('type').toString() !== '0',
                message: i18n.errors.company,
              },
            }}
            errors={errors?.['company']}
          />
        </FormRowContainer>
      </FormRowSplit>
      <FormRowSplit>
        {/* 
          Email Text Field
        */}
        <FormRowContainer>
          <CRGTextFieldControlled
            name="email"
            label={i18n.email}
            control={control}
            rules={{
              required: { value: true, message: i18n.errors.email },
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: i18n.errors.mailFormat,
              },
            }}
            errors={errors?.['email']}
          />
        </FormRowContainer>

        {/* 
          Email Text Field
        */}
        <FormRowContainer>
          <CRGTextFieldControlled
            name="confirmEmail"
            label={i18n.confirmEmail}
            control={control}
            rules={{
              required: { value: true, message: i18n.errors.confirmEmail },
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: i18n.errors.mailFormat,
              },
            }}
            errors={errors?.['confirmEmail']}
          />
        </FormRowContainer>
      </FormRowSplit>
      <FormRowSplit>
        {/* 
          Language Text Field
        */}
        <FormRowContainer>
          <CRGSelectControlled
            name="language"
            label={i18n.language}
            control={control}
            rules={{
              required: { value: true, message: i18n.errors.language },
            }}
            errors={errors?.['language']}
            options={[
              {
                value: 0,
                label: userLanguageList[0],
              },
              {
                value: 1,
                label: userLanguageList[1],
              },
            ]}
          />
        </FormRowContainer>

        {/* 
          Country Text Field
        */}
        <FormRowContainer>
          {/* <CRGSelectControlled
            name="country"
            label={i18n.country}
            control={control}
            rules={{
              required: { value: true, message: i18n.errors.country },
            }}
            errors={errors?.['country']}
            options={countries}
          /> */}

          <CRGAutocompleteControlled
            name="country"
            control={control}
            options={countries}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <CRGTextField
                {...params}
                label={i18n.country}
                customWidth={'100%'}
                error={errors?.['country']}
              />
            )}
            rules={{
              required: { value: true, message: i18n.errors.country },
            }}
            errors={errors?.['country']}
          />
        </FormRowContainer>
      </FormRowSplit>
      {/* 
          Note Text Field
        */}
      <FormRowContainer>
        <CRGTextFieldControlled
          name="note"
          label={i18n.note}
          control={control}
          rules={{ required: { value: false } }}
          errors={errors?.['note']}
          multiline
        />
      </FormRowContainer>

      <ReCAPTCHA
        style={{ display: 'inline-block' }}
        ref={_reCaptchaRef}
        sitekey={RECAPTCHA_KEY}
        asyncScriptOnLoad={asyncScriptOnLoad}
        onChange={onCaptchaChange}
        hl={lang === 'it-IT' ? 'it' : 'en-GB'}
      />

      {/* 
          Note Text Field
        */}
      <FormRowContainer>
        <CRGCheckboxControlled
          label={
            <>
              <CRGText
                colorVariant="neutralDarkBlack"
                fontSize={14}
                fontWeight={500}
              >
                {i18n.privacyPolicyPre}{' '}
                <PrivacyPolicyText onClick={openPrivacyPolicy}>
                  {i18n.privacyPolicy}
                </PrivacyPolicyText>
              </CRGText>
            </>
          }
          name="privacyPolicy"
          control={control}
          rules={{
            required: { value: true, message: i18n.errors.privacyPolicy },
          }}
          errors={errors?.['privacyPolicy']}
        />
      </FormRowContainer>

      <CRGButton
        type="submit"
        colorVariant={'mainSecondary'}
        disabled={!reCaptchaToken}
      >
        {i18n.button}
      </CRGButton>
    </FormStyle>
  );
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func,
};

export { SignInForm };
