import styled from 'styled-components';

import { Badge } from '@mui/material';

const CRGBadgeStyled = styled(Badge)``;

const CRGBadge = ({ ...rest }) => {
  return <CRGBadgeStyled {...rest} />;
};

CRGBadge.propTypes = {};

export { CRGBadge };
