import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { CRGButton, CRGMultiselectControlled } from 'components/atoms';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { useMapping } from 'hooks';

const FilterContainer = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FormStyle = styled.form`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  @media screen and (max-width: 1600px) {
    flex-wrap: wrap;
  }
`;

const defaultValues = {
  type: '',
};

const NotificationListFilters = ({ handleFilterChange }) => {
  // Hooks
  const { handleSubmit, control, /* reset, */ setValue } = useForm({
    defaultValues: defaultValues,
  });
  const { notificationType } = useMapping();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).manualNotificationListFilters;

  // Functions
  /* const clearFilters = () => {
    reset(defaultValues);
    handleFilterChange(defaultValues);
  }; */

  return (
    <FilterContainer>
      <FormStyle onSubmit={handleSubmit(handleFilterChange)}>
        {/* Nome Richiesta */}
        <CRGMultiselectControlled
          name="type"
          label={i18n.typeLabel}
          control={control}
          customWidth={'20vw'}
          setValue={setValue}
          options={[
            {
              value: 0,
              label: notificationType?.[0],
            },
            {
              value: 1,
              label: notificationType?.[1],
            },
            {
              value: 2,
              label: notificationType?.[2],
            },
          ]}
        />

        <ButtonContainer>
          <CRGButton
            type="submit"
            colorVariant={'mainPrimary'}
            customWidth={'150px'}
          >
            {i18n.buttonSubmitLabel}
          </CRGButton>
          {/* <CRGButton
            colorVariant={'mainSecondary'}
            customWidth={'170px'}
            onClick={clearFilters}
          >
            {i18n.buttonClearFilters}
          </CRGButton> */}
        </ButtonContainer>
      </FormStyle>
    </FilterContainer>
  );
};

NotificationListFilters.propTypes = {
  handleFilterChange: PropTypes.func,
};

export { NotificationListFilters };
