import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CRGText } from './CRGText';
import { useMediaQuery } from '@mui/material';

const Container = styled.div`
  padding: 15px;
  border-radius: 10px;
  background: ${(props) => {
    switch (props.colorVariant) {
      case 'stocklist':
        return '#EBE5E0';
      // return `${props.theme.palette.backgrounds.widgetStocklist}70`;
      case 'users':
        return '#ECCFC9';
      // return `${props.theme.palette.backgrounds.widgetUser}80`;
      case 'orders':
        return '#E1CEE1';
      default:
        return props.theme.palette.main.secondary;
    }
  }};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MainContainer = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
`;

const MainCell = styled.div`
  width: 33%;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    align-items: flex-start;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: row;
  }
`;

const TextCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.down('sm')} {
    align-items: flex-start;
    width: 200px;
  }
`;

/* const CloseIcon = styled.img`
  cursor: pointer;
`; */

const IconContainer = styled.div`
  margin-bottom: 50px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-bottom: 0px;
    margin-right: 30px;
  }
`;

/* onClickClose, */
const CRGWidget = ({ data, colorVariant, viewAll }) => {
  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Container colorVariant={colorVariant}>
      <Header>
        <CRGText
          colorVariant={'neutralDarkBlack'}
          fontSize={18}
          fontWeight={600}
        >
          {data?.title}
        </CRGText>
        {/* <CloseIcon
          onClick={onClickClose}
          src={'/images/black_close_icon.svg'}
        /> */}
      </Header>

      <MainContainer>
        <MainCell>
          <CellContainer>
            <IconContainer>{data?.cell1?.icon}</IconContainer>
            <TextCellContainer>
              <CRGText
                colorVariant="neutralDarkBlack"
                fontSize={isMobile ? 14 : 23}
                fontWeight={500}
              >
                {data?.cell1?.label}
              </CRGText>
              <CRGText
                colorVariant="neutralDarkBlack"
                fontSize={isMobile ? 35 : 50}
                fontWeight={700}
              >
                {data?.cell1Data}
              </CRGText>
            </TextCellContainer>
          </CellContainer>
        </MainCell>
        <MainCell>
          <CellContainer>
            <IconContainer>{data?.cell2?.icon}</IconContainer>
            <TextCellContainer>
              <CRGText
                colorVariant="neutralDarkBlack"
                fontSize={isMobile ? 14 : 23}
                fontWeight={500}
              >
                {data?.cell2?.label}
              </CRGText>
              <CRGText
                colorVariant="neutralDarkBlack"
                fontSize={isMobile ? 35 : 50}
                fontWeight={700}
              >
                {data?.cell2Data}
              </CRGText>
            </TextCellContainer>
          </CellContainer>
        </MainCell>
        <MainCell>
          <CellContainer>
            <IconContainer>{data?.cell3?.icon}</IconContainer>
            <TextCellContainer>
              <CRGText
                colorVariant="neutralDarkBlack"
                fontSize={isMobile ? 14 : 23}
                fontWeight={500}
              >
                {data?.cell3?.label}
              </CRGText>
              <CRGText
                colorVariant="neutralDarkBlack"
                fontSize={isMobile ? 35 : 50}
                fontWeight={700}
              >
                {data?.cell3Data}
              </CRGText>
            </TextCellContainer>
          </CellContainer>
        </MainCell>
      </MainContainer>

      {viewAll ? (
        <BottomContainer>{data?.bottomElement}</BottomContainer>
      ) : null}
    </Container>
  );
};

CRGWidget.propTypes = {
  data: PropTypes.object,
  colorVariant: PropTypes.oneOf[('stocklist', 'users')],
  onClickClose: PropTypes.func,
  viewAll: PropTypes.bool,
};

export { CRGWidget };
