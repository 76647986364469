import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Collapse, useMediaQuery, Drawer } from '@mui/material';

import { MenuList, UserInfo } from 'components/bundle';

const MenuContainerStyle = styled(Collapse)`
  height: 100vh !important;
  min-width: 80px;
  width: ${(props) => (props.in ? '300px' : '80px')} !important;
  .MuiCollapse-horizontal {
    .MuiCollapse-horizontal {
      width: 100%;
    }
  }
`;

const DrawerStyle = styled(Drawer)`
  .MuiPaper-root {
    height: calc(100% - 70px);
    top: unset;
    bottom: 0;
  }
`;

const MenuContentStyle = styled.div`
  height: 100%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 70vw;
  }
`;

const MenuLogoContainerStyle = styled.img`
  filter: invert(1);
  box-sizing: content-box;
  width: calc(100% - 40px);
  padding: 20px;
  margin-top: 10px;
`;

const UserInfoContainer = styled.div`
  color: white;
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Menu = ({ isMenuExpanded, onClose }) => {
  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return isMobile ? (
    <DrawerStyle anchor={'left'} open={isMenuExpanded} onClose={onClose}>
      <MenuContentStyle>
        <UserInfoContainer>
          <UserInfo />
        </UserInfoContainer>
        <MenuList isOpen={isMenuExpanded} onChange={onClose} />
      </MenuContentStyle>
    </DrawerStyle>
  ) : (
    <MenuContainerStyle
      in={isMenuExpanded}
      collapsedSize={80}
      orientation="horizontal"
      className="menu"
    >
      <MenuContentStyle>
        <MenuLogoContainerStyle
          isOpen={isMenuExpanded}
          src={`/images/${isMenuExpanded ? 'white_logo' : 'small_logo'}.svg`}
        />
        <MenuList isOpen={isMenuExpanded} />
      </MenuContentStyle>
    </MenuContainerStyle>
  );
};

Menu.propTypes = {
  isMenuExpanded: PropTypes.bool,
  onClose: PropTypes.func,
};

export { Menu };
