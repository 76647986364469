import styled from 'styled-components';
import PropTypes from 'prop-types';

const SpanStyle = styled.span`
  font-size: ${(props) => {
    return `${props.fontSize}px`;
  }};
  font-weight: ${(props) => props.fontWeight};
  font-family: Montserrat;
  color: ${(props) => {
    switch (props.colorVariant) {
      case 'mainPrimary':
        return props.theme.palette.main.primary;
      case 'mainSecondary':
        return props.theme.palette.main.secondary;
      case 'mainAccent':
        return props.theme.palette.main.accent;
      case 'mainLight':
        return props.theme.palette.main.light;
      case 'neutralLigthWhite':
        return props.theme.palette.neutral.light.white;
      case 'neutralDarkBlack':
        return props.theme.palette.neutral.dark.black;
      case 'mainError':
        return props.theme.palette.main.error;

      default:
        return props.theme.palette.main.primary;
    }
  }};
  text-align: ${(props) => props.textAlign};
  text-decoration: ${(props) => props.textDecoration};
  cursor: ${(props) => props.cursor};
  ${(props) =>
    props.ellipsisAfter
      ? `
      white-space: pre-wrap;
      text-overflow: ellipsis;
      width: ${props.ellipsisAfter};
      display: block;
      overflow: hidden;`
      : null}
  ${(props) => (props.margin ? `margin: ${props.margin}` : null)}
  ${(props) =>
    props.lineClamp
      ? `display: -webkit-box;
    -webkit-line-clamp: ${props.lineClamp};
    -webkit-box-orient: vertical;
    overflow: hidden;`
      : null}
  ${(props) => (props.wordBreak ? `word-break: break-word;` : null)}
`;

/**
 * @param {int} fontSize                  Text font size in px
 * @param {int} fontWeight                Text Font weigth
 * @param {string} colorVariant           Text color variant, available values: mainPrimary, mainSecondary, mainAccent, mainLight, mainError, neutralLigthWhite, neutralDarkBlack
 * @param {string} textAlign              Text align value
 * @param {string} textDecoration         Text decoration value
 * @param {string} cursor                 Text cursor type
 * @param {function} onClick              Function to trigger on click, return click event
 * @param {int} ellipsisAfter             Pixel to start ellipsis
 * @param {string} margin                 Text margin param
 * @param {int} lineClamp                 Text margin param
 * @param {boolean} wordBreak             Word-break 'break-word' param
 */

const CRGText = ({
  children,
  fontSize = 18,
  fontWeight = 100,
  colorVariant,
  textAlign = 'left',
  textDecoration = 'none',
  cursor = 'auto',
  ellipsisAfter,
  onClick,
  margin,
  lineClamp,
  wordBreak,
}) => {
  return (
    <SpanStyle
      fontSize={fontSize}
      fontWeight={fontWeight}
      colorVariant={colorVariant}
      textAlign={textAlign}
      textDecoration={textDecoration}
      cursor={cursor}
      ellipsisAfter={ellipsisAfter}
      onClick={onClick}
      margin={margin}
      lineClamp={lineClamp}
      wordBreak={wordBreak}
    >
      {children}
    </SpanStyle>
  );
};

CRGText.propTypes = {
  children: PropTypes.node,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  colorVariant: PropTypes.oneOf([
    'mainPrimary',
    'mainSecondary',
    'mainAccent',
    'mainLight',
    'mainError',
    'neutralLigthWhite',
    'neutralDarkBlack',
  ]),
  textAlign: PropTypes.string,
  textDecoration: PropTypes.string,
  cursor: PropTypes.string,
  ellipsisAfter: PropTypes.string,
  onClick: PropTypes.func,
  multiline: PropTypes.bool,
  margin: PropTypes.string,
  lineClamp: PropTypes.number,
  wordBreak: PropTypes.bool,
};

export { CRGText };
