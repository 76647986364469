import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { useMediaQuery } from '@mui/material';

import { CRGButton, CRGText, CRGTextFieldControlled } from 'components/atoms';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 50%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 90%;
  }
`;

const FormRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ForgotPasswordForm = ({ onSubmit }) => {
  // Hooks
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
    },
  });

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).forgotPasswordForm;

  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <ContainerStyle>
      <CRGText fontWeight={800} fontSize={isMobile ? 22 : 30}>
        {i18n.text1}
      </CRGText>
      <CRGText fontWeight={300} fontSize={13}>
        {i18n.text2}
      </CRGText>
      <FormStyle onSubmit={handleSubmit(onSubmit)}>
        {/* 
          Username Text Field
        */}
        <FormRowContainer>
          <CRGTextFieldControlled
            label={i18n.form.username}
            name="username"
            control={control}
            rules={{
              required: { value: true, message: i18n.form.errors.username },
            }}
            errors={errors?.['username']}
          />
        </FormRowContainer>

        {/* 
          Submit Button
        */}
        <CRGButton type="submit" colorVariant={'mainSecondary'}>
          {i18n.buttonLabel}
        </CRGButton>
      </FormStyle>
    </ContainerStyle>
  );
};

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
};

export { ForgotPasswordForm };
