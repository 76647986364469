import { useContext, useState } from 'react';

import styled from 'styled-components';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { CRGText } from 'components/atoms';
import { LangContext } from 'contexts';

const SelectionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.down('sm')} {
  }
`;

const WorldIcon = styled.img`
  width: 20px;
  height: 20px;
  ${(props) => props.theme.breakpoints.down('sm')} {
  }
`;

const ArrowBottom = styled.img`
  width: 10px;
  height: 10px;
  ${(props) => props.theme.breakpoints.down('sm')} {
  }
`;

const LanguageSelector = () => {
  // State
  const [anchorEl, setAnchorEl] = useState(null);

  // Contexts
  const { lang, onChangeLang } = useContext(LangContext);

  // Constants
  const open = Boolean(anchorEl);

  // Functions
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLang = (lang) => {
    onChangeLang(lang);
    handleClose();
  };

  return (
    <div>
      <SelectionContainer onClick={handleClick}>
        <WorldIcon src={'/images/language_selector/world_icon.svg'} />
        <CRGText
          fontSize={14}
          fontWeight={500}
          colorVariant={'neutralDarkBlack'}
        >
          {lang === 'it-IT' ? 'IT' : 'EN'}
        </CRGText>
        <ArrowBottom src={'/images/language_selector/arrow_bottom.svg'} />
      </SelectionContainer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleChangeLang('it-IT')}>IT</MenuItem>
        <MenuItem onClick={() => handleChangeLang('en-GB')}>EN</MenuItem>
      </Menu>
    </div>
  );
};

export { LanguageSelector };
