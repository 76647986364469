import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { CRGButton, CRGTextFieldControlled } from 'components/atoms';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const FormRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 360px) {
    align-items: center;
  }
`;

const LoginForm = ({ onSubmit }) => {
  // Hooks
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).loginForm;

  return (
    <FormStyle onSubmit={handleSubmit(onSubmit)}>
      {/* 
          Username Text Field
        */}
      <FormRowContainer>
        <CRGTextFieldControlled
          label={i18n.username}
          name="username"
          control={control}
          rules={{ required: { value: true, message: i18n.errors.username } }}
          errors={errors?.['username']}
        />
      </FormRowContainer>

      {/* 
          Password Text Field
        */}
      <FormRowContainer>
        <CRGTextFieldControlled
          label={i18n.password}
          name="password"
          control={control}
          rules={{ required: { value: true, message: i18n.errors.password } }}
          errors={errors?.['password']}
          fieldType={'password'}
        />
      </FormRowContainer>

      {/* 
          Submit Button
        */}
      <CRGButton type="submit">{i18n.button}</CRGButton>
    </FormStyle>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
};

export { LoginForm };
