import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { CRGText } from 'components/atoms';

import { NotificationTime } from 'components/bundle';

const Container = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 1rem);
`;

const RedDotContainer = styled.div`
  height: 0.5rem;
  width: 0.5rem;
`;

const RedDot = styled.span`
  height: 0.5rem;
  width: 0.5rem;
  background-color: ${(props) => props.theme.palette.main.secondary};
  border-radius: 50%;
  display: inline-block;
`;

const NotificationPopperElement = ({ item, handleDetailStock }) => {
  return (
    <Container onClick={() => handleDetailStock(item)}>
      <RedDotContainer>
        {item?.status === 0 ? <RedDot /> : null}
      </RedDotContainer>

      <img
        src={`/images/notifications/${
          item?.notification?.type === 0
            ? 'user'
            : item?.notification?.type === 1
            ? 'stock'
            : 'general'
        }_type_icon.svg`}
      />
      <Tooltip
        title={item?.notification?.text}
        placement="top"
        enterDelay={1000}
        enterNextDelay={1000}
      >
        <TextContainer>
          <CRGText fontSize={13} fontWeight={600} lineClamp={1}>
            {item?.notification?.text}
          </CRGText>
          <CRGText fontSize={12} fontWeight={400}>
            <NotificationTime
              date={item?.notification?.createdAt}
              textStyle={{
                fontSize: 12,
                fontWeight: 500,
                colorVariant: 'mainSecondary',
              }}
            />
          </CRGText>
        </TextContainer>
      </Tooltip>
    </Container>
  );
};

NotificationPopperElement.propTypes = {
  item: PropTypes.object,
  handleDetailStock: PropTypes.func,
};

export { NotificationPopperElement };
