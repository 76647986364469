import { useState, useContext, useEffect } from 'react';

import styled from 'styled-components';

import { useMediaQuery } from '@mui/material';

import { LangContext, LoadingContext, MenuContext } from 'contexts';
import { useCountries, useNotifications, useUsers } from 'shared';

import { CRGMainLayout } from 'components/layouts';
import {
  CRGButton,
  CRGCheckBox,
  CRGTable,
  CRGText,
  CRGTextFieldControlled,
} from 'components/atoms';
import { ManualNotificationsUsersFilters } from 'components/bundle';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMapping } from 'hooks';

const ConatinerStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-top: 30px;
  padding-left: 50px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 20px;
    padding-left: 20px;
    position: relative;
  }
`;

const TitleContainer = styled.div`
  margin-left: 5px;
  width: 88vw;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  gap: 15px;
  ${(props) => props.theme.breakpoints.down('sm')} {
  }
`;
const FormStyle = styled.form`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100vw;
  }
`;

const NewButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const NewManualNotification = () => {
  // Hooks
  const usersHelper = useUsers();
  const countriesHelper = useCountries();
  const notificationsHelper = useNotifications();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm({
    defaultValues: {
      title: '',
      text: '',
      users: [],
    },
    reValidateMode: 'all',
  });
  const { fields, append } = useFieldArray({
    control,
    name: 'users',
    keyName: 'key',
  });

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).newManualNotifications;

  // State
  const [selectedItems, setSelectedItems] = useState([]);
  const [countries, setCountries] = useState([]);
  const [usersDB, setUsersDB] = useState([]);

  // Constants
  const USER_LIST_TABLE_HEADER = [
    {
      label: '-',
      key: 'isSelected',
      forceColumnSize: '4vw',
      component: (
        <CRGCheckBox
          checked={
            selectedItems.length > 0 && selectedItems.length === usersDB.length
          }
          onChange={() => selectAll()}
        />
      ),
    },
    { label: i18n.headers.nameLabel, key: 'name', forceColumnSize: '10vw' },
    {
      label: i18n.headers.surnameLabel,
      key: 'surname',
      forceColumnSize: '10vw',
    },
    { label: i18n.headers.userTypeLabel, key: 'type', forceColumnSize: '10vw' },
    {
      label: i18n.headers.countryLabel,
      key: 'country',
      forceColumnSize: '10vw',
    },
    {
      label: i18n.headers.languageLabel,
      key: 'language',
      forceColumnSize: '10vw',
    },
    {
      label: i18n.headers.companyLabel,
      key: 'company',
      forceColumnSize: '10vw',
    },
  ];

  // Media Query
  const is1600down = useMediaQuery('(max-width:1600px)');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Contexts
  const { setIsLoading } = useContext(LoadingContext);
  const { isMenuExpanded } = useContext(MenuContext);
  const { lang } = useContext(LangContext);
  const { userTypeList, userLanguageList } = useMapping();

  // Effects
  useEffect(() => {
    fetchCountries();
  }, [lang]);

  // Functions
  const selectRow = (item) => {
    const isUserPresent = selectedItems.find((si) => si.id === item.id);
    let nextSelectedItems = [];
    if (isUserPresent) {
      nextSelectedItems = selectedItems.filter((si) => si.id !== item.id);
    } else {
      nextSelectedItems = [...selectedItems];
      nextSelectedItems.push(item);
    }
    setSelectedItems(nextSelectedItems);
  };

  const selectAll = () => {
    setSelectedItems(usersDB);
  };

  const fetchCountries = async () => {
    countriesHelper
      .get(lang)
      .then((res) => {
        const nextCountries = Object.entries(res).map(([key, value]) => {
          return {
            value: key,
            label: value,
          };
        });
        setCountries(nextCountries);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onFilterChange = async (data) => {
    setIsLoading(true);
    const nextData = {
      ...data,
      ...(data.country && { country: data.country.value }),
      page: 0,
      pageSize: 10,
    };

    usersHelper
      .listAll(nextData)
      .then((res) => {
        const nextDisplayUsers = res?.users?.map((u) => ({
          ...u,
          type: <>{userTypeList[u?.type]}</>,
          country: (
            <>{countries.find((el) => u?.country === el.value)?.label}</>
          ),
          language: <>{userLanguageList[u?.language]}</>,
        }));
        let userstosave = [];
        nextDisplayUsers.forEach((u) => {
          if (!fields.find((fu) => fu.id === u.id)) {
            append(u);
            userstosave.push(u);
          }
        });
        if (usersDB.length < 1) {
          setUsersDB(userstosave);
        } else {
          setUsersDB([...usersDB, ...userstosave]);
          console.log('USERSDB', usersDB);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const onSubmit = async (data) => {
    const nextData = {
      ...data,
      users: selectedItems.map((u) => u.id),
    };
    notificationsHelper
      .createManualNotification(nextData)
      .then(() => {
        navigate('/manual-notifications');
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onRemoveAll = () => {
    setSelectedItems([]);
    resetField('users');
  };

  return (
    <CRGMainLayout>
      <ConatinerStyle>
        <FormStyle onSubmit={handleSubmit(onSubmit)}>
          <TitleContainer>
            <CRGText
              fontSize={23}
              fontWeight={600}
              colorVariant={'neutralDarkBlack'}
            >
              {i18n.title}
            </CRGText>
            <CRGButton
              type="submit"
              colorVariant={'mainPrimary'}
              customWidth={'140px'}
              disabled={selectedItems?.length === 0}
            >
              {i18n.buttonSubmitSend}
            </CRGButton>
          </TitleContainer>

          <FormContainer>
            {/* Title */}
            <CRGTextFieldControlled
              name="title"
              label={i18n.form.titleLabel}
              control={control}
              customWidth={'88vw'}
              rules={{
                required: { value: true, message: i18n.form.errors.title },
              }}
              errors={errors?.['title']}
            />
            {/* Message */}
            <CRGTextFieldControlled
              name="text"
              label={i18n.form.messageLabel}
              control={control}
              customWidth={'88vw'}
              rules={{
                required: { value: true, message: i18n.form.errors.text },
              }}
              errors={errors?.['text']}
            />
          </FormContainer>
        </FormStyle>
        <ManualNotificationsUsersFilters
          handleFilterChange={onFilterChange}
          countries={countries}
        />
        <CRGTable
          data={fields.map((f) => ({
            ...f,
            isSelected: (
              <CRGCheckBox
                checked={!!selectedItems.find((si) => si.id === f.id)}
                onChange={() => selectRow(f)}
              />
            ),
          }))}
          headers={USER_LIST_TABLE_HEADER}
          title={`${i18n.form.resultMessage} (${selectedItems?.length || 0}/${
            fields?.length || 0
          })`}
          maxColumnSize={
            isMobile
              ? '200px'
              : is1600down
              ? isMenuExpanded
                ? '21vw'
                : '22vw'
              : isMenuExpanded
              ? '22vw'
              : '23vw'
          }
          customActions={
            !isMobile ? (
              <CRGButton onClick={onRemoveAll} colorVariant={'mainSecondary'}>
                RIMUOVI TUTTI
              </CRGButton>
            ) : (
              <CRGButton
                onClick={onRemoveAll}
                colorVariant={'mainSecondary'}
                customWidth={'200px'}
              >
                <NewButtonContainer>
                  <img src={'/images/plus-black.svg'} />
                  {i18n.newMessage}
                </NewButtonContainer>
              </CRGButton>
            )
          }
        />
      </ConatinerStyle>
    </CRGMainLayout>
  );
};

export { NewManualNotification };
