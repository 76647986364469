import { useContext } from 'react';

import { useMediaQuery } from '@mui/material';

import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { CRGSplitLayout } from 'components/layouts';
import { ForgotPasswordSubmitForm } from 'components/bundle';

import { useAuthentication } from 'shared';
import { InfoDialogContext } from 'contexts';
import { CRGButton } from 'components/atoms';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const LogoImgStyle = styled.img`
  width: 210px;
  height: 150px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: 20px;
    width: 127px;
    height: 90px;
  }
`;

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: flex-start;
  }
`;

const ForgotPasswordSubmit = () => {
  // Hooks
  const authHelper = useAuthentication();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const username = searchParams.get('username');
  const code = searchParams.get('code');

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).forgotPasswordSubmit;

  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isSmallDevice = useMediaQuery('(max-width:360px)');

  // Contexts
  const { setInfoDialog } = useContext(InfoDialogContext);

  // Functions
  const handleForgotPasswordSubmit = (data) => {
    authHelper
      .submitForgotPassword(data)
      .then(() => {
        navigate('/login');
      })
      .catch((e) => {
        console.error(e);
        setInfoDialog({
          isOpen: true,
          title: i18n.dialog.error.title,
          message: i18n.dialog.error.message,
          customActions: (
            <div>
              <CRGButton
                colorVariant={'mainSecondary'}
                onClick={() => setInfoDialog(null)}
                customWidth={isSmallDevice || isMobile ? '150px' : null}
              >
                {i18n.dialog.error.buttonText}
              </CRGButton>
            </div>
          ),
        });
      });
  };

  return (
    <CRGSplitLayout imageSrc={'images/login_wallpaper.jpg'}>
      <ContainerStyle>
        <LogoImgStyle src={'images/logo.svg'} />

        <ForgotPasswordSubmitForm
          username={username}
          code={code}
          onSubmit={handleForgotPasswordSubmit}
        />
      </ContainerStyle>
    </CRGSplitLayout>
  );
};

export { ForgotPasswordSubmit };
