import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { CRGPopper, CRGText } from 'components/atoms';
import { NotificationPopperElement } from 'components/bundle';
import { useStore } from 'hooks';
import { useNotifications } from 'shared';
import { UserContext, MenuContext } from 'contexts';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { useEffect, useState, useContext } from 'react';

const PopperContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PopperHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 10px;
`;

const CloseContainer = styled.div`
  cursor: pointer;
`;

const PopperTextContainer = styled.div`
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

const ArrowStyle = styled.span`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${(props) => props.theme.palette.common.white};
  position: absolute;
  top: -10px !important;
  right: 20px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ListRow = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 30vh;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationPopper = ({
  isNotificationPopperOpen,
  setIsNotificationPopperOpen,
  anchorEl,
  arrowRef,
  setArrowRef,
}) => {
  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).notificationPopper;

  // Hooks
  const navigate = useNavigate();
  const store = useStore();
  const notificationsHelper = useNotifications();
  const [notifications, setNotifications] = useState([]);
  const { badgeCount, setBadgeCount } = useContext(UserContext);

  // Functions
  const readAllNotifications = async () => {
    notificationsHelper
      .readAllNotifications()
      .then(() => {
        fetchNotifications();
      })
      .catch((e) => {
        console.error(e);
      });
    store.readAll();
  };

  useEffect(() => {
    fetchNotifications();
  }, [badgeCount, isNotificationPopperOpen]);

  const { setCurrentItemSelected } = useContext(MenuContext);

  const fetchNotifications = async () => {
    notificationsHelper.listUserNotifications().then((res) => {
      setNotifications(res.userNotifications);
      setBadgeCount(res.badgeCount);
    });
  };

  const handleDetailStock = async (item) => {
    if (item.status === 0) {
      await notificationsHelper
        .toggleNotificationReadStatus(item?.id)
        .then(() => {
          fetchNotifications();
        });
    }

    if (item.notification.type === 1) {
      await navigate(`/stock/${item.notification.referId}`);
      setCurrentItemSelected(2);
    }
    if (item.notification.type === 0) {
      await navigate(`/users?id=${item.notification.referId}`);
      setCurrentItemSelected(1);
    }
    if (item.notification.type === 2) {
      await navigate('/notifications');
      setCurrentItemSelected(0);
    }
  };

  return (
    <CRGPopper
      id={`popper`}
      colorVariant={'fullWhite'}
      open={isNotificationPopperOpen}
      disablePortal={false}
      anchorEl={anchorEl}
      style={{ zIndex: 10, width: '25vw', height: '45vh' }}
      placement="top-end"
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'window',
        },
        arrow: {
          enabled: true,
          element: arrowRef,
        },
      }}
    >
      <PopperContainer>
        <PopperHeader>
          <CloseContainer onClick={() => setIsNotificationPopperOpen(null)}>
            <img src={'/images/black_close_icon.svg'} />
          </CloseContainer>
        </PopperHeader>
        <ArrowStyle ref={setArrowRef} />
        <PopperTextContainer>
          <HeaderRow>
            <CRGText
              fontSize={18}
              fontWeight={600}
              colorVariant={'neutralDarkBlack'}
            >
              {i18n.title}
            </CRGText>
            <CRGText
              fontSize={12}
              fontWeight={600}
              textDecoration={'underline'}
              colorVariant={'mainAccent'}
              onClick={() => readAllNotifications()}
              cursor={'pointer'}
            >
              {i18n.readAll}
            </CRGText>
          </HeaderRow>
          <ListRow>
            {notifications.map((i, idx) => (
              <NotificationPopperElement
                key={idx}
                item={i}
                handleDetailStock={handleDetailStock}
              />
            ))}
          </ListRow>
          <BottomRow>
            <CRGText
              fontSize={18}
              fontWeight={600}
              textDecoration={'underline'}
              colorVariant={'neutralDarkBlack'}
              onClick={() => navigate('/notifications')}
              cursor={'pointer'}
            >
              {i18n.viewAll}
            </CRGText>
          </BottomRow>
        </PopperTextContainer>
      </PopperContainer>
    </CRGPopper>
  );
};

NotificationPopper.propTypes = {
  isNotificationPopperOpen: PropTypes.bool,
  setIsNotificationPopperOpen: PropTypes.any,
  anchorEl: PropTypes.any,
  arrowRef: PropTypes.any,
  setArrowRef: PropTypes.any,
};

export { NotificationPopper };
