import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import dayjs from 'dayjs';

import { CRGTextField } from 'components/atoms';
import { DateRangePicker } from '@mui/lab';

const CRGDateRangePicker = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        {...props}
        // maxDate={dayjs()}
        displayStaticWrapperAs="desktop"
        renderInput={(startProps, endProps) => {
          const formattedValueStart = startProps?.inputProps?.value?.length
            ? dayjs(startProps?.inputProps?.value).format('DD/MM/YYYY')
            : '';
          const formattedValueEnd = endProps?.inputProps?.value?.length
            ? dayjs(endProps?.inputProps?.value).format('DD/MM/YYYY')
            : '';

          const formattedValue =
            formattedValueStart && formattedValueEnd
              ? formattedValueStart + ' - ' + formattedValueEnd
              : '';

          const newProps = {
            ...startProps,
            inputProps: {
              ...startProps?.inputProps,
              value: formattedValue,
            },
            // eslint-disable-next-line react/prop-types
            label: props.label,
          };

          return <CRGTextField {...newProps} value={formattedValue} />;
        }}
      />
    </LocalizationProvider>
  );
};

export { CRGDateRangePicker };
