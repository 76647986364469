import DateTimePicker from '@mui/lab/DateTimePicker';

import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import dayjs from 'dayjs';

import { CRGTextField } from 'components/atoms';

const CRGDateTimePicker = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        {...props}
        desktopModeMediaQuery={''}
        renderInput={(params) => {
          const formattedValue = params?.inputProps?.value?.length
            ? dayjs(params?.inputProps?.value).format('DD/MM/YYYY HH:mm')
            : '';

          return (
            <CRGTextField
              // eslint-disable-next-line react/prop-types
              customWidth={props.customWidth}
              {...params}
              inputProps={
                formattedValue
                  ? { value: formattedValue }
                  : { ...params?.inputProps }
              }
              value={params?.inputProps?.value}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export { CRGDateTimePicker };
