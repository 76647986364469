import { useContext, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useAuthentication, useCountries, useCache, permissions } from 'shared';

import {
  DrawerContext,
  InfoDialogContext,
  LangContext,
  LoadingContext,
  UserContext,
} from 'contexts';
import { CRGButton, CRGInfoButton, CRGText } from 'components/atoms';
import { UpdateUserSettingsDrawerContent } from 'components/bundle';
import { CRGMainLayout } from 'components/layouts';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { useNavigate } from 'react-router-dom';
import { useInfoDocument, useMapping } from 'hooks';

const ContainerStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 30px;
  padding-left: 50px;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 20px;
    padding-left: 20px;
    position: relative;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  background: ${(props) => props.theme.palette.neutral.light.white};
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 90%;
  }
  @media screen and (max-width: 360px) {
    width: 75%;
    margin-top: 20px;
  }
`;

const RowContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.main.lightTissue};
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: space-between;
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
    gap: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 90%;
  gap: 20px;
  padding-left: 40px;
  margin-bottom: 50px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-left: 0px;
    flex-direction: column;
  }
  @media screen and (max-width: 360px) {
    width: 80%;
    margin-top: 20px;
  }
`;

const LogoutCustomButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  @media screen and (max-width: 360px) {
    flex-direction: column;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  margin-left: 5px;
  margin-bottom: 37px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  @media screen and (max-width: 360px) {
    width: 75%;
  }
`;

const Settings = () => {
  // Hooks
  const authHelper = useAuthentication();
  const navigate = useNavigate();
  const { userLanguageList } = useMapping();
  const countriesHelper = useCountries();
  const cacheHelper = useCache();
  const { SETTINGS_PDF_URL } = useInfoDocument();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).settings;

  // State
  const [countries, setCountries] = useState([]);

  // Context
  const { user, setUser } = useContext(UserContext);
  console.log('🚀 ~ file: Settings.js ~ line 112 ~ Settings ~ user', user);
  const { setIsDrawerOpen, closeDrawer } = useContext(DrawerContext);
  const { infoDialog, setInfoDialog } = useContext(InfoDialogContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { lang } = useContext(LangContext);

  // Effects
  useEffect(() => {
    if (lang) fetchCountries();
  }, [lang]);

  // Functions
  const handleDelete = () => {
    setInfoDialog({
      isOpen: true,
      title: i18n.deleteInfoPopup.title,
      message: i18n.deleteInfoPopup.message,
      customActions: (
        <LogoutCustomButtonContainer>
          <CRGButton
            colorVariant={'mainSecondary'}
            onClick={() => setInfoDialog(null)}
            customWidth={'150px'}
          >
            {i18n.deleteInfoPopup.cancelButtonLabel}
          </CRGButton>
          <CRGButton onClick={deleteUser} customWidth={'150px'}>
            {i18n.deleteInfoPopup.agreeButtonLabel}
          </CRGButton>
        </LogoutCustomButtonContainer>
      ),
    });
  };

  const deleteUser = () => {
    setIsLoading(true);
    authHelper
      .deleteMe()
      .then(() => {
        handleLogout();
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
      });
  };

  const handleLogout = () => {
    authHelper
      .logout()
      .then(() => {
        localStorage.removeItem('cariaggi-web-user');
        navigate('/login');
        setUser(null);
        setInfoDialog(null);
        closeDrawer?.();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleUpdateUser = (data) => {
    setIsLoading(true);
    authHelper
      .edit(data)
      .then((res) => {
        const nextUser = { ...user, cariaggiUser: res };
        localStorage.setItem('cariaggi-web-user', JSON.stringify(nextUser));
        setUser(nextUser);
        setIsLoading(false);
        closeDrawer?.();
      })
      .catch((e) => {
        setIsLoading(false);
        closeDrawer?.();
        console.error(e);
      });
  };

  const handleUpdate = () => {
    setIsDrawerOpen({
      isOpen: true,
      customWidth: '30vw',
      children: (
        <UpdateUserSettingsDrawerContent
          onClose={closeDrawer}
          onEdit={handleUpdateUser}
          countries={countries}
        />
      ),
    });
  };

  const handleInvalidateCache = () => {
    cacheHelper
      .clean()
      .then((res) => {
        console.log(res);
        setInfoDialog({
          isOpen: true,
          title: 'INVALIDAZIONE CACHE',
          message: 'La richiesta é stata effettuata con successo',
          customActions: (
            <LogoutCustomButtonContainer>
              <CRGButton
                colorVariant={'mainSecondary'}
                customWidth={'150px'}
                onClick={() => {
                  setInfoDialog({ ...infoDialog, isOpen: false });
                }}
              >
                CHIUDI
              </CRGButton>
            </LogoutCustomButtonContainer>
          ),
        });
      })
      .catch((ex) => {
        console.log(ex);
        setInfoDialog({
          isOpen: true,
          title: 'INVALIDAZIONE CACHE',
          message: 'Ops! Qualcosa é andato storto',
          customActions: (
            <LogoutCustomButtonContainer>
              <CRGButton
                colorVariant={'mainSecondary'}
                customWidth={'150px'}
                onClick={() => {
                  setInfoDialog({ ...infoDialog, isOpen: false });
                }}
              >
                CHIUDI
              </CRGButton>
            </LogoutCustomButtonContainer>
          ),
        });
      });
  };

  const fetchCountries = () => {
    countriesHelper
      .get(lang)
      .then((res) => {
        const nextCountries = Object.entries(res).map(([key, value]) => {
          return {
            value: key,
            label: value,
          };
        });
        setCountries(nextCountries);
      })
      .catch((e) => console.error(e));
  };

  return (
    <CRGMainLayout>
      <ContainerStyle>
        <div>
          <TitleContainer>
            <CRGText
              fontSize={30}
              fontWeight={700}
              colorVariant={'neutralDarkBlack'}
            >
              {i18n.title}
            </CRGText>
            <CRGInfoButton infoUrl={SETTINGS_PDF_URL} />
          </TitleContainer>
          <InfoContainer>
            <RowContainer>
              <CRGText
                fontSize={14}
                fontWeight={600}
                colorVariant={'neutralDarkBlack'}
              >
                {i18n.info.name}
              </CRGText>
              <CRGText
                fontSize={14}
                fontWeight={400}
                colorVariant={'neutralDarkBlack'}
              >
                {user?.cariaggiUser?.name}
              </CRGText>
            </RowContainer>
            <RowContainer>
              <CRGText
                fontSize={14}
                fontWeight={600}
                colorVariant={'neutralDarkBlack'}
              >
                {i18n.info.surname}
              </CRGText>
              <CRGText
                fontSize={14}
                fontWeight={400}
                colorVariant={'neutralDarkBlack'}
              >
                {user?.cariaggiUser?.surname}
              </CRGText>
            </RowContainer>
            <RowContainer>
              <CRGText
                fontSize={14}
                fontWeight={600}
                colorVariant={'neutralDarkBlack'}
              >
                {i18n.info.company}
              </CRGText>
              <CRGText
                fontSize={14}
                fontWeight={400}
                colorVariant={'neutralDarkBlack'}
              >
                {user?.cariaggiUser?.company}
              </CRGText>
            </RowContainer>
            <RowContainer>
              <CRGText
                fontSize={14}
                fontWeight={600}
                colorVariant={'neutralDarkBlack'}
              >
                {i18n.info.email}
              </CRGText>
              <CRGText
                fontSize={14}
                fontWeight={400}
                colorVariant={'neutralDarkBlack'}
                ellipsisAfter={'50vw'}
              >
                {user?.cariaggiUser?.email}
              </CRGText>
            </RowContainer>
            <RowContainer>
              <CRGText
                fontSize={14}
                fontWeight={600}
                colorVariant={'neutralDarkBlack'}
              >
                {i18n.info.country}
              </CRGText>
              <CRGText
                fontSize={14}
                fontWeight={400}
                colorVariant={'neutralDarkBlack'}
              >
                {
                  countries.find((c) => c.value === user?.cariaggiUser?.country)
                    ?.label
                }
              </CRGText>
            </RowContainer>
            <RowContainer>
              <CRGText
                fontSize={14}
                fontWeight={600}
                colorVariant={'neutralDarkBlack'}
              >
                {i18n.info.language}
              </CRGText>
              <CRGText
                fontSize={14}
                fontWeight={400}
                colorVariant={'neutralDarkBlack'}
              >
                {userLanguageList[user?.cariaggiUser?.language]}
              </CRGText>
            </RowContainer>
            <RowContainer>
              <CRGText
                fontSize={14}
                fontWeight={600}
                colorVariant={'neutralDarkBlack'}
              >
                {i18n.info.version}
              </CRGText>
              <CRGText
                fontSize={14}
                fontWeight={400}
                colorVariant={'neutralDarkBlack'}
                //margin={isMobile ? 'auto' : defaultMargin}
              >
                {process.env.REACT_APP_VERSION}
              </CRGText>
            </RowContainer>
          </InfoContainer>
        </div>

        <ButtonContainer>
          <CRGButton
            onClick={handleDelete}
            className="delete"
            customWidth="15rem"
          >
            {i18n.deleteButtonLabel}
          </CRGButton>
          {user?.cariaggiUser?.permissions
            ?.split('|')
            .includes(permissions.INVALIDATE_CACHE?.toString()) &&
            user?.cariaggiUser?.type === 3 && (
              <CRGButton onClick={handleInvalidateCache} customWidth="15rem">
                {i18n.info.invalidateCache}
              </CRGButton>
            )}
          <CRGButton onClick={handleUpdate} customWidth="15rem">
            {i18n.updateButtonLabel}
          </CRGButton>
        </ButtonContainer>
      </ContainerStyle>
    </CRGMainLayout>
  );
};

export { Settings };
