import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { CRGSplitLayout } from 'components/layouts';
import { ForgotPasswordForm } from 'components/bundle';

import { useAuthentication } from 'shared';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { CRGButton, CRGText } from 'components/atoms';

const LogoImgStyle = styled.img`
  width: 210px;
  height: 150px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: 20px;
    width: 127px;
    height: 90px;
  }
`;

const SendSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 30px;
  gap: 15px;
`;

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: flex-start;
  }
`;

const ForgotPassword = () => {
  // Hooks
  const authHelper = useAuthentication();
  const navigate = useNavigate();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).forgotPassword;

  // State
  const [forgottenPasswordUser, setForgottenPasswordUser] = useState();

  // Functions
  const handleForgotPassword = (data) => {
    authHelper
      .forgotPassword(data)
      .then(() => {
        setForgottenPasswordUser(data?.username);
      })
      .catch((e) => {
        console.error(e);
        navigate('/login');
      });
  };

  const goBack = () => {
    navigate('/login');
  };

  return (
    <CRGSplitLayout imageSrc={'images/login_wallpaper.jpg'}>
      <ContainerStyle>
        <LogoImgStyle src={'images/logo.svg'} />
        {forgottenPasswordUser ? (
          <SendSuccessContainer>
            <CRGText
              fontSize={22}
              fontWeight={600}
              colorVariant={'neutralDarkBlack'}
            >
              {i18n.title}
            </CRGText>
            <CRGText
              fontSize={14}
              fontWeight={400}
              colorVariant={'neutralDarkBlack'}
            >
              {i18n.subtitle}
            </CRGText>
            <CRGButton colorVariant={'mainSecondary'} onClick={goBack}>
              {i18n.button}
            </CRGButton>
          </SendSuccessContainer>
        ) : (
          <ForgotPasswordForm onSubmit={handleForgotPassword} />
        )}
      </ContainerStyle>
    </CRGSplitLayout>
  );
};

export { ForgotPassword };
