import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LanguageSelector } from 'components/bundle';

const ContainerStyle = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-basis: 0;
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const ImageContainerStyleStyle = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  ${(props) => props.theme.breakpoints.down('sm')} {
    height: 45vh;
  }
`;

const ChildrenContainerStyleStyle = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-x: scroll;
  ${(props) => props.theme.breakpoints.down('sm')} {
    height: 100%;
    overflow: hidden;
  }
`;

const BottomElementContainer = styled.div`
  height: 100px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
`;

const LanguageSelectorContainer = styled.div`
  position: absolute;
  right: 0;
  padding: 10px;
`;

const ImageStyle = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${(props) => props.theme.breakpoints.down('sm')} {
    object-fit: fill;
  }
`;

/**
 * @param {string} imageSrc           Source of the image to show
 * @param {node} bottomElement        Node to show at the bottom of image container
 */

const CRGSplitLayout = ({ children, imageSrc, bottomElement }) => {
  return (
    <ContainerStyle>
      <ImageContainerStyleStyle>
        <ImageStyle src={imageSrc}></ImageStyle>
        <BottomElementContainer>{bottomElement}</BottomElementContainer>
      </ImageContainerStyleStyle>
      <ChildrenContainerStyleStyle>
        <LanguageSelectorContainer>
          <LanguageSelector />
        </LanguageSelectorContainer>
        {children}
      </ChildrenContainerStyleStyle>
    </ContainerStyle>
  );
};

CRGSplitLayout.propTypes = {
  children: PropTypes.node,
  imageSrc: PropTypes.string,
  bottomElement: PropTypes.node,
};

export { CRGSplitLayout };
