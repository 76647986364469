import { createTheme } from '@mui/material';

import { shared } from './shared';

// Create a theme instance.
const dark = createTheme({
  palette: {
    main: {
      primary: '#00171F',
      secondary: '#C03A1F',
      accent: '#B96672',
      accentSecondary: '#8B1893',
      light: '#D1BFAE',
      lightTissue: '#EFEAE6',
      error: '#FF0000',
    },
    neutral: {
      dark: {
        black: '#000000',
        spanishGray: '#959B9D',
        lightGray: '#CBCECF',
      },
      light: {
        white: '#FFFFFF',
        cultured: '#F6F5F4',
      },
    },
    primary: {
      main: '#00171F',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
  ...shared,
});

export { dark };
