import { useState, useContext } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { CRGButton, CRGTextField } from 'components/atoms';
import { UserMobileFiltersDrawerContent } from 'components/bundle';
import { DrawerContext } from 'contexts';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const ContainerStyle = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const UserListFiltersMobile = ({ onSearch, countries }) => {
  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).userListFiltersMobile;

  // State
  const [email, setEmail] = useState();
  const [lastFullSearch, setLastFullSearch] = useState();

  // Context
  const { setIsDrawerOpen, closeDrawer } = useContext(DrawerContext);

  // Functions
  const handleEmailChange = (e) => {
    setEmail(e?.target?.value);
  };

  const onFilterClick = () => {
    setIsDrawerOpen({
      isOpen: true,
      children: (
        <UserMobileFiltersDrawerContent
          onClose={closeDrawer}
          lastFullSearch={lastFullSearch}
          setLastFullSearch={setLastFullSearch}
          onSearch={(data) => {
            setEmail('');
            onSearch(data);
          }}
          countries={countries}
        />
      ),
    });
  };

  return (
    <ContainerStyle>
      <CRGTextField
        label={i18n.emailLabel}
        onChange={handleEmailChange}
        value={email}
        customWidth={'calc(100% - 70px)'}
        customHeight={'30px'}
        customLabelTopPosition={'-10px'}
      />
      <CRGButton
        colorVariant={'mainSecondary'}
        isSmall
        onClick={() => {
          setLastFullSearch(null);
          onSearch({ email });
        }}
      >
        <img width={'12px'} height={'12px'} src={'images/search_lens.svg'} />
      </CRGButton>
      <CRGButton isSmall onClick={onFilterClick}>
        <img width={'12px'} height={'12px'} src={'images/filter_burger.svg'} />
      </CRGButton>
    </ContainerStyle>
  );
};

UserListFiltersMobile.propTypes = {
  onSearch: PropTypes.func,
  countries: PropTypes.array,
};

export { UserListFiltersMobile };
