import { useState, useContext } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import { CRGSplitLayout } from 'components/layouts';
import { CRGButton, CRGInfoButton, CRGText } from 'components/atoms';
import {
  SignInForm,
  AlreadyRegistered,
  SignInComplete,
} from 'components/bundle';

import { useAuthentication } from 'shared';

import { useCustomIntl, useInfoDocument, usePermissions } from 'hooks';
import translation from 'utils/translation';
import { InfoDialogContext, LangContext, LoadingContext } from 'contexts';

const SigninContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: flex-start;
  }
`;

const FormContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: 50%;
  margin-top: 60px;
  margin-top: 60px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: 30px;
  }
`;

const AlreadyRegisteredContainer = styled.div`
  margin-top: 30px;
  @media screen and (max-width: 360px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TopTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 360px) {
    margin-left: 30px;
    width: fit-content;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SignIn = () => {
  // Hooks
  const navigate = useNavigate();
  const authHelper = useAuthentication();
  const permissionHelper = usePermissions();
  const { SIGNUP_PDF_URL } = useInfoDocument();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).signin;

  // Contexts
  const { setIsLoading } = useContext(LoadingContext);
  const { setInfoDialog } = useContext(InfoDialogContext);
  const { lang } = useContext(LangContext);

  // State
  const [signInComplete, setSignInComplete] = useState(false);

  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Functions
  const onSubmit = (data) => {
    setIsLoading(true);
    const userPermissions = permissionHelper.getPermissionByUserType(data.type);
    const nextData = {
      ...data,
      permissions: permissionHelper.getStringFromPermissionObj(userPermissions),
    };
    authHelper
      .signin(nextData)
      .then(() => {
        setIsLoading(false);
        setSignInComplete(true);
      })
      .catch((err) => {
        setIsLoading(false);
        const errorToHandle = JSON.parse(err.message);
        if (
          errorToHandle?.message?.indexOf('User account already exists') !== -1
        ) {
          setInfoDialog({
            isOpen: true,
            title: i18n.errors.userAlreadyExists.title,
            message: i18n.errors.userAlreadyExists.message,
            customActions: (
              <div>
                <CRGButton
                  colorVariant={'mainSecondary'}
                  onClick={() => setInfoDialog(null)}
                  customWidth={'150px'}
                >
                  {i18n.errors.userAlreadyExists.button}
                </CRGButton>
              </div>
            ),
          });
        }
      });
  };

  const handleGoToLogin = () => navigate('/login');

  return (
    <CRGSplitLayout
      imageSrc={`images/signin_wallpaper${signInComplete ? '_step_2' : ''}${
        isMobile ? '_mobile' : ''
      }${lang === 'it-IT' ? '' : '-en'}.jpg`}
      bottomElement={
        isMobile || signInComplete ? null : (
          <AlreadyRegistered onButtonClick={handleGoToLogin} />
        )
      }
    >
      <SigninContainerStyle>
        <FormContainerStyle>
          {signInComplete ? (
            <SignInComplete />
          ) : (
            <TopTextContainer>
              <InfoContainer>
                <TitleContainer>
                  <CRGText fontWeight={800} fontSize={30}>
                    {i18n.title}
                  </CRGText>
                  <CRGText fontWeight={300} fontSize={13}>
                    {i18n.subtitle}
                  </CRGText>
                </TitleContainer>
                <CRGInfoButton infoUrl={SIGNUP_PDF_URL} />
              </InfoContainer>
              <SignInForm onSubmit={onSubmit} />
            </TopTextContainer>
          )}
          {isMobile ? (
            <AlreadyRegisteredContainer>
              <AlreadyRegistered onButtonClick={handleGoToLogin} />
            </AlreadyRegisteredContainer>
          ) : null}
        </FormContainerStyle>
      </SigninContainerStyle>
    </CRGSplitLayout>
  );
};

export { SignIn };
