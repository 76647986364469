import styled from 'styled-components';

import { CRGText } from 'components/atoms';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const PasswordPolicyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const PasswordPolicyInfoRow = styled.div`
  display: flex;
  gap: 10px;
`;

const RedCircleImage = styled.img`
  width: 10px;
  heigth: 10px;
`;

const PasswordPolicyList = () => {
  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).passwordPolicyList;

  return (
    <PasswordPolicyInfoContainer>
      <CRGText fontSize={14} fontWeight={500} colorVariant={'neutralDarkBlack'}>
        {i18n.main}
      </CRGText>
      <PasswordPolicyInfoRow>
        <RedCircleImage src={'images/red_empty_circle.svg'} />
        <CRGText
          fontSize={14}
          fontWeight={500}
          colorVariant={'neutralDarkBlack'}
        >
          {i18n.policy1}
        </CRGText>
      </PasswordPolicyInfoRow>
      <PasswordPolicyInfoRow>
        <RedCircleImage src={'images/red_empty_circle.svg'} />
        <CRGText
          fontSize={14}
          fontWeight={500}
          colorVariant={'neutralDarkBlack'}
        >
          {i18n.policy2}
        </CRGText>
      </PasswordPolicyInfoRow>
      <PasswordPolicyInfoRow>
        <RedCircleImage src={'images/red_empty_circle.svg'} />
        <CRGText
          fontSize={14}
          fontWeight={500}
          colorVariant={'neutralDarkBlack'}
        >
          {i18n.policy3}
        </CRGText>
      </PasswordPolicyInfoRow>
      <PasswordPolicyInfoRow>
        <RedCircleImage src={'images/red_empty_circle.svg'} />
        <CRGText
          fontSize={14}
          fontWeight={500}
          colorVariant={'neutralDarkBlack'}
        >
          {i18n.policy4}
        </CRGText>
      </PasswordPolicyInfoRow>
      <PasswordPolicyInfoRow>
        <RedCircleImage src={'images/red_empty_circle.svg'} />
        <CRGText
          fontSize={14}
          fontWeight={500}
          colorVariant={'neutralDarkBlack'}
        >
          {i18n.policy5}
        </CRGText>
      </PasswordPolicyInfoRow>
    </PasswordPolicyInfoContainer>
  );
};

export { PasswordPolicyList };
