import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { CRGText } from 'components/atoms';
import { timeFromDateToNow } from 'utils/utils';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const NotificationTime = ({ date, textStyle }) => {
  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).notificationTime;

  // State
  const [parsedValue, setParsedValue] = useState();

  //Effects
  useEffect(() => {
    if (date) {
      let nextValue = '';
      const dateObj = timeFromDateToNow(date);
      if (dateObj.day > 1) {
        nextValue = i18n.moreDayAgo;
      } else if (dateObj.day === 1) {
        nextValue = i18n.oneDayAgo;
      } else if (dateObj.hour > 1) {
        nextValue = i18n.moreHourAgo;
      } else {
        nextValue = `${dateObj.minute} ${i18n.minuteAgo}`;
      }
      setParsedValue(nextValue);
    }
  }, [date]);

  return <CRGText {...textStyle}>{parsedValue}</CRGText>;
};

NotificationTime.propTypes = {
  date: PropTypes.string,
  textStyle: PropTypes.object,
};

export { NotificationTime };
