import { Grid, useMediaQuery } from '@mui/material';
import { useOrdersMapping } from 'hooks';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CRGGridCell } from 'components/atoms';

const GridStyle = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const OrderItem = ({ order, ...props }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { header, content } = useOrdersMapping(order);

  return (
    <GridStyle {...props}>
      <Grid container item direction={'column'}>
        <div>
          <Grid
            container
            item
            direction={isMobile ? 'column' : 'row'}
            columns={{ xs: 4, md: 'auto', lg: 14 }}
            spacing={!isMobile ? 5 : 0}
          >
            {header.map((item) => (
              <CRGGridCell
                key={item.header}
                size={item.md}
                header={item.header}
                value={item.value}
                valueFontWeight={item.valueFontWeight}
                showDivider={item.showDivider}
              >
                {item.children}
              </CRGGridCell>
            ))}
          </Grid>
          <Grid
            // ref={rowRef2}
            container
            item
            marginTop={'16px'}
            paddingLeft={isMobile ? '11px' : '22px'}
            paddingTop={'16px'}
            paddingBottom={isMobile ? '0px' : '30px'}
            paddingRight={isMobile ? '12px' : '0px'}
            style={{
              backgroundColor: '#F6F5F4',
            }}
            direction={isMobile ? 'column' : 'row'}
            columns={{ xs: 4, md: 'auto', lg: 14 }}
            spacing={!isMobile ? 5 : 0}
          >
            {content.map((item) => {
              return item.visibility ? (
                <CRGGridCell
                  key={item.header}
                  size={item.md}
                  header={item.header}
                  value={item.value}
                  valueFontWeight={item.valueFontWeight}
                  showDivider={item.showDivider}
                >
                  {item.children}
                </CRGGridCell>
              ) : null;
            })}
          </Grid>
        </div>
      </Grid>
    </GridStyle>
  );
};

OrderItem.propTypes = {
  order: PropTypes.object,
};

export { OrderItem };
