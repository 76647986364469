import React, { createContext, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { CRGText } from 'components/atoms';

const DialogTitleStyle = styled(DialogTitle)`
  text-align: center;
`;

const DialogContentTextStyle = styled(DialogContentText)`
  text-align: center;
`;

const DialogStyle = styled(Dialog)`
  .MuiPaper-root {
    padding: 30px;
  }
`;

export const InfoDialogContext = createContext();
const InfoDialogContextProvider = ({ children }) => {
  // State
  const [infoDialog, setInfoDialog] = useState();

  // Functions
  const handleClose = () => {
    setInfoDialog(null);
  };

  return (
    <InfoDialogContext.Provider value={{ infoDialog, setInfoDialog }}>
      {children}
      <DialogStyle
        open={infoDialog?.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitleStyle id="alert-dialog-title">
          <CRGText
            fontSize={18}
            fontWeight={700}
            colorVariant={'neutralDarkBlack'}
          >
            {infoDialog?.title}
          </CRGText>
        </DialogTitleStyle>
        <DialogContent>
          <DialogContentTextStyle id="alert-dialog-description">
            <CRGText
              fontSize={16}
              fontWeight={400}
              colorVariant={'neutralDarkBlack'}
            >
              {infoDialog?.message}
            </CRGText>
          </DialogContentTextStyle>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'center',
          }}
        >
          {infoDialog?.customActions}
        </DialogActions>
      </DialogStyle>
    </InfoDialogContext.Provider>
  );
};

InfoDialogContextProvider.propTypes = {
  children: PropTypes.node,
};

export default InfoDialogContextProvider;
