import useFetch from 'use-http';

import { Auth } from 'aws-amplify';
import { BACKEND_URL } from '../utils/constants';

const authUrls = ['/stock'];

const useStocklist = (isMobile) => {
  const backendHelper = useFetch(BACKEND_URL, {
    onError: ({ error }) => {
      throw error;
    },
    persist: !isMobile,
    cachePolicy: isMobile ? 'no-cache' : 'cache-first',
    cacheLife: 30000,
    interceptors: {
      request: async ({ options, route }) => {
        const authenticatedUrl = authUrls.find((p) => route?.indexOf(p) !== -1);

        if (authenticatedUrl) {
          try {
            const data = await Auth.currentSession();
            const jwt = data?.getAccessToken().getJwtToken();
            options.headers.Authorization = `Bearer ${jwt}`;
          } catch (error) {
            console.log(
              '🚀 ~ file: useArticles.js:23 ~ request: ~ error:',
              error
            );
          }
        }
        return options;
      },
      response: ({ response }) => {
        if (!response.ok) throw new Error(JSON.stringify(response.data));
        return response;
      },
    },
  });

  const list = (params) =>
    new Promise((resolve, reject) => {
      const normalizedParams = {
        ...params,
        page: params.page || 0,
        pageSize: params.pageSize || 10,
      };

      const multipleFields = {};

      Object.keys(normalizedParams).forEach((key) => {
        if (
          normalizedParams[key] === null ||
          normalizedParams[key] === '' ||
          normalizedParams[key] === undefined
        ) {
          delete normalizedParams[key];
        } else if (Array.isArray(normalizedParams[key])) {
          multipleFields[key] = normalizedParams[key];
          delete normalizedParams[key];
        }
      });

      const queryParams = new URLSearchParams(normalizedParams);

      Object.keys(multipleFields).forEach((key) => {
        const valueArray = multipleFields[key];
        valueArray.forEach((v) => {
          queryParams.append(`${key}[]`, v);
        });
      });

      backendHelper
        .get(`/stock?${queryParams.toString()}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error get stocklist', e);
          reject(e);
        });
    });

  const create = (params) =>
    new Promise((resolve, reject) => {
      backendHelper
        .post(`/stock`, params)
        .then((res) => {
          backendHelper.cache.clear();
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error create stocklist', e);
          reject(e);
        });
    });

  const get = ({ id }) =>
    new Promise((resolve, reject) => {
      backendHelper
        .get(`/stock/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error delete stocklist', e);
          reject(e);
        });
    });

  return {
    list,
    create,
    get,
  };
};

export { useStocklist };
