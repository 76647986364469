import { Grid, useMediaQuery } from '@mui/material';
import { CRGButton, CRGDesktopTable, CRGGridCell } from 'components/atoms';
import { useCustomIntl, useOrdersMapping } from 'hooks';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { orderStatus, useOrders } from 'shared';
import translation from 'utils/translation';
import { useContext, useState } from 'react';
import { CRGMobileList } from 'components/atoms/table/CRGMobileList';
import { LoadingContext } from 'contexts';

const DetailContainerStyle = styled.div`
  display: flex;
  padding: 33px 35px 35px 33px;
  background-color: ${(props) => props.colorVariant};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 30px 25px 30px 25px;
    border-radius: 10px;
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 34%;
  margin-left: -20px;
  margin-top: 40px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const InfoStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const ButtonStyle = styled.div`
  display: flex;
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: flex-end;
  }
`;

const BottomDetailInfo = ({ order }) => {
  // Contexts
  const { setIsLoading } = useContext(LoadingContext);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { contentDetail, detailMapping } = useOrdersMapping(order);

  const intl = useCustomIntl();
  const i18n = translation.pages(intl).orders.headers;

  const headers = [
    {
      label: i18n.shipmentNumber,
      key: 'NUM_SPEDIZIONI',
    },
    {
      label: i18n.firstShipmentDate,
      key: 'DATA_PRIMA_SPED',
    },
    {
      label: i18n.lastShipmentDate,
      key: 'DATA_ULTIMA_SPED',
    },
  ];

  const DdtHeaders = [
    {
      label: i18n.ddtReference,
      key: 'RIF_DDT_E_PL',
    },
    {
      label: i18n.ddtDate,
      key: 'DATA_DDT_E_PL',
    },
    {
      label: i18n.invoiceNumber,
      key: 'NUM_FATT',
    },
    {
      label: i18n.invoiceDate,
      key: 'DATA_FATT',
    },
  ];

  const { getShipping } = useOrders();

  const [isShippingInfoOpen, setIsShippingInfoOpen] = useState(false);
  const [shippingInfo, setShippingInfo] = useState([]);

  const getShippingInfo = async () => {
    if (order?.RIF_ORDINE) {
      if (isShippingInfoOpen) {
        setIsShippingInfoOpen(false);
        return;
      }
      setIsLoading(true);
      getShipping({ rifOrdine: order?.RIF_ORDINE })
        .then((res) => {
          setIsShippingInfoOpen(true);
          setShippingInfo(res);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <DetailContainerStyle colorVariant={'#fff'} border={'bottom'}>
      <Grid container spacing={isMobile ? undefined : '40px'}>
        <Grid
          container
          item
          direction={isMobile ? 'column' : 'row'}
          columns={{ xs: 4, md: 14 }}
          spacing={!isMobile ? 5 : 0}
        >
          {contentDetail.map((item) => (
            <>
              {item.visibility && (
                <CRGGridCell
                  key={item.header}
                  size={item.md}
                  header={item.header}
                  value={item.value}
                  valueFontWeight={item.valueFontWeight}
                  showDivider={item.showDivider}
                >
                  {item.children}
                </CRGGridCell>
              )}
            </>
          ))}
        </Grid>
        <Grid
          container
          item
          direction={isMobile ? 'column' : 'row'}
          columns={{ xs: 4, md: 12 }}
          spacing={isMobile ? undefined : '10px'}
        >
          {detailMapping.map((item) => (
            <>
              {item.visibility && (
                <CRGGridCell
                  key={item.header}
                  md={item.md}
                  header={item.header}
                  value={item.value}
                  valueFontWeight={item.valueFontWeight}
                  showDivider={item.showDivider}
                >
                  {item.children}
                </CRGGridCell>
              )}
            </>
          ))}
        </Grid>
      </Grid>
      <InfoStyle>
        {(order?.COD_STATO_ORDINE === orderStatus.PartiallyShipped ||
          order?.COD_STATO_ORDINE === orderStatus.Shipped) && (
          <ButtonStyle>
            <CRGButton
              colorVariant="mainSecondary"
              customHeight={'46px'}
              customWidth={'200px'}
              onClick={getShippingInfo}
            >
              {i18n.shippingDocRef}
            </CRGButton>
          </ButtonStyle>
        )}
        {isShippingInfoOpen &&
          (!isMobile ? (
            <TableContainer>
              <CRGDesktopTable data={[shippingInfo[0]]} headers={headers} />
              <CRGDesktopTable data={shippingInfo} headers={DdtHeaders} />
            </TableContainer>
          ) : (
            <CardsContainer>
              <CRGMobileList data={[shippingInfo[0]]} headers={headers} />
              {shippingInfo.map((info, idx) => (
                <CRGMobileList key={idx} data={[info]} headers={DdtHeaders} />
              ))}
            </CardsContainer>
          ))}
      </InfoStyle>
    </DetailContainerStyle>
  );
};

BottomDetailInfo.propTypes = {
  order: PropTypes.object,
};

export { BottomDetailInfo };
