import { useState, useEffect } from 'react';

import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CRGButton, CRGText } from 'components/atoms';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TreeContainer = styled.div`
  margin-top: 10px;
`;

const TreeItemStyled = styled(TreeItem)`
  .MuiTreeItem-content > .MuiTreeItem-label {
    display: flex;
    align-items: center;
    padding: 4px 0;
    background: transparent !important;
    pointer-events: none;
  }
  .MuiTreeItem-content > .MuiTreeItem-label::before {
    content: '';
    display: inline-block;
    width: 23px;
    height: 23px;
    border-radius: 5px;
    margin-right: 8px;
    border: 1px solid #ccc;
    background: white;
    box-shadow: 5px 5px 10px #0000000d;
  }
  .MuiTreeItem-iconContainer {
    margin-right: 12px;
    visibility: ${(props) => props.test && 'hidden'};

    & > svg {
      padding: 8px;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  label: {
    padding: 0;
  }
  .Mui-selected {
    background: transparent;
    .MuiTreeItem-label::before {
      background: ${(props) => props.theme.palette.main.primary};
      border: 1px solid transparent;
      content: url('/images/whitecheck.svg');
      display: flex;
      justify-content: center;
    }
  }
`;

const CRGPermissionSelector = ({
  permission,
  setPermission,
  onReset,
  isDisabled,
}) => {
  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).permissionSelector;

  // State
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  // Effects
  useEffect(() => {
    if (!permission) return;

    const nextSelected = [];
    Object.entries(permission).map(([key, parentObj]) => {
      if (parentObj.value) nextSelected.push(key);
      const aclList = parentObj.acl;
      Object.entries(aclList).map(([childKey, childObj]) => {
        if (childObj.value) {
          nextSelected.push(childKey);
          const isParentSelected = nextSelected.find((s) => s === key);
          if (!isParentSelected) {
            nextSelected.push(key);
          }
        }
      });
    });

    setSelected(nextSelected);
  }, [permission]);

  // Functions
  const handleToggle = (event, nodeIds) => {
    if (event.target.nodeName !== 'IMG') {
      return;
    }
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    if (event.target.nodeName === 'IMG') {
      return;
    }
    const nodeId = nodeIds[0];

    const objToUpdate = {};
    Object.entries(permission).map(([key, parentObj]) => {
      if (key === nodeId) objToUpdate.parent = key;
      const aclList = parentObj.acl;
      Object.entries(aclList).map(([childKey]) => {
        if (childKey === nodeId) {
          objToUpdate.parent = key;
          objToUpdate.child = childKey;
        }
      });
    });

    const isSelected = !!selected.find((id) => id === nodeId);

    const nextPermission = { ...permission };

    if (objToUpdate.child) {
      nextPermission[objToUpdate.parent].acl[objToUpdate.child].value =
        !isSelected;
      nextPermission[objToUpdate.parent].value = true;
    } else {
      nextPermission[objToUpdate.parent].value = !isSelected;
    }

    setPermission(nextPermission);
  };

  return (
    <Container>
      <TopContainer>
        <CRGText
          colorVariant={'neutralDarkBlack'}
          fontSize={18}
          fontWeight={600}
        >
          {i18n.title}
        </CRGText>
        <CRGButton
          customWidth={100}
          customHeight={30}
          customFontSize={18}
          onClick={onReset}
          disabled={isDisabled}
        >
          {i18n.resetButtonlabel}
        </CRGButton>
      </TopContainer>
      <TreeContainer>
        <TreeView
          aria-label="icon expansion"
          defaultCollapseIcon={<img src="/images/tree/tree_collapse.svg" />}
          defaultExpandIcon={<img src="/images/tree/tree_expand.svg" />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          multiSelect
        >
          {Object.entries(permission).map(([key, value]) => {
            return (
              <TreeItemStyled
                key={key}
                nodeId={key}
                test={value?.hidePlus}
                disabled={isDisabled || value.disabled}
                label={
                  <CRGText
                    colorVariant={'neutralDarkBlack'}
                    fontSize={14}
                    fontWeight={700}
                  >
                    {value.label}
                  </CRGText>
                }
              >
                {Object.entries(value.acl).map(([childKey, childValue]) => {
                  return (
                    <TreeItemStyled
                      key={childKey}
                      nodeId={childKey}
                      label={
                        <CRGText
                          colorVariant={'neutralDarkBlack'}
                          fontSize={14}
                          fontWeight={500}
                        >
                          {childValue.label}
                        </CRGText>
                      }
                    />
                  );
                })}
              </TreeItemStyled>
            );
          })}
        </TreeView>
      </TreeContainer>
    </Container>
  );
};

CRGPermissionSelector.propTypes = {
  permission: PropTypes.obj,
  setPermission: PropTypes.func,
  onReset: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export { CRGPermissionSelector };
