import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CRGText } from './CRGText';

const Container = styled.div`
  display: flex;
  gap: 10px;
  background: ${(props) => props.theme.palette.main.secondary};
  padding: 6px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  align-items: center;
`;

const RemoveContainer = styled.div`
  display: flex;
`;

const CloseImage = styled.img`
  width: 8px;
  cursor: pointer;
`;

const CRGChips = ({ onRemove, text }) => {
  return (
    <Container>
      <CRGText fontSize={13} colorVariant={'neutralDarkBlack'} fontWeight={400}>
        {text}
      </CRGText>
      <div>
        {onRemove ? (
          <RemoveContainer onClick={onRemove}>
            <CloseImage src={'/images/black_close_icon.svg'} />
          </RemoveContainer>
        ) : null}
      </div>
    </Container>
  );
};

CRGChips.propTypes = {
  onRemove: PropTypes.func,
  text: PropTypes.string,
};

export { CRGChips };
