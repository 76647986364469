/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import { Controller, useWatch } from 'react-hook-form';

import { MenuItem } from '@mui/material';

import { CRGSelect, CRGText, CRGCheckBox } from 'components/atoms';

const CRGMultiselectControlled = ({
  control,
  name,
  label,
  rules,
  options,
  errors,
  customWidth,
  setValue,
  defaultValue = '',
}) => {
  // Hooks
  const valueWatch = useWatch({
    control,
    name,
  });

  // State
  const [currentError, setCurrentError] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Effect
  useEffect(() => {
    if (name && errors) {
      const errorObj = errors;
      const errorKey = Object?.keys?.(errorObj?.message)?.[0];
      const nextCurrentError = errorObj?.message?.[errorKey];
      setCurrentError(nextCurrentError);
    } else {
      setCurrentError(null);
    }
  }, [errors, name]);

  useEffect(() => {
    if (valueWatch === '') {
      setSelectedOptions([]);
    }
  }, [valueWatch]);

  // Functions
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const nextValue = typeof value === 'string' ? value.split(',') : value;

    setValue(name, nextValue);

    setSelectedOptions(nextValue);
  };

  return control ? (
    <>
      <Controller
        name={name}
        control={control}
        multiple
        defaultValue={defaultValue}
        render={({ field }) => (
          <CRGSelect
            label={label}
            customWidth={customWidth}
            {...field}
            multiple
            labelId={label}
            renderValue={(selected) => {
              return selected
                .map((s) => options.find((o) => o.value === s)?.label)
                ?.join?.(', ');
            }}
            value={selectedOptions}
            onChange={handleChange}
          >
            {options.map((o) => {
              const isSelected = selectedOptions?.indexOf?.(o.value) > -1;
              return (
                <MenuItem key={o.value} value={o.value}>
                  <CRGCheckBox checked={isSelected} />
                  <CRGText
                    fontSize={14}
                    fontWeight={isSelected ? 600 : 400}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {o.label}
                  </CRGText>
                </MenuItem>
              );
            })}
          </CRGSelect>
        )}
        rules={rules}
      />
      {currentError && (
        <CRGText fontWeight={500} fontSize={14} colorVariant={'mainError'}>
          {currentError}
        </CRGText>
      )}
    </>
  ) : null;
};

export { CRGMultiselectControlled };
