import { useEffect, useState, useContext } from 'react';

import styled from 'styled-components';

import { useMediaQuery } from '@mui/material';

import { LoadingContext, MenuContext, UserContext } from 'contexts';
import { permissions, useNotifications } from 'shared';
import { CRGMainLayout } from 'components/layouts';
import { CRGTable, CRGButton, CRGText } from 'components/atoms';
import {
  ManualNotificationListFilters,
  NotificationTime,
} from 'components/bundle';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

const ConatinerStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-top: 30px;
  padding-left: 50px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 20px;
    padding-left: 20px;
    position: relative;
  }
`;

const MobileCustomActionContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.palette.main.lightTissue};
  border-radius: 5px;
`;

const NewButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const TitleContainer = styled.div`
  margin-left: 5px;
  width: 90vw;
  margin-bottom: 10px;
`;

const FiltersContainer = styled.div`
  margin-left: 5px;
  width: ${(props) => (props.isMenuExpanded ? '79vw' : '90vw')};
  margin-bottom: 10px;
  @media screen and (max-width: 1600px) {
    width: ${(props) => (props.isMenuExpanded ? '80vw' : '85vw')};
  }
`;

const RecipientsCell = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;

const ManualNotifications = () => {
  // Hooks
  const notificationHelper = useNotifications();
  const navigate = useNavigate();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).manualNotifications;

  // Constants
  const USER_LIST_TABLE_HEADER = [
    {
      label: i18n.headers.title,
      key: 'title',
      forceColumnSize: '20vw',
      hideOrder: true,
    },
    {
      label: i18n.headers.message,
      key: 'text',
      forceColumnSize: '30vw',
      hideOrder: true,
    },
    {
      label: i18n.headers.time,
      key: 'createdAt',
      forceColumnSize: '13vw',
      hideOrder: true,
    },
    {
      label: i18n.headers.recipients,
      key: 'recipients',
      forceColumnSize: '7vw',
      hideOrder: true,
    },
  ];

  // Media Query
  const is1600down = useMediaQuery('(max-width:1600px)');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Contexts
  const { setIsLoading } = useContext(LoadingContext);
  const { isMenuExpanded } = useContext(MenuContext);
  const { user } = useContext(UserContext);

  // State
  const [notifications, setNotifications] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [pageSize] = useState(10);
  const [currentFilters, setCurrentFilters] = useState({});
  const [currentOrder, setCurrentOrder] = useState({
    order: 'ASC',
    orderBy: 'status',
  });

  //Effects
  useEffect(() => {
    fetchNotifications();
  }, [currentPage, currentFilters]);

  // Functions
  const fetchNotifications = async () => {
    setIsLoading(true);

    notificationHelper
      .listManualNotifications({
        page: currentPage,
        pageSize: pageSize,
        ...currentFilters,
      })
      .then((res) => {
        const nextTotalNotifications = res.total;
        setTotalUsers(nextTotalNotifications);

        const nextNotifications = res.notifications.map((n) => {
          return {
            ...n,
            sentDate: (
              <NotificationTime
                date={n?.sentDate}
                textStyle={{
                  fontSize: 14,
                  fontWeight: 400,
                  colorVariant: 'neutralDarkBlack',
                }}
              />
            ),
            recipients: (
              <RecipientsCell>
                <CRGText
                  fontSize={14}
                  fontWeight={500}
                  colorVariant={'neutralDarkBlack'}
                >
                  {n?.usersCount}
                </CRGText>
                <CRGButton
                  customWidth="30px"
                  customHeight="30px"
                  customFontSize={'14px'}
                  colorVariant={'mainSecondary'}
                  onClick={() => onClickDetail(n)}
                >
                  {i18n.viewButtonLabel}
                </CRGButton>
              </RecipientsCell>
            ),
            createdAt: (
              <CRGText
                fontSize={14}
                fontWeight={500}
                colorVariant={'neutralDarkBlack'}
              >
                {n?.createdAt
                  ? dayjs(n?.createdAt).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </CRGText>
            ),
          };
        });
        setNotifications(nextNotifications);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const onNewMessageClick = () => {
    navigate(`/manual-notifications/new`);
  };

  const onPaginationPageChange = (e, page) => {
    setCurrentPage(page - 1);
  };

  const handleOrderChange = (key) => {
    const isNewOrder = currentOrder.orderBy !== key;
    const nextCurrentOrder = {
      order: isNewOrder
        ? 'DESC'
        : currentOrder.order === 'DESC'
        ? 'ASC'
        : 'DESC',
      orderBy: key,
    };
    setCurrentOrder(nextCurrentOrder);
  };

  const onClickDetail = (item) => {
    navigate(`/manual-notifications/detail/${item.id}`);
  };

  const onFilterChange = (data) => {
    const nextData = {
      ...data,
      fromDate: data?.fromDate ? dayjs(data?.fromDate).toDate().getTime() : '',
      toDate: data?.toDate ? dayjs(data?.toDate).toDate().getTime() : '',
    };

    const nextCurrentFilters = {
      ...currentFilters,
      ...nextData,
    };
    setCurrentFilters(nextCurrentFilters);
  };

  return (
    <CRGMainLayout>
      <ConatinerStyle>
        <TitleContainer>
          <CRGText
            fontSize={23}
            fontWeight={600}
            colorVariant={'neutralDarkBlack'}
          >
            {i18n.title}
          </CRGText>
        </TitleContainer>
        {isMobile ? null : (
          <FiltersContainer isMenuExpanded={isMenuExpanded}>
            <ManualNotificationListFilters
              handleFilterChange={onFilterChange}
            />
          </FiltersContainer>
        )}
        <CRGTable
          data={notifications}
          headers={USER_LIST_TABLE_HEADER}
          title={i18n.notificationListLabel}
          maxColumnSize={
            isMobile
              ? '200px'
              : is1600down
              ? isMenuExpanded
                ? '21vw'
                : '22vw'
              : isMenuExpanded
              ? '22vw'
              : '23vw'
          }
          currentOrder={currentOrder}
          handleOrderChange={handleOrderChange}
          customActions={
            user?.cariaggiUser?.permissions
              ?.split('|')
              ?.includes(permissions?.SEND_MANUAL_NOTIFICATION?.toString()) ? (
              isMobile ? (
                <MobileCustomActionContainer>
                  <CRGText
                    fontSize={14}
                    fontWeight={700}
                    colorVariant={'neutralDarkBlack'}
                  >
                    {i18n.newMessage}
                  </CRGText>

                  <CRGButton
                    isSmall
                    onClick={onNewMessageClick}
                    colorVariant={'mainSecondary'}
                  >
                    <img
                      width={'12px'}
                      height={'12px'}
                      src={'images/white_plus.svg'}
                    ></img>
                  </CRGButton>
                </MobileCustomActionContainer>
              ) : (
                <CRGButton
                  onClick={onNewMessageClick}
                  colorVariant={'mainSecondary'}
                  customWidth={'200px'}
                >
                  <NewButtonContainer>
                    <img src={'/images/plus-black.svg'} />
                    {i18n.newMessage}
                  </NewButtonContainer>
                </CRGButton>
              )
            ) : null
          }
          pagination={{
            count: Math.ceil(totalUsers / pageSize),
            onChange: onPaginationPageChange,
          }}
        />
      </ConatinerStyle>
    </CRGMainLayout>
  );
};

export { ManualNotifications };
