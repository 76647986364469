import useFetch from 'use-http';
import { Auth } from 'aws-amplify';

import { BACKEND_URL } from '../utils/constants';

const authUrls = ['/article'];

const useArticles = () => {
  const backendHelper = useFetch(BACKEND_URL, {
    onError: ({ error }) => {
      throw error;
    },
    interceptors: {
      request: async ({ options, route }) => {
        const authenticatedUrl = authUrls.find((p) => route?.indexOf(p) !== -1);

        if (authenticatedUrl) {
          try {
            const data = await Auth.currentSession();
            const jwt = data?.getAccessToken().getJwtToken();
            options.headers.Authorization = `Bearer ${jwt}`;
          } catch (error) {
            console.log(
              '🚀 ~ file: useArticles.js:23 ~ request: ~ error:',
              error
            );
          }
        }
        return options;
      },
      response: ({ response }) => {
        if (!response.ok) throw new Error(JSON.stringify(response.data));
        return response;
      },
    },
  });

  const list = (params) =>
    new Promise((resolve, reject) => {
      const queryParams = new URLSearchParams(params);

      backendHelper
        .get(`/article?${queryParams}`)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log('🔴 error get articles', e);
          reject(e);
        });
    });

  return {
    list,
  };
};

export { useArticles };
