import React, { createContext, useState } from 'react';

import styled from 'styled-components';

import PropTypes from 'prop-types';

import { Backdrop, CircularProgress } from '@mui/material';

const BackdropStyle = styled(Backdrop)`
  z-index: 1501;
  color: ${(props) => props.theme.palette.neutral.light.white};
`;

export const LoadingContext = createContext();
const LoadingContextProvider = ({ children }) => {
  // State
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
      <BackdropStyle open={isLoading}>
        <CircularProgress color="inherit" />
      </BackdropStyle>
    </LoadingContext.Provider>
  );
};

LoadingContextProvider.propTypes = {
  children: PropTypes.node,
};

export default LoadingContextProvider;
