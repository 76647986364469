import { LoadingContext } from 'contexts';
import { useState, useEffect, useContext } from 'react';
import { orderType, useOrders } from 'shared';
// import mockOrders from '../mock_orders.json';
import {
  checkIfIncludes,
  checkIfIdEquals,
  checkIfDateIsBetween,
  rangeDateToJulFormat,
  checkIfIsAfter,
  checkIfIsBefore,
} from 'utils/utils';

const filterFunctions = {
  VS_ORD: (order, filterValue) => checkIfIncludes(order.VS_ORD, filterValue),
  DATA_VS_ORD: (order, filterValue) => {
    if (filterValue[0] !== null && filterValue[1] !== null) {
      return checkIfDateIsBetween(order.DATA_VS_ORD, filterValue);
    }
    return true;
  },
  COD_CONFERMA_ORDINE: (order, filterValue) =>
    checkIfIdEquals(order.COD_CONFERMA_ORDINE, filterValue),
  COD_TIPO_ORDINE: (order, filterValue) =>
    checkIfIdEquals(order.COD_TIPO_ORDINE, filterValue),
  COD_AVANZAMENTO_ORDINE: (order, filterValue) =>
    checkIfIdEquals(order.COD_AVANZAMENTO_ORDINE, filterValue),
  COD_STATO_ORDINE: (order, filterValue) =>
    checkIfIdEquals(order.COD_STATO_ORDINE, filterValue),
  COD_CLIENTE: (order, filterValue) =>
    checkIfIdEquals(order.COD_CLIENTE, filterValue),
  CODICE_MATERIALE_CARIAGGI: (order, filterValue) =>
    checkIfIncludes(order.CODICE_MATERIALE_CARIAGGI, filterValue),
  CODICE_MATERIALE_CLIENTE: (order, filterValue) =>
    checkIfIncludes(order.CODICE_MATERIALE_CLIENTE, filterValue),
  DATA_CONSEGNA: (order, filterValue) => {
    if (filterValue === '0') return order.DATA_CONSEGNA == filterValue;
    if (filterValue[0] !== null && filterValue[1] !== null) {
      return checkIfDateIsBetween(order.DATA_CONSEGNA, filterValue);
    }
    return true;
  },
  RAGIONE_SOCIALE_CLIENTE_FINALE: (order, filterValue) =>
    checkIfIncludes(order.RAGIONE_SOCIALE_CLIENTE_FINALE, filterValue),
  INDIRIZZO_DESTINAZIONE: (order, filterValue) =>
    checkIfIncludes(order.INDIRIZZO_DESTINAZIONE, filterValue),
  STAGIONE_CLIENTE: (order, filterValue) =>
    checkIfIncludes(order.STAGIONE_CLIENTE, filterValue),
  DESCR_VS_LINEA: (order, filterValue) =>
    checkIfIncludes(order.DESCR_VS_LINEA, filterValue),
  DESCR_VS_BOARD: (order, filterValue) =>
    checkIfIncludes(order.DESCR_VS_BOARD, filterValue),
  MONETA: (order, filterValue) => checkIfIncludes(order.MONETA, filterValue),
  PAGAMENTO: (order, filterValue) =>
    checkIfIncludes(order.PAGAMENTO, filterValue),
};

const formattedFilters = (filters) => {
  if (!filters) return {};
  const { DATA_VS_ORD, DATA_CONSEGNA } = filters;
  return {
    VS_ORD: filters?.VS_ORD?.toUpperCase(),
    COD_CONFERMA_ORDINE: filters?.COD_CONFERMA_ORDINE,
    COD_TIPO_ORDINE: filters?.COD_TIPO_ORDINE,
    COD_AVANZAMENTO_ORDINE: filters?.COD_AVANZAMENTO_ORDINE,
    COD_STATO_ORDINE: filters?.COD_STATO_ORDINE,
    COD_CLIENTE: filters?.COD_CLIENTE,
    CODICE_MATERIALE_CARIAGGI:
      filters?.CODICE_MATERIALE_CARIAGGI?.toUpperCase(),
    CODICE_MATERIALE_CLIENTE: filters?.CODICE_MATERIALE_CLIENTE?.toUpperCase(),
    RAGIONE_SOCIALE_CLIENTE_FINALE:
      filters?.RAGIONE_SOCIALE_CLIENTE_FINALE?.toUpperCase(),
    INDIRIZZO_DESTINAZIONE: filters?.INDIRIZZO_DESTINAZIONE?.toUpperCase(),
    STAGIONE_CLIENTE: filters?.STAGIONE_CLIENTE?.toUpperCase(),
    DESCR_VS_LINEA: filters?.DESCR_VS_LINEA?.toUpperCase(),
    DESCR_VS_BOARD: filters?.DESCR_VS_BOARD?.toUpperCase(),
    MONETA: filters?.MONETA?.toUpperCase(),
    PAGAMENTO: filters?.PAGAMENTO?.toUpperCase(),
    DATA_VS_ORD_JUL: rangeDateToJulFormat(DATA_VS_ORD),
    DATA_CONSEGNA_JUL:
      DATA_CONSEGNA === '0'
        ? DATA_CONSEGNA
        : rangeDateToJulFormat(DATA_CONSEGNA),
  };
};

const structuredResult = (orders) => {
  const filatiOrders = orders.filter(
    (order) => order.COD_TIPO_ORDINE === orderType.Yarn
  );
  const campionarioOrders = orders.filter(
    (order) => order.COD_TIPO_ORDINE === orderType.Sampling
  );
  const labdipOrders = orders.filter(
    (order) => order.COD_TIPO_ORDINE === orderType.Labdip
  );

  return { filatiOrders, campionarioOrders, labdipOrders };
};

const useOrdersFilter = ({ filters, orderByObj, isFromHistory = false }) => {
  const { setIsLoading } = useContext(LoadingContext);

  const { get: getOrders, exportData } = useOrders();

  const [orders, setOrders] = useState([]);
  const [ordersFilterd, setOrdersFiltered] = useState({
    filatiOrders: [],
    campionarioOrders: [],
    labdipOrders: [],
  });

  const filterBy = (data) => {
    if (filters.fetchData) {
      filters.fetchData = false;
      getOrdersData(filters.GIORNI);
      return;
    }
    let newData = [...data];
    for (const [filterType, filterValue] of Object.entries(filters)) {
      const filterFunction = filterFunctions[filterType];
      if (filterFunction && filterValue !== '' && filterValue != null) {
        newData = newData.filter((order) => filterFunction(order, filterValue));
      }
    }
    orderBy(newData);
  };

  const orderBy = (data) => {
    const newData = [...data];
    if (!orderByObj.field) {
      setOrdersFiltered(structuredResult(newData));
      return;
    }
    newData.sort((a, b) => {
      if (
        orderByObj.field === 'DATA_VS_ORD' ||
        orderByObj.field === 'DATA_CONSEGNA'
      ) {
        if (a[orderByObj.field] === '0')
          return orderByObj.direction === 'ASC' ? -1 : 1;
        if (b[orderByObj.field] === '0')
          return orderByObj.direction === 'ASC' ? 1 : -1;
        if (checkIfIsAfter(a[orderByObj.field], b[orderByObj.field])) {
          return orderByObj.direction === 'ASC' ? 1 : -1;
        }
        if (checkIfIsBefore(a[orderByObj.field], b[orderByObj.field])) {
          return orderByObj.direction === 'ASC' ? -1 : 1;
        }
        return 0;
      }
      if (a[orderByObj.field] > b[orderByObj.field]) {
        return orderByObj.direction === 'ASC' ? 1 : -1;
      }
      if (a[orderByObj.field] < b[orderByObj.field]) {
        return orderByObj.direction === 'ASC' ? -1 : 1;
      }
      return 0;
    });
    setOrdersFiltered(structuredResult(newData));
  };

  const getOrdersData = async () => {
    try {
      setIsLoading(true);
      const res = await getOrders(isFromHistory, filters.GIORNI);
      // const res = mockOrders;
      setOrders(res);
      filterBy(res);
    } catch (error) {
      console.log(
        '🚀 ~ file: useOrdersFilter.js:27 ~ getOrdersData ~ error:',
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getExcelData = async () => {
    try {
      setIsLoading(true);

      const filtersFormatted = formattedFilters(filters);

      const data = await exportData(isFromHistory, {
        ...filtersFormatted,
        ...(isFromHistory && { GIORNI: filters.GIORNI }),
      });
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`;
      const link = document.createElement('a');
      link.href = linkSource;
      link.setAttribute('download', `Orders_${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(
        '🚀 ~ file: useOrdersFilter.js:62 ~ getExcelData ~ error:',
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (filters && orders.length !== 0) {
      filterBy(orders);
    }
  }, [filters]);

  useEffect(() => {
    orderBy([
      ...ordersFilterd.filatiOrders,
      ...ordersFilterd.campionarioOrders,
      ...ordersFilterd.labdipOrders,
    ]);
  }, [orderByObj.direction, orderByObj.field]);

  return {
    orders: ordersFilterd,
    filterBy,
    getOrdersData,
    getExcelData,
    orderBy,
  };
};

export { useOrdersFilter };
