import { useEffect } from 'react';

import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { CRGText, CRGButton, CRGTextFieldControlled } from 'components/atoms';
import { PasswordPolicyList } from 'components/bundle';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';

const FormRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 360px) {
    margin-left: 30px;
  }
`;

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 360px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ForceUpdatePasswordForm = ({ cognitoUser, onSubmit }) => {
  // Hooks
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
      confirmPassword: '',
    },
  });

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).forceUpdatePasswordForm;

  // Effects
  useEffect(() => {
    if (cognitoUser) {
      setValue('username', cognitoUser?.username);
    }
  }, [cognitoUser]);

  // Functions
  const submitForm = (data) => {
    const { password, confirmPassword } = data;
    if (password === confirmPassword) {
      onSubmit?.(data);
    } else {
      setError('confirmPassword', {
        type: 'manual',
        message: { mailNotMatch: i18n.form.errors.passwordNotMatching },
      });
    }
  };

  return (
    <>
      <TopTextContainer>
        <CRGText fontWeight={800} fontSize={30}>
          {i18n.text1}
        </CRGText>
        <CRGText fontWeight={300} fontSize={13}>
          {i18n.text2}
        </CRGText>
      </TopTextContainer>
      <FormStyle onSubmit={handleSubmit(submitForm)}>
        {/* 
          Username Text Field
        */}
        <FormRowContainer>
          <CRGTextFieldControlled
            label={i18n.form.username}
            name="username"
            control={control}
            disabled
          />
        </FormRowContainer>

        {/* 
          Username Text Field
        */}
        <FormRowContainer>
          <CRGTextFieldControlled
            label={i18n.form.password}
            name="password"
            control={control}
            fieldType={'password'}
            rules={{
              required: { value: true, message: i18n.form.errors.password },
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-"!@#%&/,><\\':;|_~`])\S{8,99}$/,
                message: i18n.form.errors.passwordFormat,
              },
            }}
            errors={errors?.['password']}
          />
        </FormRowContainer>

        {/* 
          Username Text Field
        */}
        <FormRowContainer>
          <CRGTextFieldControlled
            label={i18n.form.confirmPassword}
            name="confirmPassword"
            control={control}
            fieldType={'password'}
            rules={{
              required: {
                value: true,
                message: i18n.form.errors.confirmPassword,
              },
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-"!@#%&/,><\\':;|_~`])\S{8,99}$/,
                message: i18n.form.errors.passwordFormat,
              },
            }}
            errors={errors?.['confirmPassword']}
          />
        </FormRowContainer>

        <PasswordPolicyList />

        {/* 
          Submit Button
        */}
        <CRGButton type="submit">{i18n.buttonLabel}</CRGButton>
      </FormStyle>
    </>
  );
};

ForceUpdatePasswordForm.propTypes = {
  cognitoUser: PropTypes.object,
  onSubmit: PropTypes.func,
};

export { ForceUpdatePasswordForm };
