import { useContext, useEffect, useState } from 'react';

import styled from 'styled-components';

import { CRGMainLayout } from 'components/layouts';
import { CRGInfoButton, CRGText, CRGWidget } from 'components/atoms';

import { useWidgets, permissions, orderType } from 'shared';
import { useNavigate } from 'react-router-dom';

import { useCustomIntl, useInfoDocument } from 'hooks';

import translation from 'utils/translation';
import { MenuContext, UserContext } from 'contexts';

const ContainerStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-top: 30px;
  padding-left: 50px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
  }
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WidgetsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
  margin-bottom: 50px;
`;

const Home = () => {
  // Hooks
  const widgetHelper = useWidgets();
  const navigate = useNavigate();
  const { HOME_PDF_URL } = useInfoDocument();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.pages(intl).home;

  // Context
  const { setCurrentItemSelected } = useContext(MenuContext);
  const { user } = useContext(UserContext);

  const userPermissionsArray =
    user?.cariaggiUser?.permissions?.split('|') || [];
  const userPreferencesArray =
    user?.cariaggiUser?.permissionsPrefs?.split('|') || [];
  // State
  const [usersWidgetData, setUsersWidgetData] = useState();
  const [stocksWidgetData, setStocksWidgetData] = useState();
  const [ordersWidgetData, setOrdersWidgetData] = useState([]);

  const ordersFilati = userPreferencesArray?.includes(
    permissions.WIDGET_ORDERS_FILATI.toString()
  )
    ? (ordersWidgetData || []).find(
        (item) => item?.COD_TIPO_ORDINE === orderType.Yarn
      )
    : null;
  const ordersCampionario = userPreferencesArray?.includes(
    permissions.WIDGET_ORDERS_CAMPIONARIO.toString()
  )
    ? (ordersWidgetData || []).find(
        (item) => item?.COD_TIPO_ORDINE === orderType.Sampling
      )
    : null;
  const ordersLabdip = userPreferencesArray?.includes(
    permissions.WIDGET_ORDERS_LABDIP.toString()
  )
    ? (ordersWidgetData || []).find(
        (item) => item?.COD_TIPO_ORDINE === orderType.Labdip
      )
    : null;

  const [isStockViewAllVisible, hideStockViewAll] = useState(false);
  const [isUsersViewAllVisible, hideUsersViewAll] = useState(false);
  const [isOrdersViewAllVisible, hideOrdersViewAll] = useState(false);

  // Constants
  const userWidget = {
    title: i18n.usersWidget.title,
    bottomElement: (
      <CRGText
        colorVariant={'neutralDarkBlack'}
        fontSize={14}
        fontWeight={600}
        textDecoration={'underline'}
        cursor={'pointer'}
        onClick={() => {
          setCurrentItemSelected(1);
          navigate('/users');
        }}
      >
        {i18n.usersWidget.bottomText?.toUpperCase?.()}
      </CRGText>
    ),
    cell1: {
      label: i18n.usersWidget.cell1Label,
      icon: (
        <img
          height={56}
          width={56}
          src="/images/home/user_widget/users_cell1_icon.svg"
        />
      ),
    },
    cell2: {
      label: i18n.usersWidget.cell2Label,
      icon: (
        <img
          height={56}
          width={56}
          src="/images/home/user_widget/users_cell2_icon.svg"
        />
      ),
    },
    cell3: {
      label: i18n.usersWidget.cell3Label,
      icon: (
        <img
          height={56}
          width={56}
          src="/images/home/user_widget/users_cell3_icon.svg"
        />
      ),
    },
  };

  const stockWidget = {
    title: i18n.stockWidget.title,
    bottomElement: (
      <CRGText
        colorVariant={'neutralDarkBlack'}
        fontSize={14}
        fontWeight={600}
        textDecoration={'underline'}
        cursor={'pointer'}
        onClick={() => {
          setCurrentItemSelected(2);
          navigate('/stocklist');
        }}
      >
        {i18n.stockWidget.bottomText?.toUpperCase?.()}
      </CRGText>
    ),
    cell1: {
      label: i18n.stockWidget.cell1Label,
      icon: (
        <img
          height={56}
          width={56}
          src="/images/home/stocklist_widget/stock_cell1_icon.svg"
        />
      ),
    },
    cell2: {
      label: i18n.stockWidget.cell2Label,
      icon: (
        <img
          height={56}
          width={56}
          src="/images/home/stocklist_widget/stock_cell2_icon.svg"
        />
      ),
    },
    cell3: {
      label: i18n.stockWidget.cell3Label,
      icon: (
        <img
          height={56}
          width={56}
          src="/images/home/stocklist_widget/stock_cell3_icon.svg"
        />
      ),
    },
  };

  const ordersWidget = {
    bottomElement: (
      <CRGText
        colorVariant={'neutralDarkBlack'}
        fontSize={14}
        fontWeight={600}
        textDecoration={'underline'}
        cursor={'pointer'}
        onClick={() => {
          setCurrentItemSelected(6);
          navigate('/orders');
        }}
      >
        {i18n.ordersWidget.bottomText?.toUpperCase?.()}
      </CRGText>
    ),
    cell1: {
      label: i18n.ordersWidget.cell1Label,
      icon: (
        <img
          height={56}
          width={56}
          src="/images/home/orders_widget/orders_cell1_icon.svg"
        />
      ),
    },
    cell2: {
      label: i18n.ordersWidget.cell2Label,
      icon: (
        <img
          height={56}
          width={56}
          src="/images/home/orders_widget/orders_cell2_icon.svg"
        />
      ),
    },
    cell3: {
      label: i18n.ordersWidget.cell3Label,
      icon: (
        <img
          height={56}
          width={56}
          src="/images/home/orders_widget/orders_cell3_icon.svg"
        />
      ),
    },
  };

  // Effects
  useEffect(() => {
    if (user) {
      if (
        (user?.cariaggiUser?.type === 3 || user?.cariaggiUser?.type === 0) &&
        userPreferencesArray.includes(permissions.WIDGET_USERS.toString())
      )
        fetchWidgets('users', setUsersWidgetData);

      if (
        userPreferencesArray.includes(permissions.WIDGET_STOCKLIST.toString())
      )
        fetchWidgets('stocks', setStocksWidgetData);

      if (
        userPreferencesArray?.includes(
          permissions.WIDGET_ORDERS_CAMPIONARIO.toString()
        ) ||
        userPreferencesArray?.includes(
          permissions.WIDGET_ORDERS_LABDIP.toString()
        ) ||
        userPreferencesArray?.includes(
          permissions.WIDGET_ORDERS_FILATI.toString()
        )
      )
        fetchWidgets('orders', setOrdersWidgetData);

      if (
        userPermissionsArray.includes(permissions.STOCKLIST_READ.toString())
      ) {
        hideStockViewAll(true);
      }

      if (userPermissionsArray.includes(permissions.USER_READ.toString())) {
        hideUsersViewAll(true);
      }

      if (userPermissionsArray.includes(permissions.ORDERS_READ.toString())) {
        hideOrdersViewAll(true);
      }
    }
  }, [user]);

  // Functions
  const fetchWidgets = (entity, setter) => {
    widgetHelper
      .get(entity)
      .then((res) => {
        setter(res);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <CRGMainLayout>
      <ContainerStyle>
        <TopContainer>
          <CRGText
            colorVariant={'neutralDarkBlack'}
            fontSize={23}
            fontWeight={600}
          >
            {i18n.title}
          </CRGText>
          <CRGInfoButton infoUrl={HOME_PDF_URL} />
        </TopContainer>
        <WidgetsContainer>
          {stocksWidgetData ? (
            <CRGWidget
              viewAll={isStockViewAllVisible}
              data={{
                ...stockWidget,
                cell1Data: stocksWidgetData?.available,
                cell2Data: stocksWidgetData?.processing,
                cell3Data: stocksWidgetData?.sent,
              }}
              colorVariant={'stocklist'}
              onClickClose={() => setStocksWidgetData(null)}
            />
          ) : null}
          {usersWidgetData ? (
            <CRGWidget
              viewAll={isUsersViewAllVisible}
              data={{
                ...userWidget,
                cell1Data: usersWidgetData?.toApprove,
                cell2Data: usersWidgetData?.total,
                cell3Data: usersWidgetData?.disabled,
              }}
              colorVariant={'users'}
              onClickClose={() => setUsersWidgetData(null)}
            />
          ) : null}
          {ordersFilati ? (
            <CRGWidget
              viewAll={isOrdersViewAllVisible}
              data={{
                ...ordersWidget,
                title: i18n.ordersWidget.filati,
                cell1Data: ordersFilati?.ORD_CL_TOT ?? 0,
                cell2Data: ordersFilati?.ORD_CL_CONF ?? 0,
                cell3Data: ordersFilati?.ORD_CL_DACONF ?? 0,
              }}
              colorVariant={'orders'}
              onClickClose={() => setOrdersWidgetData(null)}
            />
          ) : null}
          {ordersLabdip ? (
            <CRGWidget
              viewAll={isOrdersViewAllVisible}
              data={{
                ...ordersWidget,
                title: i18n.ordersWidget.labdip,
                cell1Data: ordersLabdip?.ORD_CL_TOT ?? 0,
                cell2Data: ordersLabdip?.ORD_CL_CONF ?? 0,
                cell3Data: ordersLabdip?.ORD_CL_DACONF ?? 0,
              }}
              colorVariant={'orders'}
              onClickClose={() => setOrdersWidgetData(null)}
            />
          ) : null}
          {ordersCampionario ? (
            <CRGWidget
              viewAll={isOrdersViewAllVisible}
              data={{
                ...ordersWidget,
                title: i18n.ordersWidget.campionario,
                cell1Data: ordersCampionario?.ORD_CL_TOT ?? 0,
                cell2Data: ordersCampionario?.ORD_CL_CONF ?? 0,
                cell3Data: ordersCampionario?.ORD_CL_DACONF ?? 0,
              }}
              colorVariant={'orders'}
              onClickClose={() => setOrdersWidgetData(null)}
            />
          ) : null}
        </WidgetsContainer>
      </ContainerStyle>
    </CRGMainLayout>
  );
};

export { Home };
