import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import { useCustomIntl } from 'hooks/useCustomIntl';
import translation from 'utils/translation';
import { CRGButton, CRGText } from 'components/atoms';

const SignInCompleteContaienrStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LogoImgStyle = styled.img`
  filter: invert(1);
  width: 210px;
  height: 150px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 127px;
    height: 90px;
  }
`;

const TextContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 120px;
  @media screen and (max-width: 360px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const RedBulletStyle = styled.img`
  width: 90px;
  height: 90px;
  margin-top: 50px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 60px;
    height: 60px;
  }
`;

const SignInComplete = () => {
  // Hooks
  const navigate = useNavigate();

  // i18n
  const intl = useCustomIntl();
  const i18n = translation.components(intl).signInComplete;

  // Media Query
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Functions
  const handleClose = () => navigate('/login');

  return (
    <SignInCompleteContaienrStyle>
      <LogoImgStyle src={'images/logo.svg'} />
      <RedBulletStyle src={'images/red_bullet.svg'} />
      <TextContainerStyle>
        <CRGText
          fontSize={isMobile ? 20 : 30}
          fontWeight={700}
          textAlign={'center'}
        >
          {i18n.text1}
        </CRGText>
        <CRGText
          fontSize={isMobile ? 12 : 14}
          fontWeight={600}
          textAlign={'center'}
        >
          {i18n.text2}
        </CRGText>
        <CRGText
          fontSize={isMobile ? 12 : 14}
          fontWeight={400}
          textAlign={'center'}
        >
          {i18n.text3}
        </CRGText>
      </TextContainerStyle>

      <CRGButton colorVariant="mainSecondary" onClick={handleClose}>
        {i18n.buttonLabel}
      </CRGButton>
    </SignInCompleteContaienrStyle>
  );
};

export { SignInComplete };
